import { HasPermission, IsStudent } from "../../../helper/helperFunction";
import { Link } from "react-router-dom";
import FilterBase from "../../../components/filterJob/filter-base";
import { Box, Select, MenuItem, Grid, Typography, Button } from "@mui/material";
import CSVDownloadButton from "../../../components/downloadCSV";
import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { JobCards } from "./job-cards";
import Pagination from "@mui/material/Pagination";
import AdminApi from "../../../config/apis/admin";
import { api } from "../../../config/apis";
import { DataLoader } from "../../../helper/helperFunction";
import JobSingleCard from "./job-single-card";

export const Jobs = () => {
  const userIsStudent = IsStudent();

  const [filters, setFilters] = useState();
  const [currentValue, setCurrentValue] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [meta, setMeta] = useState(null);

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  const [jobData, setJobData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const ApiUrl = userIsStudent
          ? AdminApi.JOBS_LIST_STUDENT
          : AdminApi.JOBS_LIST;
        const res = await api.get(ApiUrl, {
          params: {
            sort_order: currentValue,
            filters: filters,
            page: currentPage,
            limit: 10,
          },
        });

        setJobData(res.data.data);
        setTotalPages(res.data.meta.totalPages);
        setMeta(res.data.meta);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentValue, filters, currentPage]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Box className="container">
        <Box className="course-content">
          <h1 className="main-heading">Job Board</h1>

          <HasPermission permission="manage-jobs-board.create">
            <Box className="btn-wrapper">
              <Link to={"edit"}>Add a Job</Link>
            </Box>
          </HasPermission>
        </Box>
        <Box className={styles.display_left}>
          <FilterBase onFiltersChange={handleFiltersChange} />
          <Box className={styles.display_right}>
            <Box marginRight={2}>
              {!userIsStudent && (
                <CSVDownloadButton
                  apiEndpoint={AdminApi.JOBS_LIST}
                  fileName={"Jobs"}
                  params={currentValue}
                />
              )}
            </Box>
            <Box>
              <Select
                value={currentValue}
                onChange={handleChange}
                className="select-menu"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
        {!userIsStudent && <JobCards />}

        <Grid container marginTop={2} spacing={2}>
          {loading ? (
            <DataLoader />
          ) : (
            jobData &&
            jobData.map((item, index) => {
              return <JobSingleCard item={item} key={index} />;
            })
          )}
        </Grid>

        <Grid
          container
          marginTop={2}
          spacing={2}
          justifyContent={"space-between"}
        >
          <Grid item xs={4}>
            <span className={"count"}>
              {`${
                (meta?.currentPage - 1) * meta?.itemsPerPage + 1
              } - ${Math.min(
                meta?.currentPage * meta?.itemsPerPage,
                meta?.totalItems
              )} of ${meta?.totalItems}`}
            </span>
          </Grid>
          <Grid item xs={8} container justifyContent={"flex-end"}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              className={styles.pagination_wrap}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
