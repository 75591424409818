import CSVDownloadButton from "../../../components/downloadCSV";
import { HasPermission } from "../../../helper/helperFunction";
import { Link } from "react-router-dom";
import FilterBase from "../../../components/filterJob/filter-base1";
import { Box, Select, MenuItem, Grid } from "@mui/material";
import styles from "./style.module.css";
import { useState } from "react";
import { StudentJobRecordsTable } from "./view-table";
import AdminApi from "../../../config/apis/admin";

export const StudentJobRecords = () => {
  const [filters, setFilters] = useState();
  const [currentValue, setCurrentValue] = useState("desc");

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  return (
    <>
      <Box className="container">
        <Box className="course-content">
          <h1 className="main-heading">Job Records </h1>
        </Box>
        <Box className={styles.display_left}>
          <FilterBase onFiltersChange={handleFiltersChange} name={"Name"} />
          <Box className={styles.display_right}>
            <Box marginRight={2}>
              <CSVDownloadButton
                apiEndpoint={AdminApi.STUDENT_JOB_RECORDS_LIST}
                fileName={"Student job records"}
                params={currentValue}
              />
            </Box>
            <Box>
              <Select
                value={currentValue}
                onChange={handleChange}
                className="select-menu"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>

        <StudentJobRecordsTable filters={filters} currentValue={currentValue} />
        <Grid container marginTop={5} justifyContent={"flex-end"}>
          <Box className="course-content">
            <HasPermission permission="manage-job-record.create">
              <Box className="btn-wrapper">
                <Link to={"edit"}>Add a Job Records</Link>
              </Box>
            </HasPermission>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
