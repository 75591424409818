import { useState } from "react";
import { formatLabel } from "../../../../../../helper/helperFunction";

const useTypeText = ({ label, updateCourseState }) => {
  const [customValue, setCustomValue] = useState("");
  const modifiedLabel = label && formatLabel(label);

  const handleCustomInputChange = (event) => {
    setCustomValue(event.target.value);
  };

  const handleCustomInputBlur = () => {
    if (customValue !== "") {
      updateCourseState({
        [modifiedLabel]: customValue,
      });
    }
  };

  const handleDeleteChip = () => {
    setCustomValue("");
    updateCourseState({
      [modifiedLabel]: "yes",
    });
  };

  return {
    handleCustomInputChange,
    handleCustomInputBlur,
    handleDeleteChip,
    customValue,
  };
};

export default useTypeText;
