import { Typography } from "@mui/material";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, resolveUrl } from "../../../config/apis";
import Moment from "react-moment";
import { EditOrgFunds } from "./edit";

export const OrgFundGrid = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const tableColumns = [
    {
      cell: (row) => (
        <>
          <Typography variant="body1">#{row.id} </Typography>
        </>
      ),
      name: "ID",
      sortable: true,
      minWidth: "120px",
      selector: "id",
    },

    {
      cell: (row) => (
        <Typography variant="body1">
          <Moment format="MMM DD, YYYY">{row.datetime}</Moment>
        </Typography>
      ),
      name: "Transaction Date",
      minWidth: "200px",
      sortable: true,
      selector: "datetime",
    },

    {
      cell: (row) => (
        <Typography variant="body1" fontWeight={"700"}>
          {row.amount} {row.currency_symbol}
        </Typography>
      ),
      name: "Funded Amount",
      sortable: true,
      minWidth: "200px",
      selector: "amount",
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/organization/view/${id}`);
  };

  const [reRender, setReRender] = useState(false);
  const [viewId, setViewId] = useState("");
  const [editData, setEditData] = useState({});

  const onDelete = async (id) => {
    props.setFormSuccess((prevReRender) => !prevReRender);
    return await api.delete(resolveUrl(AdminApi.ORGANIZATION_FUND_DELETE, id));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.get(resolveUrl(AdminApi.ORGANIZATION_EDIT, viewId));
      setEditData(res.data.data);
    };
    if (viewId) {
      fetchData();
    }
  }, [viewId]);

  useEffect(() => {
    setReRender(props.formSuccess);
    props.setModalOpen(false);
    setViewId("");
  }, [props.formSuccess]);

  useEffect(() => {
    if (props.modalOpen) {
      props.setModalOpen(props.modalOpen);
    }
  }, [props.modalOpen]);

  useEffect(() => {
    if (props.setModalOpen) {
      if (!props.modalOpen) {
        props.setModalOpen(false);
        setViewId("");
      }
    }
  }, [props.modalOpen]);

  const onEdit = async (id) => {
    props.setModalOpen(true);
    setViewId(id);
  };

  return (
    <>
      <div className="page-container table-phases" style={{ width: "100%" }}>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(
            AdminApi.ORGANIZATION_FUNDS_PAGINATION,
            params.id
          )}
          onView={onView}
          onDelete={onDelete}
          onEdit={onEdit}
          filters={props.filters ?? ""}
          sortOrder={props.currentValue ?? "asc"}
          actions={{
            view: false,
            delete: true,
          }}
          module="manage-organization-transactions"
          refreshHandle={reRender}
        />
      </div>
      <EditOrgFunds
        modalOpen={props.modalOpen}
        handleCloseModal={props.handleCloseModal}
        setModalOpen={props.setModalOpen}
        editId={params.id ?? ""}
        viewId={viewId ?? ""}
        editData={editData}
        setFormSuccess={props.setFormSuccess}
      />
    </>
  );
};
