import React, { useEffect, useState } from "react";
import styles from "./filter.module.css";
import { Box, Button, TextField, Typography, Grid } from "@mui/material";
import SelectOptionsApi from "../../../../components/SelectApi";
import AdminApi from "../../../../config/apis/admin";
import { useFilterContext } from "../../../../context/filter-context";
import SelectCities from "../../../../components/SearchableSelect";

const FilterBase = ({ onFiltersChange, currentFilterData }) => {
  const [filterData, setFilterData] = useState(currentFilterData ?? {});
  const handleUpdate = (name, value) => {
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleApplyFilter = () => {
    onFiltersChange(filterData);
  };

  return (
    <Box className={styles.baseFilterMain}>
      <form className={styles.studentTopFilterMain}>
        <Typography
          variant="body1"
          className={styles.studentTopFilterMainTitle}
        >
          Search By
        </Typography>
        <Box className={styles.studentTopFilterKeyword}>
          {/* ID Search */}
          <TextField
            id="id"
            variant="outlined"
            fullWidth
            type="number"
            placeholder="ID"
            className={styles.studentTopFilterKeywordInput}
            value={filterData.id}
            onChange={(event) => handleUpdate("id", event.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleApplyFilter();
              }
            }}
          />
          {/* Place Search */}
          <Grid item xs={12} sx={{ paddingRight: "10px" }}>
            <SelectOptionsApi
              apiEndpoint={AdminApi.CITIES}
              formData={filterData}
              onUpdate={handleUpdate}
              labelKey="title"
              name="place"
              valueKey="id"
              errorText=""
              paramId={""}
              defaultText="Select Place"
            />
            {/* <SelectCities /> */}
          </Grid>

          <span className={styles.spanor}>or</span>
          {/* Keyword Search */}
          <TextField
            id="username"
            variant="outlined"
            fullWidth
            placeholder="Keyword"
            className={styles.studentTopFilterKeywordInput}
            value={filterData.username}
            onChange={(event) => handleUpdate("username", event.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleApplyFilter();
              }
            }}
          />

          <Box className={styles.studentTopFilterBtn}>
            <Button
              className={styles.studentTopFilterBtnContent}
              onClick={() => handleApplyFilter()}
            >
              Go
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default FilterBase;
