import styles from "./style.module.css";

import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import AdminApi from "../../../../config/apis/admin";
import { api } from "../../../../config/apis";
import { DataLoader } from "../../../../helper/helperFunction";

const JobSingleCard = () => {
  const [jobData, setJobData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const res = await api.get(AdminApi.ACTIVE_JOB_LIST);

        setJobData(res.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <DataLoader />
      ) : (
        jobData &&
        jobData.map((item, index) => {
          return <JobCard item={item} key={index} lg={4} />;
        })
      )}
    </>
  );
};

const JobCard = ({ item, lg = 6 }) => {
  return (
    <Grid item xs={12} lg={lg} className={styles.col}>
      <Box className={styles.col_wrap}>
        <Link className={styles.Link} to={`/admin/jobs/view/${item.id}`}></Link>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h3">{item.job_title}</Typography>
          <Box display={"flex"} className={styles.edit_wrap}>
            <span className={styles.active}>
              {item.job_status ? "Active" : "InActive"}
            </span>
          </Box>
        </Box>
        <Typography variant="h4">{item.company_name}</Typography>
        <Typography variant="p">{item.company_overview}</Typography>

        <Box>
          <Box className={styles.small_box}>
            <span className={styles.key}>Applicants</span>
            <span className={styles.value}>{item.applicant_count}</span>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default JobSingleCard;
