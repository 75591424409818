import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";
import Flag from "../../../static/images/Flag.png";
import FlagFalse from "../../../static/images/Flag-false.png";

const DiscountForm = ({ id, setFormSuccess }) => {
  const [formData, setFormData] = useState({
    name: "",
    city_id: null,
    timezone_id: null,
    address: "",
    currency_id: null,
    is_super_location: false,
  });

  const [currency, setCurrency] = useState([]);

  const [city, setCity] = useState([]);

  const [errors, setErrors] = useState({});

  const [timezone, setTimeZone] = useState([]);

  const handleOnChange = (event) => {
    const target = event.target;
    setFormData(() => ({
      ...formData,
      [target.name]: target.value,
    }));
  };

  const schema = yup.object().shape({
    name: yup.string().required("Fields are required"),
    city_id: yup.string().required("Fields are required"),
    timezone_id: yup.string().required("Fields are required"),
    address: yup.string().required("Fields are required"),
    currency_id: yup.string().required("Fields are required"),
    // is_super_location: yup.string().required("Fields are required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      // Additional validation for uploadPreview images
      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      is_super_location: event.target.checked,
    });
  };

  const handleFormSuccess = async (response) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.LOCATION_VIEW, id));
        setFormData({
          name: res.data.data.name || "",
          city_id: res.data.data.city_id || "",
          timezone_id: res.data.data.time_zone_id || "",
          address: res.data.data.address || "",
          currency_id: res.data.data.currency_id || "",
          is_super_location: res.data.data.is_super_location || false,
        });
      } catch (error) {
        // Error handling
        console.error(error);
      }
    };

    const fetchCurrency = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.CURRENCY_VIEW));

        setCurrency(res.data.data || "");
      } catch (error) {
        console.error(error);
      }
    };

    const fetchCity = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.CITIES_PAGINATE));
        setCity(res.data.data || "");
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTimeZone = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.TIMEZONE_PAGINATE));
        setTimeZone(res.data.data || "");
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    fetchCurrency();
    fetchCity();
    fetchTimeZone();
  }, [id]);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.LOCATION_SAVE}
              formData={formData}
              editId={id}
              onSuccess={handleFormSuccess}
              validate={customValidation}
              successPath="/admin/setting"
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Name*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter name"
                    value={formData.name}
                    name="name"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.name !== undefined}
                  />
                  {errors.name && (
                    <FormHelperText error>{errors.name}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>City*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    select
                    fullWidth
                    className="poppins-font"
                    value={formData.city_id}
                    name="city_id"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.city_id !== undefined}
                  >
                    {errors.city_id && (
                      <FormHelperText error>{errors.city_id}</FormHelperText>
                    )}
                    {city &&
                      city.map((city, index) => (
                        <MenuItem key={index.id} value={city.id}>
                          {city.title}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Time Zone*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    select
                    fullWidth
                    placeholder="Enter time zone"
                    value={formData.timezone_id}
                    name="timezone_id"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.timezone_id !== undefined}
                  >
                    {errors.timezone_id && (
                      <FormHelperText error>
                        {errors.timezone_id}
                      </FormHelperText>
                    )}
                    {timezone &&
                      timezone.map((timezone, index) => (
                        <MenuItem key={index.id} value={timezone.id}>
                          {timezone.title}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Address*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter address"
                    value={formData.address}
                    name="address"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.address !== undefined}
                  />
                  {errors.address && (
                    <FormHelperText error>{errors.address}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Currency*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    select
                    fullWidth
                    placeholder="Enter currency"
                    value={formData.currency_id}
                    name="currency_id"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.currency_id !== undefined}
                  >
                    {errors.currency_id && (
                      <FormHelperText error>
                        {errors.currency_id}
                      </FormHelperText>
                    )}
                    {currency &&
                      currency.map((currency, index) => (
                        <MenuItem key={index.id} value={currency.id}>
                          {currency.symbol}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                sx={{
                  mb: 3,
                  alignItems: "center",
                  flexDirection: "row !important",
                }}
              >
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Super Location
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <Grid xs={1} className="role_swith_img">
                    <Checkbox
                      required
                      fullWidth
                      name="is_super_admin"
                      // value={formData.is_admin}
                      onChange={handleCheckboxChange}
                      size="small"
                      checked={formData.is_super_location}
                      error={errors.is_super_location !== undefined}
                      helperText={errors.is_super_location}
                    />
                    {errors.is_super_location && (
                      <FormHelperText error>
                        {errors.is_super_location}
                      </FormHelperText>
                    )}
                    {formData.is_super_location ? (
                      <img src={Flag} alt="Flag" width={"20px"} />
                    ) : (
                      <img src={FlagFalse} alt="Flag" width={"20px"} />
                    )}
                  </Grid>
                  {errors.is_super_location && (
                    <FormHelperText error>
                      {errors.is_super_location}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DiscountForm;
