import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useParams } from "react-router-dom";
import { Button, Box, Grid, Typography, Chip } from "@mui/material";
import { useState } from "react";
import styles from "./style.module.css";
import { EditCourseOutline } from "./edit";
import AddIcon from "@mui/icons-material/Add";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useEffect } from "react";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
import { limitStringLength } from "../../../helper/helperFunction";
import GlobalModal from "../../../components/modalBox";
import {
  HasPermission,
  HasPermissionTab,
} from "../../../helper/helperFunction";

export const ViewOutline = () => {
  const params = useParams();
  const [editId, setEdit] = useState("");
  const [open, setOpen] = useState(false);

  const [uploadMedia, setUploadMedia] = useState();

  const tableColumns = [
    {
      selector: (row) => (
        <>
          <Typography variant="body1" component="sapn" className={styles.fs14}>
            Lecture {row.lecture_order}:
          </Typography>
        </>
      ),
      minWidth: "130px",
      name: "Number",
      sortable: true,
      selector: "lecture_order",
    },
    {
      selector: (row) => (
        <>
          <Typography
            variant="body1"
            component="sapn"
            className={styles.fs14}
            sx={{ fontWeight: "700" }}
          >
            {row.name && limitStringLength(row.name, 15)}
          </Typography>
        </>
      ),
      minWidth: "200px",
      name: "Lecture Title",
      sortable: true,
      selector: "name",
    },
    {
      selector: (row) => (
        <>
          <Box>
            {row.upload_media && row.upload_media.length > 0 ? (
              row.upload_media.slice(0, 3).map((mediaItem, index) => (
                <a
                  style={{ cursor: "pointer", display: "inline-block" }}
                  key={index}
                  href={`${SERVER_KEY}${mediaItem.upload_file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Chip
                    className={styles.ChipSet}
                    label={
                      mediaItem.upload_title &&
                      limitStringLength(mediaItem.upload_title, 10)
                    }
                    icon={<InsertDriveFileIcon />}
                  />
                </a>
              ))
            ) : (
              <p>No media uploaded yet.</p>
            )}
            {row.upload_media && row.upload_media.length > 3 ? (
              <>
                <span
                  className={styles.viewMore}
                  onClick={() => {
                    setOpen(true);
                    setUploadMedia(row.upload_media ?? []);
                  }}
                >
                  View All
                </span>
              </>
            ) : (
              ""
            )}
          </Box>
        </>
      ),

      name: "Content",
      sortable: false,
    },
    {
      selector: (row) => (
        <>
          <Typography
            variant="body1"
            component="a"
            className={styles.fs14}
            sx={{ fontWeight: "500", textDecoration: "none" }}
            href={row.url}
            target="_blank"
          >
            {row.url && limitStringLength(row.url, 20)}
          </Typography>
        </>
      ),
      minWidth: "200px",
      name: "Links",
      sortable: true,
      selector: "url",
    },
    {
      selector: (row) => (
        <>
          <Typography variant="body1" component="sapn" className={styles.fs14}>
            {row.duration} Hrs
          </Typography>
        </>
      ),
      minWidth: "150px",
      name: "Duration",
      sortable: true,
      selector: "duration",
    },
  ];

  const onEdit = async (id) => {
    setEdit(id);
    setModalOpen(true);
  };

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.COURSE_OUTLINE_DELETE, id));
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    setOpen(false);
    setEdit("");
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    setReRender(formSuccess);
    setModalOpen(false);
  }, [formSuccess]);

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        padding={"0 20px"}
        marginBottom={3}
        marginTop={2}
      >
        <Typography variant="h2" className={styles.heading}>
          Course Outline
        </Typography>
        <HasPermission permission="manage-course-outline.create">
          <Button
            className={styles.addOutline}
            variant="contained"
            endIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            Add Lecture
          </Button>
        </HasPermission>
      </Grid>
      <div
        className={`page-container table-phases ${styles.courseOutlineTable}`}
      >
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(AdminApi.COURSE_OUTLINE_PAGINATION, params.id)}
          id={editId}
          onEdit={onEdit}
          onDelete={onDelete}
          refreshHandle={reRender}
          actions={{
            view: false,
          }}
          module="manage-sections"
        />
      </div>
      <EditCourseOutline
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        setModalOpen={setModalOpen}
        setFormSuccess={setFormSuccess}
        editId={editId}
      />
      {open && (
        <GlobalModal open={open} onClose={handleCloseModal} width={691}>
          <Box className={styles.chipModal}>
            {uploadMedia && uploadMedia.length > 0 ? (
              uploadMedia.map((mediaItem, index) => (
                <a
                  style={{ cursor: "pointer", display: "inline-block" }}
                  key={index}
                  href={`${SERVER_KEY}${mediaItem.upload_file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Chip
                    className={styles.ChipSet}
                    label={mediaItem.upload_title}
                    icon={<InsertDriveFileIcon />}
                  />
                </a>
              ))
            ) : (
              <p>No media uploaded yet.</p>
            )}
          </Box>
        </GlobalModal>
      )}
    </>
  );
};
