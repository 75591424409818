import LinearProgress, {
    linearProgressClasses,
  } from "@mui/material/LinearProgress";
  import { styled } from "@mui/material/styles";
  

const ProgressBar = ({ progress}) => {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 6,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: theme.palette.mode === "light" ? "#C33F28" : "#308fe8",
        },
      }));
      
  return (
    <BorderLinearProgress variant="determinate" value={progress && progress} />
  );
};

export default ProgressBar;
