import DynamicTabs from "../../../../components/Tabs/Tabs";
import { Box } from "@mui/material";
import { ViewOutline } from "../../manage-course-outline/view";
import ViewSections from "../../manage-sections/view";
import {HasPermission,HasPermissionTab} from '../../../../helper/helperFunction'


function CourseTabs() {
  const tabs = [
    {
      label: "Course Outline",
      content: <HasPermission permission="manage-course-outline.view"><ViewOutline /></HasPermission>,
      
    },
    {
      label: "Sections",
      content: <HasPermission permission="manage-sections.view"><ViewSections /></HasPermission>,
    },
  ];
  
  
  const allowedTabs = tabs.filter(tab => {
    const hasPermission = HasPermissionTab(tab.content.props.permission);
    return tab.content !== null && (tab.content.props.permission ? hasPermission : true);
  });

  return (
    <>
       {allowedTabs && allowedTabs.length > 0 && (
      <Box className="settings" mt={"50px"}>
         <DynamicTabs tabs={allowedTabs} />
      </Box>
      )}
    </>
  );
}

export default CourseTabs;
