import { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const useFilterContext = () => useContext(FilterContext);

export const FilterContextProvider = ({ children }) => {
    const [filtersData, setFiltersData] = useState({});
  
    const updateFilters = (filters) => {
      setFiltersData(filters);
    };
  
    return (
      <FilterContext.Provider value={{ filtersData, updateFilters }}>
        {children}
      </FilterContext.Provider>
    );
  };