import React, { useState } from "react";
import { formatLabel } from "../../../../../../helper/helperFunction";

const useTypeMultiSelect = ({ label, updateCourseState }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const modifiedLabel = label && formatLabel(label);

  const handleFilterChange = (event) => {
    const { value } = event.target;
    setSelectedValues(value);
    updateCourseState({
      [modifiedLabel]: value,
    });
  };

  const handleDeleteOption = (deletedValue) => {
    const newValues = selectedValues.filter((value) => value !== deletedValue);
    setSelectedValues(newValues);
    updateCourseState({
      [modifiedLabel]: newValues.length === 0 ? ["yes"] : newValues,
    });
  };

  return {
    selectedValues,
    handleFilterChange,
    handleDeleteOption,
  };
};

export default useTypeMultiSelect;
