import { useState } from "react";
import { formatLabel } from "../../../../../../helper/helperFunction";

const useTypeSelect = ({ label, updateCourseState }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const modifiedLabel = label && formatLabel(label);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    updateCourseState({
      [modifiedLabel]: value,
    });
  };

  const handleDeleteOption = () => {
    setSelectedValue("");
    updateCourseState({
      [modifiedLabel]: "yes",
    });
  };
  return {
    selectedValue,
    handleFilterChange,
    handleDeleteOption,
  };
};

export default useTypeSelect;
