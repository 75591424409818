import { Link } from "react-router-dom";
import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
// import styles from "./manage-discount.module.scss";
import { Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import AdminFormModal from "./modal";
import React from "react";
import { DateView } from "../../../components/DateView/DateView";
import { HasPermission } from "../../../helper/helperFunction";
import Moment from "react-moment";

export default function ViewAdmin() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState("");
  const [reRender, setReRender] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const tableColumns = [
    {
      selector: (row) => row.first_name,
      name: "Name",
      sortable: true,
    },
    {
      selector: (row) => row.email,
      name: "Email",
      sortable: true,
      width: "500px",
    },
    {
      selector: (row) => row.role_name,
      name: "Role",
      sortable: true,
    },
    {
      selector: (row) => row.location_name,
      name: "Location",
      sortable: true,
    },
    {
      cell: (row) => <Moment format="MMM, DD YYYY">{row.created_at}</Moment>,
      selector: "created_at",
      name: "Added on",
      sortable: true,
    },
  ];

  const onEdit = async (id) => {
    setViewId(id);
    if (id) {
      setIsModalOpen(true);
    }
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.ADMIN_DELETE, id));
  };

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);

  return (
    <>
      <div className="page-container table-phases">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.ADMIN_PAGINATE}
          onEdit={onEdit}
          onDelete={onDelete}
          refreshHandle={reRender}
          actions={{
            view: false,
          }}
          module="manage-admins"
        />
      </div>

      <HasPermission permission="manage-admins.create">
        <div className="course_content">
          <Box className="btn-wrapper">
            <Link to={""} onClick={toggleModal}>
              Add Admin
            </Link>
          </Box>
        </div>
      </HasPermission>
      <AdminFormModal
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
