import React, { useState } from "react";
import { Button, Box, Grid, Typography, Container } from "@mui/material";
import styles from "./manage-OrgTransactions.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchData } from "../../../helper/helperFunction";
import AdminApi from "../../../config/apis/admin";
import Moment from "react-moment";
import { limitStringLength } from "../../../helper/helperFunction";
import GlobalModal from "../../../components/modalBox";
import {HasPermission} from '../../../helper/helperFunction'


export const DetailsOrgBanner = ({
  formSuccess,
  setModalOpen,
  tittle,
  showBar,
  amoutBox
}) => {
  const params = useParams();
  const nevigate = useNavigate();

  const [orgTransactionData, setOrgTransactionData] = useState({
    organization_name: "",
    description: "",
    created_at: "",
    total: null,
  });

  const { organization_name, description, created_at, total, currency_symbol } =
    orgTransactionData;

  const handleFetchSuccess = (data) => {
    setOrgTransactionData(data);
  };

  const handleFetchError = (error) => {
    console.error(error);
  };

  // Use the custom hook to fetch data
  useFetchData(
    params.id ?? "",
    handleFetchSuccess,
    handleFetchError,
    AdminApi.ORGANIZATION_DETAIL,
    formSuccess
  );

  const viewHistory = async () => {
    nevigate(`/admin/org-history/view/${params.id}`);
  };

  const [readOpen, setReadOpen] = useState(false);
  const handleCloseModal = () => {
    setReadOpen(false);
  };
  return (
    <>
      <Container maxWidth="xl" className={styles.transactionsDetail}>
        <Grid container marginTop={12}>
          <Typography variant="h1" component="h1" className={styles.heading}>
            {tittle ?? ""}
          </Typography>
          <Grid container className={styles.gradientBackground}>
            <Grid item xs={12}>
              <Grid container marginTop={1}>
                <Typography
                  variant="body1"
                  component="sapn"
                  className={styles.transactionsId}
                >
                  {orgTransactionData && organization_name}
                </Typography>
              </Grid>
              <Grid container>
                <Grid
                  xs={8}
                  item
                  container
                  className={styles.infoWrap}
                  justifyContent={"space-between"}
                >
                  <Box className={styles.borderLeft}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={styles.email}
                    >
                      Description
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      className={styles.emailAdress}
                      maxWidth={700}
                    >
                      {orgTransactionData && limitStringLength(description, 150)}
                    </Typography>

                    {orgTransactionData && description.length > 55 ? (
                      <Typography
                        variant="body1"
                        component="span"
                        // maxWidth={400}
                        sx={{
                          color: "#fff",
                          textAlign: "right",
                          display: "block",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => setReadOpen(true)}
                      >
                        Readmore
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Box className={styles.borderLeft}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={styles.email}
                    >
                      Added On
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      className={styles.emailAdress}
                    >
                      <Moment format="MMM DD, YYYY hh:mmA">
                        {orgTransactionData && created_at}
                      </Moment>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} container justifyContent={"flex-end"}>
                    <Box className={styles.ammoutWrap}>
                      <Typography
                        variant="body1"
                        component="span"
                        className={styles.label}
                      >
                        {amoutBox ?? "Amount"}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className={styles.amount}
                      >
                        {orgTransactionData && currency_symbol} {orgTransactionData && total}
                      </Typography>
                    </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showBar ? (
            ""
          ) : (
            <Grid
              container
              justifyContent={"space-between"}
              marginTop={4}
              sx={{ padding: "0 30px" }}
            >
              <HasPermission permission="manage-organization-transactions.view">
                <Box
                  className={styles.buttonWrap}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    variant="body1"
                    component="h2"
                    className={styles.fundHeading}
                  >
                    Fund Transactions
                  </Typography>
                </Box>
                </HasPermission>
              <Box className={styles.buttonWrap}>
                <HasPermission permission="manage-organization-transactions.view">
                <Button
                  onClick={() => {
                    viewHistory();
                  }}
                >
                  View Histroy
                </Button>
                </HasPermission>
                <HasPermission permission="manage-organization-transactions.create">
                    <Button
                      className={styles.buttonRed}
                      onClick={() => {
                        setModalOpen(true);
                      }}
                      >
                      Add Transaction
                    </Button>
                </HasPermission>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>

      <GlobalModal open={readOpen} onClose={handleCloseModal} width={619}>
        {orgTransactionData && description}
      </GlobalModal>
    </>
  );
};
