import React, { useState, useEffect } from "react";
import { FormHelperText, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { SERVER_KEY } from "../../config/constants/EnviormentVariables";

const ImageUploadInput = styled("input")({
  display: "none",
});

const ImageShow = ({
  name,
  label,
  previewWidth,
  previewHeight,
  maxFileSize,
  onChange,
  uploadPreviewProp,
  isRequired, // New prop indicating whether upload is required
  
}) => {
  const [formData, setFormData] = useState(null);
  const [uploadPreview, setUploadPreview] = useState(null);
  const [errors, setErrors] = useState({});


  useEffect(() => {
    if (formData) {
      validateFile(formData);
    }
  }, [formData]);

  useEffect(() => {
    // Set the prop as the uploadPreview if it exists and formData is not set
    if (uploadPreviewProp && !formData) {
      setUploadPreview(`${SERVER_KEY}${uploadPreviewProp}`);
    }
  }, [uploadPreviewProp, formData]);

  useEffect(() => {
    // Trigger validation if isRequired prop is set and uploadPreview is empty
    if (isRequired && !uploadPreviewProp) {
      setErrors({ uploadRequired: "Upload is required." });
    }
  }, [isRequired, uploadPreviewProp, formData]);

  const handleChange = (e) => {
    const { files } = e.target;
    const selectedFile = files ? files[0] : null;

    setFormData(selectedFile);
    onChange(name, selectedFile);
    

    if (selectedFile) {
      setUploadPreview(URL.createObjectURL(selectedFile));
      setErrors({});
    } else {
      setUploadPreview(null);
    }
  };

  const validateFile = (file) => {
    const newErrors = {};
    const fileType = file.type.split("/")[0];

    if (fileType !== "image") {
      newErrors.fileType = "Only image files are allowed.";
    }

    if (file.size > maxFileSize) {
      newErrors.fileSize = "File size exceeds the maximum limit.";
    }

    setErrors(newErrors);
  };

  return (
    <div>
      {/* <Button
        variant="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
      >
        {label}
        <ImageUploadInput
          type="file"
          name={name}
          accept="image/*"
          onChange={handleChange}
        />
      </Button> */}
      {uploadPreview && (
        <img
          src={uploadPreview}
          alt={`${label} Preview`}
          width={previewWidth}
          height={previewHeight}
        />
      )}
     {uploadPreview ? null : ( // Conditionally render error messages
      <>
        {errors.fileType && <div>{errors.fileType}</div>}
        {errors.fileSize && <div>{errors.fileSize}</div>}
        {errors.uploadRequired && (
          <FormHelperText error>{errors.uploadRequired}</FormHelperText>
        )}
      </>
    )}
    </div>
  );
};

export default ImageShow;
