import React, { useState, useEffect } from "react";
import {Box,Grid,InputLabel,TextField,FormHelperText} from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";

const DiscountForm = ({ id , setFormSuccess }) => {
  const [formData, setFormData] = useState({
    title : "" 
  });


  const [errors, setErrors] = useState({});


  const handleOnChange = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      // ...formData,
      title : valueTarget
    });
  };

  const schema = yup.object().shape({
    title: yup.string().required("Fields are required"),
  });


  const customValidation = async () => {

    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      // Additional validation for uploadPreview images
      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleFormSuccess = async (response) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.PHASES_EDIT, id));

        setFormData({
          name: res.data.data.title || "",
        });
      } catch (error) {
        // Error handling
      }
    };
    fetchData();
  }, [id]);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form 
              submitUrl={AdminApi.PHASES_ADD}
              formData={formData}
              editId={id}
              onSuccess={handleFormSuccess}  
              validate={customValidation} 
              successPath="/admin/setting">
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Title</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter name"
                    value={formData.name}
                    name="title"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.title !== undefined}
                  />
                  {errors.title && (
                  <FormHelperText error>{errors.title}</FormHelperText>
                )}
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DiscountForm;
