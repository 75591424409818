import React from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import CousesDropdown from "./CousesDropdown";
import CohortDropdown from "./CohortDropdown";
import SectionDropdown from "./SectionDropdown";
import GenderSelect from "./Gender";

const ReportFilter = ({ courseState, updateCourseState }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <InputLabel sx={{ marginBottom: 1 }} htmlFor="courses-dropdown">
          Courses
        </InputLabel>
        <CousesDropdown
          courseState={courseState}
          updateCourseState={updateCourseState}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <InputLabel sx={{ marginBottom: 1 }} htmlFor="cohort-dropdown">
          Cohort
        </InputLabel>
        <CohortDropdown
          courseState={courseState}
          updateCourseState={updateCourseState}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <InputLabel sx={{ marginBottom: 1 }} htmlFor="section-dropdown">
          Section
        </InputLabel>
        <SectionDropdown
          courseState={courseState}
          updateCourseState={updateCourseState}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <InputLabel sx={{ marginBottom: 1 }}>Gender</InputLabel>
        <GenderSelect
          courseState={courseState}
          updateCourseState={updateCourseState}
        />
      </Grid>
    </Grid>
  );
};

export default ReportFilter;
