import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  FormHelperText,
  Button,
  Chip,
} from "@mui/material";
import { api, resolveUrl } from "../../../../config/apis";
import AdminApi from "../../../../config/apis/admin";
import Form from "../../../../components/forms/Forms";
import styles from "./style.module.css";
import { useParams } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";

const ApplyJob = () => {
  const [formData, setFormData] = useState({
    resume: null,
  });
  const [viewReume, setViewReume] = useState();
  const params = useParams();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      resume: file,
    });
  };
  useEffect(() => {
    const FechData = async () => {
      try {
        const res = await api.get(AdminApi.PROFILE_VIEW);
        const data = res.data.data || {};

        setViewReume(data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    FechData();
  }, []);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.JOBS_APPLY}
              formData={formData}
              successPath="/admin/jobs"
              saveText="OK"
              multipart={true}
              editId={params.id}
            >
              <Grid
                item
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
                className={styles.apply_form_wrap}
              >
                <Typography variant="h3">Confirm</Typography>
                <Typography variant="h6">
                  Are you sure you want to Apply?
                </Typography>
                <Grid item>
                  <Box className={styles.upload_file_wrap}>
                    <Typography variant="body1">
                      {formData.resume && formData.resume.name}
                    </Typography>
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="resumeInput"
                    />
                    <label htmlFor="resumeInput">
                      <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                        className={styles.u_button}
                      >
                        Choose File
                      </Button>
                    </label>
                  </Box>
                  <Grid item textAlign={"center"}>
                    {viewReume && viewReume.upload_file && (
                      <a
                        style={{
                          cursor: "pointer",
                          display: "inline-block",
                          marginTop: "20px",
                        }}
                        href={`${SERVER_KEY}/${viewReume.upload_file}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Chip
                          className={styles.ChipSet}
                          label={viewReume.upload_title}
                          icon={<InsertDriveFileIcon />}
                          sx={{ cursor: "pointer" }}
                        />
                      </a>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ApplyJob;
