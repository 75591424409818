import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { useState, useEffect } from "react";
import React from "react";
import { Box, Modal, Tooltip } from "@mui/material";
import EditSkills from "./edit";
import {
  HasPermission,
  limitStringLength,
} from "../../../helper/helperFunction";
import SwitchCell from "../../../components/switch";

export default function ViewSkillsTable(props) {
  const [viewId, setViewId] = useState("");
  const [reRender, setReRender] = useState(false);

  const tableColumns = [
    {
      selector: (row, index) => index + 1,
      name: "S.NO",
      sortable: false,
      width: "100px",
    },
    {
      name: "Skill Name",
      sortable: false,
      cell: (row) => (
        <Tooltip title={row.skill || ""} arrow>
          <span>{row.skill && limitStringLength(row.skill, 60)}</span>
        </Tooltip>
      ),
    },
    {
      cell: (row) => (
        <>
          {props.actionColumns && (
            <Tooltip title={"Active or Inactive for students"} arrow>
              <span>
                <HasPermission permission="manage-job-skills.edit">
                  <SwitchCell
                    id={row.id}
                    is_active={row.skill_status === "active" ? true : false}
                    EndPoint={AdminApi.SKILLS_STATUS_STUDENTS}
                    setReloadData={setReRender}
                  />
                </HasPermission>
              </span>
            </Tooltip>
          )}
        </>
      ),
      name: "",
      width: "100px",
    },
  ];

  const onEdit = async (id) => {
    setViewId(id);
    if (id) {
      props.setIsModalOpen(true);
    }
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.DELETE_SKILL, id));
  };

  useEffect(() => {
    if (!props.isModalOpen) {
      setViewId("");
    }
  }, [props.isModalOpen]);

  useEffect(() => {
    if (props.setIsModalOpen) {
      props.setIsModalOpen(false);
    }
    setReRender(formSuccess);
  }, [formSuccess]);

  return (
    <>
      <div className="page-container table-phases">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.SKILLS_LISTING}
          onEdit={onEdit}
          onDelete={onDelete}
          refreshHandle={reRender}
          pagination={props.pagination}
          actionColumns={props.actionColumns}
          actions={{
            view: false,
          }}
          module="manage-job-skills"
        />
      </div>
      {props.toggleModal && (
        <Modal open={props.isModalOpen} onClose={props.toggleModal}>
          <Box className="popup">
            <button
              onClick={props.toggleModal}
              style={{ opacity: 0 }}
              className="close_btn"
            >
              Close
            </button>
            <EditSkills id={viewId} setFormSuccess={setFormSuccess} />
          </Box>
        </Modal>
      )}
    </>
  );
}
