import { useState, useEffect } from "react";
import AdminApi from "../../../config/apis/admin";
import ScoringCriteria from "../../../components/ScoringCriteria";
import { Textarea } from "@mui/joy";
import {
  InputLabel,
  Select,
  TextField,
  Typography,
  FormHelperText,
  Chip,
  Button,
  MenuItem,
} from "@mui/material";
import { Box, Grid } from "@mui/material";
import Form from "../../../components/forms/Forms";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import SelectOptionsApi from "../../../components/SelectApi";
import styles from "./manage-course.module.css";
import { createTheme } from "@mui/material/styles";
import { api, resolveUrl } from "../../../config/apis";

export default function EditCourses() {
  const [formData, setFormData] = useState({
    title: "",
    phase_id: "",
    duration_in_hours: "",
    description: "",
    scoring_criteria: [
      {
        title: "Following Best Practices",
        score: "",
      },
      {
        title: "Achieving Target Output",
        score: "",
      },
      {
        title: "Class Performance",
        score: "",
      },
      {
        title: "Quality of work",
        score: "",
      },
    ],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const [errors, setErrors] = useState({});

  const scoringCriteriaSchema = yup.object().shape({
    score: yup.string().required("Scoring criteria score is required"),
  });

  const schema = yup.object().shape({
    title: yup.string().required("Fields are required"),
    phase_id: yup.string().required("Fields are required"),
    scoring_criteria: yup
      .array()
      .of(scoringCriteriaSchema)
      .required("Scoring criteria are required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false }); // Validate with Yup schema
      const newErrors = {};

      // Custom validation for assessmentWeightage
      if (assessmentWeightage !== 100) {
        newErrors["assessmentWeightage"] =
          "Assessment weightage cannot be greater than 100% and less than 100%";
      }

      setErrors(newErrors); // Set Yup validation errors in the state

      // Check if there are any errors (Yup or custom)
      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors); // Set Yup validation errors in the state
      return false; // Validation failed
    }
  };

  const handleOnChangeDuration = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      duration_in_hours: parseInt(valueTarget, 10),
    });
  };

  const params = useParams();

  const [assessmentWeightage, setAssessmentWeightage] = useState(0);

  useEffect(() => {
    const totalWeightage = formData.scoring_criteria.reduce(
      (sum, field) => sum + (field.score || 0),
      0
    );
    setAssessmentWeightage(totalWeightage);
  }, [formData.scoring_criteria]);

  const theme = createTheme({
    components: {
      MuiChip: {
        styleOverrides: {
          icon: {
            color: "#fff",
          },
        },
      },
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id) {
          const res = await api.get(
            resolveUrl(AdminApi.COURSES_MODULE_VIEW, params.id)
          );
          setFormData({
            title: res.data.data.title,
            phase_id: res.data.data.phase_id,
            duration_in_hours: res.data.data.duration_in_hours,
            description: res.data.data.description,
            currency_id: res.data.data.currency_id,
            scoring_criteria: res.data.data.scoring_criteria,
            media: res.data.data.media ?? [],
          });
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [params.id]);

  return (
    <>
      <div className="container global-user">
        <Typography variant="h1" className="main-heading">
          {/* Add Courses */}
          {params.id ? "Edit Course" : "Add Course"}
        </Typography>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <Grid container className="page-container">
            <Grid item xs={12} className="inner-container">
              <Form
                successPath="/admin/courses"
                submitUrl={AdminApi.COURSES_MODULE_SAVE}
                formData={formData}
                editId={params.id}
                validate={customValidation}
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item xl={9} lg={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item lg={2} md={2.5}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Course Title*
                        </InputLabel>
                      </Grid>
                      <Grid item lg={6} md={9.5}>
                        <TextField
                          fullWidth
                          name="title"
                          variant="outlined"
                          placeholder="Course Title"
                          value={formData.title}
                          onChange={handleInputChange}
                          error={errors.title !== undefined}
                        />
                        {errors.title && (
                          <FormHelperText error>{errors.title}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item lg={2} md={2.5}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Description
                        </InputLabel>
                      </Grid>
                      <Grid item lg={6} md={9.5}>
                        <Textarea
                          minRows={4}
                          maxRows={4}
                          fullWidth
                          name="description"
                          variant="outlined"
                          placeholder="Description"
                          value={formData.description}
                          onChange={handleInputChange}
                          error={errors.description !== undefined}
                        />

                        {errors.description && (
                          <FormHelperText error>
                            {errors.description}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xl={9}
                  lg={12}
                  md={12}
                  container
                  sx={{ mb: 3, alignItems: "center" }}
                >
                  <Grid item lg={2} md={2.5}>
                    <InputLabel> Select Phase* </InputLabel>
                  </Grid>
                  <Grid item lg={6} md={9.5}>
                    <SelectOptionsApi
                      apiEndpoint={AdminApi.PHASES_VIEW}
                      formData={formData}
                      onUpdate={handleUpdate}
                      name="phase_id"
                      labelKey="title"
                      valueKey="id"
                      defaultText="Select Option"
                      errorText={errors.phase_id}
                      // error={errors.status !== undefined}
                    />
                    {/* {errors.status && (
                    <FormHelperText error>{errors.status}</FormHelperText>
                  )} */}
                  </Grid>
                </Grid>

                <Grid
                  className="editCourse"
                  item
                  xl={9}
                  lg={12}
                  md={12}
                  container
                  sx={{ alignItems: "center" }}
                >
                  <Grid item lg={2} md={2.5}>
                    <InputLabel> Duration </InputLabel>
                  </Grid>
                  <Grid item xl={1.5} lg={1.5} md={2.5} sx={{ pr: 3 }}>
                    <TextField
                      fullWidth
                      type="number"
                      name="duration_in_hours"
                      variant="outlined"
                      placeholder="0"
                      value={formData.duration_in_hours}
                      onChange={handleOnChangeDuration}
                      error={errors.duration_in_hours !== undefined}
                    />
                    {errors.duration_in_hours && (
                      <FormHelperText error>
                        {errors.duration_in_hours}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xl={1} lg={1} md={1.5} sx={{ pr: 3 }}>
                    <span> Months </span>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    borderBottom: "1px solid #EDECED",
                    margin: "50px 0 20px 0",
                  }}
                ></Grid>
                <Grid item lg={6} md={12} container marginBottom={2}>
                  <ScoringCriteria
                    formData={formData}
                    setFormData={setFormData}
                    heding={"Scoring Criteria"}
                    errors={errors}
                  />
                </Grid>
              </Form>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}
