import React from "react";
import DataTable from "react-data-table-component";
import { Box } from "@mui/material";
import styles from "./manage-style.module.css";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import useStudentsReports from "./hook/useStudentsReports";

const StudentsReports = () => {
  const { modifiedData, columns, csvData } = useStudentsReports();

  return (
    <>
      <Box className="container">
        <Box className="course-content">
          <h1 className="main-heading">Student Reports</h1>
        </Box>
        <Box className={styles.stu_report_wrap}>
          <StudentsReportsDownload modifiedData={csvData} />
          <Box
            className="page-container table-phases students_reports"
            style={{ paddingBottom: "0" }}
            sx={{ marginTop: 3, marginBottom: 3 }}
          >
            <DataTable
              columns={columns}
              data={modifiedData ?? []}
              highlightOnHover
              pagination={true}
            />
          </Box>
          {modifiedData.length > 0 && (
            <StudentsReportsDownload modifiedData={csvData} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default StudentsReports;

const StudentsReportsDownload = ({ modifiedData }) => {
  return (
    <Box className={styles.filter_flex}>
      <Box className={styles.Back_btn_wrap}>
        <Box className={styles.filter_btn}>
          <Box className="btn-wrapper">
            <Link to={"/admin/reports"} className={styles.back_btn}>
              Back
            </Link>
          </Box>
        </Box>
      </Box>
      <Box className={styles.filter_flex}>
        <Box className={styles.filter_btn}>
          {modifiedData.length > 0 && (
            <CSVLink
              className={`${styles.filter_btna_csv} ${styles.filter_btna_black_csv}`}
              data={modifiedData ?? ""}
              filename={"Student Report"}
            >
              Download
            </CSVLink>
          )}
        </Box>
      </Box>
    </Box>
  );
};
