import NotFound from "../pages/error/NotFound";

export const FrontendRoutes = {
  path: "/",
  children: [
    // {
    //     path: 'pre-assessment-form',
    //     element: <PreAssessmentForm />
    // },
    // {
    //     path: 'Post-assessment-form',
    //     element: <PostAssessmentForm />
    // }
    // {
    //   path: "*",
    //   element: <NotFound />,
    // },
  ],
};
