import { Link } from "react-router-dom";
import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { Box, Grid, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import DiscountFormModal from "./modal";
import React from "react";
import { HasPermission } from "../../../helper/helperFunction";
import ActionButtons from "../../../components/dataTables/ActionButtons";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function ViewCurrency() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState("");
  const [reRender, setReRender] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const currentMonth = months[new Date().getMonth()];
  const [filterData, setFilterData] = useState({
    year: currentYear,
    month: currentMonth,
  });

  const tableColumns = [
    {
      selector: (row) => row.name,
      name: "Name Currency",
      sortable: false,
    },
    {
      selector: (row) => (row.is_base ? "Yes" : ""),
      name: "Is Base",
      sortable: false,
    },
    {
      selector: (row) => row.value,
      name: "1 USD =",
      sortable: false,
    },
    {
      selector: (row) => row.symbol,
      name: "Currency Code",
      sortable: false,
    },
  ];
  const actionCol = {
    name: "Actions",
    width: "120px",
    cell: (row) => (
      <>
        <ActionButtons
          id={row.id}
          onEdit={onEdit}
          onDelete={onDelete}
          refreshHandle={refresh}
          actions={{
            view: false,
          }}
          module="manage-currency"
        />
      </>
    ),
  };

  if (filterData.year === currentYear && filterData.month === currentMonth) {
    tableColumns.push(actionCol);
  }

  const onEdit = async (id) => {
    setViewId(id);
    if (id) {
      setIsModalOpen(true);
    }
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.CURRENCY_DELETE, id));
  };

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);

  const refresh = () => {
    setReRender((prevState) => !prevState);
  };

  const years = [];
  const endYear = 2022;
  for (let year = currentYear; year >= endYear; year--) {
    years.push(year);
  }

  return (
    <>
      <Grid xs={12} container px={5} py={1.5} alignItems={"center"}>
        <Grid item xl={6}>
          <Typography fontSize={24} fontWeight={700}>
            {filterData.month + "-" + filterData.year}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Select
            value={filterData.year}
            onChange={(e) =>
              setFilterData({
                ...filterData,
                year: e.target.value,
              })
            }
            fullWidth
            sx={{ maxWidth: "150px", marginRight: "10px" }}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={filterData.month}
            onChange={(e) =>
              setFilterData({
                ...filterData,
                month: e.target.value,
              })
            }
            fullWidth
            sx={{ maxWidth: "150px", marginRight: "10px" }}
          >
            {months.map((month) => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <div className="page-container table-phases">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.CURRENCY_VIEW_HISTORY}
          refreshHandle={reRender}
          module="manage-currency"
          actionColumns={false}
          filters={filterData}
        />
      </div>

      <HasPermission permission="manage-currency.create">
        <div className="course_content">
          <Box className="btn-wrapper">
            <Link to={""} onClick={toggleModal}>
              Add Currency
            </Link>
          </Box>
        </div>
      </HasPermission>
      <DiscountFormModal
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
