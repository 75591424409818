import { useState } from "react";
import { formatLabel } from "../../../../../../helper/helperFunction";

const useTypeRageSelect = ({ label, updateCourseState }) => {
  const [ageRange, setAgeRange] = useState([null, null]);
  const modifiedLabel = label && formatLabel(label);

  const handleAgeChange = (event, newAgeRange) => {
    setAgeRange(newAgeRange);
    updateCourseState({
      [modifiedLabel]: {
        min: newAgeRange[0],
        max: newAgeRange[1],
      },
    });
  };

  const handleDeleteChip = () => {
    setAgeRange([null, null]);
    updateCourseState({
      [modifiedLabel]: {
        min: 1,
        max: 100,
      },
    });
  };

  return { handleDeleteChip, handleAgeChange, ageRange };
};

export default useTypeRageSelect;
