import React, { useRef } from "react";
import { useLocation } from "react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";

export default function OutletTransition(params) {
  const location = useLocation();
  const nodeRef = useRef(null);

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        nodeRef={nodeRef}
        timeout={900}
        classNames="transition-screen"
        unmountOnExit
      >
        {(state) => (
          <div ref={nodeRef}>{params.outlet}</div>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
}
