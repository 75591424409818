import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import { api, resolveUrl } from "../../config/apis";

const SelectOptionsApi = ({
  disabled = false,
  apiEndpoint = "",
  formData = {},
  onUpdate = () => {},
  labelKey = "",
  name = "",
  valueKey = "",
  errorText = "",
  paramId = "",
  defaultText = "Select Option",
  customStyle = {},
  optionData,
  queryparams = {},
}) => {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (apiEndpoint) {
          const res = await api.get(resolveUrl(apiEndpoint, paramId || ""), {
            params: {
              ...queryparams,
            },
          });
          setOptions(res.data.data);
          if (optionData) {
            optionData(res.data.data);
          }
          setError(null);
        } else {
          setError("API endpoint not provided.");
        }
      } catch (error) {
        setError("Error fetching options.");
      }
    };

    fetchData();
  }, [apiEndpoint, paramId, formData[name]]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    onUpdate(name, value);
  };

  if (!options || options.length === 0) {
    // Handle the case when options are not available yet or are empty
    return (
      <>
        <Select
          maxWidth="100%"
          sx={customStyle}
          required
          fullWidth
          name={name}
          value={formData[name] || ""}
          onChange={handleChange}
          error={!!errorText}
          size="small"
          disabled={disabled}
          className="select"
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em className="select">{defaultText}</em>;
            }
            return "";
          }}
        >
          <MenuItem key="" value="">
            <em className="select">{defaultText}</em>
          </MenuItem>
        </Select>
        {errorText && <FormHelperText error>{errorText}</FormHelperText>}
      </>
    );
  }

  return (
    <>
      <Select
        maxWidth="100%"
        sx={customStyle}
        required
        fullWidth
        name={name}
        value={formData[name] || ""}
        onChange={handleChange}
        error={!!errorText}
        size="small"
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <em>{defaultText}</em>;
          }
          const selectedOption = options.find(
            (option) => option[valueKey] === selected
          );
          return selectedOption ? <em> {selectedOption[labelKey]} </em> : "";
        }}
      >
        <MenuItem key="" value="">
          <em>{defaultText}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </>
  );
};

export default SelectOptionsApi;
