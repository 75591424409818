import {
  Box,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useEffect } from "react";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState } from "react";
import GlobalModal from "../../../components/modalBox";
import SelectOptions from "../../../components/Select";
import Form from "../../../components/forms/Forms";
import SelectOptionsApi from "../../../components/SelectApi/index";
import * as yup from "yup";
import styles from "./manage-transactions.module.css";

export const EditTransactions = (props) => {
  const defaultFormData = {
    student_Id: "",
    section: "",
    organization_id: "",
    transaction_type: "",
    registration_fee: null,
    currency_id: null,
    payer_payee: "student",
    email: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  const handleCloseModal = () => {
    props.setModalOpen(false);
    setErrors({});
    setFormData(defaultFormData);
    setUserID("");
    setSearchQuery("");
  };

  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    student_Id: yup.string().required("This field required"),
    organization_id: yup.string().required("This field required"),
    transaction_type: yup.string().required("This field required"),
    registration_fee: yup.string().required("This field required"),
    currency_id: yup.string().required("This field required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [userID, setUserID] = useState();

  const handleOnChangeUser = (event) => {
    const valueTarget = event.target.value;
    setUserID(valueTarget);

    setErrors((prevErrors) => ({
      ...prevErrors,
      student_Id: undefined,
    }));
  };

  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  const handleFeeUpdate = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      registration_fee: value ? Number(value) : "",
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const PaymentInfo = [
    { id: "Cohort Fee", options: "Cohort Fee" },
    { id: "Registration", options: "Registration" },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleStudentClick = (student) => {
    setUserID(student.id);
    setSearchQuery(student.name);
    setFormData({
      ...formData,
      student_Id: student.id,
      organization_id: searchResults[0].organization_id,
      email: searchResults[0].email,
      section: searchResults[0].section,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchQuery.length >= 3) {
          const paramsText = searchQuery;
          const response = await api.get(
            resolveUrl(AdminApi.TRANSACTIONS_PREFILLED_DATA),
            {
              params: {
                text: paramsText,
              },
            }
          );
          setSearchResults(response.data.data);
        } else {
          setSearchResults([]);
          setUserID("");
          setFormData({
            ...formData,
            student_Id: "",
            organization: "",
            organization_id: null,
            email: "",
            section: "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userID.length > 0) {
          const paramsId = userID;
          const response = await api.get(
            resolveUrl(AdminApi.TRANSACTIONS_PREFILLED_DATA),
            {
              params: {
                text: paramsId,
              },
            }
          );
          setFormData({
            ...formData,
            student_Id: response.data.data[0].id,
            organization_id: response.data.data[0].organization_id,
            email: response.data.data[0].email,
            section: response.data.data[0].section,
          });
          setSearchQuery(response.data.data[0].name);
        } else {
          if (!userID) {
            setSearchQuery("");
            setFormData({
              ...formData,
              student_Id: "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [userID]);

  useEffect(() => {
    if (props.editId) {
      const fetchEditData = async () => {
        try {
          const response = await api.get(
            resolveUrl(AdminApi.TRANSACTIONS_VIEW, props.editId)
          );
          setFormData({
            student_Id: response.data.data.user_id ?? "",
            section: response.data.data.section,
            organization_id: response.data.data.organization_id ?? "",
            transaction_type: response.data.data.type ?? "",
            registration_fee: response.data.data.registration_fee ?? "",
            email: response.data.data.email ?? "",
            currency_id: response.data.data.currency_id ?? "",
          });
          setUserID(response.data.data.user_id);
          setSearchQuery(response.data.data.name);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchEditData();
    }
  }, [props.editId, props.modalOpen]);

  const submitUrl = props.editId
    ? AdminApi.TRANSACTIONS_UPDATE
    : AdminApi.TRANSACTIONS_SAVE;

  return (
    <>
      <GlobalModal
        open={props.modalOpen}
        onClose={handleCloseModal}
        width={619}
      >
        <Form
          successPath={
            props.editId
              ? `/admin/transactions/view/${props.editId}`
              : "/admin/transactions"
          }
          submitUrl={submitUrl}
          formData={formData}
          setFormSuccess={props.setFormSuccess}
          editId={props.editId}
          validate={customValidation}
          customClass={styles.tractionForm}
        >
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ marginBottom: 2 }}
          >
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={12}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Student ID*
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ maxWidth: "120px" }}
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                  variant="outlined"
                  placeholder="1251"
                  value={userID}
                  onChange={handleOnChangeUser}
                  inputProps={{
                    type: "number",
                    pattern: "[0-9]*",
                  }}
                  disabled={!!props.editId}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={12}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Select Student*
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!!props.editId}
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  placeholder="Search student..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  // error={errors.student_Id !== undefined}
                />
                {errors.student_Id && (
                  <FormHelperText error>{errors.student_Id}</FormHelperText>
                )}
                <div>
                  {searchResults.map((student) => (
                    <div
                      className="list_students"
                      key={student.id}
                      onClick={() => handleStudentClick(student)}
                    >
                      <span className="course_list">{student.name}</span>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Organization*{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <SelectOptionsApi
                  apiEndpoint={AdminApi.ORGANIZATIONS}
                  formData={formData}
                  onUpdate={handleUpdate}
                  labelKey="name"
                  valueKey="id"
                  name="organization_id"
                  errorText={errors.organization_id}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Email *{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                  name="email"
                  variant="outlined"
                  value={formData.email}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Section *{" "}
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                  name="section"
                  variant="outlined"
                  value={formData.section}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={12}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Payment Type *
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <SelectOptions
                  options={PaymentInfo}
                  formData={formData}
                  onUpdate={handleUpdate}
                  name="transaction_type"
                  labelKey="options"
                  valueKey="id"
                  errorText={errors.transaction_type}
                  disabled={!!props.editId}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ alignItems: "center" }}>
              <Grid item xs={12}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Fees *
                </InputLabel>
              </Grid>
              <Grid container item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    maxWidth: "200px",
                  }}
                  className="currncy_ammount"
                >
                  <Box
                    sx={{
                      maxWidth: "120px",
                    }}
                    className="SelectOptionsApi"
                  >
                    <SelectOptionsApi
                      apiEndpoint={AdminApi.CURRENCY_VIEW}
                      formData={formData}
                      onUpdate={handleUpdate}
                      name="currency_id"
                      labelKey="symbol"
                      valueKey="id"
                      defaultText="Currency"
                      customStyle={{ border: "0" }}
                    />
                  </Box>
                  <TextField
                    className="registration_fee"
                    fullWidth
                    name="registration_fee"
                    variant="outlined"
                    placeholder="fee"
                    value={formData.registration_fee}
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0 10px",
                      },
                    }}
                    inputProps={{
                      type: "number",
                      pattern: "[0-9]*",
                    }}
                    onChange={handleFeeUpdate}
                    error={errors.registration_fee !== undefined}
                  />
                </Box>
              </Grid>
            </Grid>
            {errors.registration_fee && (
              <FormHelperText error>{errors.registration_fee}</FormHelperText>
            )}
          </Grid>
        </Form>
      </GlobalModal>
    </>
  );
};
