import CircularProgress from "@mui/material/CircularProgress";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Grid } from "@mui/material";
window.Chart = Chart;

const AttendenceChart = ({ chartData, isDataLoaded }) => {
  const data = {
    labels: [
      `Not Marked ${chartData ? chartData.notMarkedCount : "-"}`,
      `Present ${chartData ? chartData.presentCount : "-"}`,
      `Holiday ${chartData ? chartData.holidayCount : "-"}`,
    ],
    datasets: [
      {
        labels: [`Not Marked `, `Present `, `Holiday`],
        data: [
          chartData && chartData.notmarkedpercentage,
          chartData && chartData.presentPercentage,
          chartData && chartData.holidayPercentage,
        ],
        backgroundColor: ["#FFAA2A ", "#064763 ", "#20D568 "],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = data.datasets[0].labels[context.dataIndex];
            const value = data.datasets[0].data[context.dataIndex];
            const percentage = value.toFixed(2);
            return `${label}: ${percentage}%`;
          },
        },
      },
    },
    legend: {
      display: true,
      position: "bottom",
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 15, // Adjust this value to change the font size
            weight: "bold", // Add this line to make the labels bold
          },
          padding: 20,
        },
      },
    },
  };
  return (
    <>
      {!isDataLoaded ? (
        <Grid
          sx={{ height: "450px", display: "flex", justifyContent: "center" }}
        >
          <Pie data={data} options={options} />
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: 250,
          }}
        >
          <CircularProgress sx={{ color: "#001c28" }} />
        </div>
      )}
    </>
  );
};

export default AttendenceChart;
