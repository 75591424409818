import Stack from "@mui/material/Stack";
import { Typography, Button } from "@mui/material";
import { toTitleCase } from "../../../../helper/helperFunction";
import Moment from "react-moment";
import ImageView from "../../../../components/imageShow";
import Tooltip from "@mui/material/Tooltip";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";

export const tableColumns = [
  {
    selector: (row) => `#${row.id}`,
    name: "ID",
    sortable: true,
    width: "80px",
  },
  {
    cell: (row) => (
      <>
        <ImageView imageName={row.profile_image} name={row.student_name} />
        {toTitleCase(`${row.student_name}`)}
      </>
    ),
    name: "Name",
    sortable: true,
    selector: "student_name",
    width: "200px",
  },
  {
    selector: (row) => (
      <Tooltip title={row.student_email} arrow>
        {row.student_email}
      </Tooltip>
    ),
    name: "Email",
    sortable: true,
    width: "250px",
  },
  {
    selector: (row) => row.location,
    name: "Location",
    sortable: true,
    width: "150px",
    wrap: "true",
  },

  {
    cell: (row) => {
      const skills = row.skills.split(",").map((item) => item.trim());
      const firstThreeSkills = skills.slice(0, 3);
      const remainingSkills = skills.slice(3);

      return (
        <Tooltip title={remainingSkills.join(", ")} arrow>
          <span>
            {firstThreeSkills.join(", ")}
            {remainingSkills.length > 0 && "..."}
          </span>
        </Tooltip>
      );
    },
    selector: "skills",
    sortable: true,
    name: "Skills",
    width: "150px",
  },
  {
    cell: (row) => {
      const skills = row.other_skills.split(",").map((item) => item.trim());
      const firstThreeSkills = skills.slice(0, 3);
      const remainingSkills = skills.slice(3);

      return (
        <Tooltip title={remainingSkills.join(", ")} arrow>
          <span>
            {firstThreeSkills.join(", ")}
            {remainingSkills.length > 0 && "..."}
          </span>
        </Tooltip>
      );
    },
    selector: "skills",
    sortable: true,
    name: "Other Skills",
    width: "150px",
  },

  {
    selector: (row) => row.job_type,
    name: "Job Type",
    sortable: true,
    width: "150px",
    wrap: "true",
  },
  {
    selector: (row) => <Moment format="MMM, DD YYYY">{row.applied_on}</Moment>,
    name: "Applied on",
    width: "120px",
  },
  {
    selector: (row) => (
      <a href={`${SERVER_KEY}${row.resume}`} target="_blank">
        <Button
          className="view resume"
          style={{ display: "inline-block", minWidth: "42px" }}
          // onClick={() => {
          //   handleDownload(`${SERVER_KEY}${row.resume}`, "resume");
          // }}
        >
          resume
        </Button>
      </a>
    ),
    name: "Resume",
    width: "100px",
  },
];
