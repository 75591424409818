import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  father_name: Yup.string().required("Father / Husband Name is required"),
  cnic: Yup.string().required("cnic number is required"),
  emergency_number: Yup.string().required("emergency number is required"),
  phone: Yup.string().required("Whatsapp number is required"),
  gender: Yup.string().required("Gender is required"),
  date_of_birth: Yup.string().required("Date of Birth is required"),
  country_id: Yup.string().required("Country is required"),
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  is_studying: Yup.string().required("Studying status is required"),
  qualification_id: Yup.string().required("Qualification is required"),
  qualification_field_id: Yup.string().required(
    "Qualification field is required"
  ),
});

export default validationSchema;
