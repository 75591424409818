import {
  Grid,
  InputLabel,
  TextField,
  Chip,
  FormHelperText,
  Box,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import GlobalModal from "../../../components/modalBox";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";
import styles from "./style.module.css";
import DatePicker from "react-datepicker";
import { Textarea } from "@mui/joy";
import MultiSelect from "./dropdown";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { formatDateYYMMDD } from "../../../helper/helperFunction";
import { useParams, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
import useFetchLocations from "../../../hooks/useFetchLocations";

export const EditWorkShop = (props) => {
  const params = useParams();
  const location = useLocation();
  const locations = useFetchLocations();

  const defaultFormData = {
    title: "",
    workshop_sections: [],
    date: "",
    time: "",
    duration: null,
    teacher: "",
    teacher_bio: "",
    location_id: null,
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [dropDownData, setDropDownData] = useState([]);

  const handleCloseModal = () => {
    props.setModalOpen(false);
    setErrors({});
    if (!params.id) {
      setFormData(defaultFormData);
    }
  };

  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    // workshop_sections: yup
    //   .array()
    //   .of(yup.string())
    //   .min(1, "At least one workshop section is required"),
    date: yup.string().required("Date is required"),
    time: yup.string().required("Time is required"),
    duration: yup.number().nullable().required("Duration is required"),
    location_id: yup.number().nullable().required("Location is required"),
    teacher: yup.string().required("Teacher is required"),
    teacher_bio: yup.string().required("Teacher Bio is required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const DropDownData = async () => {
      try {
        const response = await api.get(AdminApi.WORKSHOP_DROPDOWN);
        setDropDownData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    DropDownData();
  }, []);

  useEffect(() => {
    if (params.id) {
      const fetchData = async () => {
        const res = await api.get(
          resolveUrl(AdminApi.WORKSHOP_VIEW, params.id)
        );
        const {
          title,
          workshop_sections,
          date,
          time,
          duration,
          teacher,
          teacher_bio,
          profile_image,
          location_id,
        } = res.data.data;

        setFormData({
          title: title ?? "",
          workshop_sections: workshop_sections ?? [],
          date: new Date(date) || "",
          time: time ?? "",
          duration: duration ?? null,
          teacher: teacher ?? "",
          teacher_bio: teacher_bio ?? "",
          location_id: location_id ?? null,
        });
        setPreviewImage(`${SERVER_KEY}${profile_image}`);
      };
      fetchData();
    }
  }, [params.id]);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const objectURL = URL.createObjectURL(file);
      setPreviewImage(objectURL);
    }
  };
  const [successRes, setSuccesRes] = useState(false);

  const payload = {
    profile_image: selectedFile,
  };

  useEffect(() => {
    if (successRes.workshop_id) {
      if (selectedFile !== null) {
        const PostImage = async () => {
          try {
            const res = await api.post(
              resolveUrl(
                AdminApi.WORKSHOP_EDIT_WITH_IMAGE,
                successRes.workshop_id
              ),
              payload,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (res.status === 200) {
              setSuccesRes(false);
            } else {
              console.error("Error:", res.status, res.statusText);
            }
          } catch (error) {
            console.error("Error:", error);
          }
        };
        PostImage();
      } else {
        setSuccesRes(false);
      }
    }
  }, [successRes.workshop_id]);

  const updatedData = {
    ...formData,
    date: formData.date ? formatDateYYMMDD(formData.date) : "",
    duration: Number(formData.duration ?? null),
  };

  const chips =
    formData &&
    formData.workshop_sections.map((value, index) => (
      <div key={index}>
        {value.cohort_name && (
          <Chip sx={{ margin: "5px" }} label={value.cohort_name} />
        )}
        {value.sections.map((section, sectionIndex) => (
          <Chip
            key={sectionIndex}
            sx={{ margin: "5px" }}
            label={section.section_name}
          />
        ))}
      </div>
    ));

  const timeZone = [
    "1:00",
    "1:30",
    "2:00",
    "2:30",
    "3:00",
    "3:30",
    "4:00",
    "4:30",
    "5:00",
    "5:30",
    "6:00",
    "6:30",
    "7:00",
    "7:30",
    "8:00",
    "8:30",
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];
  const handleOnChange = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };

  return (
    <>
      <GlobalModal
        open={props.modalOpen}
        onClose={handleCloseModal}
        width={650}
      >
        <Form
          successPath={location}
          submitUrl={AdminApi.WORKSHOP_EDIT}
          formData={updatedData}
          setFormSuccess={props.setFormSuccess}
          editId={params.id}
          validate={customValidation}
          customClass={styles.tractionForm}
          onSuccess={setSuccesRes}
        >
          <Grid xs={12} container marginTop={4} className={styles.addWorkshp}>
            <Grid xs={12} item className={styles.formRow}>
              <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Workshop Title *
              </InputLabel>
              <TextField
                onChange={(e) => handleInputChange(e)}
                name="title"
                variant="outlined"
                value={formData.title ?? ""}
                fullWidth
                error={errors.title !== undefined}
                helperText={errors.title}
              />
            </Grid>
            <Grid xs={12} item className={styles.formRow}>
              <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Phase(s) and Course(s) *{" "}
              </InputLabel>
              <MultiSelect
                options={dropDownData ?? []}
                setFormData={setFormData}
                formData={formData}
              />
              {errors.workshop_sections && (
                <FormHelperText error>
                  {errors.workshop_sections}
                </FormHelperText>
              )}
              <Grid container marginTop={1}>
                {chips}
              </Grid>
            </Grid>
            <Grid xs={12} item className={styles.formRow}>
              <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Location *
              </InputLabel>
              <TextField
                select
                fullWidth
                placeholder="Enter location"
                value={formData.location_id}
                name="location_id"
                variant="outlined"
                onChange={handleOnChange}
                error={errors.location_id !== undefined}
                helperText={errors.location_id}
              >
                {locations &&
                  locations.map((location, index) => (
                    <MenuItem key={index.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid xs={4} item className={styles.formRow} paddingRight={2}>
              <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Date *
              </InputLabel>
              <DatePicker
                dateFormat="dd-MM-yyyy"
                required
                id="outlined-required"
                fullWidth
                name="date"
                selected={formData.date ? new Date(formData.date) : null}
                onChange={(date) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    date: date,
                  }))
                }
                showTimeSelect={false}
                size="small"
                placeholderText="Date"
              />
              {errors.date && (
                <FormHelperText error>{errors.date}</FormHelperText>
              )}
            </Grid>
            <Grid
              xs={4}
              item
              className={styles.formRow}
              paddingLeft={1}
              paddingRight={1}
            >
              <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Start Time(GMT) *
              </InputLabel>
              <select
                className={styles.select_custom}
                value={formData.time}
                name="time"
                onChange={(e) => handleInputChange(e)}
              >
                {timeZone &&
                  timeZone.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>

              {errors.time && (
                <FormHelperText error>{errors.time}</FormHelperText>
              )}
            </Grid>
            <Grid xs={4} item className={styles.formRow} paddingLeft={2}>
              <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Duration (Hrs) *
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="duration"
                value={formData.duration ?? ""}
              />
              {errors.duration && (
                <FormHelperText error>{errors.duration}</FormHelperText>
              )}
            </Grid>
            <Grid xs={8} item className={styles.formRow} paddingRight={4}>
              <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Assign Teacher *
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="teacher"
                value={formData.teacher ?? ""}
              />
              {errors.teacher && (
                <FormHelperText error>{errors.teacher}</FormHelperText>
              )}
            </Grid>
            <Grid
              xs={4}
              item
              className={`${styles.upload_teacher_image} ${styles.formRow}`}
            >
              <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Upload Image
              </InputLabel>
              <Box>
                {previewImage && (
                  <img src={previewImage} alt={formData && formData.teacher} />
                )}
                <Button component="label" variant="contained">
                  <FileUploadIcon color="#fff" />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </Box>
            </Grid>
            <Grid xs={12} item className={styles.formRow}>
              <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Teacher Bio
              </InputLabel>
              <Textarea
                value={formData.teacher_bio ?? ""}
                onChange={(e) => handleInputChange(e)}
                name="teacher_bio"
                minRows={4}
                maxRows={4}
                error={errors.teacher_bio !== undefined}
              />
              {errors.teacher_bio && (
                <FormHelperText error>{errors.teacher_bio}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Form>
      </GlobalModal>
    </>
  );
};
