import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
  Typography,
} from "@mui/material";
import AdminApi from "../../../../config/apis/admin";
import Form from "../../../../components/forms/Forms";
import * as yup from "yup";
import styles from "./manage-user-student.module.css";

const ResetPassword = ({ id, setFormSuccess, toggleModal }) => {
  const [formData, setFormData] = useState({
    admin_password: null,
  });

  const [errors, setErrors] = useState({});

  const handleOnChange = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });
  };

  const schema = yup.object().shape({
    admin_password: yup.string().required("This field is required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.PASSWORD_RESET}
              formData={formData}
              editId={id}
              validate={customValidation}
              successPath={`/admin/user/student/view/${id}`}
              cancleShowForModals={true}
              toggleModal={toggleModal}
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Typography className={styles.heading_password}>
                  Are you sure you want to Reset Student’s Password?
                </Typography>
                <Grid item xs={2} mb={2}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Please confirm your password to continue
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    type="password"
                    value={formData.admin_password}
                    name="admin_password"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.admin_password !== undefined}
                  />
                  {errors.admin_password && (
                    <FormHelperText error>
                      {errors.admin_password}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ResetPassword;
