import { Box, Grid, Button } from "@mui/material";
import React, { useState } from "react";
import Datatable from "../../../components/dataTables/DataTable";
import StudentApi from "../../../config/apis/student";
import { DateView } from "../../../components/DateView/DateView";
import "react-datepicker/dist/react-datepicker.css"; // Import the DatePicker CSS
import "../../../static/css/style.css";
import { formatDate } from "../../../helper/helperFunction";
import DatePicker from "react-datepicker";
import { Typography } from "@mui/material";
import styles from "./style.module.css";
import AttendenceSummary from "../manage-attendence-summary/view";
import Tooltip from "@mui/material/Tooltip";

export default function Attendence() {
  const tableColumns = [
    {
      cell: (row) => (
        <Typography variant="body1" className="font-s-13">
          <DateView date={row.date} />
        </Typography>
      ),
      name: "Date",
      sortable: true,
      minWidth: "150px",
      selector: "date",
    },
    {
      cell: (row) => (
        <Tooltip title={row.holiday_reason} arrow>
          <Typography
            variant="body1"
            className="font-s-22"
            style={{
              color:
                row.status === "A"
                  ? "#D3371C"
                  : row.status === "H"
                  ? "#FFAA2A"
                  : "#001C28",
            }}
          >
            {row.status}
          </Typography>
        </Tooltip>
      ),
      name: "Attendance",
      selector: "status",
      minWidth: "150px",
      sortable: true,
    },
  ];

  const [filterData, setFilterData] = useState({});

  const [filters, setFilters] = useState({});

  const handleFilter = () => {
    setFilters({
      from_date: formatDate(filterData.from_date),
      to_date: formatDate(filterData.to_date),
    });
  };

  return (
    <>
      <Box className="container">
        <Box class="course-content">
          <h1 class="main-heading">Attendence</h1>
        </Box>

        <Grid container spacing={2} className={styles.attendance_wrap}>
          <Grid
            item
            xs={6}
            className="table-phases attendence_filter"
            paddingBottom={0}
            borderRight={"1px solid #ced4da"}
            paddingRight={"20px"}
          >
            <Grid container marginBottom={2}>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  required
                  id="outlined-required"
                  fullWidth
                  name="from_date"
                  selected={
                    filterData.from_date ? new Date(filterData.from_date) : null
                  }
                  onChange={(date) =>
                    setFilterData((prevFormData) => ({
                      ...prevFormData,
                      from_date: date,
                    }))
                  }
                  showTimeSelect={false}
                  size="small"
                  placeholderText="Date From"
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  required
                  id="outlined-required"
                  fullWidth
                  selected={
                    filterData.to_date ? new Date(filterData.to_date) : null
                  }
                  name="to_date"
                  onChange={(date) =>
                    setFilterData((prevFormData) => ({
                      ...prevFormData,
                      to_date: date,
                    }))
                  }
                  showTimeSelect={false}
                  size="small"
                  placeholderText="Date To"
                />
              </Grid>

              <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{
                    backgroundColor: "rgba(0, 28, 40, 1)",
                    color: "#fff",
                    height: "40px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 28, 40, 1)",
                      color: "#fff",
                      opacity: "0.8",
                    },
                  }}
                  onClick={() => {
                    handleFilter();
                  }}
                >
                  Go
                </Button>
              </Grid>
            </Grid>
            <Datatable
              tableColumns={tableColumns}
              dataUrl={StudentApi.ATTENDANCE}
              actionColumns={false}
              filters={filters}
              module="manage-attendance"
            />
          </Grid>
          <Grid item xs={6}>
            <AttendenceSummary />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
