import DynamicTabs from "../../../components/Tabs/Tabs";
import { Box } from "@mui/material";
import ViewEnrolledStudents from "../manage-enrolled-students/view";
import { ViewSecOutline } from "../manage-sec-course-outline/view";
import ViewAssignments from "../manage-assignments/view";
import ViewAttendance from "../manage-attendance/view";
import ViewGrade from "../manage-grade/view";
import {
  HasPermission,
  HasPermissionTab,
} from "../../../helper/helperFunction";

function CourseTabs(params) {
  const tabs = [
    {
      label: "Course Outline",
      content: (
        <HasPermission permission="manage-section-outline.view">
          <ViewSecOutline courseId={params.courseId ?? ""} />
        </HasPermission>
      ),
    },
    {
      label: "Enrolled Students",
      content: (
        <HasPermission permission="manage-enrolled-students.view">
          <ViewEnrolledStudents
            courseId={params.courseId}
            setReloadEnroll={params.setReloadEnroll}
            locationId={params.locationId}
            cohort_id={params.cohort_id}
          />
        </HasPermission>
      ),
    },
    {
      label: "Attendance",
      content: (
        <HasPermission permission="manage-attendance.view">
          <ViewAttendance setReRender={params.setReRender} />
        </HasPermission>
      ),
    },
    {
      label: "Assignment",
      content: (
        <HasPermission permission="manage-assignment.view">
          <ViewAssignments
            sectionName={params.sectionName}
            courseTitle={params.courseTitle}
            sectionId={params.sectionId}
            courseId={params.courseId}
          />
        </HasPermission>
      ),
    },

    {
      label: "Reviews",
      content: <HasPermission permission="manage-reviews.view"></HasPermission>,
    },
    {
      label: "Grade Book",
      content: (
        <HasPermission permission="manage-gradebook.view">
          <ViewGrade />
        </HasPermission>
      ),
    },
  ];
  const allowedTabs = tabs.filter((tab) => {
    const hasPermission = HasPermissionTab(tab.content.props.permission);
    return (
      tab.content !== null &&
      (tab.content.props.permission ? hasPermission : true)
    );
  });
  return (
    <>
      <Box className="settings" mt={"40px"}>
        {allowedTabs && allowedTabs.length > 0 && (
          <DynamicTabs tabs={allowedTabs} setTabsCount={params.setTabsCount} />
        )}
      </Box>
    </>
  );
}

export default CourseTabs;
