import { Typography, Link, Box, Grid } from "@mui/material";
import Card from "../../../components/Card/Cards";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./manage-section.module.css";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import CourseTabs from "../course-tabs/course-tabs";
import ImageView from "../../../components/imageShow";
import SwitchCell from "../../../components/switch";
import CSVDownloadButton from "../../../components/downloadCSV";
import { HasPermission } from "../../../helper/helperFunction";

// SECTION MODULE
export default function SectionView() {
  const navigate = useNavigate();
  const params = useParams();
  const [sectionInfo, setSectionInfo] = useState();
  const [courseInfo, setCourseInfo] = useState();
  const [courseID, setCourseID] = useState(null);
  const [tabsCount, setTabsCount] = useState("");
  const [reloadEnroll, setReloadEnroll] = useState(false);
  const [apiUrl, setApiUrl] = useState();
  const course_id = sectionInfo?.course_id ?? "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (course_id) {
          const res = await api.get(
            resolveUrl(AdminApi.COURSES_DETAIL_HEADER_VIEW, course_id)
          );
          setCourseInfo(res.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [course_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id) {
          const res = await api.get(
            resolveUrl(AdminApi.SECTION_MODULE_DETAIL, params.id)
          );
          setSectionInfo(res.data.data);
          setCourseID(res.data.data.course_id);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [params.id, tabsCount]);

  const View = async () => {
    navigate(`/admin/section/edit/${params.id}`);
  };

  const [csvName, setCsvName] = useState();
  const [reRender, setReRender] = useState(0);

  console.log(reRender, "reRender");

  useEffect(() => {
    let csvUrl;

    if (tabsCount === "Course Outline") {
      if (courseID) {
        csvUrl = resolveUrl(
          AdminApi.COURSE_SECTION_OUTLINE_CSV,
          courseID ?? ""
        );
        setApiUrl(`${csvUrl}?section_id=${params.id}`);
        setCsvName("Course Outline");
      }
    } else if (tabsCount === "Enrolled Students") {
      csvUrl = resolveUrl(
        AdminApi.COURSE_STUDENTS_ENROLLED_CSV,
        params.id ?? ""
      );
      setCsvName("Enrolled Students");
      setApiUrl(csvUrl);
    } else if (tabsCount === "Assignment") {
      csvUrl = resolveUrl(AdminApi.COURSE_ASSIGNMENTS_CSV, params.id ?? "");
      setCsvName("Assignment");
      setApiUrl(csvUrl);
    } else if (tabsCount === "Attendance") {
      csvUrl = resolveUrl(AdminApi.COURSE_ATTENDANCE_CSV, params.id ?? "");
      setCsvName("Attendance");
      setApiUrl(csvUrl);
    } else if (tabsCount === "Grade Book") {
      csvUrl = resolveUrl(AdminApi.COURSE_GRADE_CSV, params.id ?? "");
      setCsvName("Grade Book");
      setApiUrl(csvUrl);
    }
  }, [tabsCount, courseID]);

  return (
    <>
      <Box className="container">
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <h1>{courseInfo?.title}</h1>
          <CSVDownloadButton
            apiEndpoint={apiUrl}
            variant={"black"}
            fileName={csvName}
            reRender={reRender}
          />
        </Grid>
        <Box className={styles.courseDetailFlex}>
          <Box className={styles.courseDetailFlexContent}>
            <Box className={styles.courseDetailFlexHeading}>
              <h2>{sectionInfo?.section_name}</h2>
              {/* <h2>Course</h2> */}
              <Typography variant="p" gutterBottom className={styles.coude}>
                {/* <span className="bold">{sectionInfo?.average_rating}</span>
                <span className="bold">{sectionInfo?.review_count} Reviews</span> */}
              </Typography>
            </Box>
            <Typography variant="p" gutterBottom className={styles.coude}>
              <span className="bold">{sectionInfo?.description}</span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Typography
                variant="p"
                gutterBottom
                className={styles.coude}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ImageView
                    imageName={sectionInfo?.profile_image}
                    name={sectionInfo?.name}
                  />
                  <span className={styles.bold}>
                    {sectionInfo?.teacher_name}
                  </span>{" "}
                  |
                </Box>
                <span className={styles.bold}>{sectionInfo?.cohort_name}</span>{" "}
                |<span className={styles.bold}>{sectionInfo?.shift_name}</span>
              </Typography>
            </Box>
          </Box>
          <Box className={styles.courseDetailSecond}>
            {/* <Typography variant="p" gutterBottom className={styles.coufee}>
              <span className="bold">{sectionInfo?.fee} PKR</span>
            </Typography> */}

            <Box className={styles.stuViewDetailBtn}>
              <HasPermission permission="manage-sections.edit">
                <Box
                  sx={{ width: "100%", marginBottom: "20px" }}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <SwitchCell
                    id={params.id}
                    is_active={sectionInfo?.status === "active" ? true : false}
                    EndPoint={AdminApi.SECTION_ACTIVE_INACTIVE}
                  />
                </Box>

                <Link className={styles.couViewBtn} to={""} onClick={View}>
                  Edit Section
                </Link>
              </HasPermission>
            </Box>
          </Box>
        </Box>
        <Grid container mt={"40px"}>
          <Card
            apiEndpoint={AdminApi.ENROLLED_LEARNER_TOTAL_COUNT}
            params={params.id}
            reloadEnroll={reloadEnroll}
          />
        </Grid>
        <CourseTabs
          courseId={courseID ?? ""}
          locationId={sectionInfo ? sectionInfo.location_id : ""}
          setTabsCount={setTabsCount}
          courseTitle={courseInfo ? courseInfo.title : ""}
          sectionName={sectionInfo ? sectionInfo.section_name : ""}
          sectionId={sectionInfo ? sectionInfo.id : ""}
          setReloadEnroll={setReloadEnroll}
          cohort_id={sectionInfo?.cohort_id}
          setReRender={setReRender}
        />
      </Box>
    </>
  );
}
