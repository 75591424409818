import { useAuth } from "../../../hooks/auth/auth";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { SvgIcon, TextField } from "@mui/material";
import Image from "../../../static/images/profilepic.jpg";
import BellIcon from "../../../static/images/Bell.svg";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import ImageView from "../../../components/imageShow";

const pages = ["setting"];

export default function MiniDrawer() {
  const { dispatch } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("auth");
    dispatch({ type: "LOGOUT" });
  };
  const authenUser = useAuth();
  const authenUserName = authenUser.state.user.username;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      <AppBar position="static" className="top_header">
        <Toolbar disableGutters className="top_header_inner">
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
            }}
          >
            Logged in as {authenUserName}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box
            className="header-search"
            sx={{ mr: 2, display: "flex", flexDirection: "row-reverse" }}
          >
            <TextField
              placeholder="Search"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
          </Box>

          <Box className="loginUser" sx={{ flexGrow: 0 }}>
            <Grid container alignItems={"center"}>
              <Grid item>
                <ImageView
                  width={50}
                  height={50}
                  name={authenUserName && authenUserName}
                />
              </Grid>
              <Grid item>
                <Typography className="userName"></Typography>
                <Tooltip
                  title={
                    <span className="signOut_tooltip">{authenUserName}</span>
                  }
                >
                  <Typography className="signOut" onClick={logout}>
                    <span>{authenUserName}</span>
                    Sign Out
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
