import * as Yup from "yup";


const validationSchema = Yup.object().shape({

  student_id: Yup.number().required("This field is required"),
  cohort: Yup.string().required("This field is required"),
  section: Yup.string().required("This field is required"),
  registration_date: Yup.string().required("This field is required"),
  shift_days: Yup.string().required("This field is required"),
  shift_time: Yup.string().required("This field is required"),
  household_items: Yup.array()
  .of(
    Yup.string().required("Each household item must have a valid name")
  )
  .min(1, "At least one household item is required"),
  smartphone: Yup.string().required("This field is required"),
  laptopdesktop: Yup.string().required("This field is required"),
  currently_employed: Yup.string().required("This field is required"),
  position: Yup.string().test({
    test: function (value) {
      return this.parent.currently_employed === 'Yes' ? !!value : true;
    },
    message: "This field is required",
  }),
  salary: Yup.string().test({
    test: function (value) {
      return this.parent.currently_employed === 'Yes' ? !!value : true;
    },
    message: "This field is required",
  }),
  employer_name: Yup.string().test({
    test: function (value) {
      return this.parent.currently_employed === 'Yes' ? !!value : true;
    },
    message: "This field is required",
  }),
  code_girls_training_related_work: Yup.string().required("This field is required"),
  code_girls_training_related_work_other: Yup.string().test({
    test: function (value) {
      return this.parent.code_girls_training_related_work === 'Other' ? !!value : true;
    },
    message: "This field is required",
  }),
  interested_in_partner_opportunity: Yup.string().required("This field is required"),
  interested_in_partner_opportunity_other: Yup.string().test({
    test: function (value) {
      return this.parent.interested_in_partner_opportunity === 'Other' ? !!value : true;
    },
    message: "This field is required",
  }),
  opportunity_preference: Yup.string().test({
    test: function (value) {
      return this.parent.interested_in_partner_opportunity === 'Yes' ? !!value : true;
    },
    message: "This field is required",
  }),
  interested_in_advanced_phase: Yup.string().required("This field is required"),
  interested_in_advanced_phase_other: Yup.string().test({
    test: function (value) {
      return this.parent.interested_in_advanced_phase === 'Other' ? !!value : true;
    },
    message: "This field is required",
  }),
  recommend_to_family_friends: Yup.string().required("This field is required"),
  recommend_to_family_friends_other: Yup.string().test({
    test: function (value) {
      return this.parent.recommend_to_family_friends === 'Other' ? !!value : true;
    },
    message: "This field is required",
  }),
  interested_in_events: Yup.string().required("This field is required"),
  interested_in_events_other: Yup.string().test({
    test: function (value) {
      return this.parent.interested_in_events === 'Other' ? !!value : true;
    },
    message: "This field is required",
  }),
  prefer_freelancing: Yup.string().required("This field is required"),
  prefer_freelancing_other: Yup.string().test({
    test: function (value) {
      return this.parent.prefer_freelancing === 'Other' ? !!value : true;
    },
    message: "This field is required",
  }),
  prefer_freelancing: Yup.string().required("This field is required"),
  prefer_freelancing_other: Yup.string().test({
    test: function (value) {
      return this.parent.prefer_freelancing === 'Other' ? !!value : true;
    },
    message: "This field is required",
  }),

});

export default validationSchema;
