import { Typography } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, resolveUrl } from "../../../config/apis";
import Moment from "react-moment";
import { EditOrgTransactions } from "./edit";
import { limitStringLength } from "../../../helper/helperFunction";

export const OrgTransactionsGrid = (props) => {
  const navigate = useNavigate();

  const tableColumns = [
    {
      selector: (row) => `#${row.id}`,
      name: "ID",
      sortable: true,
      minWidth: "120px",
    },
    {
      cell: (row) => (
        <>
          <Typography variant="body1">
            {row.organization_name &&
              limitStringLength(row.organization_name, 35)}
          </Typography>
        </>
      ),
      name: "Organization name",
      sortable: true,
      minWidth: "300px",
      selector: "organization_name",
    },
    {
      selector: (row) => (
        <>
          <Typography variant="body1">
            {row.description && limitStringLength(row.description, 35)}
          </Typography>
        </>
      ),
      name: "Description",
      sortable: true,
      minWidth: "300px",
      selector: "description",
    },

    {
      cell: (row) => (
        <Typography variant="body1">
          <Moment format="MMM DD, YYYY">{row.created_at}</Moment>
        </Typography>
      ),
      name: "Added On",
      minWidth: "230px",
      sortable: true,
      selector: "created_at",
    },

    {
      cell: (row) => (
        <Typography variant="body1" fontWeight={"700"}>
          {row.amount} {row.currency_symbol}
        </Typography>
      ),
      name: "Amount",
      sortable: true,
      minWidth: "200px",
      selector: "amount",
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/organization/view/${id}`);
  };

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.ORGANIZATION_DELETE, id));
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    setReRender(formSuccess);
    setModalOpen(false);
  }, [formSuccess]);

  useEffect(() => {
    if (props.modalOpen) {
      setModalOpen(props.modalOpen);
    }
  }, [props.modalOpen]);

  useEffect(() => {
    if (props.setModalOpen) {
      if (!modalOpen) {
        props.setModalOpen(false);
      }
    }
  }, [modalOpen]);

  return (
    <>
      <div className="page-container table-phases">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.ORGANIZATION_PAGINATION}
          onView={onView}
          onDelete={onDelete}
          filters={props.filters ?? ""}
          sortOrder={props.currentValue ?? "asc"}
          actions={{
            view: true,
            edit: false,
            delete: props.delete ?? true,
          }}
          module="manage-organization"
          refreshHandle={reRender}
        />
      </div>
      <EditOrgTransactions
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        setModalOpen={setModalOpen}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
};
