import {
  Box,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from "@mui/material";
import AdminApi from "../../../config/apis/admin";
import { useEffect, useState } from "react";
import GlobalModal from "../../../components/modalBox";
import Form from "../../../components/forms/Forms";
import SelectOptionsApi from "../../../components/SelectApi/index";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import styles from "./manage-OrgTransactions.module.css";
import { formatDate } from "../../../helper/helperFunction";
import { useParams } from "react-router-dom";
import moment from "moment";

export const EditOrgFunds = (props) => {
  const params = useParams();
  const defaultFormData = {
    organization_id: Number(params.id),
    date: null,
    currency_id: null,
    amount: null,
  };

  const [formData, setFormData] = useState(defaultFormData);

  const handleCloseModal = () => {
    props.setModalOpen(false);
    setErrors({});
    setFormData(defaultFormData);
  };

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.editData && props.viewId) {
      const { datetime, currency_id, amount } = props.editData;
      setFormData({
        organization_id: Number(params.id),
        date: datetime && moment(datetime).format("DD-MM-YYYY"),
        currency_id: currency_id,
        amount: amount,
      });
    }
  }, [props.editData]);

  const schema = yup.object().shape({
    organization_id: yup.string().required("This field required"),
    date: yup.string().required("This field required"),
    amount: yup.string().required("This field required"),
    currency_id: yup.string().required("This field required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  const handleFeeUpdate = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      amount: value ? Number(value) : "",
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  return (
    <>
      <GlobalModal
        open={props.modalOpen}
        onClose={handleCloseModal}
        width={619}
      >
        <Form
          successPath={`/admin/organization/view/${params.id}`}
          submitUrl={
            props.viewId
              ? AdminApi.ORGANIZATION_FUNDS_UPDATE
              : AdminApi.ORGANIZATION_FUNDS
          }
          formData={formData}
          setFormSuccess={props.setFormSuccess}
          editId={props.viewId}
          validate={customValidation}
        >
          <Grid
            container
            justifyContent={"space-between"}
            className={styles.OrgTransactions}
          >
            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Organization Name*
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <SelectOptionsApi
                  apiEndpoint={AdminApi.ORGANIZATIONS}
                  formData={formData}
                  onUpdate={handleUpdate}
                  labelKey="name"
                  valueKey="id"
                  name="organization_id"
                  errorText={errors.organization_id}
                  disabled={true}
                />
              </Grid>
            </Grid>

            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Transaction Date*
                </InputLabel>
              </Grid>
              <Grid item xs={12} className={styles.datePicker}>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  required
                  id="outlined-required"
                  fullWidth
                  name="date"
                  selected={
                    formData.date
                      ? moment(formData.date, "DD-MM-YYYY").toDate()
                      : null
                  }
                  onChange={(date) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      date: date ? moment(date).format("DD-MM-YYYY") : null,
                    }));
                  }}
                  showTimeSelect={false}
                  size="small"
                  placeholderText="Date"
                />
              </Grid>
              {errors.date && (
                <FormHelperText error>{errors.date}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={12}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Funded Amount *
                </InputLabel>
              </Grid>
              <Grid container item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    maxWidth: "250px",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "150px",
                    }}
                  >
                    <SelectOptionsApi
                      apiEndpoint={AdminApi.CURRENCY_VIEW}
                      formData={formData}
                      onUpdate={handleUpdate}
                      name="currency_id"
                      labelKey="symbol"
                      valueKey="id"
                      defaultText="Currency"
                      customStyle={{ border: "0" }}
                    />
                  </Box>
                  <TextField
                    fullWidth
                    name="amount"
                    variant="outlined"
                    placeholder="amount"
                    value={formData.amount}
                    InputProps={{
                      sx: {
                        height: "40px",
                        padding: "0 10px",
                      },
                    }}
                    inputProps={{
                      type: "number",
                      pattern: "[0-9]*",
                    }}
                    onChange={handleFeeUpdate}
                    error={errors.amount !== undefined}
                  />
                </Box>
              </Grid>
              {errors.amount && (
                <FormHelperText error>{errors.amount}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Form>
      </GlobalModal>
    </>
  );
};
