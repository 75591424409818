import { toTitleCase } from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { useNavigate, useParams } from "react-router-dom";
import { resolveUrl } from "../../../config/apis";
import ImageView from "../../../components/imageShow";
import styles from "./style.module.css";

export const WorkshopAttendenceGrid = ({ reRender }) => {
  const navigate = useNavigate();

  const params = useParams();

  const tableColumns = [
    {
      selector: (row) => `#${row.id}`,
      name: "ID",
      sortable: true,
      minWidth: "120px",
    },
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.student_name} />
          {toTitleCase(`${row.student_name ?? ""}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      minWidth: "200px",
      selector: "student_name",
    },
    {
      selector: (row) => row.email,
      name: "Email",
      sortable: true,
      minWidth: "300px",
    },
    {
      cell: (row) => (
        <>
          <p className={styles.text_style}>{row.cohort_name || ""}</p>
        </>
      ),
      name: "Cohort (s)",
      sortable: true,
      selector: "cohort_name",

      // width: "150px",
    },
    {
      cell: (row) => (
        <>
          <p className={styles.text_style}>{row.section_name || ""}</p>
        </>
      ),
      name: "Cohort (s)",
      sortable: true,
      selector: "section_name",

      // width: "150px",
    },
    {
      cell: (row) => (
        <>
          <p className={`${styles.text_style} ${styles.bold}`}>
            {row.status || "N/M"}
          </p>
        </>
      ),
      name: "Attendance",
      sortable: true,
      minWidth: "150px",
      selector: "status",
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/user/student/view/${id}`);
  };

  return (
    <>
      <div
        className={`page-container table-phases ${styles.workshop_attendence_table}`}
      >
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(
            AdminApi.WORKSHOP_ATTENDENCE_PAGINATION,
            params.id ?? 1
          )}
          onView={onView}
          actions={{
            view: true,
            edit: false,
            delete: false,
          }}
          module="manage-work-shop-attendance"
          refreshHandle={reRender}
        />
      </div>
    </>
  );
};
