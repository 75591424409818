import { Box, Grid, Button, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Datatable from "../../../components/dataTables/DataTable";
import { DateView } from "../../../components/DateView/DateView";
import "react-datepicker/dist/react-datepicker.css"; // Import the DatePicker CSS
import "../../../static/css/style.css";
import { formatDate, getDatesById } from "../../../helper/helperFunction";
import DatePicker from "react-datepicker";
import { Typography } from "@mui/material";
import styles from "./style.module.css";
import AttendenceSummary from "./pie-chart/view";
import AdminApi from "../../../config/apis/admin";
import SelectOptionsApi from "../../../components/SelectApi";

export default function Attendence() {
  const tableColumns = [
    {
      cell: (row) => (
        <Typography variant="body1" className="font-s-13">
          <DateView date={row.date} />
        </Typography>
      ),
      name: "Date",
      sortable: true,
      selector: "date",
    },
    {
      selector: (row) => (
        <Tooltip title={row.holiday_reason} arrow>
          <Typography
            variant="body1"
            className="font-s-22"
            style={{
              color:
                row.status === "A"
                  ? "#D3371C"
                  : row.status === "H"
                  ? "#FFAA2A"
                  : "#001C28",
            }}
          >
            {row.status}
          </Typography>
        </Tooltip>
      ),
      name: "Attendance",
      sortable: false,
    },
  ];

  const [filterData, setFilterData] = useState({});
  const [filtersId, setFiltersId] = useState();
  const [optionData, setOptionData] = useState();

  const dates = getDatesById(filterData.section_id, optionData);

  const [filters, setFilters] = useState({});

  const handleUpdate = (name, value) => {
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFiltersId((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "teacher_id") {
      setFilterData({
        teacher_id: value,
        cohort_id: null,
        section_id: null,
        from_date: null,
        to_date: null,
      });
    }
  };

  const handleFilter = () => {
    let obj = {
      section_id: filterData.section_id,
      teacher_id: filterData.teacher_id,
      from_date: formatDate(filterData.from_date),
      to_date: formatDate(filterData.to_date),
    };

    for (let key in obj) {
      if (obj[key] === "") {
        delete obj[key];
      }
    }
    setFilters(obj);
  };

  return (
    <>
      <Box className="container">
        <Box class="course-content">
          <h1 class="main-heading">Teacher’s Attendance</h1>
        </Box>

        <Grid
          container
          spacing={2}
          className={styles.attendance_wrap}
          marginTop={2}
        >
          <Box className={styles.filter_container_Teacher}>
            <Box className={`${styles.item} ${styles.item_1}`}>
              <Typography variant="body2">Teacher</Typography>
              <Box className={styles.select_box}>
                <SelectOptionsApi
                  apiEndpoint={AdminApi.TEACHERS}
                  formData={filterData}
                  onUpdate={handleUpdate}
                  labelKey="name"
                  name="teacher_id"
                  valueKey="id"
                  errorText=""
                  paramId={""}
                  defaultText="Select Teacher"
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.filter_container}>
            <Box className={`${styles.item} ${styles.item_1}`}>
              <Typography variant="body2">Course</Typography>
              <Box className={styles.select_box}>
                <SelectOptionsApi
                  apiEndpoint={
                    filtersId && filtersId.teacher_id
                      ? AdminApi.COURSES_LIST_ATTENDENCE
                      : null
                  }
                  formData={filtersId}
                  onUpdate={handleUpdate}
                  labelKey="title"
                  name="course_id"
                  valueKey="id"
                  errorText=""
                  paramId={filtersId ? filtersId.teacher_id : ""}
                  defaultText="Select Course"
                  disabled={!filtersId || !filtersId.teacher_id}
                />
              </Box>
            </Box>
            <Box className={`${styles.item} ${styles.item_2}`}>
              <Typography variant="body2" className={styles.Cohort_label}>
                Cohort
              </Typography>
              <Box className={styles.select_box}>
                <SelectOptionsApi
                  apiEndpoint={
                    filtersId && filtersId.course_id
                      ? AdminApi.COHORTS_LIST_ATTENDENCE
                      : null
                  }
                  formData={filterData}
                  onUpdate={handleUpdate}
                  labelKey="name"
                  name="cohort_id"
                  valueKey="id"
                  errorText=""
                  paramId={
                    filtersId
                      ? `${filtersId.teacher_id}?course_id=${filtersId.course_id}`
                      : ""
                  }
                  defaultText="Select Cohort"
                  disabled={!filtersId || !filtersId.course_id}
                />
              </Box>
            </Box>
            <Box className={`${styles.item} ${styles.item_3}`}>
              <Typography variant="body2" className={styles.Cohort_label}>
                Section
              </Typography>
              <Box className={styles.select_box}>
                <SelectOptionsApi
                  apiEndpoint={
                    filtersId && filtersId.cohort_id
                      ? AdminApi.COHORTS_SECTIONS_LIST
                      : null
                  }
                  formData={filterData}
                  onUpdate={handleUpdate}
                  labelKey="name"
                  name="section_id"
                  valueKey="id"
                  errorText=""
                  paramId={
                    filtersId
                      ? `${filtersId.teacher_id}?cohort_id=${filtersId.cohort_id}`
                      : ""
                  }
                  optionData={setOptionData}
                  defaultText="Select Section"
                  disabled={!filtersId || !filtersId.cohort_id}
                />
              </Box>
            </Box>
            <Box className={`${styles.item} ${styles.item_4}`}>
              <Typography variant="body2" className={styles.Cohort_label}>
                From Date
              </Typography>
              <Box className={styles.select_box}>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  required
                  id="outlined-required"
                  fullWidth
                  name="from_date"
                  selected={
                    filterData.from_date ? new Date(filterData.from_date) : null
                  }
                  onChange={(date) =>
                    setFilterData((prevFormData) => ({
                      ...prevFormData,
                      from_date: date,
                    }))
                  }
                  showTimeSelect={false}
                  size="small"
                  placeholderText="Date From"
                  minDate={dates && new Date(dates.start_date)}
                  maxDate={dates && new Date(dates.end_date)}
                  startDate={filterData && filterData.from_date}
                  endDate={filterData && filterData.to_date}
                />
              </Box>
            </Box>
            <Box className={`${styles.item} ${styles.item_5}`}>
              <Typography variant="body2" className={styles.Cohort_label}>
                Date To
              </Typography>
              <Box className={styles.select_box}>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  required
                  id="outlined-required"
                  fullWidth
                  selected={
                    filterData.to_date ? new Date(filterData.to_date) : null
                  }
                  name="to_date"
                  onChange={(date) =>
                    setFilterData((prevFormData) => ({
                      ...prevFormData,
                      to_date: date,
                    }))
                  }
                  showTimeSelect={false}
                  size="small"
                  placeholderText="Date To"
                  minDate={dates && new Date(dates.start_date)}
                  maxDate={dates && new Date(dates.end_date)}
                  startDate={filterData && filterData.from_date}
                  endDate={filterData && filterData.to_date}
                />
              </Box>
              <Button
                sx={{
                  backgroundColor: "rgba(0, 28, 40, 1)",
                  color: "#fff",
                  height: "40px",
                  marginLeft: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 28, 40, 1)",
                    color: "#fff",
                    opacity: "0.8",
                  },
                }}
                onClick={() => {
                  handleFilter();
                }}
              >
                Go
              </Button>
            </Box>
          </Box>
          <Grid
            item
            xl={6}
            lg={6}
            md={12}
            xs={12}
            className="table-phases attendence_filter"
          >
            <Datatable
              tableColumns={tableColumns}
              dataUrl={AdminApi.TEACHER_ATTENDENCE}
              actionColumns={false}
              qureyParam={filters}
              module="manage-trainer-attendence"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            <AttendenceSummary filters={filters} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
