import React from 'react';
import FooterLinks from './FooterLinks';
import FooterText from './FooterText';
import "./footer-style.css";


const currentYear = new Date().getFullYear();
const Footer = () => {
  return (
      <footer className={`footer front-footer`}>
        <FooterText text={`Copyright © ${currentYear} LMS. All rights reserved.`} />
        <FooterLinks />
      </footer>
  );
};

export default Footer;