import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import CKEditorComponent from "../../../components/CKEditorComponent";
import Form from "../../../components/forms/Forms";
import styles from "./style.module.css";
import AdminApi from "../../../config/apis/admin";
import validationSchema from "./validation";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { api, resolveUrl } from "../../../config/apis";
import { useParams } from "react-router-dom";
import CreateableSelect from "../../../components/CreateableSelect";
import moment from "moment";

export const JobsEdit = () => {
  const params = useParams();

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [currency, setCurrency] = useState([]);

  const [formData, setFormData] = useState({
    company_name: "",
    Location: "",
    company_overview: "",
    job_title: "",
    job_description: "",
    qualification_id: null,
    skill_id: [],
    job_type: "",
    timings: "",
    salary: "",
    job_status: 0,
    job_posted: "",
    last_date_apply: "",
    currency_id: null,
  });
  const [errors, setErrors] = useState({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(updatedData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };
  const handleInputChange = (event) => {
    const valueTarget = event.target.value;

    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };

  const handleEditorChange = (event, editor) => {
    const editor_data = editor.getData();
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editor_data;
    const links = tempDiv.querySelectorAll("a");
    links.forEach((link) => {
      if (link.host !== window.location.host) {
        link.setAttribute("target", "_blank");
      }
    });

    const data = tempDiv.innerHTML;
    setFormData((prevFormData) => ({
      ...prevFormData,
      job_description: data,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      job_description: undefined,
    }));
  };

  useEffect(() => {
    const fetchQualifications = async () => {
      try {
        const res = await api.get(AdminApi.JOBS_QUALIFICATIONS);
        setQualifications(res.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    const CurrencyView = async () => {
      try {
        const res = await api.get(AdminApi.CURRENCY_VIEW);
        setCurrency(res.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchQualifications();
    CurrencyView();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.JOBS_VIEW, params.id));

        const { data } = res.data;
        setFormData({
          company_name: data.company_name ?? "",
          company_overview: data.company_overview ?? "",
          job_description: data.job_description ?? "",
          job_posted: data.job_posted ?? "",
          job_status: data.job_status ?? 0,
          job_title: data.job_title ?? "",
          job_type: data.job_type ?? "",
          timings: data.timings ?? "",
          last_date_apply: data.last_date_apply ?? "",
          Location: data.location ?? "",
          qualification_id: data.qualification_id ?? null,
          salary: data.salary ?? "",
          skill_id: data.skill_id ?? [],
          currency_id: data.currency_id ?? null,
        });
        setSelectedSkills(data.skill_id ?? []);
      } catch (error) {
        console.error(error);
      }
    };

    if (params.id) {
      fetchData();
    }
  }, [params.id]);

  useEffect(() => {
    if (formData.salary == "") {
      setFormData({
        ...formData,
        currency_id: null,
      });
    }
  }, [formData.salary]);

  const updatedData = {
    ...formData,
    skill_id: selectedSkills,
  };

  return (
    <>
      <Box className="container" marginBottom={5}>
        <Box className="course-content">
          <h1 className="main-heading">Add a Job</h1>
        </Box>
        <Form
          successPath="/admin/jobs"
          submitUrl={AdminApi.JOBS_SAVE}
          formData={updatedData}
          editId={params.id}
          validate={customValidation}
          customClass={styles.job_form}
          cancleShow={true}
        >
          <Grid container spacing={2} maxWidth={"885px"}>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="company_name">Company Name *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                id="company_name"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
                error={errors.company_name !== undefined}
                helperText={errors.company_name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="Location">Location *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                id="Location"
                name="Location"
                value={formData.Location}
                onChange={handleInputChange}
                error={errors.Location !== undefined}
                helperText={errors.Location}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel
                className={styles.company_overview}
                htmlFor="company_overview"
                style={{ whiteSpace: "normal" }}
              >
                Company’s Overview *
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <Textarea
                name="company_overview"
                value={formData.company_overview}
                onChange={handleInputChange}
                minRows={4}
                maxRows={4}
                error={errors.company_overview !== undefined}
              />
              {errors.company_overview && (
                <FormHelperText error>{errors.company_overview}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="job_title">Job Title *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                id="job_title"
                name="job_title"
                value={formData.job_title}
                onChange={handleInputChange}
                error={errors.job_title !== undefined}
                helperText={errors.job_title}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="company_name">Job Description *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <CKEditorComponent
                initialValue={formData.job_description}
                onChange={handleEditorChange}
              />
              {errors.job_description && (
                <FormHelperText error>{errors.job_description}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="qualification_id">
                Qualification *
              </InputLabel>
            </Grid>

            <Grid item xs={12} lg={9.5}>
              <Select
                id="qualification_id"
                name="qualification_id"
                fullWidth
                displayEmpty
                value={formData.qualification_id}
                onChange={handleInputChange}
              >
                <MenuItem value={null}>Select Qualification</MenuItem>

                {qualifications &&
                  qualifications.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              {errors.qualification_id && (
                <FormHelperText error>{errors.qualification_id}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="skill_id">Skills Requires *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <CreateableSelect
                setSelectedSkills={setSelectedSkills}
                selectedSkills={selectedSkills}
              />
              {errors.skill_id && (
                <FormHelperText error>{errors.skill_id}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="job_type">Job Type *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                id="job_type"
                name="job_type"
                value={formData.job_type}
                onChange={handleInputChange}
                error={errors.job_type !== undefined}
                helperText={errors.job_type}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={1.5}>
              <InputLabel htmlFor="job_type" className={styles.job_type}>
                Timings *
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                id="timings"
                name="timings"
                value={formData.timings}
                onChange={handleInputChange}
                error={errors.timings !== undefined}
                helperText={errors.timings}
                fullWidth
              />
            </Grid>

            {/* <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="salary">Timings *</InputLabel>
            </Grid> */}
            {/* <Grid item xs={12} lg={9.5}>
              <Select
                name="currency_id"
                sx={{ maxWidth: { lg: "115px", xs: "30%" }, marginRight: "2%" }}
                fullWidth
                value={formData.currency_id}
                onChange={handleInputChange}
                displayEmpty
              >
                {currency &&
                  currency.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.symbol}
                    </MenuItem>
                  ))}
              </Select>
              <span className={styles.job_Timming_to}>To</span>
              <Select
                name="currency_id"
                sx={{ maxWidth: { lg: "115px", xs: "30%" }, marginLeft: "2%" }}
                fullWidth
                value={formData.currency_id}
                onChange={handleInputChange}
                displayEmpty
              >
                {currency &&
                  currency.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.symbol}
                    </MenuItem>
                  ))}
              </Select>
            </Grid> */}
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="salary">Salary</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                sx={{ maxWidth: { lg: "175px", xs: "68%" } }}
                id="salary"
                name="salary"
                onChange={handleInputChange}
                value={formData.salary}
                fullWidth
              />
              <Select
                name="currency_id"
                sx={{ maxWidth: { lg: "88px", xs: "30%" }, marginLeft: "2%" }}
                fullWidth
                value={formData.currency_id}
                onChange={handleInputChange}
                displayEmpty
              >
                {/* <MenuItem value=""></MenuItem> */}
                {currency &&
                  currency.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.symbol}
                    </MenuItem>
                  ))}
              </Select>
              {errors.currency_id && (
                <FormHelperText error>{errors.currency_id}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="job_status">Job Status *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <Select
                sx={{ maxWidth: { lg: "278px" } }}
                id="job_status"
                name="job_status"
                fullWidth
                value={formData.job_status}
                onChange={handleInputChange}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>In Active</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="company_name">Job Posted On *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <Grid item xs={12} sx={{ maxWidth: { lg: "278px" } }}>
                <DatePicker
                  calendarAriaLabel="Toggle calendar"
                  clearAriaLabel="Clear value"
                  dayAriaLabel="Day"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date"
                  onChange={(date) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      job_posted: moment(date).format("YYYY-MM-DD"),
                    }))
                  }
                  value={
                    formData.job_posted ? new Date(formData.job_posted) : null
                  }
                  yearAriaLabel="Year"
                />
              </Grid>
              {errors.job_posted && (
                <FormHelperText error>{errors.job_posted}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="company_name">
                Last Date to Apply *
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <Grid item xs={12} sx={{ maxWidth: { lg: "278px" } }}>
                <DatePicker
                  calendarAriaLabel="Toggle calendar"
                  clearAriaLabel="Clear value"
                  dayAriaLabel="Day"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date"
                  onChange={(date) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      last_date_apply: moment(date).format("YYYY-MM-DD"),
                    }))
                  }
                  value={
                    formData.last_date_apply
                      ? new Date(formData.last_date_apply)
                      : null
                  }
                  yearAriaLabel="Year"
                />
              </Grid>
              {errors.last_date_apply && (
                <FormHelperText error>{errors.last_date_apply}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Form>
      </Box>
    </>
  );
};
