import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import styles from "./manage-general.module.css";
import { Textarea } from "@mui/joy";
import Form from "../../../components/forms/Forms";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
import * as yup from "yup";

const SettingGeneral = () => {
  const initialFormData = {
    site_name: "",
    meta_data: "",
    description: "",
    logo: null,
    favicon: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [uploadPreview, setUploadPreview] = useState({
    favicon: {
      src: null,
    },
    logo: {
      blob: false,
      src: null,
    },
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Clear the error message for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files ? files[0] : value,
    }));

    if (files && files.length > 0) {
      setUploadPreview((prevUploadPreview) => ({
        ...prevUploadPreview,
        [name]: {
          blob: true,
          src: URL.createObjectURL(files[0]),
        },
      }));
    } else {
      setUploadPreview((prevUploadPreview) => ({
        ...prevUploadPreview,
        [name]: {
          blob: false,
          src: "",
        },
      }));
    }
  };

  const schema = yup.object().shape({
    site_name: yup.string().required("Site Name is required"),
    meta_data: yup.string().required("Meta Data is required"),
    description: yup.string().required("Description is required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      // Additional validation for uploadPreview images
      const newErrors = {};

      if (!uploadPreview.favicon.src) {
        newErrors.favicon = "Favicon is required";
      }

      if (!uploadPreview.logo.src) {
        newErrors.logo = "Logo is required";
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  useEffect(() => {
    const fetchGeneralData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.GENERAL_VIEW));
        const resdata = res.data.data[0];
        setFormData({
          site_name: resdata?.site_name || "",
          meta_data: resdata?.meta_data || "",
          description: resdata?.description || "",
          logo: null,
          favicon: null,
        });
        if (resdata.favicon && resdata.logo) {
          setUploadPreview({
            favicon: {
              blob: false,
              src: `${SERVER_KEY}${resdata.favicon}`,
            },
            logo: {
              blob: false,
              src: `${SERVER_KEY}${resdata.logo}`,
            },
          });
        }
      } catch (error) {
        console.error("Error fetching general data:", error);
      }
    };

    fetchGeneralData();
  }, []);

  useEffect(() => {
    // window.onload = function () {
    const faviconLink = document.querySelector('link[rel="icon"]');
    if (faviconLink) {
      const favicon = `${uploadPreview.favicon.src}`;
      faviconLink.href = favicon;
    } else {
      faviconLink.href = "%PUBLIC_URL%/favicon.ico";
    }
    // };
  }, [uploadPreview]);

  return (
    <Box className={styles.settingGeneralMain}>
      <Grid container className={styles.settingGeneralContainer}>
        <Form
          submitUrl={AdminApi.GENERAL_ADD}
          successPath="/admin/setting"
          formData={formData}
          multipart={true}
          validate={customValidation}
          customClass="gernel_Settings"
        >
          <Grid item xs={12} container className={styles.settingGeneralForm}>
            <Box className={styles.settingGeneralFormFieldBox}>
              <Typography
                variant="body1"
                className={styles.settingGeneralFormFieldLabel}
              >
                Site Name
              </Typography>
              <TextField
                name="site_name"
                value={formData.site_name}
                onChange={handleChange}
                className={styles.settingGeneralFormFieldInput}
                error={errors.site_name !== undefined}
                helperText={errors.site_name}
              />
            </Box>
            <Box className={styles.settingGeneralFormFieldBox}>
              <Typography
                variant="body1"
                className={styles.settingGeneralFormFieldLabel}
              >
                Meta Data
              </Typography>
              <TextField
                name="meta_data"
                value={formData.meta_data}
                onChange={handleChange}
                className={styles.settingGeneralFormFieldInput}
                error={errors.meta_data !== undefined}
                helperText={errors.meta_data}
              />
            </Box>
            <Box className={styles.settingGeneralFormFieldBox}>
              <Typography
                variant="body1"
                className={styles.settingGeneralFormFieldLabel}
              >
                Description
              </Typography>
              <Box width={"100%"}>
                <Textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className={styles.settingGeneralFormFieldTextArea}
                  minRows={4}
                  maxRows={4}
                  error={errors.description !== undefined}
                />
                {errors.description && (
                  <FormHelperText error>{errors.description}</FormHelperText>
                )}
              </Box>
            </Box>
            <Box className={styles.settingGeneralFormFieldBox}>
              <Typography
                variant="body1"
                className={styles.settingGeneralFormFieldLabel}
              >
                Logo
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  component="label"
                  className={styles.settingGeneralFormFieldUpload}
                  error={errors.logo !== undefined}
                >
                  {uploadPreview.logo.src ? (
                    <img
                      className={styles.logo}
                      src={uploadPreview.logo.src}
                      alt="Logo Preview"
                    />
                  ) : (
                    "Logo"
                  )}
                  <input
                    type="file"
                    name="logo"
                    onChange={handleChange}
                    hidden
                  />
                </Button>
                {errors.logo && (
                  <FormHelperText error>{errors.logo}</FormHelperText>
                )}
              </Box>
            </Box>
            <Box className={styles.settingGeneralFormFieldBox}>
              <Typography
                variant="body1"
                className={styles.settingGeneralFormFieldLabel}
              >
                Favicon
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  component="label"
                  className={`${styles.settingGeneralFormFieldUpload} ${styles.favicon}`}
                  error={errors.favicon !== undefined}
                  helperText={errors.favicon}
                >
                  {uploadPreview.favicon.src ? (
                    <img
                      className={styles.logo}
                      src={uploadPreview.favicon.src}
                      alt="Favicon Preview"
                    />
                  ) : (
                    "LMS"
                  )}
                  <input
                    type="file"
                    name="favicon"
                    onChange={handleChange}
                    hidden
                  />
                </Button>
                {errors.favicon && (
                  <FormHelperText error>{errors.favicon}</FormHelperText>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className={styles.settingGeneralCheckBoxContent}>
            <Typography
              variant="h2"
              className={styles.settingGeneralCheckBoxContentTitle}
            >
              General Setting
            </Typography>
            <Box className={styles.settingGeneralCheckBoxContainer}>
              <Box className={styles.sGCBoxContent}>
                <FormControlLabel
                  value="1"
                  control={<Checkbox />}
                  label={undefined}
                />
              </Box>
              <Box className={styles.sGCBoxText}>
                <Typography variant="h4">Site Under Maintenance</Typography>
              </Box>
            </Box>
          </Grid>
        </Form>
      </Grid>
    </Box>
  );
};

export default SettingGeneral;
