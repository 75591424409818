import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  company_name: Yup.string().required("Company name is required"),
  Location: Yup.string().required("Location is required"),
  company_overview: Yup.string().required("Company overview is required"),
  job_title: Yup.string().required("Job title is required"),
  job_description: Yup.string().required("Job description is required"),
  qualification_id: Yup.number().required("Qualification ID is required"),
  skill_id: Yup.array()
    .of(Yup.string().required("This field is required"))
    .min(1, "At least one Skill is required"),
  job_type: Yup.string().required("Job type is required"),
  job_status: Yup.number().required("Job status is required"),
  job_posted: Yup.string().required("Job posted date is required"),
  last_date_apply: Yup.string().required("Last date to apply is required"),

  currency_id: Yup.string()
    .nullable()
    .test({
      test: function (value) {
        return this.parent.salary !== "" ? !!value : true;
      },
      message: "Select is required when salary is provided",
    }),
});

export default validationSchema;
