// FilterButtons.jsx
import React from "react";
import { Button, Grid } from "@mui/material";
import styles from "./style.module.css";

const FilterButtons = ({
  activeButton,
  handleSoFar,
  handleThisWeek,
  handleCurrentMonth,
  handleCurrentYear,
}) => {
  return (
    <Grid item xs={12} marginBottom={2} className={styles.totalJobsContainer_}>
      <Button
        className={`${styles.btns} ${
          activeButton === "soFar" ? styles.active : ""
        }`}
        onClick={handleSoFar}
      >
        So Far
      </Button>
      <Button
        className={`${styles.btns} ${
          activeButton === "thisWeek" ? styles.active : ""
        }`}
        onClick={handleThisWeek}
      >
        This week
      </Button>
      <Button
        className={`${styles.btns} ${
          activeButton === "currentMonth" ? styles.active : ""
        }`}
        onClick={handleCurrentMonth}
      >
        This Month
      </Button>
      <Button
        className={`${styles.btns} ${
          activeButton === "currentYear" ? styles.active : ""
        }`}
        onClick={handleCurrentYear}
      >
        This Year
      </Button>
    </Grid>
  );
};

export default FilterButtons;
