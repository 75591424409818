import React, { useState } from "react";
import { Chip, FormControl, Grid, Typography, TextField } from "@mui/material";
import useTypeText from "./Hooks/useTypeText";

const TypeText = ({ label, updateCourseState }) => {
  const {
    handleDeleteChip,
    handleCustomInputChange,
    handleCustomInputBlur,
    customValue,
  } = useTypeText({
    label,
    updateCourseState,
  });

  return (
    <FormControl fullWidth className="date_range_filter">
      <Grid container alignItems={"center"}>
        <Grid item sx={{ minWidth: "220px", paddingRight: "20px" }}>
          <TextField
            variant="outlined"
            value={customValue}
            onChange={handleCustomInputChange}
            onBlur={handleCustomInputBlur}
            size="small"
            type="text"
            placeholder="Enter Search Keyword"
            fullWidth
            sx={{ maxWidth: "200px" }}
          />
        </Grid>
        <Grid item>
          {customValue !== "" && (
            <Chip
              label={customValue}
              onDelete={handleDeleteChip}
              style={{
                marginRight: "7px",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
            />
          )}
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default TypeText;
