import {
  HasPermission,
  HasPermissionTab,
} from "../../../../helper/helperFunction";
import styles from "./style.module.css";
import DynamicTabs from "../../../../components/Tabs/Tabs";
import { JobsDetailsTab } from "../job-details/job-detail-tab";
import { JobsAplications } from "../job-aplication/job-aplication-tab";

export const Tabs = () => {
  const tabs = [
    {
      label: "Job Detail",
      content: (
        <HasPermission permission="manage-jobs-board.view">
          <JobsDetailsTab />
        </HasPermission>
      ),
    },
    {
      label: "Applicants",
      content: (
        <HasPermission permission="manage-jobs-board.view">
          <JobsAplications />
        </HasPermission>
      ),
    },
  ];

  const allowedTabs = tabs.filter((tab) => {
    const hasPermission = HasPermissionTab(tab.content.props.permission);
    return (
      tab.content !== null &&
      (tab.content.props.permission ? hasPermission : true)
    );
  });

  return (
    <>
      <DynamicTabs tabs={allowedTabs} />
    </>
  );
};
