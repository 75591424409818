import React from "react";
import TypeSelect from "./Filters/TypeSelect";
import TypeMultiSelect from "./Filters/TypeMultiSelect";
import TypeDateSelect from "./Filters/TypeDateSelect";
import TypeRageSelect from "./Filters/TypeRageSelect";
import TypeText from "./Filters/TypeText";
import TypeMultiSelectOrg from "./Filters/TypeMultiSelectOrg";

export const FilterComponent = ({
  label,
  type,
  options,
  updateCourseState,
  courseState,
}) => {
  switch (type) {
    case "select":
      return (
        <>
          <TypeSelect
            label={label}
            options={options}
            updateCourseState={updateCourseState}
          />
        </>
      );
    case "multi_select":
      return (
        <>
          <TypeMultiSelect
            label={label}
            options={options}
            updateCourseState={updateCourseState}
            type={type}
          />
        </>
      );
    case "date_range":
      return (
        <>
          <TypeDateSelect
            label={label}
            options={options}
            updateCourseState={updateCourseState}
            type={type}
            courseState={courseState}
          />
        </>
      );
    case "range":
      return (
        <>
          <TypeRageSelect
            label={label}
            options={options}
            updateCourseState={updateCourseState}
            type={type}
            courseState={courseState}
          />
        </>
      );
    case "text":
      return (
        <>
          <TypeText
            label={label}
            options={options}
            updateCourseState={updateCourseState}
            type={type}
            courseState={courseState}
          />
        </>
      );
    case "org_multiselect":
      return (
        <>
          <TypeMultiSelectOrg
            label={label}
            options={options}
            updateCourseState={updateCourseState}
            type={type}
            courseState={courseState}
          />
        </>
      );

    default:
      return null;
  }
};
