import styles from "./style.module.css";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import AdminApi from "../../../config/apis/admin";
import { api } from "../../../config/apis";
import { DataLoader } from "../../../helper/helperFunction";
import SelectOptionsApi from "../../../components/SelectApi";

export const SelfPaid = (params) => {
  const [filters, setFilters] = useState();
  const [activeButton, setActiveButton] = useState("soFar");
  const filterActive =
    params.filterData.phase_id || params.filterData.cohort_id;

  const [selfPaidData, setPaidData] = useState();
  const [loading, setLoading] = useState(true);
  const getStartAndEndOfWeek = () => {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
    const diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust to the beginning of the week
    const startOfWeek = new Date(now.setDate(diff));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week is 6 days later

    return {
      startOfWeek: formatDate(startOfWeek),
      endOfWeek: formatDate(endOfWeek),
    };
  };
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are 0-indexed
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };

  const getStartAndEndOfMonth = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    return {
      startOfMonth: formatDate(startOfMonth),
      endOfMonth: formatDate(endOfMonth),
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Modify the API call with parameters
        const res = await api.get(
          params.ApiKey ? params.ApiKey : AdminApi.DASH_SELFPAID,
          {
            params: {
              filters: {
                ...params.filterData,
                ...filters,
              },
            },
          }
        );

        setPaidData(res.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    fetchData();
  }, [filters, params.filterData]);

  const handleCurrentYear = () => {
    setFilters({
      year: new Date().getFullYear(),
    });
    setActiveButton("currentYear");
  };
  const handleCurrentMonth = () => {
    const { startOfMonth, endOfMonth } = getStartAndEndOfMonth();

    setFilters({
      from_date: startOfMonth,
      to_date: endOfMonth,
    });
    setActiveButton("currentMonth");
  };
  const handleThisWeek = () => {
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();

    setFilters({
      from_date: startOfWeek,
      to_date: endOfWeek,
    });
    setActiveButton("thisWeek");
  };
  const handleSoFar = () => {
    setFilters();
    setActiveButton("soFar");
  };
  const handleUpdate = (name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <>
      {loading ? (
        <DataLoader />
      ) : (
        <>
          <Box marginTop={4}>
            {params.filter && (
              <Grid item xs={12} className={styles.totalJobsContainer_}>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "soFar" ? styles.active : ""
                  }`}
                  onClick={handleSoFar}
                >
                  So Far
                </Button>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "thisWeek" ? styles.active : ""
                  }`}
                  onClick={handleThisWeek}
                >
                  This week
                </Button>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "currentMonth" ? styles.active : ""
                  }`}
                  onClick={handleCurrentMonth}
                >
                  This Month
                </Button>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "currentYear" ? styles.active : ""
                  }`}
                  onClick={handleCurrentYear}
                >
                  This Year
                </Button>
              </Grid>
            )}
            <Grid
              container
              marginTop={2}
              className={styles.totalJobsContainer_wrap}
            >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {params.location ? (
                    ""
                  ) : (
                    <Box pl={"30px"} sx={{ maxWidth: "215px" }}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.LOCATION_PAGINATE}
                        formData={filters}
                        onUpdate={handleUpdate}
                        labelKey="name"
                        name="location"
                        valueKey="id"
                        errorText=""
                        paramId={""}
                        defaultText="Select location"
                      />
                    </Box>
                  )}
                </Grid>
                <Box className={styles.totalJobsContainer}>
                  <Box className={styles.left_box}>
                    <Typography variant="h6">
                      Self Paid {filterActive ? "**" : ""}
                    </Typography>

                    <Typography variant="h4">
                      {selfPaidData && selfPaidData.self_paid_fees
                        ? "$" + selfPaidData.self_paid_fees.toFixed(2)
                        : ""}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box className={styles.imageContainer}>
                      <svg
                        width="30"
                        height="29"
                        viewBox="0 0 30 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_9341_67567)">
                          <path
                            d="M15.9141 3.62573H23.5329V5.55193H15.9141V3.62573Z"
                            fill="white"
                          />
                          <path
                            d="M12.207 13.2504C15.2364 14.197 17.5868 16.6161 18.3513 19.631H28.1186V0H12.207V13.2504ZM25.3523 18.3421H23.4939V16.6425H25.3524V18.3421H25.3523ZM25.2909 1.9262V7.25157H14.1556V1.9262H25.2909Z"
                            fill="white"
                          />
                          <path
                            d="M18.6068 21.3306C18.6795 22.8131 18.3765 24.2681 17.7227 25.5937H28.1193V21.3306H18.6068Z"
                            fill="white"
                          />
                          <path
                            d="M9.36934 14.5186C5.23911 14.5186 1.87891 17.7667 1.87891 21.7593C1.87891 25.7519 5.23911 29.0001 9.36934 29.0001C13.4996 29.0001 16.8598 25.7519 16.8598 21.7593C16.8598 17.7667 13.4995 14.5186 9.36934 14.5186ZM8.87101 20.9095H9.86738C11.386 20.9095 12.6216 22.1038 12.6216 23.5719C12.6216 24.9149 11.5873 26.0282 10.2484 26.2082V27.1089H8.49024V26.2083C7.1512 26.0284 6.1168 24.915 6.1168 23.5719H7.87499C7.87499 24.1028 8.32181 24.5347 8.87101 24.5347H9.86738C10.4166 24.5347 10.8634 24.1028 10.8634 23.5719C10.8634 23.041 10.4166 22.6091 9.86738 22.6091H8.87101C7.35234 22.6091 6.1168 21.4147 6.1168 19.9467C6.1168 18.6036 7.1512 17.4902 8.49024 17.3103V16.4096H10.2484V17.3103C11.5875 17.4902 12.6219 18.6036 12.6219 19.9467H10.8637C10.8637 19.4158 10.4169 18.9839 9.86767 18.9839H8.87101C8.32181 18.9839 7.87499 19.4158 7.87499 19.9467C7.87499 20.4775 8.32181 20.9095 8.87101 20.9095Z"
                            fill="white"
                          />
                          <path
                            d="M10.4505 12.8803V0H9.28556C8.24981 0 7.41016 0.811666 7.41016 1.81289V13.0216C8.40611 12.8131 9.42804 12.7647 10.4505 12.8803Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_9341_67567">
                            <rect width="30" height="29" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
