// import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
// import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DiscountFormModal from "./modal";
import { DateView } from "../../../components/DateView/DateView";
import Flag from "../../../static/images/Flag.png";
import FlagFalse from "../../../static/images/Flag-false.png";
import EditPermissionRole from "./edit-permission";
import { HasPermission } from "../../../helper/helperFunction";

export default function ViewRoles() {
  // const navigate = useNavigate();
  const [viewId, setViewId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reRender, setReRender] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const Flags = <img src={Flag} alt="Flag" />;
  const FlagsFalse = <img src={FlagFalse} alt="Flag" />;

  const tableColumns = [
    {
      selector: (row) => row.name,
      name: "Name",
      minWidth: "900px",
      sortable: true,
    },
    {
      selector: (row) => row.is_support_admin,
      cell: (row) => (row.is_support_admin ? Flags : FlagsFalse),
      name: "Is Admin",
      sortable: true,
    },
    {
      cell: (row) => <DateView date={row.created_at} />,
      name: "Created on",
      sortable: true,
      selector: "created_at",
    },
  ];

  const onEdit = async (id) => {
    setViewId(id);
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.ROLES_DELETE, id));
  };

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);

  return (
    <>
      <DiscountFormModal
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
      {viewId && viewId ? (
        <EditPermissionRole id={viewId} setViewId={setViewId} />
      ) : (
        <>
          <div className="page-container table-phases">
            <Datatable
              tableColumns={tableColumns}
              dataUrl={AdminApi.ROLES_PAGINATE}
              onEdit={onEdit}
              onDelete={onDelete}
              refreshHandle={reRender}
              actions={{
                view: false,
              }}
              module="manage-roles"
            />
          </div>

          <HasPermission permission="manage-roles.create">
            <div className="course_content">
              <Box className="btn-wrapper">
                <Link to={""} onClick={toggleModal}>
                  Add Role
                </Link>
              </Box>
            </div>
          </HasPermission>
        </>
      )}
    </>
  );
}
