import React, { useState, useEffect } from "react";
import { Box, Grid, InputLabel, TextField, MenuItem } from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";

const AdminForm = ({ id, setFormSuccess }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    gender: "",
    original_location_id: null,
    role_id: null,
  });
  console.log(id, "id");
  const [roles, setRoles] = useState([]);

  const [locations, setLocations] = useState([]);

  const [errors, setErrors] = useState({});
  console.log(errors, "errors");

  const handleOnChange = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });
  };

  const schema = yup.object().shape({
    first_name: yup.string().required("Fields are required"),
    last_name: yup.string().required("Fields are required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    gender: yup.string().required("Fields are required"),
    original_location_id: yup.string().required("Fields are required"),
    role_id: yup.string().required("Fields are required"),
    password: yup
      .string()
      .nullable()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number"
      )
      .min(8, "Password must be at least 8 characters"),
  });

  if (!id) {
    schema.fields.password = yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number"
      )
      .min(8, "Password must be at least 8 characters")
      .required("Password is required");
  }
  console.log(schema, "schema");
  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleFormSuccess = async (response) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.ADMIN_VIEW, id));

        setFormData({
          first_name: res.data.data.first_name || "",
          last_name: res.data.data.last_name || "",
          email: res.data.data.email || "",
          gender: res.data.data.gender || "",
          original_location_id: res.data.data.location_id || "",
          role_id: res.data.data.role_id || "",
        });
      } catch (error) {
        console.error("Error fetching general data:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.ADMIN_ROLE_PAGINATE));
        setRoles(res.data.data);
      } catch (error) {
        // Error handling
      }
    };

    const fetchLocations = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.LOCATION_PAGINATE));
        setLocations(res.data.data);
      } catch (error) {
        // Error handling
      }
    };

    fetchData();
    fetchRoles();
    fetchLocations();
  }, [id]);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.ADMIN_SAVE}
              formData={formData}
              editId={id}
              onSuccess={handleFormSuccess}
              validate={customValidation}
              successPath="/admin/setting"
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    First Name*
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter first name"
                    value={formData.first_name}
                    name="first_name"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.first_name !== undefined}
                    helperText={errors.first_name}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Last Name*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter last name"
                    value={formData.last_name}
                    name="last_name"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.last_name !== undefined}
                    helperText={errors.last_name}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Email*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter email"
                    value={formData.email}
                    name="email"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.email !== undefined}
                    helperText={errors.email}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Password*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter password"
                    value={formData.password}
                    name="password"
                    variant="outlined"
                    type="password"
                    onChange={handleOnChange}
                    error={errors.password !== undefined}
                    helperText={errors.password}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Gender*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    select
                    fullWidth
                    value={formData.gender}
                    name="gender"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.gender !== undefined}
                    helperText={errors.gender}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Location*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    select
                    fullWidth
                    placeholder="Enter location"
                    value={formData.original_location_id}
                    name="original_location_id"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.original_location_id !== undefined}
                    helperText={errors.original_location_id}
                  >
                    {locations &&
                      locations.map((location, index) => (
                        <MenuItem key={index.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Role*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    select
                    fullWidth
                    value={formData.role_id}
                    name="role_id"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.role_id !== undefined}
                    helperText={errors.role_id}
                  >
                    {roles &&
                      roles.map((role, index) => (
                        <MenuItem key={index.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminForm;
