import styles from "./style.module.css";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import AdminApi from "../../../config/apis/admin";
import { api } from "../../../config/apis";
import { DataLoader } from "../../../helper/helperFunction";
import SelectOptionsApi from "../../../components/SelectApi";

export const RegistrationFees = (params) => {
  const [filters, setFilters] = useState();
  const [activeButton, setActiveButton] = useState("soFar");
  const filterActive =
    params.filterData.phase_id || params.filterData.cohort_id;

  const [regData, setRegData] = useState();
  const [loading, setLoading] = useState(true);
  const getStartAndEndOfWeek = () => {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
    const diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust to the beginning of the week
    const startOfWeek = new Date(now.setDate(diff));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week is 6 days later

    return {
      startOfWeek: formatDate(startOfWeek),
      endOfWeek: formatDate(endOfWeek),
    };
  };
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are 0-indexed
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };

  const getStartAndEndOfMonth = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    return {
      startOfMonth: formatDate(startOfMonth),
      endOfMonth: formatDate(endOfMonth),
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Modify the API call with parameters
        const res = await api.get(
          params.ApiKey ? params.ApiKey : AdminApi.DASH_REGISTRATION_FEES,
          {
            params: {
              filters: {
                ...params.filterData,
                ...filters,
              },
            },
          }
        );

        setRegData(res.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    fetchData();
  }, [filters, params.filterData]);

  const handleCurrentYear = () => {
    setFilters({
      year: new Date().getFullYear(),
    });
    setActiveButton("currentYear");
  };
  const handleCurrentMonth = () => {
    const { startOfMonth, endOfMonth } = getStartAndEndOfMonth();

    setFilters({
      from_date: startOfMonth,
      to_date: endOfMonth,
    });
    setActiveButton("currentMonth");
  };
  const handleThisWeek = () => {
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();

    setFilters({
      from_date: startOfWeek,
      to_date: endOfWeek,
    });
    setActiveButton("thisWeek");
  };
  const handleSoFar = () => {
    setFilters();
    setActiveButton("soFar");
  };
  const handleUpdate = (name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <>
      {loading ? (
        <DataLoader />
      ) : (
        <>
          <Box marginTop={4}>
            {params.filter && (
              <Grid item xs={12} className={styles.totalJobsContainer_}>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "soFar" ? styles.active : ""
                  }`}
                  onClick={handleSoFar}
                >
                  So Far
                </Button>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "thisWeek" ? styles.active : ""
                  }`}
                  onClick={handleThisWeek}
                >
                  This week
                </Button>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "currentMonth" ? styles.active : ""
                  }`}
                  onClick={handleCurrentMonth}
                >
                  This Month
                </Button>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "currentYear" ? styles.active : ""
                  }`}
                  onClick={handleCurrentYear}
                >
                  This Year
                </Button>
              </Grid>
            )}
            <Grid
              container
              marginTop={2}
              className={styles.totalJobsContainer_wrap}
            >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {params.location ? (
                    ""
                  ) : (
                    <Box pl={"30px"} sx={{ maxWidth: "215px" }}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.LOCATION_PAGINATE}
                        formData={filters}
                        onUpdate={handleUpdate}
                        labelKey="name"
                        name="location"
                        valueKey="id"
                        errorText=""
                        paramId={""}
                        defaultText="Select location"
                      />
                    </Box>
                  )}
                </Grid>
                <Box className={styles.totalJobsContainer}>
                  <Box className={styles.left_box}>
                    <Typography variant="h6">
                      Registration Fees {filterActive ? "**" : ""}
                    </Typography>

                    <Typography variant="h4">
                      {regData && regData.registration_fees
                        ? "$" + regData.registration_fees.toFixed(2)
                        : ""}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box className={styles.imageContainer}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="35"
                        viewBox="0 0 36 35"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_7943_72576)">
                          <path
                            d="M2.46484 21.8389H14.435V24.3859H2.46484V21.8389Z"
                            fill="white"
                          />
                          <path
                            d="M0.128906 17.2983H12.099V19.8453H0.128906V17.2983Z"
                            fill="white"
                          />
                          <path
                            d="M0.128906 26.3796H12.099V28.9266H0.128906V26.3796Z"
                            fill="white"
                          />
                          <path
                            d="M4.53516 5.68774V15.3049H14.1501V19.8454H16.4872V24.7927H35.1278V5.68774H4.53516ZM12.5918 10.2656H11.2643C10.9442 11.1851 10.194 11.9139 9.24754 12.2247V13.5147H7.19538V10.3945H8.22146C8.86037 10.3945 9.38018 9.88951 9.38018 9.26886V8.27209H12.5918V10.2656ZM20.8575 20.4536V21.9271H18.8054V20.456C17.2467 20.2622 16.0378 18.9662 16.0378 17.4003H18.09C18.09 18.0002 18.5924 18.4883 19.21 18.4883H20.4315C21.0704 18.4883 21.5902 17.9834 21.5902 17.3627C21.5902 16.7421 21.0704 16.2371 20.4315 16.2371H19.2314C17.4608 16.2371 16.0204 14.8378 16.0204 13.1179C16.0204 11.5384 17.2356 10.2302 18.8054 10.027V8.55344H20.8575V10.0304C22.4147 10.2452 23.6163 11.5481 23.6163 13.1192H21.5642C21.5642 12.4977 21.0437 11.9922 20.4041 11.9922H19.2315C18.5925 11.9922 18.0727 12.4972 18.0727 13.1178C18.0727 13.7385 18.5925 14.2435 19.2315 14.2435H20.4316C22.202 14.2435 23.6424 15.6428 23.6424 17.3627C23.6424 18.9422 22.4273 20.2504 20.8575 20.4536ZM32.4676 20.0858H31.4415C30.8026 20.0858 30.2828 20.5908 30.2828 21.2114V22.2082H27.0711V20.2147H28.3987C28.7187 19.2952 29.469 18.5664 30.4154 18.2556V16.9661H32.4676V20.0858ZM32.4676 13.5148H30.4154V12.2248C29.4689 11.9139 28.7187 11.1851 28.3987 10.2657H27.0711V8.27216H30.2828V9.26892C30.2828 9.88964 30.8026 10.3945 31.4415 10.3945H32.4676V13.5148Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7943_72576">
                            <rect
                              width="35"
                              height="34"
                              fill="white"
                              transform="translate(0.128906 0.307129)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
