import { useState, useEffect, useRef } from "react";
import {
  InputLabel,
  TextField,
  Typography,
  FormHelperText,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { Grid } from "@mui/material";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import styles from "./style.module.css";
import validationSchema from "./validation";
import FrontendApi from "../../../config/apis/frontend";
import Form from "../../../components/forms/Forms";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { ToastContainer } from "react-toastify";
import StudentApi from "../../../config/apis/student";
import { api, resolveUrl } from "../../../config/apis";
import moment from "moment";
import { GetUserId } from "../../../helper/helperFunction";

export default function PostAssessmentForm(props) {
  const [formSuccess, setFormSuccess] = useState(false);
  const studentId = GetUserId();

  const intaialFormData = {
    student_id: "",
    cohort: "",
    section: "",
    registration_date: "",
    shift_days: "",
    shift_time: "",
    household_items: [],
    smartphone: "",
    laptopdesktop: "",
    currently_employed: "",
    position: "",
    salary: "",
    employer_name: "",
    code_girls_training_related_work: "",
    code_girls_training_related_work_other: "",
    interested_in_partner_opportunity: "",
    interested_in_partner_opportunity_other: "",
    opportunity_preference: "",
    interested_in_advanced_phase: "",
    interested_in_advanced_phase_other: "",
    recommend_to_family_friends: "",
    recommend_to_family_friends_other: "",
    interested_in_events: "",
    interested_in_events_other: "",
    prefer_freelancing: "",
    prefer_freelancing_other: "",
  };
  const [formData, setFormData] = useState(intaialFormData);
  // const inputRef = useRef(null);

  useEffect(() => {
    setFormData(intaialFormData);
    if (props.setFormSuccess) {
      props.setFormSuccess(formSuccess);
    }
  }, [formSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(StudentApi.GET_STUDENT_DATA);
        const {
          CohortName,
          RegistrationDate,
          SectionName,
          ShiftDays,
          ShiftTime,
          StudentID,
        } = res.data.data;

        setFormData({
          ...formData,
          student_id: StudentID,
          cohort: CohortName,
          section: SectionName,
          registration_date: RegistrationDate,
          shift_days: ShiftDays,
          shift_time: ShiftTime,
        });
      } catch (error) {
        console.error("Error fetching general data:", error);
      }
    };
    if (studentId) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchEditData = async () => {
      try {
        const res = await api.get(
          resolveUrl(StudentApi.GET_POST_ASSESSMENT_FORM, studentId)
        );
        const itemsWithoutSpaces = res.data.data.household_items.map((item) =>
          item.replace(/^\s+/g, "")
        );

        setFormData({
          ...formData,
          ...res.data.data,
          household_items: itemsWithoutSpaces,
        });
      } catch (error) {
        console.error("Error fetching general data:", error);
      }
    };
    if (studentId) {
      fetchEditData();
    }
  }, [studentId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  const handleInputChangeNumber = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value ? Number(value) : null,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const [errors, setErrors] = useState({});

  // const customValidation = async () => {
  //   try {
  //     await validationSchema.validate(formData, { abortEarly: false });
  //     setErrors({});
  //     return true;
  //   } catch (error) {
  //     const newErrors = {};
  //     if (error.inner) {
  //       // inputRef.current.focus();
  //       error.inner.forEach((validationError) => {
  //         newErrors[validationError.path] = validationError.message;
  //       });
  //       console.error("Validation Errors:", newErrors);
  //     } else {
  //       console.error(error);
  //     }

  //     setErrors(newErrors);
  //     return false;
  //   }
  // };

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      const newErrors = {};
      if (error.inner) {
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
      } else {
        console.error(error);
      }

      setErrors(newErrors);

      // const firstErrorField = Object.keys(newErrors)[0];
      // console.error(firstErrorField,inputRef.current);
      // if (firstErrorField && inputRef.current) {
      //   inputRef.current.scrollIntoView({ behavior: "smooth" });
      // }

      return false;
    }
  };

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: theme.palette.primary.main,
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  const handleHouseholdCheckbox = (item) => {
    setFormData((prevFormData) => {
      const updatedHouseholdItems = prevFormData.household_items.includes(item)
        ? prevFormData.household_items.filter(
            (selectedItem) => selectedItem !== item
          )
        : [...prevFormData.household_items, item];

      return {
        ...prevFormData,
        household_items: updatedHouseholdItems,
      };
    });
  };

  const handleHouseholdGroupChange = (event) => {
    setFormData({
      ...formData,
      household_items: [event.target.value],
    });
  };

  return (
    <>
      <div
        className={`container global-user ${styles.preAssessmentFormContainer}`}
      >
        <Typography variant="h1" className="main-heading">
          Post Assessment Form
        </Typography>
        <Grid container className={`page-container ${styles.addStudent}`}>
          <Grid item xs={12} className="inner-container">
            <Form
              successPath="/admin/post-assessment"
              submitUrl={StudentApi.POST_ASSESSMENT_FORM}
              formData={formData}
              validate={customValidation}
              setFormSuccess={setFormSuccess}
              editId={studentId}
              saveText="Submit"
            >
              <Grid container justifyContent={"space-between"}>
                <Grid item lg={12}>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 1, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          xs={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Registration Number/Student ID*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          sx={{ backgroundColor: "#F4F4F4" }}
                          name="student_id"
                          variant="outlined"
                          value={formData.student_id}
                          onChange={(e) => handleInputChangeNumber(e)}
                          error={errors.student_id !== undefined}
                          helperText={errors.student_id}
                          type="number"
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 1, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Cohort*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          sx={{ backgroundColor: "#F4F4F4" }}
                          name="cohort"
                          variant="outlined"
                          placeholder="Enter Cohort"
                          value={formData.cohort}
                          onChange={handleInputChange}
                          error={errors.cohort !== undefined}
                          helperText={errors.cohort}
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 1, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Cohort Section*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ backgroundColor: "#F4F4F4" }}
                          fullWidth
                          name="section"
                          variant="outlined"
                          placeholder="Enter Cohort Section"
                          value={formData.section}
                          onChange={handleInputChange}
                          error={errors.section !== undefined}
                          helperText={errors.section}
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 1, alignItems: "center" }}
                    >
                      <Grid item xl={12} sx={{ mb: 2 }}>
                        <InputLabel> Registration Date* </InputLabel>
                      </Grid>
                      <Grid item xs={12} className="bg_field">
                        <DatePicker
                          calendarAriaLabel="Toggle calendar"
                          clearAriaLabel="Clear value"
                          dayAriaLabel="Day"
                          monthAriaLabel="Month"
                          nativeInputAriaLabel="Date"
                          disabled
                          onChange={(date) =>
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              registration_date: date
                                ? moment(date).format("YYYY-MM-DD")
                                : null,
                            }))
                          }
                          value={
                            formData.registration_date
                              ? new Date(formData.registration_date)
                              : null
                          }
                          yearAriaLabel="Year"
                        />
                        {errors.registration_date && (
                          <FormHelperText error>
                            {errors.registration_date}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 1, alignItems: "center" }}
                    >
                      <Grid item xl={3} sx={{ mb: 2 }}>
                        <InputLabel> Shift Day* </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          sx={{ backgroundColor: "#F4F4F4" }}
                          name="shift_days"
                          variant="outlined"
                          placeholder="Enter Cohort Section"
                          value={formData.shift_days}
                          onChange={handleInputChange}
                          error={errors.shift_days !== undefined}
                          helperText={errors.shift_days}
                          disabled
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={6}
                      xs={12}
                      container
                      sx={{ mb: 1, alignItems: "center" }}
                    >
                      <Grid item xl={3} sx={{ mb: 2 }}>
                        <InputLabel> Shift Time* </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          sx={{ backgroundColor: "#F4F4F4" }}
                          name="shift_time"
                          variant="outlined"
                          placeholder="Enter Cohort Section"
                          value={formData.shift_time}
                          onChange={handleInputChange}
                          error={errors.shift_time !== undefined}
                          helperText={errors.shift_time}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Which Of The Following Do You Have In Your Household?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        name="household_items"
                        defaultValue="first"
                        onChange={handleHouseholdGroupChange}
                      >
                        {[
                          "A Smartphone",
                          "A Tablet",
                          "A Desktop",
                          "A Laptop",
                          "Broadband Connection",
                        ].map((item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={item}
                              control={
                                <Checkbox
                                  checked={formData.household_items.includes(
                                    item
                                  )}
                                  onChange={() => handleHouseholdCheckbox(item)}
                                />
                              }
                              label={item}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Grid>
                    {errors.household_items && (
                      <FormHelperText error>
                        {errors.household_items}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Do You Own A Smartphone?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="smartphone"
                        defaultValue="first"
                        onChange={handleInputChange}
                        value={formData.smartphone}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.smartphone && (
                      <FormHelperText error>{errors.smartphone}</FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Do You Own A Laptop/Desktop?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="laptopdesktop"
                        defaultValue="first"
                        value={formData.laptopdesktop}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.laptopdesktop && (
                      <FormHelperText error>
                        {errors.laptopdesktop}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Are You Currently Employed?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="currently_employed"
                        defaultValue="first"
                        value={formData.currently_employed}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.currently_employed && (
                      <FormHelperText error>
                        {errors.currently_employed}
                      </FormHelperText>
                    )}
                  </Grid>
                  {formData.currently_employed === "Yes" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xl={4}
                          lg={6}
                          xs={12}
                          container
                          sx={{ mb: 1, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              Please Add The Position*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="position"
                              variant="outlined"
                              placeholder="Please Add The Position"
                              value={formData.position}
                              onChange={handleInputChange}
                              error={errors.position !== undefined}
                              helperText={errors.position}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xl={4}
                          lg={6}
                          xs={12}
                          container
                          sx={{ mb: 1, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              What Is Your Salary?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="salary"
                              variant="outlined"
                              placeholder="What Is Your Salary?"
                              value={formData.salary}
                              onChange={handleInputChange}
                              error={errors.salary !== undefined}
                              helperText={errors.salary}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xl={4}
                          lg={6}
                          xs={12}
                          container
                          sx={{ mb: 1, alignItems: "center" }}
                        >
                          <Grid item xs={12} sx={{ mb: 2 }}>
                            <InputLabel
                              sx={{
                                textAlign: "left",
                                whiteSpace: "normal",
                                lineHeight: "18px",
                              }}
                            >
                              Your Employer Name?*
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="employer_name"
                              variant="outlined"
                              placeholder="Your Employer Name?"
                              value={formData.employer_name}
                              onChange={handleInputChange}
                              error={errors.employer_name !== undefined}
                              helperText={errors.employer_name}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Did You Find Work Related To The Training You Received
                        At CodeGirls?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="code_girls_training_related_work"
                        defaultValue="first"
                        value={formData.code_girls_training_related_work}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Other"
                          label="Other"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.code_girls_training_related_work && (
                      <FormHelperText error>
                        {errors.code_girls_training_related_work}
                      </FormHelperText>
                    )}
                  </Grid>
                  {formData.code_girls_training_related_work === "Other" && (
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Other*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="code_girls_training_related_work_other"
                          variant="outlined"
                          placeholder="Please Give Your Tech Training Details"
                          value={
                            formData.code_girls_training_related_work_other
                          }
                          onChange={handleInputChange}
                          error={
                            errors.code_girls_training_related_work_other !==
                            undefined
                          }
                          helperText={
                            errors.code_girls_training_related_work_other
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Would You Be Interested In Doing An Internship/Job
                        Offered By One Of Our Partners?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="interested_in_partner_opportunity"
                        defaultValue="first"
                        value={formData.interested_in_partner_opportunity}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Other"
                          label="Other"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.interested_in_partner_opportunity && (
                      <FormHelperText error>
                        {errors.interested_in_partner_opportunity}
                      </FormHelperText>
                    )}
                  </Grid>
                  {formData.interested_in_partner_opportunity === "Other" && (
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Other*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="interested_in_partner_opportunity_other"
                          variant="outlined"
                          placeholder="Please Give Your Tech Training Details"
                          value={
                            formData.interested_in_partner_opportunity_other
                          }
                          onChange={handleInputChange}
                          error={
                            errors.interested_in_partner_opportunity_other !==
                            undefined
                          }
                          helperText={
                            errors.interested_in_partner_opportunity_other
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  {formData.interested_in_partner_opportunity === "Yes" && (
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          What Will You Prefer?*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          name="opportunity_preference"
                          defaultValue="first"
                          value={formData.opportunity_preference}
                          onChange={handleInputChange}
                        >
                          <MyFormControlLabel
                            value="A Full-Time Office Job"
                            label="A Full-Time Office Job"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="A Part-Time Office Job"
                            label="A Part-Time Office Job"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="A Full-Time Remote Job"
                            label="A Full-Time Remote Job"
                            control={<Radio />}
                          />
                          <MyFormControlLabel
                            value="A Part-Time Remote Job"
                            label="A Part-Time Remote Job"
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </Grid>
                      {errors.opportunity_preference && (
                        <FormHelperText error>
                          {errors.opportunity_preference}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Would You Be Interested In Doing An Advanced Phase Of
                        CodeGirls?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="interested_in_advanced_phase"
                        defaultValue="first"
                        value={formData.interested_in_advanced_phase}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Other"
                          label="Other"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.interested_in_advanced_phase && (
                      <FormHelperText error>
                        {errors.interested_in_advanced_phase}
                      </FormHelperText>
                    )}
                  </Grid>
                  {formData.interested_in_advanced_phase === "Other" && (
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Other*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="interested_in_advanced_phase_other"
                          variant="outlined"
                          placeholder="Please Give Your Tech Training Details"
                          value={formData.interested_in_advanced_phase_other}
                          onChange={handleInputChange}
                          error={
                            errors.interested_in_advanced_phase_other !==
                            undefined
                          }
                          helperText={errors.interested_in_advanced_phase_other}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Would You Recommend This Program To Your
                        Family/Friends?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="recommend_to_family_friends"
                        defaultValue="first"
                        value={formData.recommend_to_family_friends}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Other"
                          label="Other"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.recommend_to_family_friends && (
                      <FormHelperText error>
                        {errors.recommend_to_family_friends}
                      </FormHelperText>
                    )}
                  </Grid>
                  {formData.recommend_to_family_friends === "Other" && (
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Other*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="recommend_to_family_friends_other"
                          variant="outlined"
                          placeholder="Please Give Your Tech Training Details"
                          value={formData.recommend_to_family_friends_other}
                          onChange={handleInputChange}
                          error={
                            errors.recommend_to_family_friends_other !==
                            undefined
                          }
                          helperText={errors.recommend_to_family_friends_other}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Would You Be Interested In Attending The Networking
                        Events/Seminars/Lectures/Workshops Hosted By ConsulNet
                        And/Or WomenInTechPK?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="interested_in_events"
                        defaultValue="first"
                        value={formData.interested_in_events}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Other"
                          label="Other"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.interested_in_events && (
                      <FormHelperText error>
                        {errors.interested_in_events}
                      </FormHelperText>
                    )}
                  </Grid>
                  {formData.interested_in_events === "Other" && (
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Other*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="interested_in_events_other"
                          variant="outlined"
                          placeholder="Please Give Your Tech Training Details"
                          value={formData.interested_in_events_other}
                          onChange={handleInputChange}
                          error={
                            errors.interested_in_events_other !== undefined
                          }
                          helperText={errors.interested_in_events_other}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Would You Prefer Freelancing Over A Full-Time/Part-Time
                        Job?*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        name="prefer_freelancing"
                        defaultValue="first"
                        value={formData.prefer_freelancing}
                        onChange={handleInputChange}
                      >
                        <MyFormControlLabel
                          value="Yes"
                          label="Yes"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="No"
                          label="No"
                          control={<Radio />}
                        />
                        <MyFormControlLabel
                          value="Other"
                          label="Other"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </Grid>
                    {errors.prefer_freelancing && (
                      <FormHelperText error>
                        {errors.prefer_freelancing}
                      </FormHelperText>
                    )}
                  </Grid>
                  {formData.prefer_freelancing === "Other" && (
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Other*
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="prefer_freelancing_other"
                          variant="outlined"
                          placeholder="Please Give Your Tech Training Details"
                          value={formData.prefer_freelancing_other}
                          onChange={handleInputChange}
                          error={errors.prefer_freelancing_other !== undefined}
                          helperText={errors.prefer_freelancing_other}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </div>
      <ToastContainer />
    </>
  );
}
