// useReportState.js
import { useEffect, useState } from "react";

const useReportState = () => {
  const [courseState, setCourseState] = useState({});
  const [courseFilter, setCourseFilter] = useState({
    section_id: "",
    cohort_id: "",
    course_id: "",
  });

  const updateCourseState = (newState) => {
    setCourseState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };
  const UpdateCourseFilter = (newState) => {
    setCourseFilter((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return {
    courseState,
    updateCourseState,
    UpdateCourseFilter,
    courseFilter,
    setCourseState,
  };
};

export default useReportState;
