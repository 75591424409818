import {
  Box,
  Typography,
  Grid,
  Select,
  Button,
  Checkbox,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MenuItem } from "@mui/material";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useParams, useNavigate, json } from "react-router-dom";
import { DateView } from "../../../components/DateView/DateView";
import "react-datepicker/dist/react-datepicker.css"; // Import the DatePicker CSS
import "../../../static/css/style.css";
import Form from "../../../components/forms/Forms";
import GlobalModal from "../../../components/modalBox";
import ImageView from "../../../components/imageShow";
import { HasPermission } from "../../../helper/helperFunction";
import { formatDate } from "../../../helper/helperFunction";
import DatePicker from "react-datepicker";
import Tooltip from "@mui/material/Tooltip";

export default function ViewAttendance(props) {
  const params = useParams();
  const [viewId, setViewId] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null); // Initialize as null
  const [paramDate, setParamDate] = useState(null);

  const [tableData, setTableData] = useState([]);

  const getMaxCriteriaCount = (data) => {
    let maxCount = 0;
    data &&
      data.forEach((row) => {
        if (row.attendance && row.attendance.length > maxCount) {
          maxCount = row.attendance.length;
        }
      });
    return maxCount;
  };
  const maxCriteriaCount = getMaxCriteriaCount(tableData ?? []);

  const attendanceColumns = [];

  for (let i = 0; i < maxCriteriaCount; i++) {
    attendanceColumns.push({
      selector: (row) => {
        const attendance = row.attendance[i];
        return attendance ? (
          <Tooltip title={attendance.holiday_reason} arrow>
            <Typography
              variant="body1"
              className="font-s-22"
              style={{
                color:
                  attendance.status == "A"
                    ? "#D3371C"
                    : attendance.status == "H"
                    ? "#FFAA2A"
                    : attendance.status == "P"
                    ? "#4caf50"
                    : "#001C28",
              }}
            >
              {attendance.status}
            </Typography>
          </Tooltip>
        ) : (
          ""
        );
      },
      name: (
        <Tooltip title={tableData[0].attendance[i]?.name} arrow>
          <Typography variant="body1" className="font-s-13">
            {tableData[0].attendance[i]?.name}
          </Typography>
        </Tooltip>
      ),
      width: "130px",
      center: true,
      compact: "0",
    });
  }

  const dateRange = {
    from: selectedStartDate
      ? selectedStartDate.toISOString().split("T")[0]
      : "",
    to: selectedEndDate ? selectedEndDate.toISOString().split("T")[0] : "",
  };

  const dataUrl = resolveUrl(AdminApi.ATTENDANCE_MODULE_PAGINATION, params.id, {
    from_date: dateRange.from,
    to_date: dateRange.to,
  });

  const tableColumns = [
    {
      selector: (row) => (
        <Typography variant="body1" className="font-s-13">
          <DateView date={row.date} />
        </Typography>
      ),
      name: "Date",
      selector: "date",
      sortable: true,
      width: "120px",
    },
    ...attendanceColumns,
    {
      selector: (row) => (
        <HasPermission permission="manage-attendance.edit">
          <Box
            className="rdt_TableBody attendence_edit_btn"
            height="22px"
            sx={{ overflow: "inherit" }}
          >
            <Button className="edit" onClick={() => onEdit(row.date)}>
              Edit
            </Button>
          </Box>
        </HasPermission>
      ),
      name: "Action",
      sortable: false,
    },
  ];

  const [formData, setFormData] = useState({
    attendance: [
      {
        student_name: "",
        status: "",
        student_id: null,
      },
    ],
    section_info: [
      {
        Date: "",
        cohort_name: "",
        section_name: null,
      },
    ],
    id: "",
    holiday_reason: "",
  });
  const UpdatedformData = {
    data: {
      date: paramDate,
      holiday_reason: formData.holiday_reason,
      attendance: formData.attendance,
    },
  };

  const handleCloseModal = () => {
    setStatus("");
    setFormData({
      attendance: [
        {
          student_name: "",
          status: "",
          student_id: null,
        },
      ],
      section_info: [
        {
          Date: "",
          cohort_name: "",
          section_name: null,
        },
      ],
      id: "",
    });
    setEditOpen(false);
    setViewId(null);
    setParamDate(null);
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;

    // Create a copy of the formData object
    const updatedFormData = { ...formData };

    // Update the status field for the specific student at the given index
    updatedFormData.attendance[index] = {
      ...updatedFormData.attendance[index],
      [name]: value,
    };

    // Update the state with the modified formData
    setFormData(updatedFormData);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (paramDate) {
        try {
          const res = await api.get(
            resolveUrl(AdminApi.ATTENDANCE_MODULE_EDIT, params.id),
            {
              params: {
                date: paramDate,
              },
            }
          );

          const updatedFormData = {
            attendance: res.data.data.attendance ?? [],
            section_info: {
              Date: res.data.data.section_info.Date,
              cohort_name: res.data.data.section_info.cohort_name,
              section_name: res.data.data.section_info.section_name,
            },
            holiday_reason: res.data.data.section_info.holiday_reason ?? "",
          };
          const allHaveStatusH =
            res.data.data.attendance &&
            res.data.data.attendance.every((item) => item.status === "H");

          setStatus(allHaveStatusH ? "H" : "");

          setFormData(updatedFormData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [paramDate]);

  const [editOpen, setEditOpen] = useState(false);

  const onEdit = async (date) => {
    setParamDate(date);
    setEditOpen(true);
  };

  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    setReRender(formSuccess);
    setReRender((prevState) => !prevState);
    props.setReRender((prevState) => prevState + 1);
    setEditOpen(false);
  }, [formSuccess]);

  const [filterData, setFilterData] = useState({});

  const [filters, setFilters] = useState({
    from_date: "",
    to_date: "",
  });

  const handleFilter = () => {
    setFilters({
      from_date: formatDate(filterData.from_date),
      to_date: formatDate(filterData.to_date),
    });
  };

  const [status, setStatus] = useState("");

  const handleStatusUpdate = (e) => {
    const status = e.target.value;
    setStatus(status);

    let updatedAttendance;

    switch (status) {
      case "P":
        updatedAttendance = formData.attendance.map((student) => {
          return { ...student, status: "P" };
        });
        break;
      case "A":
        updatedAttendance = formData.attendance.map((student) => {
          return { ...student, status: "A" };
        });
        break;
      case "H":
        updatedAttendance = formData.attendance.map((student) => {
          return { ...student, status: "H" };
        });
        break;
      default:
        break;
    }
    if (updatedAttendance) {
      setFormData({
        ...formData,
        attendance: updatedAttendance,
        holiday_reason: status !== "H" ? "" : formData.holiday_reason,
      });
    }
  };

  return (
    <>
      <div className="page-container table-phases attendence_filter">
        <Grid container marginBottom={2}>
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", paddingRight: "10px" }}
          >
            <DatePicker
              dateFormat="dd-MM-yyyy"
              required
              id="outlined-required"
              fullWidth
              name="from_date"
              selected={
                filterData.from_date ? new Date(filterData.from_date) : null
              }
              onChange={(date) =>
                setFilterData((prevFormData) => ({
                  ...prevFormData,
                  from_date: date,
                }))
              }
              showTimeSelect={false}
              size="small"
              placeholderText="Date From"
            />
          </Grid>
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", paddingRight: "10px" }}
          >
            <DatePicker
              dateFormat="dd-MM-yyyy"
              required
              id="outlined-required"
              fullWidth
              selected={
                filterData.to_date ? new Date(filterData.to_date) : null
              }
              name="to_date"
              onChange={(date) =>
                setFilterData((prevFormData) => ({
                  ...prevFormData,
                  to_date: date,
                }))
              }
              showTimeSelect={false}
              size="small"
              placeholderText="Date To"
            />
          </Grid>

          <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                backgroundColor: "rgba(0, 28, 40, 1)",
                color: "#fff",
                height: "40px",
                "&:hover": {
                  backgroundColor: "rgba(0, 28, 40, 1)",
                  color: "#fff",
                  opacity: "0.8",
                },
              }}
              onClick={() => {
                handleFilter();
              }}
            >
              Go
            </Button>
          </Grid>
        </Grid>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={dataUrl}
          actionColumns={false}
          filters={filters}
          setTableData={setTableData}
          module="manage-attendance"
          refreshHandle={reRender}
        />
        <GlobalModal
          className="assignment_Detail_form"
          open={editOpen}
          onClose={handleCloseModal}
          width={800}
        >
          <Form
            submitUrl={AdminApi.ATTENDANCE_MODULE_SAVE}
            successPath={`/admin/section/view/${params.id}`}
            formData={UpdatedformData}
            editId={params.id}
            saveText="Update"
            setFormSuccess={setFormSuccess}
          >
            <Grid item xs={12} className="attendanceflex" mt={4}>
              <Grid item xs={12} className="attendance-form-flex">
                <Typography variant="p" gutterBottom>
                  <span className="bold">Cohort</span>
                </Typography>
                <Typography variant="p" gutterBottom>
                  <span className="bolds">
                    {formData.section_info.cohort_name}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} className="attendance-form-flex">
                <Typography variant="p" gutterBottom>
                  <span className="bold">Section</span>
                </Typography>
                <Typography variant="p" gutterBottom>
                  <span className="bolds">
                    {formData.section_info.section_name}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} className="attendance-form-flex">
                <Typography variant="p" gutterBottom>
                  <span className="bold">Date</span>
                </Typography>
                <Typography variant="p" gutterBottom>
                  <span className="bolds">{formData.section_info.Date}</span>
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ mb: 3 }}>
              <Grid
                item
                xs={12}
                mt={"25px"}
                container
                alignItems="center"
                className="attendance-form-edit"
              >
                <Grid item xs={5}>
                  <Typography variant="h6">Name</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">Attendance</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                // className="attendance-form-edit-reason"
                item
                xs={12}
                sx={{
                  backgroundColor: "rgba(225, 235, 237, 0.4)",
                  borderRadius: "5px",
                }}
                padding={2}
              >
                <Grid item xs={6.8} pr={1}>
                  <Select
                    value={status}
                    name="status"
                    onChange={(event) => handleStatusUpdate(event)}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value={status}>Select option</MenuItem>
                    {["P", "A", "H"].map((status) => (
                      <MenuItem key={status} value={status}>
                        {status === "H"
                          ? "Holiday"
                          : status === "P"
                          ? "Present"
                          : "Absent"}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={5.2} pl={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Add reason"
                    value={formData.holiday_reason}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        holiday_reason: e.target.value,
                      })
                    }
                    disabled={status !== "H"}
                    type="text"
                  />
                </Grid>
              </Grid>
              {formData.attendance.length > 0 ? (
                formData.attendance.map((item, index) => (
                  <Grid
                    container
                    item
                    xs={12}
                    key={index}
                    alignItems="center"
                    sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
                  >
                    <Grid item xs={5}>
                      <Grid sx={{ display: "flex", alignItems: "center" }}>
                        <ImageView
                          imageName={item.profile_image}
                          name={item.student_name}
                          width="35px"
                          height="35px"
                          marginRight="10px"
                        />
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{
                            color: "#001C28",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: 1,
                            marginRight: "15px",
                          }}
                        >
                          {item.student_name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} sx={{ padding: "10px 0" }}>
                      <Select
                        value={formData.attendance[index].status} // Replace `index` with the correct index
                        name="status"
                        onChange={(event) => handleInputChange(event, index)} // Pass the index as a parameter
                        sx={{ width: "40%", marginBottom: "0px" }}
                        disabled={status === "H"}
                      >
                        {["A", "P", "H"].map((status) => (
                          <MenuItem
                            key={status}
                            value={status}
                            className={status === "H" ? "listItemHide" : ""}
                          >
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography>No attendance data available.</Typography>
              )}
            </Grid>
          </Form>
        </GlobalModal>
      </div>
    </>
  );
}
