import { useState, useEffect } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import styles from "./style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { api, resolveUrl } from "../../../config/apis";
import StudentApi from "../../../config/apis/student";
import PreAssessmentForm from "./edit";
import Moment from "react-moment";

export default function PreAssessmentFormView() {
  const nevigate = useNavigate();
  const params = useParams();

  const [formData, setFormData] = useState();
  const [formSuccess, setFormSuccess] = useState(false);
  const onUpdate = async () => {
    nevigate("/admin/pre-assessment-form/");
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          resolveUrl(StudentApi.GET_PRE_ASSESSMENT_FORM_DATA, params.id ?? "")
        );
        console.log(res);
        setFormData(res.data.data);
      } catch (error) {
        console.error("Error fetching general data:", error);
      }
    };

    fetchData();
  }, [formSuccess]);

  const renderFormData = () => {
    if (!formData) {
      return "There are no records to display";
    }

    // Exclude the specified fields
    const excludedFields = [
      "state_id",
      "city_id",
      "country_id",
      "registration_number",
      "highest_qualifications",
    ];
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !excludedFields.includes(key))
    );

    return Object.entries(filteredFormData).map(([key, value]) => {
      // Remove underscores from the key
      const formattedKey = key.replace(/_/g, " ");
      if (
        value === "" ||
        value === null ||
        value === undefined ||
        (Array.isArray(value) && value.length === 0)
      ) {
        return null;
      }
      if (key === "number_of_kids" || key === "kids_education_numbers") {
        if (value === 0) {
          return null;
        }
      }
      const displayName =
        key === "laptopdesktop"
          ? "Laptop Desktop"
          : key === "smartphone"
          ? "Smart Phone"
          : key === "qualification"
          ? "Highest Qualification"
          : formattedKey;

      return (
        <Grid key={key} item xl={4} lg={6} xs={12} className={styles.grid_col}>
          <Box className={styles.box}>
            <Typography
              variant="body1"
              className={`${styles.label_key} ${key}`}
            >
              {displayName || formattedKey}
            </Typography>
            {Array.isArray(value) ? (
              <ul>
                {value.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            ) : (
              <Typography variant="body1" fontWeight={700}>
                {key === "registration_date" || key === "date_of_birth" ? (
                  <Moment format="MMM DD, YYYY">{value}</Moment>
                ) : (
                  value
                )}
              </Typography>
            )}
          </Box>
        </Grid>
      );
    });
  };

  return (
    <>
      {params && !params.id && !formData ? (
        <PreAssessmentForm setFormSuccess={setFormSuccess} />
      ) : (
        <Box
          className={`container global-user ${styles.preAssessmentFormContainer}  ${styles.PreAssessmentFormView}`}
        >
          <Typography variant="h1" className="main-heading">
            Pre Assessment
          </Typography>
          <Grid container className={`page-container ${styles.addStudent}`}>
            <Grid container spacing={2}>
              {renderFormData()}
            </Grid>
            <Grid container justifyContent={"flex-end"} marginTop={5}>
              {params && !params.id && (
                <Button
                  className={styles.btn}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => onUpdate()}
                >
                  Update
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
