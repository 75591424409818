import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import { HasPermission } from "../../../helper/helperFunction";
import { Box } from "@mui/material";
import ViewSkillsTable from "./view-tables";

export default function ViewSkills() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <Box className="container">
        <Box className="course-content">
          <h1 className="main-heading">Skills</h1>

          <HasPermission permission="manage-job-skills.create">
            <div className="course_content">
              <Box className="btn-wrapper">
                <Link to={""} onClick={toggleModal}>
                  Add Skill
                </Link>
              </Box>
            </div>
          </HasPermission>
        </Box>
        <ViewSkillsTable
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          actionColumns={true}
        />
      </Box>
    </>
  );
}
