import {
  Avatar,
  Box,
  Typography,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useEffect } from "react";
import { toTitleCase } from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState } from "react";
import { Link, json, useNavigate, useParams } from "react-router-dom";
import { DateView } from "../../../components/DateView/DateView";
import ImageView from "../../../components/imageShow";
import GlobalModal from "../../../components/modalBox";
import SelectOptions from "../../../components/Select";
import Form from "../../../components/forms/Forms";
import SelectOptionsApi from "../../../components/SelectApi/index";
import * as yup from "yup";
import "../../../static/css/style.css";
import SwitchCell from "../../../components/switch";
import { HasPermission } from "../../../helper/helperFunction";

export default function ViewEnrolledStudents(props) {
  const params = useParams();
  const sectionId = parseInt(params.id);

  const defaultFormData = {
    student_Id: "",
    section_id: sectionId,
    course_id: props.courseId,
    organization_id: "",
    Fund_Type: "",
    registration_fee: null,
    cohort_id: props.cohort_id,
  };

  const [formData, setFormData] = useState(defaultFormData);

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setErrors({});
    setFormData(defaultFormData);
  };

  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    student_Id: yup.string().required("Fields are required"),
    Fund_Type: yup.string().required("Fields are required"),
    registration_fee: yup.string().required("Fields are required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      // Additional validation for uploadPreview images
      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [userID, setUserID] = useState();

  const handleOnChangeUser = (event) => {
    const valueTarget = event.target.value;
    setUserID(valueTarget);

    setErrors((prevErrors) => ({
      ...prevErrors,
      student_Id: undefined,
    }));
  };

  const handleUpdate = (name, value) => {
    if (name === "registration_fee") {
      setFormData((prevFormData) => {
        const newValue = parseInt(value);
        return {
          ...prevFormData,
          registration_fee: isNaN(newValue) ? null : newValue,
        };
      });
    } else {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [name]: value,
        };
      });
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const tableColumns = [
    {
      selector: (row) => `#${row.id}`,
      name: "ID",
      selector: "id",
      sortable: true,
      minWidth: "80px",
    },
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image ?? ""} name={row.name} />
          {toTitleCase(`${row.name}`)}
        </>
      ),
      name: "Name",
      selector: "name",
      sortable: true,
      minWidth: "200px",
    },
    {
      selector: (row) => row.email,
      name: "Email",
      sortable: true,
      minWidth: "365px",
    },
    {
      selector: (row) => row.city,
      name: "Place",
      sortable: true,
    },

    {
      cell: (row) => <DateView date={row.added_on} />,
      name: "Registration date",
      sortable: true,
      minWidth: "200px",
      selector: "enrolled_on",
    },
    {
      selector: (row) => (
        <Typography variant="body1" fontWeight={"700"}>
          {row.phase}
        </Typography>
      ),
      name: "Phase",
      sortable: true,
      selector: "phase",
      minWidth: "150px",
    },

    // {
    //   cell: (row) => (
    //     <Typography variant="body1" fontWeight={"700"}>
    //       {row.avg_score}
    //     </Typography>
    //   ),
    //   name: "Avg score",
    //   sortable: true,
    //   selector: "avg_score",
    //   minWidth: "150px",
    // },
    {
      selector: (row) => row.status,
      cell: (row) => (
        <>
          <HasPermission permission="manage-enrolled-students.edit">
            <SwitchCell
              id={row.id}
              is_active={row.status === "1" ? true : false}
              EndPoint={AdminApi.ENROLLED_STUDENT_STATUS}
              queryparams={{
                section_id: params.id,
              }}
              setReloadData={setReRender}
            />
          </HasPermission>
        </>
      ),
      name: "Status",
      sortable: true,
      minWidth: "120px",
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/user/student/view/${id}`);
  };
  const View = async (id) => {
    navigate(`courses/view/${id}/section/view/${id}`);
  };

  const onDelete = async (id) => {
    return await api.delete(
      resolveUrl(
        AdminApi.ENROLLED_STUDENT_MODULE_DELETE,
        `${id}?section_id=${params.id}`
      )
    );
  };
  const PaymentInfo = [
    { id: "Partially_funded", options: "Partially Funded" },
    { id: "fully_funded", options: "Fully Funded" },
    { id: "self_paid", options: "Self Paid" },
  ];
  const registration_fee = [
    { id: "500", option: "500" },
    { id: "1000", option: "1000" },
    { id: "1500", option: "1500" },
    { id: "2000", option: "2000" },
    { id: "2500", option: "2500" },
    { id: "3000", option: "3000" },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();

  const handleStudentClick = (student) => {
    setUserID(student.id);
    setSearchQuery(student.name);
    setFormData({
      ...formData,
      student_Id: student.id,
    });
    setSelectedStudent(student);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchQuery.length >= 3) {
          const paramsText = searchQuery;
          const response = await api.get(resolveUrl(AdminApi.SEARCH_NAME), {
            params: {
              text: paramsText,
              sectionID: sectionId,
            },
          });
          setSearchResults(response.data.data);
        } else {
          setSearchResults([]);
          setUserID("");
          setFormData({
            ...formData,
            student_Id: "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [searchQuery]);

  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [viewId, setViewId] = useState("");
  useEffect(() => {
    if (!modalOpen) {
      setViewId("");
      setFormData(defaultFormData);
      setSearchQuery("");
    }
  }, [modalOpen]);

  useEffect(() => {
    setReRender(formSuccess);
    props.setReloadEnroll(formSuccess);
    setModalOpen(false);

    if (formSuccess) {
      setFormData(defaultFormData);
      setSearchQuery("");
    }
  }, [formSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userID.length > 0) {
          const paramsId = userID;
          const response = await api.get(resolveUrl(AdminApi.SEARCH_NAME), {
            params: {
              text: paramsId,
              sectionID: sectionId,
            },
          });
          setFormData({
            ...formData,
            student_Id: response.data.data[0].id,
          });
          setSearchQuery(response.data.data[0].name);
        } else {
          if (!userID) {
            setSearchQuery("");
            setFormData({
              ...formData,
              student_Id: "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [userID]);

  const updatedFormData = {
    ...formData,
    organization_id:
      formData.Fund_Type === "self_paid" ? null : formData.organization_id,
  };

  const handleFeeUpdate = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      registration_fee: value ? Number(value) : "",
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  return (
    <>
      <div className="page-container table-phases enroll-student">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(
            AdminApi.ENROLLED_STUDENT_MODULE_PAGINATION,
            params.id
          )}
          refreshHandle={reRender}
          onView={onView}
          onDelete={onDelete}
          actions={{
            view: true,
          }}
          sortOrder={"asc"}
          module="manage-enrolled-students"
        />
      </div>
      <div className="course_content">
        <HasPermission permission="manage-enrolled-students.create">
          <Box className="btn-wrapper">
            <Link to={""} onClick={handleOpenModal}>
              Add Student
            </Link>
          </Box>
        </HasPermission>

        <GlobalModal open={modalOpen} onClose={handleCloseModal} width={750}>
          <Form
            successPath={View}
            submitUrl={AdminApi.ENROLLED_STUDENT_MODULE_SAVE}
            formData={updatedFormData}
            setFormSuccess={setFormSuccess}
            id={viewId}
            //   editId={params.id}
            validate={customValidation}
          >
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={3}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Student ID*
                  </InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    sx={{ maxWidth: "120px" }}
                    variant="outlined"
                    placeholder="1251"
                    value={userID}
                    onChange={handleOnChangeUser}
                    inputProps={{
                      type: "number",
                      pattern: "[0-9]*", // Optional, adds a pattern to ensure only numbers are entered
                    }}
                  />
                  {errors.student_Id && (
                    <FormHelperText error>{errors.student_Id}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={3}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Select Student
                  </InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search student..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />

                  <div>
                    {searchResults.map((student) => (
                      <div
                        className="list_students"
                        key={student.id}
                        onClick={() => handleStudentClick(student)}
                      >
                        <span className="course_list">{student.name}</span>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
              {formData.Fund_Type != "self_paid" && (
                <Grid item container sx={{ mb: 3, alignItems: "center" }}>
                  <Grid item mr={"73px"}>
                    <InputLabel> Organization </InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <SelectOptionsApi
                      apiEndpoint={AdminApi.ORGANIZATIONS}
                      formData={formData}
                      onUpdate={handleUpdate}
                      labelKey="name"
                      valueKey="id"
                      name="organization_id"
                      errorText={errors.organization_id}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={3}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Payment Info*
                  </InputLabel>
                </Grid>
                <Grid item xs={3}>
                  <SelectOptions
                    options={PaymentInfo}
                    formData={formData}
                    onUpdate={handleUpdate}
                    name="Fund_Type"
                    labelKey="options"
                    valueKey="id"
                    errorText={errors.Fund_Type}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left", marginLeft: "15px" }}>
                    Fees *
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      maxWidth: "250px",
                    }}
                    className="currncy_ammount"
                  >
                    <Box
                      sx={{
                        maxWidth: "120px",
                      }}
                      className="SelectOptionsApi"
                    >
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.CURRENCY_VIEW}
                        formData={formData}
                        onUpdate={handleUpdate}
                        name={"country_id" || "currency_type"}
                        labelKey="symbol"
                        valueKey="id"
                        defaultText="Currency"
                        customStyle={{ border: "0" }}
                      />
                    </Box>
                    <TextField
                      className="registration_fee"
                      fullWidth
                      name="registration_fee"
                      variant="outlined"
                      placeholder="Fee"
                      value={formData.registration_fee}
                      InputProps={{
                        sx: {
                          height: "40px",
                          padding: "0 10px",
                        },
                      }}
                      inputProps={{
                        type: "number",
                        pattern: "[0-9]*",
                      }}
                      onChange={handleFeeUpdate}
                      error={errors.registration_fee !== undefined}
                    />
                  </Box>
                  {errors.registration_fee && (
                    <FormHelperText error>
                      {errors.registration_fee}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </GlobalModal>
      </div>
    </>
  );
}
