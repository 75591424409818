import {
  Box,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from "@mui/material";
import AdminApi from "../../../config/apis/admin";
import { useEffect, useState } from "react";
import GlobalModal from "../../../components/modalBox";
import Form from "../../../components/forms/Forms";
import SelectOptionsApi from "../../../components/SelectApi/index";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import styles from "./manage-OrgTransactions.module.css";
import { Textarea } from "@mui/joy";
import { formatDate } from "../../../helper/helperFunction";

export const EditOrgTransactions = (props) => {
  const defaultFormData = {
    organization_name: "",
    description: "",
    date: null,
    currency_id: null,
    amount: null,
  };

  const [formData, setFormData] = useState(defaultFormData);

  const handleCloseModal = () => {
    props.setModalOpen(false);
    setErrors({});
    setFormData(defaultFormData);
  };
  useEffect(() => {
    if (!props.modalOpen) {
      setFormData(defaultFormData);
    }
  }, [props.modalOpen]);

  const updateFormData = {
    ...formData,
    date: formData.date && formatDate(formData.date),
  };

  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    organization_name: yup.string().required("This field required"),
    description: yup.string().required("This field required"),
    date: yup.string().required("This field required"),
    amount: yup.string().required("This field required"),
    currency_id: yup.string().required("This field required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  const handleFeeUpdate = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      amount: value ? Number(value) : "",
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  return (
    <>
      <GlobalModal
        open={props.modalOpen}
        onClose={handleCloseModal}
        width={619}
      >
        <Form
          successPath="/admin/organization"
          submitUrl={AdminApi.ORGANIZATION_SAVE}
          formData={updateFormData}
          setFormSuccess={props.setFormSuccess}
          editId={props.editId}
          validate={customValidation}
        >
          <Grid
            container
            justifyContent={"space-between"}
            className={styles.OrgTransactions}
          >
            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Organization Name*
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="organization_name"
                  variant="outlined"
                  value={formData.organization_name}
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                  onChange={handleInputChange}
                  error={errors.organization_name !== undefined}
                />
                {errors.organization_name && (
                  <FormHelperText error>
                    {errors.organization_name}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Description*
                </InputLabel>
              </Grid>
              <Grid item xs={12} className={styles.datePicker}>
                <Textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  minRows={4}
                  maxRows={4}
                  error={errors.description !== undefined}
                />
                {errors.description && (
                  <FormHelperText error>{errors.description}</FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                Transaction Date*
                </InputLabel>
              </Grid>
              <Grid item xs={12} className={styles.datePicker}>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  required
                  id="outlined-required"
                  fullWidth
                  name="date"
                  selected={formData.date ? new Date(formData.date) : null}
                  onChange={(date) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      date: date,
                    }))
                  }
                  showTimeSelect={false}
                  size="small"
                  placeholderText="Date"
                />
              </Grid>
              {errors.date && (
                <FormHelperText error>{errors.date}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} container sx={{ alignItems: "center" }}>
              <Grid item xs={12}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Funded Amount *
                </InputLabel>
              </Grid>
              <Grid container item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    maxWidth: "250px",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "121px",
                    }}
                  >
                    <SelectOptionsApi
                      apiEndpoint={AdminApi.CURRENCY_VIEW}
                      formData={formData}
                      onUpdate={handleUpdate}
                      name="currency_id"
                      labelKey="symbol"
                      valueKey="id"
                      defaultText="Currency"
                      customStyle={{ border: "0" }}
                    />
                  </Box>
                  <TextField
                    fullWidth
                    name="amount"
                    variant="outlined"
                    placeholder="amount"
                    value={formData.amount}
                    InputProps={{
                      sx: {
                        height: "43px",
                        padding: "0 10px",
                        fontFamily:"Poppins"
                      },
                    }}
                    inputProps={{
                      type: "number",
                      pattern: "[0-9]*",
                    }}
                    onChange={handleFeeUpdate}
                    error={errors.amount !== undefined}
                  />
                </Box>
              </Grid>
            </Grid>
              {errors.amount && (
                <FormHelperText error>{errors.amount}</FormHelperText>
              )}
          </Grid>
        </Form>
      </GlobalModal>
    </>
  );
};
