import { useState, useEffect } from "react";

const useDraggableTable = ({ coloumData, updateCourseState }) => {
  const [rows, setRows] = useState([]);

  const rowsWithIds =
    rows &&
    rows.map((item, index) => ({
      ...item,
      id: `row_${index}`,
    }));

  useEffect(() => {
    setRows(coloumData);
  }, [coloumData]);

  function onDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRows(items);
    const selectedItem = {};
    items &&
      items.forEach((item) => {
        const key = item.label.replace(/\s+/g, "").toLowerCase();
        selectedItem[key] = "yes";
      });
    updateCourseState(selectedItem);
  }

  return { onDragEnd, rowsWithIds };
};

export default useDraggableTable;
