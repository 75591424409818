import styles from "./style.module.css";
import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { Box, Grid, Typography } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import ImageView from "../../../components/imageShow";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";

export const StudentResumeTable = (props) => {
  const tableColumns = [
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.student_name} />
          {toTitleCase(`${row.student_name}`)}
        </>
      ),
      name: "Name",
      sortable: false,
      width: "200px",
    },
    {
      selector: (row) => (
        <Tooltip title={row.student_email} arrow>
          {row.student_email}
        </Tooltip>
      ),
      name: "Email",
      sortable: false,
    },
  ];

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.STUDENT_RESUMES_DEL, id));
  };

  return (
    <>
      <Box className={`page-container table-phases ${styles.table_wrap}`}>
        {props.children}
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.STUDENT_RESUMES}
          onDelete={onDelete}
          actions={{
            view: false,
            edit: false,
          }}
          pagination={false}
          sortOrder={"desc"}
          module="manage-student-resume"
        />
      </Box>
    </>
  );
};
