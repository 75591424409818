import Datatable from "../../../../components/dataTables/DataTable";
import Tooltip from "@mui/material/Tooltip";
import { Box, Typography } from "@mui/material";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import { getTimeDifference } from "../../../../helper/helperFunction";
import AdminApi from "../../../../config/apis/admin";

const Notifications = () => {
  const tableColumns = [
    {
      selector: (row) => (
        <Tooltip
          title={`${row.owner_name} ${row.event} ${row.module} ${row.module_element} with ID ${row.effected_module_id}.`}
          arrow
        >
          <span>{`${row.owner_name} ${row.event} ${row.module} ${row.module_element} with ID ${row.effected_module_id}.`}</span>
        </Tooltip>
      ),
      name: "",
      sortable: false,
    },
    {
      selector: (row) => (
        <Tooltip title={getTimeDifference(row.created_at)} arrow>
          <span>{getTimeDifference(row.created_at)}</span>
        </Tooltip>
      ),
      name: "",
      sortable: false,
    },
  ];
  return (
    <Box className={style.noticeBoardMain}>
      <Box className={style.noticeBoardMain_header}>
        <Typography variant="h3" className={style.noticeBoardTitle}>
          Notifications
        </Typography>
        <Link to={"/admin/notifications"}>View More</Link>
      </Box>
      <Box className={style.noticeWrap}>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.DASH_TOP_NOTIFICATIONS}
          actionColumns={false}
          module="manage-job-skills"
          pagination={false}
          // filters={filterData}
        />
      </Box>
    </Box>
  );
};

export default Notifications;
