import { Typography } from "@mui/material";
import { toTitleCase } from "../../../../helper/helperFunction";
import Datatable from "../../../../components/dataTables/DataTable";
import AdminApi from "../../../../config/apis/admin";
import { resolveUrl } from "../../../../config/apis";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageView from "../../../../components/imageShow";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GlobalModal from "../../../../components/modalBox";
import ViewAssignmentsMarksDetail from "../manage-assignment-marks/view"

export default function ViewAssignmentsMarks(id) {
  const params = useParams();

  const fontStyleName = {
    color: "#001C28",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "1",
  };

  const [tableData, setTableData] = useState([]);

  const getMaxCriteriaCount = (data) => {
    let maxCount = 0;
    data.forEach((row) => {
      if (row.assessments && row.assessments.length > maxCount) {
        maxCount = row.assessments.length;
      }
    });
    return maxCount;
  };

  const maxCriteriaCount = getMaxCriteriaCount(tableData);
  const scoringCriteriaColumns = [];



  for (let i = 0; i < maxCriteriaCount; i++) {
    scoringCriteriaColumns.push({
      cell: (row) => {
        const criteria = row.assessments[i];
        return criteria ? criteria.obtained_marks : "";
      },
      name: (
        <div>
          <Typography
            variant="body2"
            component="p"
            sx={{ textAlign: "center",color: "#fff",fontSize: "13px",fontWeight: 700}}
            className="assignments_name"
          >
            <span>{tableData[0].assessments[i]?.assignment_name}</span>
            <button
                        style={{color: "#fff",fontSize: "13px",fontWeight: 700}}
              onClick={() =>
                viewAssignmentDetail(
                  tableData[0].assessments[i]?.assignment_id          
                )
              }
            >
              view <VisibilityIcon />
            </button>
          </Typography>
          <div className="out_of_box">
            <Typography
              variant="body2"
              component="p"
              sx={{ textAlign: "center" }}
            >
              Out of {tableData[0].assessments[i]?.assignment_total_marks}
            </Typography>
           
          </div>
        </div>
      ),
      sortable: false,
      width: "200px",
    });
  }

  const tableColumns = [
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.student_name} />
          <Typography variant="body2" component="span" sx={fontStyleName}>
            {toTitleCase(`${row.student_name}`)}
          </Typography>
        </>
      ),
      name: "Name",
      sortable: false,
      width: "300px",
    },
    ...scoringCriteriaColumns,
    // {
    //   selector: (row) => row.total_marks,
    //   name: "Total Marks",
    //   sortable: false,
    //   width: "150px",
    // },
    // {
    //   selector: (row) => row.percentage + "%",
    //   name: "%",
    //   sortable: false,
    //   width: "150px",
    // },
  ];
  if (maxCriteriaCount > 1) {
    tableColumns.push(
      {
        selector: (row) => row.total_marks,
        name: "Total Marks",
        sortable: false,
        width: "150px",
      },
      {
        selector: (row) => row.percentage + "%",
        name: "%",
        sortable: false,
        width: "150px",
      }
    );
  }
  const [modalOpen, setModalOpen] = useState(false);


  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [assgnId,setAssgnId] = useState();

  const viewAssignmentDetail = async (id) => {
    setAssgnId(id)
    setModalOpen(true);
  };

  return (
    <>
      <div>
      <h2 className="assinDetailheading">{tableData.length > 0 ? tableData[0].assessment_type : ""} <span>(Weightage {tableData.length > 0 ? tableData[0].assessment_weithage : ""}%)</span></h2>
        <div className="page-container table-phases assignment_detail assignment_detail_inner">
              <Datatable
            tableColumns={tableColumns}
            dataUrl={resolveUrl(
              AdminApi.GRADE_BOOK_MODULE_INNER,
              params.id
            )}
            actionColumns={false}
            setTableData={setTableData}
            module="manage-courses"
            qurreyParam={id.id} 
          />
        </div>
      </div>

      <GlobalModal open={modalOpen} onClose={handleCloseModal} width={1500  }>
        <ViewAssignmentsMarksDetail id={assgnId}/>
        </GlobalModal>
    </>
  );
}
