import React from 'react';
import { Link } from 'react-router-dom';

const FooterLinks = () => {
  return (
    <div className="footer_links">
      <ul>
        <li>
          <Link to="/">Terms</Link>
        </li>
        <li>
          <Link to="/"> <span> | </span> Privacy Policy <span> | </span> </Link>
        </li>
        <li>
          <Link to="/">Site Map</Link>
        </li>
      </ul>
    </div>
  );
};

export default FooterLinks;