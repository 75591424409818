import React, { useEffect, useState } from "react";
import styles from "./filter.module.css";
import { Box, Button, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../helper/helperFunction";

const FilterBase = ({
  onFiltersChange,
  showIdField = true,
  name = "Job Title",
}) => {
  const [filterData, setFilterData] = useState({});

  const handleDateToChange = (date) => {
    setFilterData((prevState) => ({
      ...prevState,
      to_date: date,
    }));
  };

  const handleDateFromChange = (date) => {
    setFilterData((prevState) => ({
      ...prevState,
      from_date: date,
    }));
  };

  const removeEmptyKeys = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const handleFilter = () => {
    const newFilters = {
      ...filterData,
      from_date: formatDate(filterData.from_date),
      to_date: formatDate(filterData.to_date),
    };
    const updatedFilters = removeEmptyKeys(newFilters);
    onFiltersChange(updatedFilters);
  };

  return (
    <Box className={styles.baseFilterMain}>
      <form className={styles.studentTopFilterMain}>
        <Box className={styles.studentTopFilterKeyword}>
          {showIdField && (
            <>
              <span className={styles.spanor}>Search By</span>
              <TextField
                variant="outlined"
                fullWidth
                sx={{ maxWidth: "150px" }}
                placeholder="ID"
                className={styles.studentTopFilterKeywordInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleFilter();
                  }
                }}
                onChange={(event) =>
                  setFilterData((prevState) => ({
                    ...prevState,
                    id: event.target.value,
                  }))
                }
              />
            </>
          )}

          <DatePicker
            selected={filterData.from_date ? filterData.from_date : null}
            onChange={handleDateFromChange}
            placeholderText="Date From"
          />
          <DatePicker
            selected={filterData.to_date ? filterData.to_date : null}
            onChange={handleDateToChange}
            placeholderText="Date To"
          />

          <span className={styles.spanor}>or</span>
          {/* Keyword Search */}
          <TextField
            variant="outlined"
            fullWidth
            placeholder={name}
            className={styles.studentTopFilterKeywordInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleFilter();
              }
            }}
            onChange={(event) =>
              setFilterData((prevState) => ({
                ...prevState,
                name: event.target.value,
              }))
            }
          />

          <Box className={styles.studentTopFilterBtn}>
            <Button
              className={styles.studentTopFilterBtnContent}
              onClick={() => handleFilter()}
            >
              Go
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default FilterBase;
