import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import Form from "../../../components/forms/Forms";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import BootstrapInput from "../../../layout/admin/partial/BootstrapInput";
import Flag from "../../../static/images/Flag.png";
import FlagFalse from "../../../static/images/Flag-false.png";
import * as yup from "yup";

export default function EditRole({ id, setFormSuccess }) {
  const [formData, setFormData] = useState({
    name: "",
    is_support_admin: null,
  });


  const [errors, setErrors] = useState({});

  const handleOnChange = (event) => {
    const target = event.target;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const schema = yup.object().shape({
    name: yup.string().required("Fields are required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleOnChecked = (event) => {
    setFormData({
      ...formData,
      is_support_admin: event.target.checked,
    });
  };
  const handleFormSuccess = async (response) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
    }
  };


  const editData = useCallback(async () => {
    if (id) {
      const res = await api.get(resolveUrl(AdminApi.ROLES_VIEW, id));
      setFormData({
        name: res.data.data.name || "",
        is_support_admin: res.data.data.is_support_admin || "",
      });
    }
  }, [id]);

  useEffect(() => {
    editData();
  }, [editData]);

  return (
    <>
      <Box className="page-container">
        <Form
          onSuccess={handleFormSuccess}
          successPath="/admin/setting"
          submitUrl={AdminApi.ROLES_SAVE}
          formData={formData}
          editId={id}
          validate={customValidation}
        >
          <Box className="field" mb={"20px"}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{ color: "#001C28", fontSize: "14px", fontWeight: "700" }}
              >
                Title
              </InputLabel>
              <BootstrapInput
                required
                id="outlined-required"
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleOnChange}
                error={errors.name !== undefined}
                size="small"
              />
              {errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <InputLabel
                className="is_admin_label"
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    position: "static",
                    color: "#001C28",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  Is Admin
                </InputLabel>
                <Box className="role_swith_img">
                  <Checkbox
                    required
                    fullWidth
                    name="is_super_admin"
                    // value={formData.is_admin}
                    onChange={handleOnChecked}
                    size="small"
                    checked={formData.is_support_admin}
                    error={errors.is_support_admin !== undefined}
                    helperText={errors.is_support_admin}
                  />
                  {errors.is_support_admin && (
                    <FormHelperText error>
                      {errors.is_support_admin}
                    </FormHelperText>
                  )}
                  {formData.is_support_admin ? (
                    <img src={Flag} alt="Flag" width={"20px"} />
                  ) : (
                    <img src={FlagFalse} alt="Flag" width={"20px"} />
                  )}
                </Box>
              </Box>
            </FormControl>
          </Box>
        </Form>
      </Box>
    </>
  );
}
