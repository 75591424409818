import { Grid, Box, Select, MenuItem } from "@mui/material";
import AdminApi from "../../../config/apis/admin";
import { useState } from "react";
import { Link } from "react-router-dom";
import CSVDownloadButton from "../../../components/downloadCSV";
import FilterBase from "./filter/filter-base";
import { WorkShopGrid } from "./WorkShopeTable";
import style from "./style.module.css";
import { HasPermission } from "../../../helper/helperFunction";

export const WorkShopView = () => {
  const [filters, setFilters] = useState();

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="container transaction_module">
        <div className="course-content">
          <h1 className="main-heading">Workshops</h1>

          <HasPermission permission="manage-work-shop.create">
            <Box className={`btn-wrapper ${style.addWorkshopBtn}`}>
              <Link
                to={"#"}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Add Workshops
              </Link>
            </Box>
          </HasPermission>
        </div>

        <Grid
          container
          marginBottom={2}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <FilterBase onFiltersChange={handleFiltersChange} />
          <CSVDownloadButton
            apiEndpoint={AdminApi.WORKSHOP_CSV}
            params={"desc"}
            fileName={"Workshops"}
          />
        </Grid>

        <WorkShopGrid
          filters={filters}
          currentValue={"desc"}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </div>
    </>
  );
};
