import CircularProgress from "@mui/material/CircularProgress";
// import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Grid } from "@mui/material";
window.Chart = Chart;

const AttendenceChart = ({ chartData, isDataLoaded }) => {
  const data = {
    labels: ["Not Marked", "Absent", "Present", "Holiday"],
    datasets: [
      {
        label: "",
        data: [
          chartData && chartData.notmarkedpercentage,
          chartData && chartData.absentPercentage,
          chartData && chartData.presentPercentage,
          chartData && chartData.holidayPercentage,
        ],
        backgroundColor: [
          "#FFAA2A ",
          "rgb(211, 55, 28)",
          "#064763 ",
          "#20D568 ",
        ],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = data.labels[context.dataIndex];
            const value = data.datasets[0].data[context.dataIndex];
            const percentage = value.toFixed(2);
            return `${label}: ${percentage}%`;
          },
        },
      },
    },
    legend: {
      display: true,
      position: "bottom", // Change the legend position
    },
    maintainAspectRatio: false, // Allow the chart to be responsive
    responsive: true, // Make the chart responsive
  };
  return (
    <>
      {isDataLoaded ? (
        <Grid
          sx={{ height: "450px", display: "flex", justifyContent: "center" }}
        >
          {/* <Pie data={data} /> */}
          <Pie data={data} options={options} />
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 250,
          }}
        >
          <CircularProgress sx={{ color: "#001c28" }} />
        </div>
      )}
    </>
  );
};

export default AttendenceChart;
