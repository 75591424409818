// StudentReportContext.js
import React, { createContext, useContext, useState } from "react";

const StudentReportContext = createContext();

export const useStudentReportContext = () => useContext(StudentReportContext);

export const StudentReportProvider = ({ children }) => {
  const [studentReports, setStudentReports] = useState([]);

  const updateStudentReports = (newReports) => {
    setStudentReports(newReports);
  };

  return (
    <StudentReportContext.Provider value={{ studentReports, updateStudentReports }}>
      {children}
    </StudentReportContext.Provider>
  );
};
