import styles from "./style.module.css";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import AdminApi from "../../../config/apis/admin";
import { api } from "../../../config/apis";
import { DataLoader } from "../../../helper/helperFunction";

export const SponsorshipCard = (params) => {
  const [filters, setFilters] = useState();
  const [activeButton, setActiveButton] = useState("soFar");

  const [jobFundData, setFundData] = useState();
  const [loading, setLoading] = useState(true);
  const getStartAndEndOfWeek = () => {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
    const diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust to the beginning of the week
    const startOfWeek = new Date(now.setDate(diff));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week is 6 days later

    return {
      startOfWeek: formatDate(startOfWeek),
      endOfWeek: formatDate(endOfWeek),
    };
  };
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are 0-indexed
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };

  const getStartAndEndOfMonth = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    return {
      startOfMonth: formatDate(startOfMonth),
      endOfMonth: formatDate(endOfMonth),
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Modify the API call with parameters
        const res = await api.get(AdminApi.DASH_SPONSERSHIP_FUNDS, {
          params: {
            filters: {
              ...filters,
            },
          },
        });
        setFundData(res.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    fetchData();
  }, [filters]);

  const handleCurrentYear = () => {
    setFilters({
      year: new Date().getFullYear(),
    });
    setActiveButton("currentYear");
  };
  const handleCurrentMonth = () => {
    const { startOfMonth, endOfMonth } = getStartAndEndOfMonth();

    setFilters({
      from_date: startOfMonth,
      to_date: endOfMonth,
    });
    setActiveButton("currentMonth");
  };
  const handleThisWeek = () => {
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();

    setFilters({
      from_date: startOfWeek,
      to_date: endOfWeek,
    });
    setActiveButton("thisWeek");
  };
  const handleSoFar = () => {
    setFilters();
    setActiveButton("soFar");
  };

  return (
    <>
      {loading ? (
        <DataLoader />
      ) : (
        <>
          {params.filter && (
            <Grid item xs={12} className={styles.totalJobsContainer_}>
              <Button
                className={`${styles.btns} ${
                  activeButton === "soFar" ? styles.active : ""
                }`}
                onClick={handleSoFar}
              >
                So Far
              </Button>
              <Button
                className={`${styles.btns} ${
                  activeButton === "thisWeek" ? styles.active : ""
                }`}
                onClick={handleThisWeek}
              >
                This week
              </Button>
              <Button
                className={`${styles.btns} ${
                  activeButton === "currentMonth" ? styles.active : ""
                }`}
                onClick={handleCurrentMonth}
              >
                This Month
              </Button>
              <Button
                className={`${styles.btns} ${
                  activeButton === "currentYear" ? styles.active : ""
                }`}
                onClick={handleCurrentYear}
              >
                This Year
              </Button>
            </Grid>
          )}
          <Grid
            container
            marginTop={2}
            className={styles.totalJobsContainer_wrap}
          >
            <Grid item xs={12} lg={6}>
              <Box
                className={`${styles.totalJobsContainer} ${styles.totalJobsContainer_after}`}
              >
                <Box className={styles.left_box}>
                  <Typography variant="h6">Sponsorship funds</Typography>
                  <Typography variant="h4">
                    {jobFundData && jobFundData.sponsorship_funds
                      ? "$" + jobFundData.sponsorship_funds.toFixed(2)
                      : ""}
                  </Typography>
                </Box>
                <Box className={styles.imageContainer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M17.9961 22.9766C18.5461 22.9766 18.9922 22.5306 18.9922 21.9805V17.9961C18.9922 17.4461 18.5463 17 17.9961 17H16.0039C15.4539 17 15.0078 17.4459 15.0078 17.9961V21.9805C15.0078 22.5305 15.4538 22.9766 16.0039 22.9766H17.9961Z"
                      fill="white"
                    />
                    <path
                      d="M31.0117 6.04297H23.9727C23.9727 5.84236 23.9727 4.84626 23.9727 5.04688C23.9727 3.39907 22.6322 2.05859 20.9844 2.05859C20.7682 2.05859 12.7198 2.05859 13.0156 2.05859C11.3678 2.05859 10.0273 3.399 10.0273 5.04688C10.0273 5.24749 10.0273 6.24358 10.0273 6.04297H2.98828C1.34048 6.04297 0 7.38338 0 9.03125C0 10.9821 0.239063 12.6905 0.713734 14.1465C1.18841 15.6024 1.89875 16.806 2.84139 17.7471C4.42764 19.3312 6.44811 19.9883 8.71682 19.9883H13.0156C13.0156 19.7877 13.0156 17.7955 13.0156 17.9961C13.0156 16.3483 14.356 15.0078 16.0039 15.0078C16.2045 15.0078 18.1967 15.0078 17.9961 15.0078C19.6439 15.0078 20.9844 16.3482 20.9844 17.9961C20.9844 18.1967 20.9844 20.1889 20.9844 19.9883H23.1976C25.1805 19.9059 28.424 20.5088 31.1576 17.7879C32.1007 16.8492 32.8113 15.6435 33.2861 14.1807C33.761 12.7179 34 10.9981 34 9.03125C34 7.38345 32.6596 6.04297 31.0117 6.04297ZM12.0195 5.04688C12.0195 4.4973 12.466 4.05078 13.0156 4.05078H20.9844C21.534 4.05078 21.9805 4.49723 21.9805 5.04688C21.9805 5.24749 21.9805 6.24358 21.9805 6.04297H12.0195C12.0195 5.84236 12.0195 4.84626 12.0195 5.04688Z"
                      fill="white"
                    />
                    <path
                      d="M23.2249 21.9804H20.9844C20.9844 23.6282 19.644 24.9687 17.9961 24.9687C17.7955 24.9687 15.8033 24.9687 16.0039 24.9687C14.3561 24.9687 13.0156 23.6282 13.0156 21.9804C12.7881 21.9804 8.47955 21.9804 8.72552 21.9804C5.88439 21.9804 3.38891 21.1086 1.43378 19.1565C0.881477 18.6051 0.409859 17.9843 0 17.3203V30.9452C0 31.4958 0.44552 31.9413 0.996094 31.9413H33.0039C33.5545 31.9413 34 31.4958 34 30.9452V17.3613C33.5846 18.0354 33.1111 18.6547 32.5632 19.2003C29.4202 22.3269 26.0729 21.8458 23.2249 21.9804Z"
                      fill="white"
                    />
                  </svg>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className={styles.totalJobsContainer}>
                <Box className={styles.left_box}>
                  <Typography variant="h6">Sponsorship Allocated</Typography>
                  <Typography variant="h4">
                    {jobFundData && jobFundData.allocated_funds
                      ? "$" + jobFundData.allocated_funds.toFixed(2)
                      : ""}
                  </Typography>
                </Box>
                <Box className={styles.imageContainer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_7015_53933)">
                      <path
                        d="M12.0195 6.04297C11.4699 6.04297 11.0234 6.4894 11.0234 7.03906C11.0234 7.58873 11.4699 8.03516 12.0195 8.03516C12.5692 8.03516 13.0156 7.58873 13.0156 7.03906C13.0156 6.4894 12.5692 6.04297 12.0195 6.04297Z"
                        fill="white"
                      />
                      <path
                        d="M24.9688 0.583984V6.0433H30.3617L24.9688 0.583984Z"
                        fill="white"
                      />
                      <path
                        d="M23.9727 8.03516C23.4222 8.03516 22.9766 7.58951 22.9766 7.03906V0H4.05078C3.50034 0 3.05469 0.445648 3.05469 0.996094V33.0039C3.05469 33.5544 3.50034 34 4.05078 34H29.9492C30.4997 34 30.9453 33.5544 30.9453 33.0039V8.03516H23.9727ZM12.0195 10.0273C10.3718 10.0273 9.03125 8.68677 9.03125 7.03906C9.03125 5.39136 10.3718 4.05078 12.0195 4.05078C13.6672 4.05078 15.0078 5.39136 15.0078 7.03906C15.0078 8.68677 13.6672 10.0273 12.0195 10.0273C14.7655 10.0273 17 12.2618 17 15.0078C17 15.5583 16.5544 16.0039 16.0039 16.0039H8.03516C7.48471 16.0039 7.03906 15.5583 7.03906 15.0078C7.03906 12.2618 9.27353 10.0273 12.0195 10.0273ZM16.0039 27.957H8.03516C7.48471 27.957 7.03906 27.5114 7.03906 26.9609C7.03906 26.4105 7.48471 25.9648 8.03516 25.9648H16.0039C16.5544 25.9648 17 26.4105 17 26.9609C17 27.5114 16.5544 27.957 16.0039 27.957ZM20.3032 27.9059C19.7813 28.0797 19.2173 27.7978 19.0433 27.2758C18.8695 26.7539 19.1515 26.19 19.6734 26.0159C20.1953 25.8419 20.7592 26.1241 20.9333 26.646C21.1071 27.1677 20.8251 27.7319 20.3032 27.9059ZM25.9648 23.9727H8.03516C7.48471 23.9727 7.03906 23.527 7.03906 22.9766C7.03906 22.4261 7.48471 21.9805 8.03516 21.9805H25.9648C26.5153 21.9805 26.9609 22.4261 26.9609 22.9766C26.9609 23.527 26.5153 23.9727 25.9648 23.9727ZM25.9648 19.9883H8.03516C7.48471 19.9883 7.03906 19.5426 7.03906 18.9922C7.03906 18.4417 7.48471 17.9961 8.03516 17.9961H25.9648C26.5153 17.9961 26.9609 18.4417 26.9609 18.9922C26.9609 19.5426 26.5153 19.9883 25.9648 19.9883ZM25.9648 16.0039H19.9883C19.4378 16.0039 18.9922 15.5583 18.9922 15.0078C18.9922 14.4574 19.4378 14.0117 19.9883 14.0117H25.9648C26.5153 14.0117 26.9609 14.4574 26.9609 15.0078C26.9609 15.5583 26.5153 16.0039 25.9648 16.0039ZM25.9648 12.0195H19.9883C19.4378 12.0195 18.9922 11.5739 18.9922 11.0234C18.9922 10.473 19.4378 10.0273 19.9883 10.0273H25.9648C26.5153 10.0273 26.9609 10.473 26.9609 11.0234C26.9609 11.5739 26.5153 12.0195 25.9648 12.0195Z"
                        fill="white"
                      />
                      <path
                        d="M12.0212 12.0195C10.7227 12.0195 9.61479 12.8522 9.20312 14.0117H14.8394C14.4277 12.8522 13.3198 12.0195 12.0212 12.0195Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7015_53933">
                        <rect width="34" height="34" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
