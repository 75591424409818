import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../auth/auth';
import { ToastContainer } from 'react-toastify';

const UnProtectRoutes = () => {
  const { state } = useAuth();
  return !state ? (
    <>
      <ToastContainer />
      <Outlet />
    </>
  ) : (
    <Navigate to="/admin" />
  );
};

export default UnProtectRoutes;
