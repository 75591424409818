import { useState, useEffect } from "react";
import { api, resolveUrl } from "../../config/apis";
import AdminApi from "../../config/apis/admin";

const useFetchLocations = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.LOCATION_PAGINATE));
        setLocations(res.data.data ?? []);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLocations();

    return () => {
      // Optionally, perform any clean-up if needed
    };
  }, []);

  return locations;
};

export default useFetchLocations;
