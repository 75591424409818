import { Box, Typography } from "@mui/material";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FilterBase from "./filter/filter-base";
import styles from "./manage-course.module.css";
import CSVDownloadButton from "../../../components/downloadCSV";
import Rating from "@mui/material/Rating";
import { limitStringLength } from "../../../helper/helperFunction";
import { HasPermission } from "../../../helper/helperFunction";

export default function ViewCourses() {
  const navigate = useNavigate();

  const [filters, setFilters] = useState();

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const tableColumns = [
    {
      selector: (row) => limitStringLength(row.title, 20),
      name: "Course Name",
      sortable: true,
      minWidth: "400px",
    },
    {
      selector: (row) => row.average_rating,
      name: "Ratings",
      minWidth: "200px",
      sortable: true,
      cell: (row) => (
        <Rating name={`rating-${row.id}`} value={row.average_rating} readOnly />
      ),
    },
    {
      selector: (row) => row.phase,
      name: "Phase",
      sortable: true,
    },
    {
      selector: (row) => row.duration_in_hours + " Months",
      name: "Duration",
      sortable: true,
    },

    {
      selector: (row) => row.status,
      cell: (row) => (
        <Typography
          sx={{ textTransform: "capitalize" }}
          variant="body1"
          className={styles.staus_active}
        >
          {row.status}
        </Typography>
      ),
      name: "Status",
      sortable: true,
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/courses/view/${id}`);
  };

  const onEdit = async (id) => {
    navigate(`/admin/courses/edit/${id}`);
  };
  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.COURSES_MODULE_DELETE, id));
  };

  return (
    <>
      <div className="container">
        <div className="course-content">
          <h1 className="main-heading">Courses</h1>

          <HasPermission permission="manage-courses.create">
            <Box className="btn-wrapper">
              <Link to={"edit"}>Add Course</Link>
            </Box>
          </HasPermission>
        </div>

        <div className={styles.filter_flex}>
          <FilterBase
            palcehoder="Course Name"
            onFiltersChange={handleFiltersChange}
          />
          <div className={styles.filter_flex}>
            <Box className={styles.filter_btn}>
              <CSVDownloadButton
                apiEndpoint={AdminApi.COURSES_MODULE_DOWNLOAD_CSV}
                params={"asc"}
                fileName={"Courses"}
              />
            </Box>
          </div>
        </div>

        <div className="page-container table-phases">
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.COURSES_MODULE_PAGINATE}
            onEdit={onEdit}
            onView={onView}
            onDelete={onDelete}
            actions={{
              view: true,
            }}
            filters={filters}
            module="manage-courses"
          />
        </div>
      </div>
    </>
  );
}
