import { Typography, capitalize } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, resolveUrl } from "../../../config/apis";
import ImageView from "../../../components/imageShow";
import Moment from "react-moment";
import { EditSupportTicket } from "./edit";

export const SupportTicketsGrid = (props) => {
  const navigate = useNavigate();

  const tableColumns = [
    {
      selector: (row) => `#${row.id}`,
      name: "ID",
      sortable: true,
      minWidth: "100px",
    },
    {
      cell: (row) => (
        <>
          <ImageView
            imageName={row.profile_image_path}
            name={row.student_name}
          />
          {toTitleCase(`${row.student_name ?? ""}`)}
        </>
      ),
      name: "Name",
      selector: "student_name",
      sortable: true,
      minWidth: "200px",
    },
    {
      selector: (row) => row.email,
      name: "Email",
      sortable: true,
      minWidth: "300px",
    },
    {
      selector: (row) => row.cohort_name,
      name: "Cohort",
      sortable: true,
      minWidth: "150px",
    },
    {
      selector: (row) => row.section_name,
      name: "Section",
      sortable: true,
      minWidth: "150px",
    },
    {
      cell: (row) => (
        <Typography variant="2" textTransform={"capitalize"}>
          {row.type}
        </Typography>
      ),
      selector: "type",
      name: "Payment Type",
      sortable: true,
      minWidth: "150px",
    },
    {
      cell: (row) => (
        <Moment format="MMM DD, YYYY hh:mmA">{row.date_time}</Moment>
      ),
      selector: "date_time",
      name: "Date / Time",
      minWidth: "180px",
      sortable: true,
    },

    {
      cell: (row) => (
        <Typography variant="body1" fontWeight={"700"}>
          {row.amount} {row.currency_symbol}
        </Typography>
      ),
      selector: "amount",
      name: "Amount",
      sortable: true,
      minWidth: "150px",
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/transactions/view/${id}`);
  };

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.TRANSACTIONS_DELETE, id));
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    setReRender(formSuccess);
    setModalOpen(false);
  }, [formSuccess]);

  useEffect(() => {
    if (props.modalOpen) {
      setModalOpen(props.modalOpen);
    }
  }, [props.modalOpen]);

  useEffect(() => {
    if (props.setModalOpen) {
      if (!modalOpen) {
        props.setModalOpen(false);
      }
    }
  }, [modalOpen]);

  return (
    <>
      <div className="page-container table-phases">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.TRANSACTIONS_PAGINATION}
          onView={onView}
          onDelete={onDelete}
          filters={props.filters ?? ""}
          sortOrder={props.currentValue ?? "asc"}
          actions={{
            view: true,
            edit: false,
            delete: props.delete ?? true,
          }}
          module="manage-transactions"
          refreshHandle={reRender}
        />
      </div>
      <EditSupportTicket
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        setModalOpen={setModalOpen}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
};
