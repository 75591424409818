import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";

const EditSkills = ({ id, setFormSuccess }) => {
  const [formData, setFormData] = useState({
    skill: null,
  });

  const [errors, setErrors] = useState({});

  const handleOnChange = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });
  };

  const schema = yup.object().shape({
    skill: yup.string().required("Fields are required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleFormSuccess = async (response) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.SKILL_PREFILLED, id));
        setFormData({
          skill: res.data.data.skill || "",
        });
      } catch (error) {
        // Error handling
      }
    };

    fetchData();
  }, [id]);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.SKILL_SAVE}
              formData={formData}
              editId={id}
              onSuccess={handleFormSuccess}
              validate={customValidation}
              successPath="/admin/skills"
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2} mb={2}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Skills Title
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter name"
                    value={formData.skill}
                    name="skill"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.skill !== undefined}
                  />
                  {errors.skill && (
                    <FormHelperText error>{errors.skill}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditSkills;
