import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  student_id: Yup.string().required("This field is required"),
  job_type: Yup.string().required("This field is required"),
  joining_date: Yup.string().required("This field is required"),
  referrer: Yup.string().required("This field is required"),
  working_hours: Yup.string().required("This field is required"),
  work_experience: Yup.string().required("This field is required"),
  salary: Yup.string().required("This field is required"),
  total_work_experience: Yup.string().required("This field is required"),

  job_title: Yup.string().test({
    test: function (value) {
      return this.parent.job_type !== "Freelancer" ? !!value : true;
    },
    message: "This field is required",
  }),

  company_name: Yup.string().required("Company name is required"),

  end_date: Yup.string()
    .test({
      test: function (value) {
        return this.parent.left_job
          ? value !== null && value !== undefined && value !== ""
          : true;
      },
      message: "This field is required",
    })
    .nullable(),

  leaving_reason: Yup.string().test({
    test: function (value) {
      return this.parent.left_job ? !!value : true;
    },
    message: "This field is required",
  }),
});

export default validationSchema;
