import "../../../static/css/style.css";
import { Grid } from "@mui/material";
import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/auth/auth";

const DashboardAdmins = () => {
  const authenUser = useAuth();
  const authenUserName = authenUser.state.user.username;
  return (
    <>
      <Box className="container">
        <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={2}>
            <Typography variant="h2" className={styles.main_heading}>
              Dashboard
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={{ xl: 4, lg: 3 }}>
          <Grid item xl={12}>
            <Typography variant="h2" className={styles.wlcm_heading}>
              welcome <span>{authenUserName},</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardAdmins;
