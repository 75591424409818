import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  InputLabel,
  FormHelperText,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import Form from "../../../components/forms/Forms";
import styles from "./style.module.css";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import validationSchema from "./validation";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import StudentApi from "../../../config/apis/student";
import AdminApi from "../../../config/apis/admin";
import { useAuth } from "../../../hooks/auth/auth";
import { api, resolveUrl } from "../../../config/apis";
import moment from "moment";

export const JobRecordsEdit = (props) => {
  const params = useParams();
  const { state } = useAuth();

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: "#001c28",
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }
  const intaialFormData = {
    student_id: "",
    job_type: "",
    company_name: "",
    job_title: "",
    joining_date: null,
    left_job: null,
    end_date: "",
    leaving_reason: "",
    referrer: "",
    working_hours: null,
    work_experience: "",
    salary: "",
    total_work_experience: "",
  };
  const [formData, setFormData] = useState(intaialFormData);

  const [errors, setErrors] = useState({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      const newErrors = {};
      if (error.inner) {
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
      } else {
        console.error(error);
      }

      setErrors(newErrors);

      return false;
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          resolveUrl(StudentApi.JOB_RECORDS_VIEW, params.id)
        );
        setFormData(res.data.data);
        setSelectedStudent({
          email: res.data.data.email,
          id: res.data.data.student_id,
          name: res.data.data.user_name,
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (params.id) {
      fetchData();
    }
  }, [params.id]);

  const [students, setStudents] = useState([]);

  const initalData = {
    email: "",
    id: "",
    name: "",
  };

  useEffect(() => {
    if (state.userRole === "Student") {
      setSelectedStudent({
        email: state.user.email,
        id: state.user.id,
        name: state.user.username,
      });
      setFormData({
        ...formData,
        student_id: state.user.id,
      });
    }
  }, []);

  const [selectedStudent, setSelectedStudent] = useState(initalData);

  const handleAutocompleteChange = (newValue) => {
    if (!newValue) {
      setSelectedStudent(initalData);
      return false;
    }
    const { id } = newValue;
    setSelectedStudent(newValue);
    setFormData({
      ...formData,
      student_id: id,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      student_id: undefined,
    }));
  };

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.ALL_STUDENT_JOB_RECORDS_SEARCH)
        );
        setStudents(res.data.data);
      } catch (error) {
        console.error("Error fetching students", error);
      }
    };

    fetchStudents();
  }, []);

  const handleLeftJobChange = (event) => {
    setFormData((prevFormData) => {
      if (event.target.checked === true) {
        return {
          ...prevFormData,
          left_job: 1,
        };
      } else {
        return {
          ...prevFormData,
          end_date: "",
          leaving_reason: "",
          left_job: null,
        };
      }
    });
  };

  return (
    <>
      <Box className="container" marginBottom={5}>
        <Box className="course-content">
          <h1 className="main-heading">
            {params.id ? "Update Job Experience" : "Add Job Experience"}
          </h1>
        </Box>
        <Form
          successPath={props.successUrl}
          submitUrl={StudentApi.JOB_RECORDS_SAVE}
          formData={formData}
          editId={params.id ?? ""}
          validate={customValidation}
          customClass={styles.Job_Experience}
          cancleShow={true}
        >
          <Grid container spacing={3.5} maxWidth={"824px"}>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="student_id">ID *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <Autocomplete
                disabled={state.userRole === "Student" || !!params.id}
                style={
                  state.userRole === "Student"
                    ? { backgroundColor: "#F4F4F4" }
                    : {}
                }
                options={students}
                getOptionLabel={(option) => option.id}
                value={selectedStudent}
                onChange={(event, newValue) =>
                  handleAutocompleteChange(newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Enter ID"
                    variant="outlined"
                    className={styles.aSCTContentTitleInput}
                    error={errors.student_id !== undefined}
                    helperText={errors.student_id}
                  />
                )}
              />
              {errors.studentId && (
                <FormHelperText error>{errors.studentId}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="email">Student Email *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                id="email"
                value={selectedStudent ? selectedStudent.email : ""}
                disabled
                fullWidth
                sx={{ backgroundColor: "#F4F4F4" }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel>Student Name *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                fullWidth
                value={selectedStudent ? selectedStudent.name : ""}
                disabled
                sx={{ backgroundColor: "#F4F4F4" }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="email"> Job Type *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <RadioGroup
                row
                name="job_type"
                defaultValue="first"
                onChange={handleInputChange}
                value={formData.job_type}
              >
                <MyFormControlLabel
                  value="Employed"
                  label="Employed"
                  control={<Radio />}
                />
                <MyFormControlLabel
                  value="Freelancer"
                  label="Freelancer"
                  control={<Radio />}
                />
              </RadioGroup>
              {errors.job_type && (
                <FormHelperText error>{errors.job_type}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="company_name">
                Company Name {formData.job_type === "Freelancer" ? "" : "*"}
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                id="company_name"
                name="company_name"
                fullWidth
                variant="outlined"
                value={formData.company_name}
                onChange={handleInputChange}
                error={errors.company_name !== undefined}
                helperText={errors.company_name}
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="job_title">
                Position {formData.job_type === "Freelancer" ? "" : "*"}
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                id="job_title"
                name="job_title"
                fullWidth
                variant="outlined"
                value={formData.job_title}
                onChange={handleInputChange}
                error={errors.job_title !== undefined}
                helperText={errors.job_title}
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="job_title">Joining date *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={5}>
              <DatePicker
                calendarAriaLabel="Toggle calendar"
                clearAriaLabel="Clear value"
                dayAriaLabel="Day"
                monthAriaLabel="Month"
                nativeInputAriaLabel="Date"
                onChange={(date) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    joining_date: date
                      ? moment(date).format("YYYY-MM-DD")
                      : null,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    joining_date: undefined,
                  }));
                }}
                value={
                  formData.joining_date ? new Date(formData.joining_date) : null
                }
                yearAriaLabel="Year"
              />
              {errors.joining_date && (
                <FormHelperText error>{errors.joining_date}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} lg={3.5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.left_job}
                    onChange={handleLeftJobChange}
                  />
                }
                label="Have you left the job?"
              />
            </Grid>

            {formData.left_job === 1 && (
              <>
                <Grid item xs={12} lg={2.5}>
                  <InputLabel htmlFor="job_title">Leaving date *</InputLabel>
                </Grid>
                <Grid item xs={12} lg={9.5}>
                  <Grid item xs={12} lg={6}>
                    <DatePicker
                      calendarAriaLabel="Toggle calendar"
                      clearAriaLabel="Clear value"
                      dayAriaLabel="Day"
                      monthAriaLabel="Month"
                      nativeInputAriaLabel="Date"
                      onChange={(date) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          end_date: date
                            ? moment(date).format("YYYY-MM-DD")
                            : "",
                        }));
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          end_date: undefined,
                        }));
                      }}
                      value={
                        formData.end_date ? new Date(formData.end_date) : ""
                      }
                      yearAriaLabel="Year"
                    />
                    {errors.end_date && (
                      <FormHelperText error>{errors.end_date}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={2.5}>
                  <InputLabel
                    htmlFor="referrer"
                    style={{ whiteSpace: "normal" }}
                  >
                    Leaving Reason *
                  </InputLabel>
                </Grid>
                <Grid item xs={12} lg={9.5}>
                  <Textarea
                    name="leaving_reason"
                    value={formData.leaving_reason}
                    onChange={handleInputChange}
                    minRows={4}
                    maxRows={4}
                    error={errors.leaving_reason !== undefined}
                  />
                  {errors.leaving_reason && (
                    <FormHelperText error>
                      {errors.leaving_reason}
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="referrer" style={{ whiteSpace: "normal" }}>
                Who referred you to the job? *
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                sx={{ maxWidth: "300px" }}
                id="referrer"
                name="referrer"
                fullWidth
                variant="outlined"
                value={formData.referrer}
                onChange={handleInputChange}
                error={errors.referrer !== undefined}
                helperText={errors.referrer}
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="working_hours">Working hours *</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                sx={{ maxWidth: "300px" }}
                id="working_hours"
                name="working_hours"
                fullWidth
                variant="outlined"
                type="number"
                value={formData.working_hours}
                onChange={(e) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    working_hours: e.target.value
                      ? Number(e.target.value)
                      : null,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    working_hours: undefined,
                  }));
                }}
                error={errors.working_hours !== undefined}
                helperText={errors.working_hours}
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel
                htmlFor="work_experience"
                style={{ whiteSpace: "normal" }}
              >
                Total work experience so far *
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                sx={{ maxWidth: "300px" }}
                id="work_experience"
                name="work_experience"
                fullWidth
                variant="outlined"
                value={formData.work_experience}
                onChange={handleInputChange}
                error={errors.work_experience !== undefined}
                helperText={errors.work_experience}
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel htmlFor="salary">Salary Range*</InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                sx={{ maxWidth: "300px" }}
                id="salary"
                name="salary"
                fullWidth
                variant="outlined"
                value={formData.salary}
                onChange={handleInputChange}
                error={errors.salary !== undefined}
                helperText={errors.salary}
              />
            </Grid>
            <Grid item xs={12} lg={2.5}>
              <InputLabel
                htmlFor="total_work_experience"
                style={{ whiteSpace: "normal" }}
              >
                Student Job experience*
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                sx={{ maxWidth: "300px" }}
                id="total_work_experience"
                name="total_work_experience"
                fullWidth
                variant="outlined"
                value={formData.total_work_experience}
                onChange={handleInputChange}
                error={errors.total_work_experience !== undefined}
                helperText={errors.total_work_experience}
              />
            </Grid>
          </Grid>
        </Form>
      </Box>
    </>
  );
};
