import { Select, MenuItem, Checkbox } from "@mui/material";

import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "./style.module.css";

const MultiSelect = ({ options, setFormData, formData }) => {
  const extractCohortIds = (data) => {
    return data.map((item) => item.cohort_id);
  };
  const extractSectionIds = (data) => {
    const sectionIds = [];
    data.forEach((item) => {
      item.sections.forEach((section) => {
        sectionIds.push(section.section_id);
      });
    });
    return sectionIds;
  };
  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);

  const toggleCohort = (cohortId) => {
    if (selectedCohorts.includes(cohortId)) {
      setSelectedCohorts(selectedCohorts.filter((id) => id !== cohortId));
      setSelectedSections(
        selectedSections.filter(
          (sectionId) => !isSectionInCohort(sectionId, cohortId)
        )
      );
    } else {
      setSelectedCohorts([...selectedCohorts, cohortId]);
      const cohortSections = getCohortSections(cohortId);
      setSelectedSections([...selectedSections, ...cohortSections]);
    }
  };

  const toggleSection = (sectionId) => {
    const sectionCohortId = getSectionCohortId(sectionId);
    if (selectedSections.includes(sectionId)) {
      setSelectedSections(selectedSections.filter((id) => id !== sectionId));
    } else {
      setSelectedSections([...selectedSections, sectionId]);
    }

    const cohortSections = getCohortSections(sectionCohortId);

    let isCohortSelected = true;
    for (let i = 0; i < cohortSections.length; i++) {
      if (selectedSections.includes(cohortSections[i])) {
        isCohortSelected = true;
        break;
      }
    }

    if (isCohortSelected) {
      setSelectedCohorts([...selectedCohorts, sectionCohortId]);
    } else {
      setSelectedCohorts(
        selectedCohorts.filter((id) => id !== sectionCohortId)
      );
    }
  };

  const isCohortChecked = (cohortId) => selectedCohorts.includes(cohortId);
  const isSectionChecked = (sectionId) => selectedSections.includes(sectionId);
  useEffect(() => {
    const cohortIds = extractCohortIds(formData.workshop_sections);
    const sectionIds = extractSectionIds(formData.workshop_sections);
    setSelectedCohorts(cohortIds);
    setSelectedSections(sectionIds);
  }, []);
  const getCohortSections = (cohortId) => {
    const cohort = options.find((data) => data.cohort_id === cohortId);
    return cohort ? cohort.sections.map((section) => section.section_id) : [];
  };

  const getSectionCohortId = (sectionId) => {
    for (const cohort of options) {
      if (cohort.sections.some((section) => section.section_id === sectionId)) {
        return cohort.cohort_id;
      }
    }
    return null;
  };

  const isSectionInCohort = (sectionId, cohortId) => {
    const cohortSections = getCohortSections(cohortId);
    return cohortSections.includes(sectionId);
  };

  const sections = options.map((sectionData) => (
    <li key={sectionData.cohort_id}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isCohortChecked(sectionData.cohort_id)}
            onChange={() => toggleCohort(sectionData.cohort_id)}
          />
        }
        label={sectionData.cohort_name}
      />
      <br />
      <ul>
        {sectionData.sections.map((section) => (
          <li key={section.section_id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSectionChecked(section.section_id)}
                  onChange={() => toggleSection(section.section_id)}
                />
              }
              label={`${section.section_name}   (${section.course_name}) `}
            />
          </li>
        ))}
      </ul>
    </li>
  ));

  const generateWorkshopSections = (selectedCohorts, selectedSections) => {
    const filteredCohorts = options.filter((item) => {
      return selectedCohorts.includes(item.cohort_id);
    });

    const workshopSections = filteredCohorts.map((cohort) => {
      const sections = cohort.sections.filter((section) =>
        selectedSections.includes(section.section_id)
      );
      return { ...cohort, sections };
    });

    return workshopSections;
  };

  const selectedWorkshopSections = generateWorkshopSections(
    selectedCohorts,
    selectedSections
  );

  useEffect(() => {
    setFormData({
      ...formData,
      workshop_sections: selectedWorkshopSections,
    });
  }, [selectedCohorts]);

  return (
    <>
      <Select fullWidth value={1}>
        <MenuItem value={1}>
          <em>Select Cohot or Sections</em>
        </MenuItem>
        <ul className={styles.phaseCohortList}>{sections}</ul>
      </Select>
    </>
  );
};

export default MultiSelect;
