import { Grid, InputLabel, TextField, FormHelperText } from "@mui/material";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GlobalModal from "../../../components/modalBox";
import Form from "../../../components/forms/Forms";
import { Textarea } from "@mui/joy";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";

export default function EditAssignment({
  viewId,
  setReRender,
  handleCloseModal,
  editOpen,
  setEditOpen,
  setViewId
}) {
  const params = useParams();

  const defaultFormData = {
    feedback: "",
    name: "",
    obtained_marks: null,
    scoring_criteria: [],
    section_name: "",
    totalMarks: null,
    student_id: "",
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [success, setSuccess] = useState();


  const updatedFormData = {
    ...formData,
    obtained_marks: Number(formData.obtained_marks),
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (viewId) {
        try {
          const res = await api.get(
            resolveUrl(AdminApi.STUDENT_SUBMITED_ASSIGNMENT_EDIT, params.id),
            {
              params: {
                student_id: viewId,
              },
            }
          );

          const updatedScoringCriteria =
            res.data.data.scoring_criteria &&
            res.data.data.scoring_criteria.map((criteria) => ({
              ...criteria,
              score: criteria.score.toString(),
            }));
          const updatedFormData = {
            feedback: res.data.data.feedback,
            name: res.data.data.name,
            obtained_marks: res.data.data.obtained_marks,
            scoring_criteria: updatedScoringCriteria,
            totalMarks: res.data.data.totalMarks,
            section_name: res.data.data.section_name,
            student_id: res.data.data.id,
          };

          setFormData(updatedFormData);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [viewId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "obtained_marks") {
      setFormData({
        ...formData,
        obtained_marks: value,
      });
    } else {
      // For other input fields, update formData directly
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleScoringChange = (event, index) => {
    const { value } = event.target;
    const updatedFormData = { ...formData }; // Create a shallow copy
    updatedFormData.scoring_criteria[index].score = value;
    setFormData(updatedFormData);
  };
  const inputStyle = {
    height: "auto",
    borderRadius: "5px",
    border: "2px solid rgb(0 28 40 / 12%)",
    padding: "6.5px 10px",
  };
  const [errors, setErrors] = useState({});

  const scoringCriteriaSchema = yup.object().shape({
    score: yup.string().required("Scoring criteria score is required"),
  });

  const schema = yup.object().shape({
    obtained_marks: yup.string().required("Obtained marks is required"),
    scoring_criteria: yup
      .array()
      .of(scoringCriteriaSchema)
      .required("Scoring criteria are required"),
  });



  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
  
      const newErrors = {};
      
      formData.scoring_criteria.forEach(item => {
        const outOf = parseInt(item.out_of, 10);
        const score = item.score ? parseInt(item.score, 10) : 0;
  
        if (isNaN(outOf) || isNaN(score) || score > outOf) {
          newErrors.scoring_criteria = '';
        }
      });
  
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
  
      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};
  
      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });
  
      setErrors(newErrors);
      return false;
    }
  };
  



  useEffect(() => {
    if (success) {
      setEditOpen(false);
      setFormData(defaultFormData);
      setReRender((prevState) => !prevState);
      setViewId(null);
    }
  }, [success]);

  return (
    <>
      {viewId && (
        <>
          <GlobalModal
            className="assignment_Detail_form"
            open={editOpen}
            onClose={handleCloseModal}
            width={650}
          >
            {isLoading ? (
              <Grid
                className="loader"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "400px",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              formData && (
                <Form
                  successPath={`/admin/assignment/view/${params.id}`}
                  submitUrl={AdminApi.STUDENT_SUBMITED_ASSIGNMENT_MARKED}
                  formData={updatedFormData}
                  editId={params.id}
                  validate={customValidation}
                  saveText="Update"
                  onSuccess={setSuccess}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                   
                    <Grid item xs={12} marginBottom={1}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Total Marks
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{ style: inputStyle }}
                        fullWidth
                        name="totalMarks"
                        variant="outlined"
                        value={formData.totalMarks ?? ""}
                        onChange={handleInputChange}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} marginBottom={1} marginTop={"30px"}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Section
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{ style: inputStyle }}
                        fullWidth
                        name="section_name"
                        variant="outlined"
                        value={formData.section_name ?? ""}
                        onChange={handleInputChange}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} marginBottom={1} marginTop={"30px"}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Student Name
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{ style: inputStyle }}
                        fullWidth
                        name="name"
                        variant="outlined"
                        value={formData.name}
                        onChange={handleInputChange}
                        disabled
                      />
                    </Grid>

                    {formData.scoring_criteria.length > 0 ? (
                      formData.scoring_criteria.map((item, index) => (
                        <>
                          <Grid item xs={12} key={index}>
                            <Grid
                              item
                              xs={12}
                              marginBottom={1}
                              marginTop={"30px"}
                            >
                              <InputLabel sx={{ textAlign: "left" }}>
                                {item.title} (out of {item.out_of})
                              </InputLabel>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                inputProps={{ style: inputStyle }}
                                fullWidth
                                name="name"
                                variant="outlined"
                                value={item.score}
                                onChange={(event) =>
                                  handleScoringChange(event, index)
                                }
                              />

                              {errors &&
                                errors[`scoring_criteria[${index}].score`] && (
                                  <FormHelperText error>
                                    {errors[`scoring_criteria[${index}].score`]}
                                  </FormHelperText>
                                )}
                                {item.score > item.out_of &&
                                <FormHelperText error>
                                    Number should not greater than given score.          
                                </FormHelperText>
                                }
                                 

                                 
                            </Grid>
                          </Grid>
                        </>
                      ))
                    ) : (
                      <>
                        <Grid item xs={12} marginBottom={1} marginTop={"30px"}>
                          <InputLabel sx={{ textAlign: "left" }}>
                            Obtained Marks
                          </InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            type="number"
                            fullWidth
                            inputProps={{ style: inputStyle }}
                            name="obtained_marks"
                            variant="outlined"
                            value={formData.obtained_marks}
                            error={errors.obtained_marks !== undefined}
                            helperText={errors.obtained_marks}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} marginBottom={1} marginTop={"30px"}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Feedback
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <Textarea
                        name="feedback"
                        value={formData.feedback}
                        onChange={handleInputChange}
                        minRows={4}
                        maxRows={4}
                      />
                    </Grid>
                    {errors && 
                        <FormHelperText error>
                              {errors.scoring_criteria}
                        </FormHelperText>
                    }
                  </Grid>
                </Form>
              )
            )}
          </GlobalModal>
        </>
      )}
    </>
  );
}
