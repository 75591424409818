import React from "react";
import { Box, Modal } from "@mui/material";
import styles from "./manage-shift.module.css";
import ShiftForm from "./edit";

const ShiftFormModal = ({ open, id, closeModal , setFormSuccess}) => {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box className={styles.popup}>
        <button onClick={closeModal} className={styles.close_btn}>
          Close
        </button>
        <ShiftForm id={id} setFormSuccess={setFormSuccess}/>
      </Box>
    </Modal>
  );
};

export default ShiftFormModal;
