import React, { useState } from "react";
import { Chip, FormControl, Grid, MenuItem, Select } from "@mui/material";
import useTypeSelect from "./Hooks/useSelect";

const TypeSelect = ({ label, options, updateCourseState }) => {
  const { selectedValue, handleFilterChange, handleDeleteOption } =
    useTypeSelect({ updateCourseState, label });
  return (
    <>
      <FormControl fullWidth>
        <Grid containeralignItems={"center"}>
          <Grid item xs={12}>
            <Select
              value={selectedValue}
              onChange={handleFilterChange}
              sx={{ maxWidth: "200px", marginRight: "20px" }}
              fullWidth
              displayEmpty
            >
              <MenuItem key="" value="">
                <em className="select">{"Select an option"}</em>
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {selectedValue && (
              <Chip
                label={selectedValue}
                onDelete={handleDeleteOption}
                style={{
                  marginRight: "7px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
            )}
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};
export default TypeSelect;
