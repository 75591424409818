import CircularProgress from "@mui/material/CircularProgress";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import AdminApi from "../../../../config/apis/admin";
import { api } from "../../../../config/apis";
import styles from "./style.module.css";
import {
  getStartAndEndOfMonth,
  getStartAndEndOfWeek,
} from "../../../../helper/helperFunction";

window.Chart = Chart;

const StudentChart = (params) => {
  const [chartData, setChartData] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [activeButton, setActiveButton] = useState("soFar");
  const [filters, setFilters] = useState();

  const handleCurrentMonth = () => {
    const { startOfMonth, endOfMonth } = getStartAndEndOfMonth();

    setFilters({
      from_date: startOfMonth,
      to_date: endOfMonth,
    });
    setActiveButton("currentMonth");
  };
  const handleThisWeek = () => {
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();

    setFilters({
      from_date: startOfWeek,
      to_date: endOfWeek,
    });
    setActiveButton("thisWeek");
  };
  const handleSoFar = () => {
    setFilters();
    setActiveButton("soFar");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(AdminApi.DASH_ACTIVE_INACTIVE_STUDENT, {
          params: {
            filters: {
              ...filters,
              ...params.filterData,
            },
          },
        });
        setChartData(res.data.data);
        setIsDataLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [filters, params.filterData]);

  const data = {
    labels: ["Active", "Inactive"],
    datasets: [
      {
        label: "",
        data: [
          chartData && chartData.active_students,
          chartData && chartData.inactive_students,
        ],
        backgroundColor: ["#053E57", "#CA3319"],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = data.labels[context.dataIndex];
            const value = data.datasets[0].data[context.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
    },
    legend: {
      display: true,
      position: "bottom", // Change the legend position
    },
    maintainAspectRatio: false, // Allow the chart to be responsive
    responsive: true, // Make the chart responsive
  };
  const filterActive =
    params.filterData.phase_id || params.filterData.cohort_id;
  return (
    <>
      {isDataLoaded ? (
        <>
          <Grid
            item
            xs={12}
            className={`${styles.Chart_Container}`}
            padding={filterActive ? 2 : ""}
          >
            <Grid item xs={12} className={styles.totalJobsContainer_}>
              <Box>
                <Typography variant="h3">
                  Active Students {filterActive ? "**" : ""}
                </Typography>
              </Box>
              <Box className={styles.btn_wrapper}>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "soFar" ? styles.active : ""
                  }`}
                  onClick={handleSoFar}
                >
                  So Far
                </Button>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "thisWeek" ? styles.active : ""
                  }`}
                  onClick={handleThisWeek}
                >
                  This week
                </Button>
                <Button
                  className={`${styles.btns} ${
                    activeButton === "currentMonth" ? styles.active : ""
                  }`}
                  onClick={handleCurrentMonth}
                >
                  This Month
                </Button>
              </Box>
            </Grid>
            <Grid className={styles.chart_wrap}>
              <Pie data={data} options={options} />
            </Grid>
          </Grid>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 250,
          }}
        >
          <CircularProgress sx={{ color: "#001c28" }} />
        </div>
      )}
    </>
  );
};

export default StudentChart;
