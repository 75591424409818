import React from "react";
import { Box, Modal } from "@mui/material";
import "../manage-phases/manage-phases-modal.css";
import AdminForm from "./edit";

const AdminFormModal = ({ open, id, closeModal , setFormSuccess}) => {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box className="popup">
        <button onClick={closeModal} className="close_btn">
          Close
        </button>
        <AdminForm id={id} setFormSuccess={setFormSuccess}/>
      </Box>
    </Modal>
  );
};

export default AdminFormModal;
