import { useParams } from "react-router-dom";
import FilterBase from "../../../../components/filterJob/filter-base1";
import CSVDownloadButton from "../../../../components/downloadCSV";
import styles from "./style.module.css";
import Datatable from "../../../../components/dataTables/DataTable";
import { resolveUrl } from "../../../../config/apis";
import AdminApi from "../../../../config/apis/admin";
import { useState } from "react";
import { Box, Select, MenuItem } from "@mui/material";
import { tableColumns } from "./tableColumns";
import { useCreateCsv } from "./csv-data";

export const JobsAplications = () => {
  const param = useParams();
  const [filters, setFilters] = useState();
  const [currentValue, setCurrentValue] = useState("desc");
  const { reportData } = useCreateCsv(currentValue);

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  return (
    <>
      <Box className="container" style={{ marginTop: "50px" }}>
        <Box className={styles.display_left}>
          <FilterBase onFiltersChange={handleFiltersChange} name="Name" />
          <Box className={styles.display_right}>
            <Box marginRight={2}>
              <CSVDownloadButton
                fileName={"Jobs Aplications"}
                data={reportData ?? []}
              />
            </Box>
            <Box>
              <Select
                value={currentValue}
                onChange={handleChange}
                className="select-menu"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
        <Box
          className={` table-phases table-skills_popup ${styles.table_wrap}`}
          marginTop={5}
          marginBottom={5}
        >
          <Datatable
            actionColumns={false}
            tableColumns={tableColumns}
            dataUrl={resolveUrl(AdminApi.JOBS_APPLICANTS, param.id)}
            filters={filters}
            sortOrder={currentValue}
            module="manage-student-resume"
          />
        </Box>
      </Box>
    </>
  );
};
