import { Link } from "react-router-dom";
import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
// import styles from "./manage-discount.module.scss";
import { Box} from "@mui/material";
import { useState, useEffect } from "react";
import DiscountFormModal from "./modal";
import React from "react";
import { DateView } from "../../../components/DateView/DateView";
import {HasPermission} from '../../../helper/helperFunction'


export default function ViewPhases() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState("");
  const [reRender, setReRender] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const tableColumns = [
    {
      selector: (row) => row.title,
      name: "Name",
      sortable: true,
    },
    {
      selector: (row) => (
        <DateView date={row.created_at} />
      ),
      name: "Started on",
      sortable: false,
    },
    {
      selector: (row) => (
        <DateView date={row.updated_at} />
      ),
      name: "Edited on",
      sortable: false,
    },
  ];

  const onEdit = async (id) => {
    setViewId(id);
    if (id) {
      setIsModalOpen(true);
    }
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.PHASES_DELETE, id));
  };

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);


  return (
    <>
      <div className="page-container table-phases">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.PHASES_VIEW}
          onEdit={onEdit}
          onDelete={onDelete}
          refreshHandle={reRender}
          actions={{
            view: false,
          }}
          module="manage-phases"
        />
      </div>
    
      <HasPermission permission="manage-phases.create">
        <div className="course_content">
            <Box className="btn-wrapper">
              <Link to={""} onClick={toggleModal}>
                Add Phase
              </Link>
            </Box>
          </div>
      </HasPermission>

      <DiscountFormModal
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
