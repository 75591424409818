import HasPermission from "../hooks/ProtectedRoutes/HasPermission";
import Attendence from "../pages/student/manage-attendence/view";
import Assignment from "../pages/student/manage-Assignment/view";
import PreAssessmentForm from "../pages/student/manage-pre-assessment-form/edit";
import PostAssessmentForm from "../pages/student/manage-post-assessment-form/edit";
import PreAssessmentFormView from "../pages/student/manage-pre-assessment-form/view";
import PostAssessmentFormView from "../pages/student/manage-post-assessment-form/view";
import { JobRecords } from "../pages/student/manage-job-records/view";
import { StudentAppliedJobs } from "../pages/student/manage-applied-Jobs/view";
import { JobRecordsEdit } from "../pages/student/manage-job-records/edit";
import EditStudentProfile from "../pages/student/manage-profile/edit";

export const StudentRoutes = [
  {
    element: <HasPermission permission="manage-student-attendance.view" />,
    children: [
      {
        path: "student-attendence",
        element: <Attendence />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-student-assignment.view" />,
    children: [
      {
        path: "student-assignment",
        element: <Assignment />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-pre-assessment.view" />,
    children: [
      {
        path: "pre-assessment-form",
        element: <PreAssessmentForm />,
      },
      {
        path: "pre-assessment",
        element: <PreAssessmentFormView />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-post-assessment.view" />,
    children: [
      {
        path: "post-assessment-form",
        element: <PostAssessmentForm />,
      },
      {
        path: "post-assessment",
        element: <PostAssessmentFormView />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-student-job-record.view" />,
    children: [
      {
        path: "job-records",
        element: <JobRecords />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-student-job-record.create" />,
    children: [
      {
        path: "job-records/edit/:id?",
        element: <JobRecordsEdit successUrl="/admin/job-records" />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-student-applied-job.view" />,
    children: [
      {
        path: "applied-job",
        element: <StudentAppliedJobs />,
      },
    ],
  },
  {
    element: <HasPermission permission="manage-student-profile.edit" />,
    children: [
      {
        path: "profile-update",
        element: <EditStudentProfile />,
      },
    ],
  },
];
