import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Link, Box } from "@mui/material";
import styles from "./manage-course.module.css";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import CourseTabs from "./course-detail-tab/course-detail-tab";
import Rating from "@mui/material/Rating";
import SwitchCell from "../../../components/switch";
import { HasPermission } from "../../../helper/helperFunction";

export default function DetailCourses() {
  const navigate = useNavigate();
  const params = useParams();
  const [coursesInfo, setCoursesInfo] = useState();
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id) {
          const res = await api.get(
            resolveUrl(AdminApi.COURSES_DETAIL_HEADER_VIEW, params.id)
          );
          setCoursesInfo(res.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [params.id, reloadData]);

  const View = async () => {
    navigate(`/admin/courses/edit/${params.id}`);
  };

  return (
    <>
      <div className="container">
        <h1>COURSES</h1>
        <Box className={styles.courseDetailFlex}>
          <Box className={styles.courseDetailFlexContent}>
            <Box className={styles.courseDetailFlexHeading}>
              <h2>{coursesInfo?.title}</h2>
              {/* <h2>Course</h2> */}
              <Typography variant="p" gutterBottom className={styles.coude}>
                <p className="bold rating">
                  {}
                  <Rating
                    name={`rating`}
                    value={coursesInfo?.average_rating}
                    readOnly
                  />
                </p>
                <span className="bold">
                  {coursesInfo?.review_count} Reviews
                </span>
              </Typography>
            </Box>
            <Typography variant="p" gutterBottom className={styles.coude}>
              <span>{coursesInfo?.description}</span>
            </Typography>
            <HasPermission permission="manage-courses.edit">
              <div className={styles.bold}>
                <SwitchCell
                  id={params.id}
                  is_active={coursesInfo?.status === "active" ? true : false}
                  EndPoint={AdminApi.COURSE_ACTIVE_INACTIVE}
                  setReloadData={setReloadData}
                />
              </div>
            </HasPermission>
          </Box>
          <Box className={styles.courseDetailSecond}>
            <HasPermission permission="manage-courses.edit">
              <Box className={styles.stuViewDetailBtn}>
                <Link
                  sx={{ cursor: "pointer" }}
                  className={styles.couViewBtn}
                  onClick={View}
                >
                  Edit Course
                </Link>
              </Box>
            </HasPermission>
          </Box>
        </Box>

        <Box>
          <CourseTabs />
        </Box>
      </div>
    </>
  );
}
