import AdminApi from "../../../../../config/apis/admin";
import useApiData from "../../../../../hooks/useApiData/useApiData";
import { useState } from "react";

const useCourseDropdown = ({ courseState, updateCourseState }) => {
  const { options } = useApiData(AdminApi.REPORTS_COURSES);

  const [selectedIds, setSelectedIds] = useState(courseState.course_id || []);

  const handleAutocompleteChange = (event, value) => {
    const newSelectedIds = value.map((option) => option.id);
    setSelectedIds(newSelectedIds);
    updateCourseState({
      course_id: newSelectedIds,
    });
  };
  const handleChipDelete = (idToDelete) => {
    const updatedIds = selectedIds.filter((id) => id !== idToDelete);
    setSelectedIds(updatedIds);
    updateCourseState({
      course_id: updatedIds,
    });
  };
  return {
    handleAutocompleteChange,
    setSelectedIds,
    handleChipDelete,
    options,
    selectedIds,
  };
};

export default useCourseDropdown;
