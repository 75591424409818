import ViewOrgFundHistory from "./view";
import { DetailsOrgBanner } from "../manage-organization-transactions/detail-banner";
import { Grid, Container} from "@mui/material";


export default function DetailsOrgFundHistrory() {
  return (
    <>
      <DetailsOrgBanner showBar={true} tittle="Organization Transaction Detail"/>
      <Container maxWidth="xl">
        <Grid container marginTop={4}>
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ padding: "0 30px" }}
          >
           <ViewOrgFundHistory/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
