import {
  Box,
  Typography,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
  FormControl,
} from "@mui/material";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DateView } from "../../../components/DateView/DateView";
import GlobalModal from "../../../components/modalBox";
import SelectOptionsApi from "../../../components/SelectApi";
import Form from "../../../components/forms/Forms";
import ScoringCriteria from "../../../components/ScoringCriteria";
import { Textarea } from "@mui/joy";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as yup from "yup";
import "../../../static/css/style.css";
import { GetUserRole, HasPermission } from "../../../helper/helperFunction";

export default function ViewAssignments(props) {
  const params = useParams();

  const initialScoringCriteria = [
    {
      title: "Following Best Practices",
      score: "",
    },
    {
      title: "Achieving Target Output",
      score: "",
    },
    {
      title: "Class Performance",
      score: "",
    },
    {
      title: "Quality of work",
      score: "",
    },
  ];

  const [CriteraData, setCritera] = useState([]);

  const [formData, setFormData] = useState({
    assignment_title: "",
    course_id: props.courseId,
    section_id: props.sectionId,
    start_date: "",
    end_date: "",
    assignment_description: "",
    assessment_weightage_id: "",
    total_marks: 100,
    scoring_criteria: initialScoringCriteria,
  });

  const scoringCriteriaSchema = yup.object().shape({
    score: yup.string().required("Scoring criteria score is required"),
  });

  const schema = yup.object().shape({
    assignment_title: yup.string().required("This field is required"),
    start_date: yup.string().required("This field is required"),
    end_date: yup.string().required("This field is required"),
    assignment_description: yup.string().required("This field is required"),
    assessment_weightage_id: yup.string().required("This field is required"),
    total_marks: yup.string().required("This field is required"),
    scoring_criteria: yup
      .array()
      .of(scoringCriteriaSchema)
      .required("Scoring criteria are required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false }); // Validate with Yup schema
      const newErrors = {};

      // Custom validation for assessmentWeightage
      if (assessmentWeightage !== 100) {
        newErrors["assessmentWeightage"] =
          "Assessment weightage cannot be greater than 100% and less than 100%";
      }

      setErrors(newErrors); // Set Yup validation errors in the state

      // Check if there are any errors (Yup or custom)
      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors); // Set Yup validation errors in the state
      return false; // Validation failed
    }
  };

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setErrors({});
    setFormData({
      assignment_title: "",
      course_id: props.courseId,
      section_id: props.sectionId,
      start_date: "",
      end_date: "",
      assignment_description: "",
      assessment_weightage_id: "",
      total_marks: 100,
      scoring_criteria: CriteraData,
    });
    setViewId("");
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleMarksChange = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      total_marks: parseInt(valueTarget, 10),
    });
  };
  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const [errors, setErrors] = useState({});

  const tableColumns = [
    {
      selector: (row) => (
        <Typography variant="body1" fontWeight={"700"}>
          {row.title}
        </Typography>
      ),
      name: "Title",
      sortable: true,
      selector: "title",
      width: "300px",
    },
    {
      selector: (row) => row.type,
      name: "Type",
      sortable: true,
      width: "300px",
    },
    {
      cell: (row) => <DateView date={row.assigned_date} />,
      name: "Started on",
      sortable: true,
      selector: "assigned_date",
    },
    {
      cell: (row) => <DateView date={row.due_date} />,
      name: "Due On",
      sortable: true,
      selector: "due_date",
    },
    {
      cell: (row) => row.submitted,
      name: "Submitted",
      sortable: true,
      selector: "submitted",
    },
    {
      cell: (row) => row.marked,
      name: "Marked",
      sortable: true,
      selector: "marked",
    },
    {
      cell: (row) => row.total_marks,
      name: "Total Marks",
      sortable: true,
      selector: "total_marks",
    },
  ];
  const [viewId, setViewId] = useState("");

  const onView = async (id) => {
    navigate(`/admin/assignment/view/${id}`);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onEdit = async (id) => {
    setIsModalOpen(handleOpenModal);
    setViewId(id);
  };

  const View = async (id) => {
    navigate(`admin/courses/view/${id}`);
  };
  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.ASSIGNMENT_MODULE_DELETE, id));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.ASSIGNMENT_MODULE_VIEW, viewId)
        );

        // Check if scoring_criteria is empty and set a default value if needed
        const scoringCriteria =
          res.data.data.scoring_criteria.length > 0
            ? res.data.data.scoring_criteria.map((item) => ({
                title: item.title,
                score: Math.floor(item.score),
              }))
            : initialScoringCriteria;

        setFormData((prevFormData) => ({
          ...prevFormData,
          assignment_title: res.data.data.assignment_title,
          assignment_description: res.data.data.assignment_description,
          assessment_weightage_id: res.data.data.assessment_weightage_id,
          type: res.data.data.type,
          total_marks: res.data.data.total_marks,
          start_date: moment(res.data.data.start_date).format("YYYY-MM-DD"),
          end_date: moment(res.data.data.end_date).format("YYYY-MM-DD"),
          scoring_criteria: scoringCriteria,
          course_id: props.courseId,
          section_id: props.sectionId,
        }));
      } catch (error) {
        // Error handling
      }
    };
    fetchData();
  }, [viewId]);

  useEffect(() => {
    if (!viewId) {
      const sectionCriteria = async () => {
        try {
          const res = await api.get(
            resolveUrl(AdminApi.SECTION_CRITERIA, params.id)
          );
          const scoringCriteria =
            res.data.data.length > 0
              ? res.data.data.map((item) => ({
                  title: item.title,
                  score: Math.floor(item.weightage ?? ""),
                }))
              : initialScoringCriteria;
          setCritera(scoringCriteria);
          setFormData((prevFormData) => ({
            ...prevFormData,
            scoring_criteria: scoringCriteria,
          }));
        } catch (error) {
          console.error(error);
        }
      };
      sectionCriteria();
    }
  }, [params.id, viewId]);

  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    setReRender(formSuccess);
    setReRender((prevState) => !prevState);
    setModalOpen(false);
  }, [formSuccess]);

  const [assessmentWeightage, setAssessmentWeightage] = useState(0);

  useEffect(() => {
    const totalWeightage = formData.scoring_criteria.reduce(
      (sum, field) => sum + (field.score || 0),
      0
    );
    setAssessmentWeightage(totalWeightage);
  }, [formData.scoring_criteria]);

  const isTeacher = GetUserRole();

  return (
    <>
      <Box
        className="page-container table-phases"
        sx={{ paddingLeft: "15px", paddingRight: "15px" }}
      >
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(AdminApi.ASSIGNMENT_MODULE_PAGINATION, params.id)}
          onEdit={onEdit}
          id={viewId}
          onView={onView}
          onDelete={onDelete}
          actions={{
            view: true,
            edit: isTeacher !== "Teacher",
          }}
          sortOrder={"desc"}
          module="manage-assignment"
          refreshHandle={reRender}
        />
      </Box>
      <Box className="course_content">
        <HasPermission permission="manage-assignment.create">
          <Box className="btn-wrapper">
            <Link to={""} onClick={handleOpenModal}>
              Add Assignment
            </Link>
          </Box>
        </HasPermission>

        <GlobalModal
          className="assignment_form"
          open={modalOpen}
          onClose={handleCloseModal}
          width={1302}
        >
          <Form
            successPath={View}
            submitUrl={AdminApi.ASSIGNMENT_MODULE_SAVE}
            formData={formData}
            editId={viewId}
            validate={customValidation}
            className="assignment_form"
            setFormSuccess={setFormSuccess}
          >
            <Grid
              container
              className="assignment_form"
              justifyContent={"space-between"}
            >
              <Grid
                item
                xs={12}
                className="assignment_form_wrapper"
                container
                sx={{ mb: 3 }}
              >
                <Grid item xs={6} className="assignment_form_wrapper_section">
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} marginBottom={1}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Assignment Title*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="assignment_title"
                        variant="outlined"
                        placeholder="Enter Assignment Title"
                        value={formData.assignment_title}
                        onChange={handleInputChange}
                        error={errors.assignment_title !== undefined}
                      />
                      {errors.assignment_title && (
                        <FormHelperText error>
                          {errors.assignment_title}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={6} marginBottom={1} pr={"10px"}>
                      <InputLabel sx={{ textAlign: "left" }}>Course</InputLabel>
                      <TextField
                        sx={{ marginTop: "16px" }}
                        fullWidth
                        variant="outlined"
                        value={props.courseTitle}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} marginBottom={1}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Section
                      </InputLabel>
                      <TextField
                        sx={{ marginTop: "16px" }}
                        fullWidth
                        variant="outlined"
                        value={props.sectionName}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} marginBottom={1.5}>
                      <InputLabel sx={{ textAlign: "left" }}>Type*</InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.ASSESSMENT_WEIGHTAGE_TYPE}
                        formData={formData}
                        disabled={viewId}
                        onUpdate={handleUpdate}
                        name="assessment_weightage_id"
                        labelKey="type"
                        paramId={params.id}
                        valueKey="id"
                        errorText={errors.assessment_weightage_id}
                        error={errors.assessment_weightage_id !== undefined}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    className="field-set"
                    mb="20px"
                    mr="25px"
                    sx={{ width: "100%" }}
                  >
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        sx={{
                          color: "#001c28",
                          fontWeight: 700,
                          fontSize: "20px!important",
                          fontFamily: "Poppins",
                        }}
                      >
                        Started On*
                      </InputLabel>

                      <DatePicker
                        sx={{ paddingTop: "20px" }}
                        selected={
                          formData.start_date
                            ? new Date(formData.start_date)
                            : null
                        }
                        dateFormat="yyyy-MM-dd"
                        required
                        id="outlined-required"
                        fullWidth
                        name="start_date"
                        value={formData.start_date ? formData.start_date : ""}
                        error={errors.start_date !== undefined}
                        onChange={(date) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            start_date: moment(date).format("YYYY-MM-DD"),
                          }))
                        }
                        size="small"
                      />
                      {errors.start_date && (
                        <FormHelperText error>
                          {errors.start_date}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                  <Box className="field-set" mb="20px" sx={{ width: "100%" }}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        sx={{
                          color: "#001c28",
                          fontWeight: 700,
                          fontSize: "20px!important",
                          fontFamily: "Poppins",
                        }}
                      >
                        Ended On*
                      </InputLabel>
                      <DatePicker
                        selected={
                          formData.end_date ? new Date(formData.end_date) : null
                        }
                        dateFormat="yyyy-MM-dd"
                        required
                        id="outlined-required"
                        fullWidth
                        name="end_date"
                        value={formData.end_date ? formData.end_date : ""}
                        error={errors.end_date !== undefined}
                        onChange={(date) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            end_date: moment(date).format("YYYY-MM-DD"),
                          }))
                        }
                        size="small"
                      />
                      {errors.end_date && (
                        <FormHelperText error>{errors.end_date}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} marginBottom={1}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Description*
                      </InputLabel>
                      <Textarea
                        name="assignment_description"
                        value={formData.assignment_description}
                        onChange={handleInputChange}
                        // className={styles.settingGeneralFormFieldTextArea}
                        minRows={4}
                        maxRows={4}
                        error={errors.assignment_description !== undefined}
                      />
                      {errors.assignment_description && (
                        <FormHelperText error>
                          {errors.assignment_description}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  mb={"20px"}
                  className="assignment_form_wrapper_section"
                >
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xs={12} marginBottom={1}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Total Marks
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="total_marks"
                        variant="outlined"
                        placeholder="Enter Marks"
                        value={formData.total_marks}
                        onChange={handleMarksChange}
                        error={errors.total_marks !== undefined}
                        disabled={true}
                      />
                      {errors.total_marks && (
                        <FormHelperText error>
                          {errors.total_marks}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <ScoringCriteria
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </GlobalModal>
      </Box>
    </>
  );
}
