import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { Link, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import styles from "./manage-user-student.module.css";
import ImageShow from "../../../components/ShowImage";
import ImageView from "../../../components/imageShow";
import ProgressBar from "../../../components/progressBar";
import { toast } from "react-toastify";
import EditSkills from "../manage-skills/edit-skills";
import "react-toastify/dist/ReactToastify.css";
import {
  HasPermission,
  HasPermissionTab,
} from "../../../helper/helperFunction";
import StudentApi from "../../../config/apis/student";

export default function StudentProfile() {
  const navigate = useNavigate();
  const [studentInfo, setStudentInfo] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(StudentApi.STUDENT_PROFILE);
        setStudentInfo(res.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // const View = async () => {
  //   navigate(`/admin/user/student/edit/${params.id}`);
  // };

  return (
    <>
      <div className={styles.filter_flex}>
        <div className={styles.filter_flex}>
          <Box className={styles.filter_btn}>
            {/* <CSVDownloadButton apiEndpoint={AdminApi.USER_MODULE_TEACHER_DOWNLOAD_CSV} params={currentValue} /> */}
          </Box>
        </div>
      </div>
      <Box
        className={styles.studentProfileList}
        sx={{ borderBottom: "1px solid #edeced" }}
        marginBottom={4}
      >
        <Grid container>
          <Grid item xl={2.5} xs={3.5}>
            <Card className={styles.studentCardBx}>
              <CardContent className={styles.studentCardContent}>
                <ImageView
                  imageName={studentInfo && studentInfo?.profile_image}
                  name={studentInfo && studentInfo?.first_name}
                  width="140px"
                  height="140px"
                  sx={{ alignItems: "end" }}
                  className={styles.profileImage}
                  marginRight={"0"}
                />

                <Typography
                  variant="h4"
                  gutterBottom
                  className={styles.stuName}
                  sx={{
                    color: "#001C28",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "22px",
                    fontWeight: 700,
                    lineHeight: 1,
                    marginTop: 2,
                  }}
                >
                  <span className="bold">
                    {studentInfo?.first_name} {studentInfo?.last_name}
                  </span>
                </Typography>
              </CardContent>

              <HasPermission permission="manage-student-profile.edit">
                <div className={styles.stuViewDetailBtn}>
                  <Link
                    variant="contained"
                    color="primary"
                    to={`/admin/profile-update`}
                    className={styles.teaViewBtn}
                  >
                    Edit Profile
                  </Link>
                </div>
              </HasPermission>
            </Card>
          </Grid>
          <Grid item xl={9.5} xs={8.5} className={styles.rightSide}>
            <Grid container>
              <Grid
                item
                xs={12}
                className={styles.gridItem}
                sx={{ borderBottom: "1px solid #edeced" }}
              >
                <Typography variant="h3">Personal Data</Typography>
                <span>
                  Gender:<span className="bold">{studentInfo?.gender}</span>
                </span>
                <span>
                  Date of Birth:
                  <span className="bold">
                    <Moment format="DD-MM-YYYY">
                      {studentInfo?.date_of_birth}
                    </Moment>
                  </span>
                </span>
                <span>
                  Phone:
                  <span className="bold">{studentInfo?.phone}</span>
                </span>
                <span>
                  Email:<span className="bold">{studentInfo?.email}</span>
                </span>
              </Grid>
              <Grid item xs={12} className={styles.gridItem}>
                <Typography variant="h3">Address</Typography>
                <span>
                  Street address:
                  <span className="bold">{studentInfo?.street_address}</span>
                </span>
                <span>
                  City:
                  <span className="bold">{studentInfo?.city_name}</span>
                </span>
                <span>
                  State:
                  <span className="bold">{studentInfo?.state_name}</span>
                </span>
                <span>
                  Zip:<span className="bold">{studentInfo?.zip_code}</span>
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <EditSkills />
    </>
  );
}
