import axios from "axios";
import { API_KEY } from "../constants/EnviormentVariables";
import { IsTokenExpired } from "../../helper/helperFunction";

export * from "./auth";

const token = localStorage.getItem("auth");
const tokenObject = token ? JSON.parse(token) : "";
IsTokenExpired(tokenObject.exp);

export const api = axios.create({
  baseURL: API_KEY,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + tokenObject.token,
  },
});

export const resolveUrl = (route, param = "") => {
  let url = route;
  const paramRequired = url.includes(":");
  const paramOptional = url.includes("?");
  let paramName = url.substr(url.lastIndexOf(":") + 1);

  if (paramRequired && !paramOptional && !param)
    throw new Error(`parameter ${paramName} is required`);

  if (paramName) url = url.replace(`:${paramName}`, param);
  return url;
};
