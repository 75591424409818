import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

const GenderSelect = ({ updateCourseState, courseState }) => {
  const handleUpdate = (event) => {
    const { name, value } = event.target;
    updateCourseState({
      [name]: value,
    });
  };

  return (
    <FormControl fullWidth>
      <Select
        name="gender"
        value={courseState.gender}
        onChange={handleUpdate}
        sx={{ textTransform: "capitalize" }}
      >
        {["male", "female", "other"].map((item, i) => (
          <MenuItem key={i} value={item} sx={{ textTransform: "capitalize" }}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GenderSelect;
