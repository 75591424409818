import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Form from "../../../components/forms/Forms";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import "./style.css";

export default function EditPermissionRole(params) {
  const [formData, setFormData] = useState({
    name: "",
    permissions: {},
  });
  const [onSucces, setOnSucces] = useState(false);

  const [permissions, setPermissions] = useState([]);
  const [modules, setModules] = useState([]);

  const [rolePermissions, setRolePermission] = useState({});

  const handlePermissionChange = (event) => {
    const target = event.target;

    setFormData({
      ...formData,
      permissions: {
        ...formData.permissions,
        [`${target.dataset.permission}`]: target.checked,
      },
    });

    setRolePermission({
      ...rolePermissions,
      [`${target.dataset.permission}`]: target.checked,
    });
  };

  const editData = useCallback(async () => {
    if (params.id) {
      const res = await api.get(resolveUrl(AdminApi.ROLES_EDIT, params.id));
      setRolePermission(res.data.rolePermissions);
      setFormData({
        name: res.data.data.name,
        role_id: params.id,
        permissions: res.data.rolePermissions,
      });
    }

    const permissionRes = await api.get(AdminApi.PERMISSIONS_LIST);
    setPermissions(permissionRes.data.data ?? []);

    const modulesRes = await api.get(AdminApi.MODULES_LIST);
    setModules(modulesRes.data.data ?? []);
  }, [params.id]);

  useEffect(() => {
    editData();
  }, [editData]);

  useEffect(() => {
    if (onSucces) {
      params.setViewId("");
    }
  }, [onSucces]);

  return (
    <>
      <Box className="page-container permission_wrap">
        <Form
          submitUrl={AdminApi.ROLES_PERMISSION_SAVE}
          formData={formData}
          successPath={"/admin/setting"}
          setFormSuccess={setOnSucces}
        >
          <Box className="field">
            <span className="role_name_label role_name_">
              {" "}
              Role Name :{" "}
              <span className="role_name role_name_">{formData.name}</span>
            </span>
          </Box>
          <Box className="permission_table">
            <Table>
              <TableHead>
                <TableRow className="table-head">
                  <TableCell className="table-heading">Name</TableCell>
                  {permissions.map((permission) => {
                    return (
                      <TableCell className="table-heading" key={permission.id}>
                        {permission.name}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {modules.map((module) => {
                  return (
                    <TableRow key={module.id}>
                      <TableCell className="table-cell">
                        {module.name}
                      </TableCell>
                      {permissions.map((permission) => {
                        return (
                          <TableCell key={`${permission.name}.${module.name}`}>
                            <Checkbox
                              name="rolePermission"
                              value={true}
                              inputProps={{
                                "data-permission": `${permission.name}.${module.name}`,
                              }}
                              color="success"
                              checked={
                                rolePermissions &&
                                rolePermissions[
                                  `${permission.name}.${module.name}`
                                ]
                                  ? true
                                  : false
                              }
                              onChange={handlePermissionChange}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Button className="cancle_btn" onClick={() => params.setViewId("")}>
              Cancel
            </Button>
          </Box>
        </Form>
      </Box>
    </>
  );
}
