import { useState, useEffect } from "react";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";

export const useAccodianData = () => {
  const [accodianData, setAccodianData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.STUDENT_REPORTS_FILTERS));
        setAccodianData(res.data.data ?? []);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return accodianData;
};
