import React from "react";
import { SERVER_KEY } from "../../config/constants/EnviormentVariables";
import { Avatar } from "@mui/material";

const ImageView = (params) => {
  const baseUrl = SERVER_KEY;
  const imageUrl = `${baseUrl}${params.imageName}`;

  const style = {
    width: "40px",
    height: "40px",
    marginRight: "15px",
    // alignItems:"end"
  };
  if (params.width) {
    style.width = params.width;
  }
  if (params.width) {
    style.height = params.height;
  }
  if (params.marginRight) {
    style.marginRight = params.marginRight;
  }
  return (
    <>
      <Avatar
        sx={style}
        alt={params.name ? params.name.toUpperCase() : ""}
        src={imageUrl ?? ""}
      />
    </>
  );
};

export default ImageView;
