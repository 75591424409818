import React from "react";
import { Chip, Grid, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import useTypeRageSelect from "./Hooks/useTypeRageSelect";

const TypeRageSelect = ({ label, updateCourseState }) => {
  const { handleDeleteChip, handleAgeChange, ageRange } = useTypeRageSelect({
    label,
    updateCourseState,
  });

  return (
    <Grid container className="date_range_filter" alignItems={"center"}>
      <Grid item sx={{ minWidth: "220px" }} marginRight={"20px"}>
        <Typography variant="subtitle1">{"Filter"}</Typography>
        <Slider
          className="range_slider"
          value={ageRange}
          onChange={handleAgeChange}
          valueLabelDisplay="auto"
          min={1}
          max={100}
          step={1}
        />
      </Grid>
      <Grid item>
        <div style={{ marginTop: "25px", marginLeft: "20px" }}>
          {ageRange[0] !== null && ageRange[1] !== null && (
            <Chip
              label={`${ageRange[0]}-${ageRange[1]}`}
              onDelete={handleDeleteChip}
              style={{
                marginRight: "7px",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default TypeRageSelect;
