import React, { useEffect } from 'react';
import ChangePassword from "../../pages/auth/ChangePassword";
import Footer from "../frontend/partials/Footer/Footer";

const PasswordChange = () =>{
    useEffect(() => {
        // Add class to body element when the component mounts
        document.body.classList.add('for-bg');
    
        // Remove class from body element when the component unmounts
        return () => {
          document.body.classList.remove('for-bg');
        };
      }, []);
    return(
        <>
            <div className='container'>
                <ChangePassword/>
            </div>
            <Footer />
        </>
    )

}
export default PasswordChange;
