import { Link } from "react-router-dom";
import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import DiscountFormModal from "./modal";
import React from "react";
import { DateView } from "../../../components/DateView/DateView";
import { HasPermission } from "../../../helper/helperFunction";

export default function ViewLocation() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState("");
  const [reRender, setReRender] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const tableColumns = [
    {
      selector: (row) => row.name,
      name: "Name",
      sortable: true,
      minWidth: "150",
    },
    {
      selector: (row) => row.currency,
      name: "Currency",
      sortable: true,
      minWidth: "150",
    },
    {
      selector: (row) => row.city_name,
      name: "City",
      sortable: true,
      minWidth: "150",
    },
    {
      selector: (row) => row.time_zone,
      name: "Time Zone",
      sortable: true,
      minWidth: "150",
    },
    {
      selector: (row) => row.address,
      name: "Address",
      sortable: true,
      minWidth: "500px",
    },
    {
      cell: (row) => <DateView date={row.created_at} />,
      name: "Created on",
      sortable: true,
      selector: "created_at",
      minWidth: "150",
    },
  ];

  const onEdit = async (id) => {
    setViewId(id);
    if (id) {
      setIsModalOpen(true);
    }
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.LOCATION_DELETE, id));
  };

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);

  return (
    <>
      <div className="page-container table-phases">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.LOCATION_PAGINATE}
          onEdit={onEdit}
          onDelete={onDelete}
          customClass="TabelOverflow"
          refreshHandle={reRender}
          actions={{
            view: false,
          }}
          module="manage-locations"
        />
      </div>

      <HasPermission permission="manage-locations.create">
        <div className="course_content">
          <Box className="btn-wrapper">
            <Link to={""} onClick={toggleModal}>
              Add Location
            </Link>
          </Box>
        </div>
      </HasPermission>
      <DiscountFormModal
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
