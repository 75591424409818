import React, { useState } from "react";
import { AuthProvider } from "./auth/auth";
import { LoadingProvider } from "../pages/admin/Dashboard/loader";
import { StudentReportProvider } from "../context/StudentReportContext";
import { FilterContextProvider } from "../context/filter-context";

const AppProvider = function (props) {
  return (
    <>
      <AuthProvider>
        <LoadingProvider>
          <StudentReportProvider>
            <FilterContextProvider>{props.children}</FilterContextProvider>
          </StudentReportProvider>
        </LoadingProvider>
      </AuthProvider>
    </>
  );
};

export default AppProvider;
