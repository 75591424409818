import { useState } from "react";
import { api, resolveUrl } from "../../../../config/apis";
import AdminApi from "../../../../config/apis/admin";
import { useNavigate } from "react-router-dom";
import { useStudentReportContext } from "../../../../context/StudentReportContext";

const useGenrateReport = ({ columnsWithIds }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [responseData, setResponseData] = useState(null);

  const nevigate = useNavigate();

  const { updateStudentReports } = useStudentReportContext();

  const handleSubmit = async ({ courseState, courseFilter }) => {
    setIsLoading(true);
    const selectedItem = {};
    if (columnsWithIds) {
      columnsWithIds.forEach((item) => {
        const key = item.label.replace(/\s+/g, "_").toLowerCase();
        const type = item.type;
        if (!(key in courseState)) {
          switch (type) {
            case "multi_select":
              selectedItem[key] = ["yes"];
              break;
            case "range":
              selectedItem[key] = {
                min: 1,
                max: 100,
              };
              break;
            default:
              selectedItem[key] = "yes";
              break;
          }
          if (key === "organization") {
            selectedItem[key] = [0];
          }
        }
      });
    }

    try {
      const response = await api.post(
        resolveUrl(AdminApi.GENRATE_STUDENT_REPORTS),
        { ...courseFilter, ...courseState, ...selectedItem }
      );
      if (response.data) {
        setIsError(false);
        setSuccessMsg(response.data.message);
        setResponseData(response.data);
        updateStudentReports(response.data.data ?? []);
        nevigate(`/admin/students-reports`);
        setIsLoading(false);
      }
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      let message = "";
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          message = error.response.data.message;
        }
      }

      setErrorMsg([message]);
      console.error("Error occurred while logging in:", error);
    }
  };

  return {
    handleSubmit,
    isError,
    errorMsg,
    successMsg,
    responseData,
    isLoading,
  };
};

export default useGenrateReport;
