import { Alert, Box, Button } from "@mui/material";
import { FormEvent, useState } from "react";
import { api, resolveUrl } from "../../config/apis";
import FormLoader from "../loader/FormLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import styles from "./manage-forms.module.css";

export default function Form(params) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);

  const navigate = useNavigate();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (params.setConfirmModal) {
      params.setConfirmModal();
    }

    const isValid = params.validate ? await params.validate() : true;

    setIsLoading(true);
    setIsError(false);

    if (isValid) {
      await api
        .post(resolveUrl(params.submitUrl, params.editId), params.formData, {
          headers: {
            "Content-Type": params.multipart
              ? "multipart/form-data"
              : "application/json",
          },
        })
        .then((res) => {
          setIsLoading(false);
          toast.success(res.data.message || res.data.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setErrorMsg(false);
          if (params.onSuccess) {
            params.onSuccess(res.data);
          }
          if (params.setFormSuccess) {
            params.setFormSuccess((prevState) => !prevState);
          }

          if (params.cancleShowForModals) {
            params.toggleModal();
          }
          if (params.successPath) {
            navigate(params.successPath);
          }
        })
        .catch((error) => {
          setErrorMsg(["Something went wrong!"]);
          setIsLoading(false);
          setIsError(true);
          console.error(error);

          if (error.response) {
            if (error.response.data.error === true) {
              const errorMessage = error.response.data.message;
              setErrorMsg(errorMessage);
            } else if (
              error.response.status === 400 ||
              error.response.status === 401
            ) {
              const errorMessage =
                error.response.data.message ||
                error.response.data.error ||
                error.response.data;
              setErrorMsg(errorMessage);
            } else if (error.response.status === 404) {
              const errorMessage = error.response.data.message;
              setErrorMsg([errorMessage]);
            } else if (error.response.data.status === 400) {
              const errorMessage = error.response.data.message;
              setErrorMsg([errorMessage]);
            } else if (error.response.status === 500) {
              const errorMessage = error.response.statusText;
              setErrorMsg([errorMessage]);
            } else {
              toast.error(`Error: ${error.code}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        });
    } else {
      setIsLoading(false);
      setIsError(false);
    }
  };

  return (
    <>
      {isLoading && <FormLoader />}
      <Box
        component="form"
        onSubmit={handleFormSubmit}
        noValidate
        sx={{ mt: 1 }}
        className={params.customClass ?? ""}
      >
        {isError && (
          <Alert severity="error">
            <ul style={{ padding: "0", margin: "0" }}>
              {Array.isArray(errorMsg) ? (
                errorMsg.map((value, index) => <li key={index}>{value}</li>)
              ) : (
                <li>{errorMsg}</li>
              )}
            </ul>
          </Alert>
        )}
        {params.children}
        <Box className="btn-flex">
          {params.cancleShow
            ? params.cancleShow && (
                <Button
                  className={`${styles.btn} ${styles.cancel}`}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => window.history.back()}
                >
                  Cancel
                </Button>
              )
            : ""}
          {params.cancleShowForModals
            ? params.cancleShowForModals && (
                <Button
                  className={`${styles.btn} ${styles.cancel}`}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() =>
                    params.cancleShowForModals ? params.toggleModal() : ""
                  }
                >
                  Cancel
                </Button>
              )
            : ""}
          <Button
            className={`${styles.btn} ${styles.save}`}
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {params.saveText ? params.saveText : "Save"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
