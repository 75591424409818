import {
  Box,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useEffect } from "react";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState } from "react";
import GlobalModal from "../../../components/modalBox";
import SelectOptions from "../../../components/Select";
import Form from "../../../components/forms/Forms";
import SelectOptionsApi from "../../../components/SelectApi/index";
import * as yup from "yup";
import styles from "./manage-support-ticket.module.css";
import CKEditorComponent from "../../../components/CKEditorComponent";

export const EditSupportTicket = (props) => {
  const defaultFormData = {
    student_Id: "",
    section: "",
    organization_id: "",
    transaction_type: "",
    registration_fee: null,
    currency_id: null,
    payer_payee: "student",
    email: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  const handleCloseModal = () => {
    props.setModalOpen(false);
    setErrors({});
    setFormData(defaultFormData);
    setUserID("");
    setSearchQuery("");
  };

  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    student_Id: yup.string().required("This field required"),
    organization_id: yup.string().required("This field required"),
    transaction_type: yup.string().required("This field required"),
    registration_fee: yup.string().required("This field required"),
    currency_id: yup.string().required("This field required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [userID, setUserID] = useState();

  const handleOnChangeUser = (event) => {
    const valueTarget = event.target.value;
    setUserID(valueTarget);

    setErrors((prevErrors) => ({
      ...prevErrors,
      student_Id: undefined,
    }));
  };

  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  const handleFeeUpdate = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      registration_fee: value ? Number(value) : "",
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const PaymentInfo = [
    { id: "Cohort Fee", options: "Cohort Fee" },
    { id: "Registration", options: "Registration" },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleStudentClick = (student) => {
    setUserID(student.id);
    setSearchQuery(student.name);
    setFormData({
      ...formData,
      student_Id: student.id,
      organization_id: searchResults[0].organization_id,
      email: searchResults[0].email,
      section: searchResults[0].section,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchQuery.length >= 3) {
          const paramsText = searchQuery;
          const response = await api.get(
            resolveUrl(AdminApi.TRANSACTIONS_PREFILLED_DATA),
            {
              params: {
                text: paramsText,
              },
            }
          );
          setSearchResults(response.data.data);
        } else {
          setSearchResults([]);
          setUserID("");
          setFormData({
            ...formData,
            student_Id: "",
            organization: "",
            organization_id: null,
            email: "",
            section: "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userID.length > 0) {
          const paramsId = userID;
          const response = await api.get(
            resolveUrl(AdminApi.TRANSACTIONS_PREFILLED_DATA),
            {
              params: {
                text: paramsId,
              },
            }
          );
          setFormData({
            ...formData,
            student_Id: response.data.data[0].id,
            organization_id: response.data.data[0].organization_id,
            email: response.data.data[0].email,
            section: response.data.data[0].section,
          });
          setSearchQuery(response.data.data[0].name);
        } else {
          if (!userID) {
            setSearchQuery("");
            setFormData({
              ...formData,
              student_Id: "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [userID]);

  useEffect(() => {
    if (props.editId) {
      const fetchEditData = async () => {
        try {
          const response = await api.get(
            resolveUrl(AdminApi.TRANSACTIONS_VIEW, props.editId)
          );
          setFormData({
            student_Id: response.data.data.user_id ?? "",
            section: response.data.data.section,
            organization_id: response.data.data.organization_id ?? "",
            transaction_type: response.data.data.type ?? "",
            registration_fee: response.data.data.registration_fee ?? "",
            email: response.data.data.email ?? "",
            currency_id: response.data.data.currency_id ?? "",
          });
          setUserID(response.data.data.user_id);
          setSearchQuery(response.data.data.name);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchEditData();
    }
  }, [props.editId, props.modalOpen]);

  const submitUrl = AdminApi.TRANSACTIONS_UPDATE;

  const handleEditorChange = (event, editor) => {
    const editor_data = editor.getData();
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editor_data;
    const links = tempDiv.querySelectorAll("a");
    links.forEach((link) => {
      if (link.host !== window.location.host) {
        link.setAttribute("target", "_blank");
      }
    });

    const data = tempDiv.innerHTML;
    setFormData((prevFormData) => ({
      ...prevFormData,
      job_description: data,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      job_description: undefined,
    }));
  };

  return (
    <>
      <GlobalModal
        open={props.modalOpen}
        onClose={handleCloseModal}
        width={619}
      >
        <Form
          successPath={
            props.editId
              ? `/admin/transactions/view/${props.editId}`
              : "/admin/transactions"
          }
          submitUrl={submitUrl}
          formData={formData}
          setFormSuccess={props.setFormSuccess}
          editId={props.editId}
          validate={customValidation}
          customClass={styles.tractionForm}
        >
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ marginBottom: 2 }}
          >
            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Title *
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                  name="section"
                  variant="outlined"
                  value={formData.section}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Assigned to*
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <SelectOptionsApi
                  apiEndpoint={AdminApi.ORGANIZATIONS}
                  formData={formData}
                  onUpdate={handleUpdate}
                  labelKey="name"
                  valueKey="id"
                  name="organization_id"
                  errorText={errors.organization_id}
                />
              </Grid>
            </Grid>
            <Grid item container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item mr={"45px"}>
                <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                  Description*
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <CKEditorComponent
                  initialValue={formData.job_description}
                  onChange={handleEditorChange}
                />
                {errors.job_description && (
                  <FormHelperText error>
                    {errors.job_description}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </GlobalModal>
    </>
  );
};
