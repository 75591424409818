import React, { useEffect, useState } from "react";
import styles from "./filter.module.css";
import { Box, Button, TextField, Typography } from "@mui/material";

const FilterBase = ({ onFiltersChange }) => {
  const handleApplyFilter = () => {
    onFiltersChange(filterData);
  };

  const [filterData, setFilterData] = useState({});

  return (
    <Box className={styles.baseFilterMain}>
      <form className={styles.studentTopFilterMain}>
        <Typography
          variant="body1"
          className={styles.studentTopFilterMainTitle}
        >
          Search By
        </Typography>
        <Box className={styles.studentTopFilterKeyword}>
          <TextField
            id="username"
            variant="outlined"
            fullWidth
            placeholder="workshop name"
            className={styles.studentTopFilterKeywordInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleApplyFilter();
              }
            }}
            onChange={(event) =>
              setFilterData((prevState) => ({
                ...prevState,
                name: event.target.value,
              }))
            }
          />

          <Box className={styles.studentTopFilterBtn}>
            <Button
              className={styles.studentTopFilterBtnContent}
              onClick={() => handleApplyFilter()}
            >
              Go
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default FilterBase;
