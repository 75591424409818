import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { JobCards } from "../manage-jobs/job-cards";
import styles from "./style.module.css";
import JobSingleCard from "./job-single-card";
import { StudentJobRecordsTable } from "../manage-student-job-records/view-table";
import ViewSkillsTable from "../manage-skills/view-tables";
import { StudentResumeTable } from "../manage-student-resume/view-table";
import { HasPermission } from "../../../helper/helperFunction";

export const JobsDashboard = () => {
  return (
    <>
      <Box className="container">
        <Box className="course-content">
          <h1 className="main-heading">Job Board</h1>
          <HasPermission permission="manage-jobs-board.create">
            <Box className="btn-wrapper">
              <Link to={"/admin/jobs/edit"}>Add a Job</Link>
            </Box>
          </HasPermission>
        </Box>

        <JobCards filter={true} />

        <Grid container sx={12} marginBottom={4}>
          <Grid
            item
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            margin={"15px 0"}
          >
            <Typography variant="h3" className={styles.fs_h3}>
              Recent Jobs
            </Typography>
            <HasPermission permission="manage-jobs-board.view">
              <Link className={styles.view_btn} to={"/admin/jobs"}>
                View Jobs
              </Link>
            </HasPermission>
          </Grid>

          <Grid container sx={12} spacing={4}>
            <JobSingleCard />
          </Grid>
        </Grid>

        <Grid container sx={12} spacing={4}>
          <Grid
            item
            lg={6}
            sx={12}
            className={styles.table_wraper}
            marginTop={4}
          >
            <Grid
              sx={12}
              item
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              marginTop={"-20px"}
              marginBottom={"10px"}
            >
              <Typography variant="h3" className={styles.fs_h3}>
                Top Job Skills
              </Typography>
              <HasPermission permission="manage-job-skills.view">
                <Link className={styles.view_btn} to={"/admin/skills"}>
                  View More
                </Link>
              </HasPermission>
            </Grid>
            <ViewSkillsTable
              pagination={false}
              setIsModalOpen={""}
              isModalOpen={""}
              toggleModal={""}
              actionColumns={false}
            />
          </Grid>
          {/* <HasPermission permission="manage-jobs-board.create"> */}
          <Grid item lg={6} sx={12}>
            <StudentResumeTable>
              <Grid
                item
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                marginTop={"-20px"}
                marginBottom={"10px"}
              >
                <Typography variant="h3" className={styles.fs_h3}>
                  Recent Student Resumes
                </Typography>
                <HasPermission permission="manage-student-resume.view">
                  <Link
                    className={styles.view_btn}
                    to={"/admin/student-resume"}
                  >
                    View More
                  </Link>
                </HasPermission>
              </Grid>
            </StudentResumeTable>
          </Grid>
          {/* </HasPermission> */}
        </Grid>

        <StudentJobRecordsTable currentValue={"desc"} pagination={false}>
          <Grid
            item
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={"-20px"}
            marginBottom={"10px"}
          >
            <Typography variant="h3" className={styles.fs_h3}>
              Job Records
            </Typography>
            <HasPermission permission="manage-job-record.view">
              <Link
                className={styles.view_btn}
                to={"/admin/student-job-records"}
              >
                View More
              </Link>
            </HasPermission>
          </Grid>
        </StudentJobRecordsTable>
      </Box>
    </>
  );
};
