import {
  Box,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
  Button,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";
import { useEffect } from "react";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState } from "react";
import GlobalModal from "../../../components/modalBox";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";
import styles from "./style.module.css";
import { useParams } from "react-router-dom";

export const EditCourseOutline = (props) => {
  const params = useParams();
  const [formData, setFormData] = useState({
    media_files: [],
    name: "",
    url: "",
    content_duration: "",
    dlt_media_id: [],
    lecture_order:null
  });
  const [mediaId, setMediaId] = useState();

  useEffect(() => {
    if (!props.modalOpen) {
      setErrors({});
      setFormData({
        media_files: [],
        name: "",
        url: "",
        content_duration: "",
        dlt_media_id: [],
      });
    }
  }, [props.modalOpen]);

  const [errors, setErrors] = useState({});

  const schema = yup.object().shape({
    name: yup.string().required("This field required"),
    // url: yup.string().required("This field required"),
    content_duration: yup.string().required("This field required"),
    lecture_order: yup
    .number()
    .required("This field is required")
    .positive("Lecture order should be a positive number")
    .integer("Lecture order should be an integer")
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  useEffect(() => {
    if (props.editId) {
      const fetchEditData = async () => {
        try {
          const response = await api.get(
            resolveUrl(AdminApi.COURSE_OUTLINE_VIEW, props.editId)
          );
          const { duration, name, upload_media, url,lecture_order } = response.data.data;

          const uploadTitles = [];
          const mediaId = [];
          if (upload_media) {
            upload_media.forEach((item) => {
              uploadTitles.push(item.upload_title);
            });
          }
          if (upload_media) {
            upload_media.forEach((item) => {
              mediaId.push(item.media_id);
            });
          }
          setMediaId(mediaId);
          setFormData({
            media_files: uploadTitles ?? [],
            name: name ?? "",
            url: url ?? "",
            content_duration: duration.toString() ?? "",
            dlt_media_id: [],
            lecture_order:lecture_order ?? null
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchEditData();
    }
  }, [props.editId, props.modalOpen]);

  const handleMediaInputchange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleMediaDuration = (event) => {
    const value = parseInt(event.target.value);
    setFormData({ ...formData, content_duration: value });
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;

    const updatedMediaData = {
      ...formData,
      media_files: [
        ...formData.media_files,
        ...Array.from(files).map((file) => ({
          file,
        })),
      ],
    };
    setFormData(updatedMediaData);
  };

  const handleRemoveItem = (index) => {
    const updatedmediaId = mediaId;
    const removeId = updatedmediaId[index];
    updatedmediaId.splice(index, 1);

    const updatedUploadFiles = [...formData.media_files];
    updatedUploadFiles.splice(index, 1);

    const updatedMediaData = {
      ...formData,
      media_files: updatedUploadFiles,
      dlt_media_id: [...formData.dlt_media_id,removeId ?? null], 
    };

    setFormData(updatedMediaData);
  };

  const submitUrl = props.editId
    ? AdminApi.COURSE_OUTLINE_UPDATE
    : AdminApi.COURSE_OUTLINE_EDIT;
  const updateId = props.editId ? props.editId : params.id;



const updatedData = {
  ...formData
}
if (updatedData.media_files.length === 0) {
  delete updatedData.media_files;
}


  return (
    <>
      <GlobalModal
        open={props.modalOpen}
        onClose={props.handleCloseModal}
        width={619}
      >
        <Form
          successPath={`/admin/courses/view/${params.id}`}
          submitUrl={submitUrl}
          formData={updatedData}
          setFormSuccess={props.setFormSuccess}
          editId={updateId}
          validate={customValidation}
          multipart={true}
        >
          <Grid
            container
            justifyContent={"space-between"}
            className={styles.courseOutLineForm}
          >
            <Grid item xs={12}>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={3}>
                  <InputLabel sx={{ textAlign: "left" }}>Number</InputLabel>
                </Grid>
                <Grid item xs={9}>
                  {/* <Select
                    sx={{ width: "80px" }}
                    onChange={handleMediaInputchange}
                    name="lecture_order"
                    value={formData.lecture_order ?? null}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select> */}
                  <TextField
                   sx={{width:"100%",maxWidth:"80px"}}
                   fullWidth
                   type="number"
                    variant="outlined"
                    value={formData.lecture_order ?? null}
                    name="lecture_order"
                    onChange={handleMediaInputchange}
                    InputProps={{ inputProps: { min: 0, step: 1 } }}
                    error={errors.lecture_order !== undefined}

                  />
                   {errors.lecture_order && (
                    <FormHelperText error>{errors.lecture_order}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={3}>
                  <InputLabel sx={{ textAlign: "left" }}>Title</InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Title"
                    value={formData.name}
                    name="name"
                    onChange={handleMediaInputchange}
                    error={errors.name !== undefined}
                  />
                  {errors.name && (
                    <FormHelperText error>{errors.name}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={3}>
                  <InputLabel sx={{ textAlign: "left" }}>Upload</InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Button
                      variant="contained"
                      component="label"
                      className={styles.upload_file}
                    >
                      browse
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={handleFileUpload}
                      />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ alignItems: "center" }}>
                <Grid item xs={3}></Grid>
                {formData.media_files && formData.media_files.length > 0 && (
                  <Grid container item xs={6} marginBottom={3}>
                    {formData.media_files.length > 0
                      ? formData.media_files.map((mediaItem, index) => (
                          <Chip
                            key={index}
                            label={mediaItem.file?.name ?? mediaItem}
                            onDelete={() => handleRemoveItem(index)}
                            style={{
                              marginRight: "5px",
                              marginBottom: "5px",
                            }}
                          />
                        ))
                      : ""}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={3}>
                  <InputLabel sx={{ textAlign: "left" }}>URL</InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter url"
                    value={formData.url}
                    name="url"
                    onChange={handleMediaInputchange}
                  />
                  {errors.url && (
                    <FormHelperText error>{errors.url}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={3}>
                  <InputLabel sx={{ textAlign: "left" }}>Hrs</InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    sx={{ maxWidth: "100px" }}
                    placeholder="hours"
                    value={formData.content_duration}
                    name="content_duration"
                    onChange={handleMediaDuration}
                  />
                  {errors.content_duration && (
                    <FormHelperText error>
                      {errors.content_duration}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </GlobalModal>
    </>
  );
};
