import { createContext, useContext, useReducer } from "react";
import { api } from "../../config/apis";

export const AuthStateType = createContext({
  user: null,
  token: null,
  userRole: null,
  userRolePermissions: null,
});

export const AuthContext = createContext({
  state: AuthStateType | null,
  dispatch: null,
});

export const authReducer = function (state, action) {
  switch (action.type) {
    case "LOGIN":
      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.token}`;
      return { state: action.payload };
    case "LOGOUT":
      return { state: null };
    default:
      return state;
  }
};

export const AuthProvider = function (props) {
  const initialState = localStorage.getItem("auth");

  const [state, dispatch] = useReducer(authReducer, {
    state: initialState ? JSON.parse(initialState) : null,
  });
  if (initialState)
    api.defaults.headers.common["Authorization"] = `Bearer ${
      JSON.parse(initialState).token
    }`;

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = function () {
  return useContext(AuthContext);
};
