import { useState, useEffect } from "react";
import { TextField, Button, InputLabel, FormHelperText } from "@mui/material";
import { Box } from "@mui/material";
import styles from "../../pages/admin/manage-courses/manage-course.module.css";

export default function ScoringCriteria(params) {
  const [title, setTitle] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [totalScore, setTotalScore] = useState(0);

  const addField = () => {
    if (title.trim() !== "") {
      params.setFormData((prevFormData) => ({
        ...prevFormData,
        scoring_criteria: [
          ...prevFormData.scoring_criteria,
          { title: title, score: null },
        ],
      }));
      setTitle("");
      setValidationMessage("");
    } else {
      setValidationMessage("Title cannot be empty");
    }
  };
  useEffect(() => {
    const scores = params.formData.scoring_criteria.map((field) => field.score);
    const total = scores.reduce((acc, score) => acc + score, 0);
    setTotalScore(total);
  }, [params.formData.scoring_criteria]);

  const data = [];

  const removeField = (index) => {
    const updatedData = [...params.formData.scoring_criteria];
    updatedData.splice(index, 1);
    params.setFormData((prevFormData) => ({
      ...prevFormData,
      scoring_criteria: updatedData,
    }));
  };

  const handleFieldChange = (index, key, value) => {
    const updatedData = [...params.formData.scoring_criteria];
    updatedData[index][key] = value;
    params.setFormData((prevFormData) => ({
      ...prevFormData,
      scoring_criteria: updatedData,
    }));
  };

  return (
    <>
      <h2>{params.heding}</h2>

      <Box sx={{ width: "100%" }}>
        <InputLabel sx={{ textAlign: "left" }}>
          Add Scoring ({isNaN(totalScore) ? "0" : `${totalScore}`}%)
        </InputLabel>
        <span style={{ display: "flex", alignItems: "start" }}>
          <TextField
            sx={{ width: "88%", marginRight: "20px" }}
            name="title"
            variant="outlined"
            margin="normal"
            onChange={(event) => setTitle(event.target.value)}
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            className={styles.plusbtn}
            onClick={addField}
          >
            +
          </Button>
        </span>
        {validationMessage && (
          <FormHelperText error>{validationMessage}</FormHelperText>
        )}

        {params.formData.scoring_criteria.length > 0
          ? params.formData.scoring_criteria.map((field, index) => (
              <>
                <Box key={index} sx={{ marginTop: "20px" }}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    {field.title}*
                  </InputLabel>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      type="number"
                      fullWidth
                      value={field.score}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (isNaN(value)) {
                          handleFieldChange(index, "score", null);
                        } else {
                          handleFieldChange(index, "score", value);
                        }
                      }}
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />

                    <Button
                      variant="contained"
                      color="secondary"
                      className={styles.minusbtn}
                      onClick={() => removeField(index)}
                    >
                      -
                    </Button>
                  </Box>
                </Box>

                {params.errors &&
                  params.errors[`scoring_criteria[${index}].score`] && (
                    <FormHelperText error>
                      {params.errors[`scoring_criteria[${index}].score`]}
                    </FormHelperText>
                  )}
              </>
            ))
          : data.map((field, index) => (
              <Box key={index} sx={{ marginTop: "20px" }}>
                <InputLabel sx={{ textAlign: "left" }}>
                  {field.title}
                </InputLabel>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    type="number"
                    fullWidth
                    value={field.score}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (isNaN(value)) {
                        handleFieldChange(index, "score", null);
                      } else {
                        handleFieldChange(index, "score", value);
                      }
                    }}
                    variant="outlined"
                    margin="normal"
                    size="small"
                  />

                  <Button
                    variant="contained"
                    color="secondary"
                    className={styles.minusbtn}
                    onClick={() => removeField(index)}
                  >
                    -
                  </Button>
                </Box>
              </Box>
            ))}

        {params.errors && params.errors["assessmentWeightage"] && (
          <FormHelperText error>
            {params.errors && params.errors["assessmentWeightage"]}
          </FormHelperText>
        )}
      </Box>
    </>
  );
}
