import { Typography } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { resolveUrl } from "../../../config/apis";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ImageView from "../../../components/imageShow";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GlobalModal from "../../../components/modalBox";
import ViewAssignmentsMarks from "./gradeAssignmentDetail/view";

export default function ViewGradeBook() {
  const params = useParams();

  const fontStyleName = {
    color: "#001C28",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "1",
  };
  const fontStyle = {
    color: "#001C28",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
  };
  const [tableData, setTableData] = useState([]);

  const getMaxCriteriaCount = (data) => {
    let maxCount = 0;
    data.forEach((row) => {
      if (row.assessments && row.assessments.length > maxCount) {
        maxCount = row.assessments.length;
      }
    });
    return maxCount;
  };

  // Get the maximum number of criteria in your data
  const maxCriteriaCount = getMaxCriteriaCount(tableData);

  // Create an array to hold the columns for scoring criteria
  const scoringCriteriaColumns = [];
  for (let i = 0; i < maxCriteriaCount; i++) {
    scoringCriteriaColumns.push({
      cell: (row) => {
        const criteria = row.assessments[i];
        return criteria
          ? criteria.assessment_percentage
            ? criteria.assessment_percentage.toFixed(2) + "%"
            : "N/A"
          : "";
      },
      name: (
        <div>
          <Typography
            variant="body2"
            component="p"
            sx={{
              textAlign: "center",
              color: "#fff",
              fontSize: "13px",
              fontWeight: 700,
            }}
            className="assignments_name"
          >
            <span>{tableData[0].assessments[i]?.assessment_type}</span>
            <button
              style={{ color: "#fff", fontSize: "13px", fontWeight: 700 }}
              onClick={() =>
                viewAssignmentDetail(
                  tableData[0].assessments[i]?.assesment_weight_age_id
                )
              }
            >
              view <VisibilityIcon />
            </button>
          </Typography>
          {/* <div className="out_of_box">
            <Typography
              variant="body2"
              component="p"
              sx={{ textAlign: "center" }}
            >
              Out of 100%
            </Typography>
            <Typography
              variant="body2"
              component="p"
              sx={{ textAlign: "center" }}
            >
              (weightage {tableData[0].assessments[i]?.weightage})
            </Typography>
          </div> */}
        </div>
      ),
      sortable: false,
      minWidth: "200px",
    });
  }
  const tableColumns = [
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.student_name} />
          <Typography variant="body2" component="span" sx={fontStyleName}>
            {toTitleCase(`${row.student_name}`)}
          </Typography>
        </>
      ),
      name: "Name",
      sortable: false,
      minWidth: "300px",
    },
    {
      cell: (row) => (
        <>
          <Typography variant="body2" component="span">
            {row.attendance_score + "%"}
          </Typography>
        </>
      ),
      name: "Attendance",
      sortable: false,
      minWidth: "150px",
    },
    {
      cell: (row) => (
        <>
          <Typography variant="body2" component="span">
            {row.workshop_score + "%"}
          </Typography>
        </>
      ),
      name: "Workshop",
      sortable: false,
      minWidth: "150px",
    },
    ...scoringCriteriaColumns,

    //   {
    //     cell: (row) => (
    //       <>
    //         <Typography
    //           variant="body2"
    //           component="span"
    //           sx={fontStyle}
    //           style={{
    //             color:
    //               row.obtained_marks <= 50 && row.obtained_marks !== 0
    //                 ? "#C33F28"
    //                 : row.obtained_marks > 50
    //                 ? "#00CF53"
    //                 : "#001C28",
    //             fontWeight: "bold"
    //           }
    //         }
    //         >
    //           {row.obtained_marks} <span className="totalMarks"> / {row.assignment_total_marks} </span>
    //         </Typography>
    //       </>
    //     ),
    //   name: "Total",
    //   sortable: false,
    //   width: "150px",
    // },
    {
      selector: (row) => (
        <>
          <Typography
            variant="body2"
            component="span"
            sx={fontStyle}
            style={{
              color:
                row.result <= 50 && row.result !== 0
                  ? "#C33F28"
                  : row.result > 50
                  ? "#00CF53"
                  : "#001C28",
              fontWeight: "bold",
            }}
          >
            <span className="percentage">{`${
              row.result ? row.result.toFixed(2) + "%" : "N/A"
            }`}</span>
          </Typography>
        </>
      ),
      name: "Total Marks",
      sortable: false,
      minWidth: "150px",
    },
  ];
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [assgnId, setAssgnId] = useState();

  const viewAssignmentDetail = async (id) => {
    setAssgnId(id);
    setModalOpen(true);
  };
  return (
    <>
      <div>
        <div className="page-container table-phases assignment_detail">
          <Datatable
            tableColumns={tableColumns}
            dataUrl={resolveUrl(
              AdminApi.GRADE_BOOK_MODULE_PAGINATION,
              params.id
            )}
            actionColumns={false}
            setTableData={setTableData}
            module="manage-courses"
            sortOrder="asc"
          />
        </div>
      </div>
      <GlobalModal open={modalOpen} onClose={handleCloseModal} width={1500}>
        <ViewAssignmentsMarks id={assgnId} />
      </GlobalModal>
    </>
  );
}
