import React from "react";
import { useState, useEffect, useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import "../../static/css/style.css";
import { api, resolveUrl } from "../../config/apis";
import { useParams } from "react-router-dom";
import Icon from "../../static/images/icon.png";

const Card = ({ cardItem, apiEndpoint, params, imageUrl, reloadEnroll }) => {
  const [reportData, setReportData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(resolveUrl(apiEndpoint, params));
      setReportData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [apiEndpoint, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData,reloadEnroll]);
  return (
    <>
      {/* {cardItem.map((card) => ( */}
      <CardItem
        heading={reportData.learner_enrolled}
        paragraph={"Learner Enrolled"}
        imageUrl={Icon}
        // data={reportData}
      />
      {/* ))} */}
    </>
  );
};

const CardItem = ({ heading, paragraph, imageUrl }) => {
  return (
    <Grid
      item
      xs={4}
      className="card_hover mainData"
      sx={{
        flexBasis: "50% !important",
        maxWidth: "auto",
        background: "#fff",
        borderRadius: "10px",
        boxShadow: "0px 24px 44px rgba(37, 10, 43, 0.04)",
        marginBottom: "40px",
        marginRight: "50px",
        padding: "30px",
      }}
    >
      <Grid
        container
        sx={{}}
        className="dashboard-card"
        style={{ borderRadius: "5px" }}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="body1">{paragraph}</Typography>
          <Typography variant="h3" sx={{ fontSize: "36px", fontWeight: 600 }}>
            {heading}
          </Typography>
        </Grid>
        <Grid
          item
          className="card_img"
          xs={12}
          md={6}
          sx={{ justifyContent: "end", display: "flex" }}
        >
          <img
            src={imageUrl}
            alt=""
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Card;
