import styles from "./style.module.css";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import AdminApi from "../../../config/apis/admin";
import { api } from "../../../config/apis";
import {
  DataLoader,
  getStartAndEndOfMonth,
  getStartAndEndOfWeek,
} from "../../../helper/helperFunction";
import FilterButtons from "./FilterButton";

export const Sponsors = (params) => {
  const [filters, setFilters] = useState();
  const [activeButton, setActiveButton] = useState("soFar");

  const [sponsorsData, setSponsorsData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Modify the API call with parameters
        const res = await api.get(AdminApi.DASH_SPONSER_COUNT, {
          params: {
            filters: {
              ...filters,
            },
          },
        });

        setSponsorsData(res.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [filters]);

  const handleCurrentYear = () => {
    setFilters({
      year: new Date().getFullYear(),
    });
    setActiveButton("currentYear");
  };
  const handleCurrentMonth = () => {
    const { startOfMonth, endOfMonth } = getStartAndEndOfMonth();

    setFilters({
      from_date: startOfMonth,
      to_date: endOfMonth,
    });
    setActiveButton("currentMonth");
  };
  const handleThisWeek = () => {
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();

    setFilters({
      from_date: startOfWeek,
      to_date: endOfWeek,
    });
    setActiveButton("thisWeek");
  };
  const handleSoFar = () => {
    setFilters();
    setActiveButton("soFar");
  };

  return (
    <>
      {loading ? (
        <DataLoader />
      ) : (
        <>
          {params.filter && (
            <FilterButtons
              activeButton={activeButton}
              handleSoFar={handleSoFar}
              handleThisWeek={handleThisWeek}
              handleCurrentMonth={handleCurrentMonth}
              handleCurrentYear={handleCurrentYear}
            />
          )}

          <Grid
            container
            marginTop={2}
            className={styles.totalJobsContainer_wrap}
          >
            <Grid item xs={12}>
              <Box
                className={`${styles.totalJobsContainer} ${styles.totalJobsContainer_after}`}
              >
                <Box className={styles.left_box}>
                  <Typography variant="h6">Sponsors</Typography>
                  <Typography variant="h4">
                    {sponsorsData && sponsorsData.sponsors}
                  </Typography>
                </Box>
                <Box className={styles.imageContainer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="35"
                    viewBox="0 0 31 35"
                    fill="none"
                  >
                    <path
                      d="M13.6948 17.8778C18.4824 17.8775 20.205 13.0838 20.656 9.14614C21.2115 4.29537 18.9176 0.644531 13.6948 0.644531C8.47269 0.644531 6.17764 4.29509 6.73358 9.14614C7.18497 13.0838 8.90708 17.8781 13.6948 17.8778Z"
                      fill="white"
                    />
                    <path
                      d="M24.1636 19.8732C24.3177 19.8732 24.4707 19.8778 24.6227 19.8862C24.3949 19.5612 24.1312 19.2746 23.8226 19.0441C22.9026 18.3575 21.7112 18.1322 20.6579 17.7183C20.1452 17.5169 19.6861 17.3169 19.2551 17.0891C17.8004 18.6844 15.9034 19.5189 13.6944 19.5191C11.4862 19.5191 9.58939 18.6846 8.13489 17.0891C7.70389 17.3169 7.24466 17.5169 6.73205 17.7183C5.67877 18.1323 4.48739 18.3575 3.56739 19.0441C1.97639 20.2316 1.56522 22.903 1.24216 24.7252C0.975553 26.2294 0.796442 27.7645 0.744164 29.2924C0.703664 30.4759 1.288 30.6418 2.27811 30.9991C3.51783 31.4462 4.79789 31.7782 6.08655 32.0503C8.57527 32.5758 11.1406 32.9797 13.6949 32.9977C14.9326 32.9889 16.1727 32.8891 17.4056 32.7292C16.4927 31.4066 15.9573 29.8045 15.9573 28.0795C15.9574 23.5544 19.6387 19.8732 24.1636 19.8732Z"
                      fill="white"
                    />
                    <path
                      d="M24.1627 21.5144C20.537 21.5144 17.5977 24.4538 17.5977 28.0795C17.5977 31.7051 20.537 34.6445 24.1627 34.6445C27.7884 34.6445 30.7278 31.7051 30.7278 28.0795C30.7278 24.4537 27.7884 21.5144 24.1627 21.5144ZM27.0349 29.2058H25.2891V30.9516C25.2891 31.5737 24.7848 32.078 24.1628 32.078C23.5407 32.078 23.0363 31.5737 23.0363 30.9516V29.2058H21.2905C20.6684 29.2058 20.1641 28.7015 20.1641 28.0793C20.1641 27.4572 20.6684 26.9529 21.2905 26.9529H23.0363V25.2071C23.0363 24.585 23.5407 24.0807 24.1628 24.0807C24.7849 24.0807 25.2891 24.585 25.2891 25.2071V26.9529H27.0349C27.657 26.9529 28.1614 27.4572 28.1614 28.0793C28.1613 28.7016 27.6571 29.2058 27.0349 29.2058Z"
                      fill="white"
                    />
                  </svg>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
