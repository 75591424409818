import { Typography } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DateView } from "../../../components/DateView/DateView";
import ImageView from "../../../components/imageShow";
import EditAssignment from "./edit";
import ViewAssignmentsDetail from "./viewDetail";

export default function ViewAssignmentsMarks() {
  const params = useParams();
  const [viewId, setViewId] = useState(null);

  const fontStyle = {
    color: "#001C28",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
  };
  const fontStyleName = {
    color: "#001C28",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "1",
  };

  const [viewDetailOpen, setViewDetailOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const handleCloseModal = () => {
    setViewDetailOpen(false);
    setEditOpen(false);
    setViewId(null);
  };
  const getMaxCriteriaCount = (data) => {
    let maxCount = 0;
    data.forEach((row) => {
      if (row.scoring_criteria && row.scoring_criteria.length > maxCount) {
        maxCount = row.scoring_criteria.length;
      }
    });
    return maxCount;
  };

  // Get the maximum number of criteria in your data
  const maxCriteriaCount = getMaxCriteriaCount(tableData);

  // Create an array to hold the columns for scoring criteria
  const scoringCriteriaColumns = [];
  for (let i = 0; i < maxCriteriaCount; i++) {
    scoringCriteriaColumns.push({
      cell: (row) => {
        const criteria = row.scoring_criteria[i];
        return criteria ? criteria.score : "";
      },
      name: tableData[0].scoring_criteria[i]?.title,
      sortable: false,
      width: "200px",
    });
  }

  const isScaledByMax = [];

  const isScaledByMaxExist = tableData[0]?.scaled_by_max !== undefined;
  if (isScaledByMaxExist) {
    isScaledByMax.push({
      selector: (row) =>
        `${row.scaled_by_max && row.scaled_by_max.toFixed(2)}%` ?? "-",
      name: "Scaled by max",
      sortable: false,
    });
  }

  const tableColumns = [
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.name} />
          <Typography variant="body2" component="span" sx={fontStyleName}>
            {toTitleCase(`${row.name}`)}
          </Typography>
        </>
      ),
      name: "Submitted By",
      sortable: false,
      width: "300px",
    },
    ...scoringCriteriaColumns,
    {
      cell: (row) => row.submitted_on && DateView(row.submitted_on),
      selector: "submitted_on",
      name: "Submitted on",
      sortable: true,
      width: "150px",
    },
    {
      selector: (row) => (
        <>
          <Typography
            variant="body2"
            component="span"
            sx={fontStyle}
            style={{
              color:
                row.obtained_marks <= row.totalMarks / 2
                  ? "#C33F28"
                  : row.obtained_marks > row.totalMarks / 2
                  ? "#00CF53"
                  : "#001C28",
            }}
          >
            {row.obtained_marks}
          </Typography>
          /{row.totalMarks}
        </>
      ),
      name: "Marks Obtain",
      sortable: false,
      width: "150px",
    },
    {
      cell: (row) => (
        <>
          <Typography
            variant="body2"
            component="span"
            sx={fontStyle}
            style={{
              color:
                row.percentage <= 50 && row.percentage !== 0
                  ? "#C33F28"
                  : row.percentage > 50
                  ? "#00CF53"
                  : "#001C28",
            }}
          >
            {row.percentage.toFixed(2) ?? "-"}%
          </Typography>
        </>
      ),
      name: "%",
      sortable: false,
      width: "150px",
    },
    ...isScaledByMax,
  ];

  const onView = async (id) => {
    setViewId(id);
    setViewDetailOpen(true);
  };

  const onEdit = async (id) => {
    setViewId(id);
    setEditOpen(true);
  };
  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.COURSES_MODULE_DELETE, id));
  };
  const [reRender, setReRender] = useState(false);

  return (
    <>
      <div>
        <div className="page-container table-phases">
          <Datatable
            tableColumns={tableColumns}
            dataUrl={resolveUrl(
              AdminApi.ASSIGNMENT_MARKS_MODULE_PAGINATION,
              params.id
            )}
            onEdit={onEdit}
            onView={onView}
            onDelete={onDelete}
            actions={{
              view: true,
            }}
            setTableData={setTableData}
            module="manage-assignment"
            refreshHandle={reRender}
          />
        </div>
      </div>

      {viewId && (
        <>
          <EditAssignment
            viewId={viewId}
            setViewId={setViewId}
            setReRender={setReRender}
            handleCloseModal={handleCloseModal}
            editOpen={editOpen}
            setEditOpen={setEditOpen}
          />
          <ViewAssignmentsDetail
            viewId={viewId}
            handleCloseModal={handleCloseModal}
            viewDetailOpen={viewDetailOpen}
            reRender={reRender}
          />
        </>
      )}
    </>
  );
}
