import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText, Select } from "@mui/material";

const SelectOptions = ({
  options = [],
  formData = {},
  onUpdate = () => {},
  name = "",
  labelKey = "",
  valueKey = "",
  errorText = "",
  defaultText = "Select Option",
  disabled=false
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onUpdate(name, value);
  };


  return (
    <>
      <Select
        sx={{ maxWidth: "100%" }}
        select
        required
        id="outlined-required"
        fullWidth
        name={name}
        value={formData[name] || ""}
        onChange={handleChange}
        error={!!errorText}
        helperText={errorText}
        size="small"
        displayEmpty
        disabled={disabled}
        className="select"
      >
        <MenuItem value="" disabled>
          <em className="select">{defaultText}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </>
  );
};

export default SelectOptions;
