import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import Form from "../../../components/forms/Forms";
import styles from "./manage-shift.module.css";
import * as yup from "yup";

const ShiftForm = ({ id, setFormSuccess }) => {
  const initialFormData = {
    title: "",
    shiftdaystime: [
      { days: "", start_time: "", end_time: "" },
      { days: "", start_time: "", end_time: "" },
      { days: "", start_time: "", end_time: "" },
      { days: "", start_time: "", end_time: "" },
      { days: "", start_time: "", end_time: "" },
      { days: "", start_time: "", end_time: "" },
      { days: "", start_time: "", end_time: "" },
    ],
  };

  const [formData, setFormData] = useState(initialFormData);

  const filteredShiftdaystime = formData.shiftdaystime.filter((item) => {
    return Object.keys(item).length !== 0;
  });

  const UpdatedformData = {
    title: formData.title,
    shiftdaystime: filteredShiftdaystime,
  };

  const [selectedDays, setSelectedDays] = useState(Array(7).fill(false));

  const handleDayButtonClick = (index) => {
    const updatedSelectedDays = [...selectedDays];

    updatedSelectedDays[index] = !updatedSelectedDays[index];
    setSelectedDays(updatedSelectedDays);

    setFormData((prevFormData) => {
      const updatedShiftdaystime = [...prevFormData.shiftdaystime];
      updatedShiftdaystime[index] = {
        ...updatedShiftdaystime[index],
        days: updatedSelectedDays[index] ? getDayOfWeek(index) : "",
      };

      return {
        ...prevFormData,
        shiftdaystime: updatedShiftdaystime,
      };
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      shiftdaystime: undefined,
    }));
  };

  const handleInputChange = (fieldName, index, value) => {
    setFormData((prevFormData) => {
      const updatedShiftdaystime = [...prevFormData.shiftdaystime];
      updatedShiftdaystime[index] = {
        ...updatedShiftdaystime[index],
        [fieldName]: value,
      };
      setErrors((prevErrors) => ({
        ...prevErrors,
        shiftdaystime: undefined,
      }));

      return {
        ...prevFormData,
        shiftdaystime: updatedShiftdaystime,
      };
    });
  };

  const handleFormSuccess = async (response) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
    }
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const GetDayOfWeek = daysOfWeek.map((weekday, index) => weekday);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.SHIFTS_VIEW, id));

        const selectedDays = res.data.shift.shift_datetimes.map(
          (datetime) => datetime.days !== ""
        );

        setSelectedDays(selectedDays);

        const updatedShiftdaystime = GetDayOfWeek.map((weekday, index) => {
          const shiftDatetime = res.data.shift.shift_datetimes.find(
            (datetime) => datetime.days === weekday
          );

          return {
            days: shiftDatetime ? shiftDatetime.days : "",
            start_time: shiftDatetime ? shiftDatetime.start_time : "",
            end_time: shiftDatetime ? shiftDatetime.end_time : "",
          };
        });

        setFormData({
          title: res.data.shift.title,
          shiftdaystime: updatedShiftdaystime,
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const getDayOfWeek = (index) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[index];
  };

  const renderDayButton = (index, label) => {
    return (
      <Button
        key={index}
        className={
          formData.shiftdaystime[index]?.days || "" ? styles.active : ""
        }
        onClick={() => handleDayButtonClick(index)}
      >
        {label}
      </Button>
    );
  };

  const renderSelectOptions = () => {
    const timeOptions = [
      "12:00 AM",
      "12:30 AM",
      "1:00 AM",
      "1:30 AM",
      "2:00 AM",
      "2:30 AM",
      "3:00 AM",
      "3:30 AM",
      "4:00 AM",
      "4:30 AM",
      "5:00 AM",
      "5:30 AM",
      "6:00 AM",
      "6:30 AM",
      "7:00 AM",
      "7:30 AM",
      "8:00 AM",
      "8:30 AM",
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
      "5:30 PM",
      "6:00 PM",
      "6:30 PM",
      "7:00 PM",
      "7:30 PM",
      "8:00 PM",
      "8:30 PM",
      "9:00 PM",
      "9:30 PM",
      "10:00 PM",
      "10:30 PM",
      "11:00 PM",
      "11:30 PM",
    ];

    return [
      <MenuItem key="none" value="">
        <em>Select time</em>
      </MenuItem>,
      ...timeOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      )),
    ];
  };

  const [errors, setErrors] = useState({});

  const renderDayRow = (index) => {
    const day = getDayOfWeek(index);

    return (
      <>
        <Grid
          container
          spacing={2}
          key={index}
          sx={{ mb: 3 }}
          className={styles.days_btn}
        >
          <Grid item sm={4}>
            {renderDayButton(index, day)}
          </Grid>
          <Grid item sm={3.5}>
            <Select
              className={styles.select_time}
              fullWidth
              value={formData.shiftdaystime[index]?.start_time || ""}
              disabled={!formData.shiftdaystime[index]?.days}
              displayEmpty
              onChange={(e) =>
                handleInputChange("start_time", index, e.target.value)
              }
            >
              {renderSelectOptions()}
            </Select>
          </Grid>
          <Grid item sm={1} textAlign={"center"}>
            to
          </Grid>

          <Grid item sm={3.5}>
            <Select
              fullWidth
              displayEmpty
              className={styles.select_time}
              value={formData.shiftdaystime[index]?.end_time || ""}
              disabled={!formData.shiftdaystime[index]?.days}
              onChange={(e) =>
                handleInputChange("end_time", index, e.target.value)
              }
            >
              {renderSelectOptions()}
            </Select>
          </Grid>
        </Grid>
      </>
    );
  };

  const customValidation = () => {
    const isTitleValid = formData.title.trim() !== "";

    const isShiftDataValid = formData.shiftdaystime.some(
      (shift) =>
        shift.days !== "" && shift.start_time !== "" && shift.end_time !== ""
    );

    const isShiftDataValidOther = formData.shiftdaystime.every(
      (shift) =>
        shift.days === "" ||
        (shift.days !== "" && shift.start_time !== "" && shift.end_time !== "")
    );

    setErrors((prevErrors) => ({
      ...prevErrors,
      title: isTitleValid ? undefined : "Title is required",
      shiftdaystime: isShiftDataValid
        ? undefined
        : "At least one shift day with start and end times is required",
      isShiftDataValidOther: isShiftDataValidOther
        ? undefined
        : "Start and end times is required",
    }));

    return isTitleValid && isShiftDataValid && isShiftDataValidOther;
  };

  return (
    <Box>
      <Box sx={{ width: "100%" }} className={styles.shift_module}>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.SHIFTS_ADD}
              formData={UpdatedformData}
              editId={id}
              onSuccess={handleFormSuccess}
              successPath="/admin/setting"
              validate={customValidation}
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={12}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Shift Title*
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <TextField
                    fullWidth
                    value={formData.title}
                    name="title"
                    variant="outlined"
                    onChange={(event) => {
                      setFormData({ ...formData, title: event.target.value });
                      // Clear the error message for the field being edited
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        title: undefined,
                      }));
                    }}
                    error={errors.title !== undefined}
                    helperText={errors.title}
                  />
                </Grid>
                <Grid item xs={12} container>
                  <Grid item sm={4}>
                    <InputLabel sx={{ textAlign: "left" }}>Days</InputLabel>
                  </Grid>
                  <Grid item sm={8}>
                    <InputLabel sx={{ textAlign: "left" }}>Timings </InputLabel>
                  </Grid>
                </Grid>

                {[1, 2, 3, 4, 5, 6, 0].map((index) => renderDayRow(index))}
                {errors.shiftdaystime && (
                  <FormHelperText error>{errors.shiftdaystime}</FormHelperText>
                )}
                {errors.isShiftDataValidOther && (
                  <FormHelperText error>
                    {errors.isShiftDataValidOther}
                  </FormHelperText>
                )}
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ShiftForm;
