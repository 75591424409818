import React, { useEffect } from 'react';
import LoginForms from "../../pages/auth/LoginForm";
import '../../static/css/style.css';
import Footer from "../frontend/partials/Footer/Footer";

const Login = () =>{
    useEffect(() => {
        // Add class to body element when the component mounts
        document.body.classList.add('for-bg');
    
        // Remove class from body element when the component unmounts
        return () => {
          document.body.classList.remove('for-bg');
        };
      }, []);
    return(
        <>
            <div className='container'>
                <LoginForms/>
            </div>
            <Footer />
        </>
    )

}
export default Login;
