import React from "react";
import { Box, Modal } from "@mui/material";
import styles from "./manage-cohorts.module.css";
import DiscountForm from "./edit";

const DiscountFormModal = ({ open, id, closeModal, setFormSuccess }) => {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box>
        <Box className={styles.popup_modal}>
          <button onClick={closeModal} className={styles.close_btn}>
            Close
          </button>
          <DiscountForm id={id} setFormSuccess={setFormSuccess} />
        </Box>
      </Box>
    </Modal>
  );
};

export default DiscountFormModal;
