import { useState, useEffect } from "react";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import StudentApi from "../../../config/apis/student";
import {
  InputLabel,
  TextField,
  Typography,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import { Box, Grid } from "@mui/material";
import Form from "../../../components/forms/Forms";
import SelectOptions from "../../../components/Select";
import ImageUpload from "../../../components/UploadImage";
import SelectOptionsApi from "../../../components/SelectApi";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import validationSchema from "./validation";
import styles from "./manage-user-student.module.css";
import useForm from "../../../hooks/useForm/useForm";
import moment from "moment";
import { GetUserId, formatDateYYMMDD } from "../../../helper/helperFunction";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

export default function EditStudentProfile() {
  const initialState = {
    first_name: "",
    last_name: "",
    father_name: "",
    cnic: null,
    emergency_number: "",
    registration_number: null,
    email: "",
    phone: "",
    gender: "",
    street_address: "",
    country_id: "",
    state_id: "",
    city_id: "",
    zip_code: "",
    is_studying: "",
    registration_date: "",
    profile_image: "",
    qualification_id: "",
    qualification_field_id: "",
    date_of_birth: "",
    document: null,
  };
  const {
    formData,
    setFormData,
    errors,
    handleImageChange,
    handleInputChange,
    handleUpdate,
    customValidation,
    setErrors,
  } = useForm(initialState, validationSchema);

  const [uploadPreviewProp, setUploadPreviewProp] = useState(null);

  const genderOptions = [
    { id: "Male", options: "Male" },
    { id: "Female", options: "Female" },
    { id: "Other", options: "Other" },
  ];

  const is_studying = [
    { id: "1", option: "Yes" },
    { id: "0", option: "No" },
  ];

  const studentId = GetUserId();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    setFormData({
      ...formData,
      document: file,
    });
    const blob = new Blob([file], { type: file.type });
    const blobUrl = URL.createObjectURL(blob);
    setSelectedFile({
      url: blobUrl,
      name: fileName,
    });
  };
  const handleDeleteFile = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
    setFormData({
      ...formData,
      document: null,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (studentId) {
          const res = await api.get(
            resolveUrl(AdminApi.USER_MODULE_STUDENTS_VIEW, studentId)
          );
          if (res.status === 200) {
            setFormData({
              registration_number: res.data.data.registration_number || "",
              first_name: res.data.data.first_name || "",
              last_name: res.data.data.last_name || "",
              emergency_number: res.data.data.emergency_number || "",
              father_name: res.data.data.father_name || "",
              cnic: res.data.data.cnic || "",
              email: res.data.data.email || "",
              phone: res.data.data.phone || "",
              gender: res.data.data.gender || "",
              date_of_birth: res.data.data.date_of_birth
                ? moment(res.data.data.date_of_birth).format("YYYY-MM-DD")
                : "",

              street_address: res.data.data.street_address || "",
              country_id: res.data.data.country_id || "",
              state_id: res.data.data.state_id || "",
              city_id: res.data.data.city_id || "",
              zip_code: res.data.data.zip_code || "",
              is_studying: res.data.data.is_studying || "0",

              qualification_id: res.data.data.qualification_id || "",
              qualification_field_id:
                res.data.data.qualification_field_id || "",
              registration_fee: res.data.data.registration_fee || "",
            });
            const fileName =
              res.data.data.document &&
              res.data.data.document.substring(
                res.data.data.document.lastIndexOf("/") + 1
              );

            setSelectedFile({
              url: res.data.data.document || "",
              name: fileName,
            });

            setUploadPreviewProp(res.data.data.profile_image || "");
          } else {
            // console.error("Error: Unexpected status code", res.status);
          }
        }
      } catch (error) {
        // console.error("Error:", error);
      }
    };

    fetchData();
  }, [studentId]);

  return (
    <>
      <div className="container global-user">
        <Typography variant="h1" className="main-heading">
          Edit Profile
        </Typography>
        <Grid container className={`page-container ${styles.addStudent}`}>
          <Grid item xs={12} className="inner-container">
            <Form
              successPath={`/admin/profile-update/`}
              submitUrl={StudentApi.STUDENT_PROFILE_UPDATE}
              formData={formData}
              editId={studentId}
              validate={customValidation}
              multipart={true}
              cancleShow={true}
            >
              <h2 className="content-title">Personal Info</h2>
              <Grid container justifyContent={"space-between"}>
                <Grid item lg={9} order={{ lg: 1, xs: 2 }}>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Registration No
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="registration_number"
                        variant="outlined"
                        value={formData.registration_number}
                        onChange={handleInputChange}
                        error={errors.registration_number !== undefined}
                        helperText={errors.registration_number}
                        sx={{ backgroundColor: "#F4F4F4" }}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        First Name
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="first_name"
                        variant="outlined"
                        placeholder="Enter First Name"
                        value={formData.first_name}
                        // onChange={handleInputChange}
                        error={errors.first_name !== undefined}
                        helperText={errors.first_name}
                        sx={{ backgroundColor: "#F4F4F4" }}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Last Name
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="last_name"
                        variant="outlined"
                        placeholder="Enter Last Name"
                        value={formData.last_name}
                        // onChange={handleInputChange}
                        error={errors.last_name !== undefined}
                        helperText={errors.last_name}
                        sx={{ backgroundColor: "#F4F4F4" }}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Father/Husband Name
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="father_name"
                        variant="outlined"
                        placeholder="Enter Father / Husband Name"
                        value={formData.father_name}
                        onChange={handleInputChange}
                        error={errors.father_name !== undefined}
                        helperText={errors.father_name}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>CNIC</InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="cnic"
                        variant="outlined"
                        placeholder="Enter CNIC"
                        value={formData.cnic}
                        // onChange={handleInputChange}
                        error={errors.cnic !== undefined}
                        helperText={errors.cnic}
                        sx={{ backgroundColor: "#F4F4F4" }}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel
                        sx={{
                          textAlign: "left",
                          whiteSpace: "normal",
                          lineHeight: "18px",
                        }}
                      >
                        Emergency Number
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="emergency_number"
                        variant="outlined"
                        placeholder="Enter Emergency Number"
                        value={formData.emergency_number}
                        onChange={handleInputChange}
                        error={errors.emergency_number !== undefined}
                        helperText={errors.emergency_number}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center " }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>Email*</InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="email"
                        variant="outlined"
                        placeholder="Enter Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={errors.email !== undefined}
                        helperText={errors.email}
                        sx={{ backgroundColor: "#F4F4F4" }}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Whatsapp
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="phone"
                        variant="outlined"
                        placeholder="Enter Phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        error={errors.phone !== undefined}
                        helperText={errors.phone}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "baseline", pr: 3 }}
                  >
                    <Grid item xl={2} sm={3.1}>
                      <InputLabel> Date of Birth </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <DatePicker
                        calendarAriaLabel="Toggle calendar"
                        clearAriaLabel="Clear value"
                        dayAriaLabel="Day"
                        monthAriaLabel="Month"
                        nativeInputAriaLabel="Date"
                        selected={
                          formData.date_of_birth
                            ? new Date(formData.date_of_birth)
                            : null
                        }
                        value={
                          formData.date_of_birth ? formData.date_of_birth : ""
                        }
                        onChange={(date) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            date_of_birth: date ? formatDateYYMMDD(date) : null,
                          }));

                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            date_of_birth: undefined,
                          }));
                        }}
                        yearAriaLabel="Year"
                      />

                      {errors.date_of_birth && (
                        <FormHelperText error>
                          {errors.date_of_birth}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xl={1} sm={2} ml={2}>
                      <InputLabel> Gender </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <SelectOptions
                        options={genderOptions}
                        formData={formData}
                        onUpdate={handleUpdate}
                        name="gender"
                        labelKey="options"
                        valueKey="id"
                        errorText={errors.gender}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} sm={3}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Street Address
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6} sm={8}>
                      <TextField
                        fullWidth
                        name="street_address"
                        variant="outlined"
                        placeholder="Street Address"
                        value={formData.street_address}
                        onChange={handleInputChange}
                        error={errors.street_address !== undefined}
                        helperText={errors.street_address}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center", pr: 3 }}
                  >
                    <Grid item xl={2} sm={3.1}>
                      <InputLabel> Country </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.COUNTRIES}
                        formData={formData}
                        // onUpdate={handleUpdate}
                        labelKey="name"
                        name="country_id"
                        valueKey="id"
                        defaultText="Select Option"
                        errorText={errors.country_id}
                        disabled={true}
                        customStyle={{ backgroundColor: "#F4F4F4" }}
                      />
                    </Grid>
                    <Grid item xl={1} sm={2} ml={2}>
                      <InputLabel> State </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.STATES}
                        paramId={formData.country_id ?? ""}
                        formData={formData}
                        // onUpdate={handleUpdate}
                        labelKey="name"
                        name="state_id"
                        valueKey="id"
                        errorText={errors.state_id}
                        // disabled={!formData.country_id}
                        disabled={true}
                        customStyle={{ backgroundColor: "#F4F4F4" }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ alignItems: "center", pr: 3 }}
                  >
                    <Grid item xl={2} sm={3.1}>
                      <InputLabel> City </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.CITIES}
                        paramId={formData.state_id ?? ""}
                        formData={formData}
                        // onUpdate={handleUpdate}
                        labelKey="name"
                        name="city_id"
                        valueKey="id"
                        errorText={errors.city_id}
                        // disabled={!formData.state_id}
                        disabled={true}
                        customStyle={{ backgroundColor: "#F4F4F4" }}
                      />
                    </Grid>
                    <Grid item xl={1} sm={2} ml={2}>
                      <InputLabel> Zip </InputLabel>
                    </Grid>
                    <Grid item xl={2.5} sm={3}>
                      <TextField
                        fullWidth
                        name="zip_code"
                        variant="outlined"
                        placeholder="zip code"
                        value={formData.zip_code}
                        onChange={handleInputChange}
                        error={errors.zip_code !== undefined}
                        helperText={errors.zip_code}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 3, mb: 6 }}>
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xl={2} sm={3}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Currently studying?
                        </InputLabel>
                      </Grid>
                      <Grid item xl={6} sm={8}>
                        <SelectOptions
                          options={is_studying}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="option"
                          valueKey="id"
                          name="is_studying"
                          errorText={errors.is_studying}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center", pr: 3 }}
                    >
                      <Grid item xl={2} sm={3.1}>
                        <InputLabel> Education level </InputLabel>
                      </Grid>
                      <Grid item xl={2.5} sm={3}>
                        <SelectOptionsApi
                          apiEndpoint={AdminApi.EDUCATION_LEVEL}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="name"
                          name="qualification_id"
                          valueKey="id"
                          errorText={errors.qualification_id}
                        />
                      </Grid>
                      <Grid item xl={1} sm={2} ml={2}>
                        <InputLabel> Group </InputLabel>
                      </Grid>
                      <Grid item xl={2.5} sm={3}>
                        <SelectOptionsApi
                          apiEndpoint={AdminApi.EDUCATION_GROUP}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="name"
                          name="qualification_field_id"
                          valueKey="id"
                          errorText={errors.qualification_field_id}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container sx={{ alignItems: "center" }}>
                      <Grid item xl={2} sm={3}>
                        <InputLabel
                          sx={{
                            textAlign: "left",
                            whiteSpace: "normal",
                            lineHeight: "18px",
                          }}
                        >
                          Upload Document
                        </InputLabel>
                      </Grid>
                      <Grid item xl={6} sm={8} container alignItems={"center"}>
                        <div className="upload_doc">
                          {formData.document && (
                            <span className="file_path">
                              {formData.document.name}
                            </span>
                          )}
                          <button className="upload_btn">
                            <input
                              type="file"
                              id="fileInput"
                              accept=".pdf"
                              onChange={handleFileChange}
                            />
                            Browse
                          </button>
                        </div>
                        {selectedFile && selectedFile.name && (
                          <div className="upload_file_preview">
                            <InsertDriveFileIcon />
                            <a
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                              }}
                              href={`${SERVER_KEY}${selectedFile.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {selectedFile && (
                                <>
                                  <span>{selectedFile.name}</span>
                                </>
                              )}
                            </a>
                            <span onClick={handleDeleteFile}>
                              <HighlightOffRoundedIcon />
                            </span>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid
                  item
                  lg={3}
                  marginBottom={5}
                  order={{ lg: 2, xs: 1 }}
                  className="circle upload-image"
                  container
                  justifyContent={{ lg: "flex-end", xs: "flex-start" }}
                >
                  <ImageUpload
                    name="profile_image"
                    label="Upload Image"
                    previewWidth={200}
                    maxFileSize={5242880}
                    onChange={handleImageChange}
                    uploadPreviewProp={uploadPreviewProp}
                    isRequired={false}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
