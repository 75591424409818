import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../auth/auth';

const ProtectRoutes = ({ permission, ...props }) => {
  const { state } = useAuth();
  return state ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectRoutes;
