import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Alert, IconButton, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LoginImage from "../../static/images/consulnet-logo.png";
import { resolveUrl, AuthApi, api } from "../../config/apis";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth/auth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const { dispatch } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      email: data.get("email"),
      password: data.get("password"),
    };

    if (!payload.email || !payload.password) {
      setIsError(true);
      setErrorMsg(["Please fill in all the fields."]);
      return;
    }

    try {
      const response = await api.post(resolveUrl(AuthApi.LOGIN), payload);
      if (response.data) {
        localStorage.setItem("auth", JSON.stringify(response.data));
        setIsError(false);
        dispatch({ type: "LOGIN", payload: response.data });
        navigate("/admin");
        window.location.reload();
      }
    } catch (error) {
      setIsError(true);

      let message = "Wrong Email";
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          message = error.response.data.message;
        }
      }

      setErrorMsg([message]); // Store the error message as an array

      // Perform additional actions based on the error, such as displaying a notification or logging the error
      console.error("Error occurred while logging in:", error);
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Container
        id="Login"
        component="main"
        maxWidth="sm"
        sx={{
          marginTop: 34.2,
          marginBottom: 34.2,
        }}
      >
        <Box
          sx={{
            boxShadow: 3,
            backgroundColor: "white",
            borderRadius: 2,
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            alt="The house from the offer."
            src={LoginImage}
          />
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {isError && (
              <Alert severity="error">
                <ul style={{ padding: "0", margin: "0" }}>
                  {errorMsg.map((value, index) => {
                    return <li key={index}>{value}</li>;
                  })}
                </ul>
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            /> */}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"} // Toggle between text and password
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#C33F28",
                color: "white",
                fontWeight: 700,
              }}
            >
              Login
            </Button>
            <Grid container>
              <Grid
                item
                xs
                sx={{
                  textAlign: "center",
                }}
              >
                <Link
                  href="/forgotpassword"
                  variant="body2"
                  sx={{
                    color: "#C33F28",
                    fontSize: 16,
                    fontWeight: 500,
                    textDecoration: "none",
                  }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
