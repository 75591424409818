import { useState, useEffect } from "react";
import { api, resolveUrl } from "../../config/apis";

const useApiData = (apiEndpoint, paramId) => {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading state as true
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true before fetching data
        if (apiEndpoint) {
          const res = await api.get(resolveUrl(apiEndpoint, paramId || ""));
          setOptions(res.data.data);
          setError(null);
        } else {
          setError("API endpoint not provided.");
          setOptions([]);
        }
      } catch (error) {
        setOptions([]);
        setError("Error fetching options.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiEndpoint, paramId]);

  return { options, error, loading };
};

export default useApiData;
