import { Grid, Typography } from "@mui/material";
import styles from "./style.module.css";
import AttendenceChart from "./summrayChart";
import React, { useState, useEffect } from "react";
import StudentApi from "../../../config/apis/student";
import { api } from "../../../config/apis";

export default function AttendenceSummary() {
  const [chartData, setChartData] = useState();
  const [percentage, setPercentage] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(StudentApi.GET_STUDENT_CHART_DATA);
        setChartData(res.data.data);
        setIsDataLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPercentData = async () => {
      try {
        const per = await api.get(StudentApi.GET_STUDENT_PERCENTAGE);
        setPercentage(per.data.data);
        setIsDataLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPercentData();
    fetchData();
  }, []);

  return (
    <>
      <Grid container spacing={2} className={styles.attendance_wrap}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Typography
            variant="body1"
            className={styles.attemdence_summray_title}
          >
            Attendance <span>( sum of class attendance ) </span>
          </Typography>
          <Typography
            variant="body1"
            className={styles.attemdence_summray_percent}
            style={
              percentage && percentage.attendancePercentage < 37
                ? { color: "#D3371C" }
                : {}
            }
          >
            {percentage && percentage.attendancePercentage.toFixed(2) + "%"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AttendenceChart chartData={chartData} isDataLoaded={isDataLoaded} />
        </Grid>
      </Grid>
    </>
  );
}
