
import React from 'react';

const FooterText = ({ text }) => {
  return (
    <div className={`dashboard-footer footer_text`}>
    {/* // <div className='footer_text'> */}
      <p>{text}</p>
    </div>
  );
};

export default FooterText;

