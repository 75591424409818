import "../../../static/css/style.css";
import { Grid } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { DasboardActiveCards } from "../dashboard-active-card";
import { Enrollments } from "../dashboard-cards/Enrollments";
import { RegistrationFees } from "../dashboard-cards/RegistrationFees";
import { SponsorshipCard } from "../dashboard-cards/Sponsorship";
import { Sponsors } from "../dashboard-cards/Sponsors";
import SelectOptionsApi from "../../../components/SelectApi";
import AdminApi from "../../../config/apis/admin";
import styles from "./style.module.css";
import React, { useEffect, useState } from "react";
import StudentChart from "./dash-active-student/student-chart";
import Notifications from "./dash-notifications";
import ViewTodaysClasses from "./Todays-classes";
import TopLocations from "./Top-Locations";
import { DataLoader } from "../../../helper/helperFunction";
import { SelfPaid } from "../dashboard-cards/SelfPaid";

const Dashboard = () => {
  const [filterData, setFilterData] = useState({});

  const handleUpdate = (name, value) => {
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Box className="container">
        <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={2}>
            <Typography variant="h2" className={styles.main_heading}>
              Dashboard
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <Box className={styles.filter_wrap}>
              <Typography variant="body2">Phase</Typography>
              <Box className={styles.select_box}>
                <SelectOptionsApi
                  apiEndpoint={AdminApi.PHASES_VIEW}
                  formData={filterData}
                  onUpdate={handleUpdate}
                  labelKey="title"
                  name="phase_id"
                  valueKey="id"
                  errorText=""
                  paramId={""}
                  defaultText="Select Phase"
                  disabled={filterData.cohort_id}
                />
              </Box>
              <Typography variant="body2" className={styles.Cohort_label}>
                Select Cohort
              </Typography>
              <Box className={styles.select_box}>
                <SelectOptionsApi
                  apiEndpoint={AdminApi.COHORTS}
                  formData={filterData}
                  onUpdate={handleUpdate}
                  labelKey="title"
                  name="cohort_id"
                  valueKey="id"
                  errorText=""
                  paramId={""}
                  defaultText="Select Cohort"
                  disabled={filterData.phase_id}
                />
              </Box>
            </Box>
            <Typography variant="body1" className={styles.Numbers_applies}>
              ** Numbers applies to the chosen phase/cohort
            </Typography>
          </Grid>
        </Grid>

        {loading && <DataLoader />}
        <div style={loading ? { display: "none" } : {}}>
          <DasboardActiveCards filterData={filterData} />
          <Grid container spacing={{ xl: 4, lg: 3 }}>
            <Grid item xl={4} lg={6} md={12} sm={12}>
              <Enrollments filter={true} filterData={filterData} />
            </Grid>
            <Grid item xl={4} lg={6} md={12} sm={12}>
              <RegistrationFees filter={true} filterData={filterData} />
            </Grid>
            <Grid item xl={4} lg={6} md={12} sm={12}>
              <SelfPaid filter={true} filterData={filterData} />
            </Grid>
            <Grid item xl={7} lg={12} md={12} sm={12}>
              <SponsorshipCard filter={true} />
            </Grid>
            <Grid item xl={5} lg={12} md={12} sm={12}>
              <Sponsors filter={true} />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12}>
              <StudentChart filterData={filterData} />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} display={"flex"}>
              <Notifications />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} display={"flex"}>
              <ViewTodaysClasses />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} display={"flex"}>
              <TopLocations />
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default Dashboard;
