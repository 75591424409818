import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";
import styles from "./styles.module.css";

const DiscountForm = ({ id, setFormSuccess }) => {
  const [formData, setFormData] = useState({
    name: null,
    symbol: "",
    value: null,
  });

  const [errors, setErrors] = useState({});

  const handleOnChange = (event) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });
  };
  const handleValueChange = (event) => {
    const valueTarget = +event.target.value; // Using unary plus operator
    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });
  };
  const schema = yup.object().shape({
    name: yup.string().required("This field is required"),
    symbol: yup.string().required("This field is required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      // Additional validation for uploadPreview images
      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleFormSuccess = async (response) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.CURRENCY_EDIT, id));
        setFormData({
          name: res.data.data.name || "",
          symbol: res.data.data.symbol || "",
          value: res.data.data.value || "",
        });
      } catch (error) {
        // Error handling
      }
    };

    // const fetchCountry = async () => {
    //   try {
    //     const res = await api.get(resolveUrl(AdminApi.COUNTRIES));
    //     setCountry(res.data.data);
    //   } catch (error) {}
    // };
    fetchData();
    // fetchCountry();
  }, [id]);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.CURRENCY_ADD}
              formData={formData}
              editId={id}
              onSuccess={handleFormSuccess}
              validate={customValidation}
              successPath="/admin/setting"
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={12}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Name Currency *
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter name"
                    value={formData.name}
                    name="name"
                    variant="outlined"
                    onChange={handleOnChange}
                    helperText={errors.name}
                  />
                </Grid>

                <Grid item xs={12} mt={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Currency</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter name"
                    value={formData.symbol}
                    name="symbol"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.symbol !== undefined}
                    helperText={errors.symbol}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={1.5}>
                  <InputLabel sx={{ textAlign: "left" }}>1 USD =</InputLabel>
                </Grid>
                <Grid item xs={10.5} className={styles.symbol_wrap}>
                  <TextField
                    fullWidth
                    placeholder="Enter Value"
                    value={formData.value}
                    name="value"
                    variant="outlined"
                    type="number"
                    onChange={handleValueChange}
                    error={errors.value !== undefined}
                    helperText={errors.value}
                  />
                  {/* <span className={styles.symbol}>PKR</span> */}
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DiscountForm;
