import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useParams } from "react-router-dom";
import { DateView } from "../../../components/DateView/DateView";
import { Box } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./manage-section.module.css";
import ImageView from "../../../components/imageShow";
import { toTitleCase, limitStringLength } from "../../../helper/helperFunction";
import Tooltip from "@mui/material/Tooltip";
import { HasPermission } from "../../../helper/helperFunction";

// SECTION MODULE
export default function ViewSections(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [viewId, setViewId] = useState("");

  const tableColumns = [
    {
      selector: (row) => limitStringLength(row.name, 20),
      name: "Title",
      sortable: true,
      minWidth: "180px",
    },
    {
      cell: (row) => (
        <>
          <ImageView
            imageName={row.profile_image ?? ""}
            name={row.teacher ?? ""}
          />
          {toTitleCase(`${row.teacher}`)}
        </>
      ),

      name: "Teacher Assign",
      sortable: true,
      minWidth: "250px",
      selector: "teacher",
    },
    {
      selector: (row) => (
        <>
          <div className="shift">
            <p>
              <Tooltip
                title={
                  <div className="shift">
                    <table>
                      <tbody>
                        {row.shift.map((item, index) => (
                          <tr key={index}>
                            <th>{item.days}</th>
                            <td>{item.start_time}</td>
                            <td>to</td>
                            <td>{item.end_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }
              >
                {row.time_slot}
              </Tooltip>
            </p>
          </div>
        </>
      ),
      name: "Time Slot",
      sortable: false,
      minWidth: "120px",
    },
    {
      cell: (row) => limitStringLength(row.cohort, 25),
      name: "Cohort",
      sortable: true,
      minWidth: "250px",
    },
    {
      selector: (row) => row.location,
      name: "Location",
      sortable: true,
      minWidth: "150px",
    },
    {
      cell: (row) => <DateView date={row.start_date} />,

      name: "Started on",
      sortable: true,
      minWidth: "120px",
      selector: "start_date",
    },
    {
      cell: (row) => <DateView date={row.end_date} />,
      name: "Ended on",
      sortable: true,
      minWidth: "120px",
      selector: "end_date",
    },
  ];

  const onEdit = async (id) => {
    setViewId(id);
    navigate(`/admin/section/edit/${id}`);
  };

  const onView = async (id) => {
    navigate(`/admin/section/view/${id}`);
  };
  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.SECTION_MODULE_DELETE, id));
  };

  return (
    <>
      <Box
        className="page-container table-phases"
        sx={{ paddingLeft: "7px", paddingRight: "7px" }}
      >
        <Datatable
          customClass={"section-table"}
          tableColumns={tableColumns}
          dataUrl={resolveUrl(AdminApi.SECTION_MODULE_PAGINATION, params.id)}
          id={viewId}
          onEdit={onEdit}
          onDelete={onDelete}
          onView={onView}
          actions={{
            view: true,
          }}
          // filters={filters}
          module="manage-sections"
        />
      </Box>
      <HasPermission permission="manage-sections.create">
        <Box className={styles.section_add}>
          <Box className="btn-wrapper">
            <Link to={`/admin/section/edit/?id=${params.id}`}>Add Section</Link>
          </Box>
        </Box>
      </HasPermission>
    </>
  );
}
