import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { api } from "../../../config/apis";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import Container from "@mui/material/Container";
import AdminApi from "../../../config/apis/admin";
import { InputLabel } from "@mui/material";
import { useAuth } from "../../../hooks/auth/auth";
import Alert from "@mui/material/Alert";

const ChangedPassword = () => {
  const authen = useAuth();
  const authenUserId = authen.state.user.id;

  const authenUser = useAuth();
  const authenUserName = authenUser.state.user.username;

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);

  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [newPassword, setNewPassword] = useState(null);

  const [passwordStrength, setPasswordStrength] = useState({
    score: 0,
    feedback: "",
  });

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const oldPassword = data.get("current_pass");
    const newPassword = data.get("new_pass");
    const confirmPassword = data.get("confirm_pass");

    const errors = [];

    if (!oldPassword || !newPassword || !confirmPassword) {
      errors.push("Please fill in all the fields.");
    }

    if (newPassword !== confirmPassword) {
      errors.push("Passwords do not match.");
    }

    if (errors.length > 0) {
      setIsError(true);
      setErrorMsg(errors);
      return;
    }

    const payload = {
      current_pass: oldPassword,
      new_pass: newPassword,
      confirm_pass: confirmPassword,
    };

    api
      .put(`${AdminApi.CHANGE_PASSWORD}/${authenUserId}`, payload)
      .then((response) => {
        if (response.data) {
          setIsError(false);
          setIsSuccess(true);
          setSuccessMsg(response.data.message);
          // Clear the password fields after a successful update
          data.set("current_pass", "");
          data.set("new_pass", "");
          data.set("confirm_pass", "");

          // Reset the password strength feedback
          setPasswordStrength({ score: 0, feedback: "" });

          // Update the form fields with the cleared values
          const newPasswordField = document.getElementById("new_password");
          newPasswordField.value = "";
          const ConfirmPasswordField =
            document.getElementById("confirm_password");
          ConfirmPasswordField.value = "";
          const OldPasswordField = document.getElementById("old_password");
          OldPasswordField.value = "";
          navigate("/admin/setting");
        }
      })
      .catch((err) => {
        setIsError(true);

        let message = ["Server Error"];
        if (err.response) {
          if (err.response.status === 400) {
            message = [err.response.data.error]; // Wrap the error message in an array
          } else {
            message = [err.response.data.message]; // Wrap the message in an array
          }
        }
        setErrorMsg(message);
      });
  };

  useEffect(() => {
    const calculatePasswordStrength = (password) => {
      let score = 0;
      let feedback = "";

      if (password.length >= 8) {
        score += 1;
      }
      if (/[A-Z]+/.test(password)) {
        score += 1;
      }
      if (/[a-z]+/.test(password)) {
        score += 1;
      }
      if (/[0-9]+/.test(password)) {
        score += 1;
      }
      if (/[!@#$%^&*()_+[\]{};':"\\|,.<>?]+/.test(password)) {
        score += 1;
      }

      if (score === 0) {
        feedback = "Weak";
      } else if (score < 3) {
        feedback = "Moderate";
      } else {
        feedback = "Strong";
      }

      setPasswordStrength({ score, feedback });
    };

    const newPasswordField = document.getElementById("new_password");

    const inputHandler = () => {
      const newPassword = newPasswordField.value;
      calculatePasswordStrength(newPassword);
    };

    newPasswordField.addEventListener("input", inputHandler);

    // Clean up the event listener when the component unmounts
    return () => {
      newPasswordField.removeEventListener("input", inputHandler);
    };
  }, []);

  return (
    <>
      <Container
        component="main"
        className="changepassword_mm"
        maxWidth="sm"
        sx={{
          width: "100% !important",
          maxWidth: "100% !important",
        }}
      >
        <Box>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 24,
            }}
          >
            Change Password
          </Typography>
          <Typography
            component="p"
            variant="p"
            sx={{
              fontWeight: 700,
              fontSize: 24,
            }}
          >
            Type and confirm a secure new password for the account{" "}
            <span>"{authenUserName}"</span>
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {isError && (
              <Alert severity="error">
                <ul style={{ padding: "0", margin: "0" }}>
                  {errorMsg.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}

            {isSuccess && <Alert severity="success">{successMsg}</Alert>}
            <Box>
              <InputLabel>Old Password</InputLabel>
              <TextField
                fullWidth={true}
                margin="normal"
                required
                id="old_password"
                name="current_pass"
                type="password"
              />
            </Box>
            <Box>
              <InputLabel>New Password</InputLabel>
              <TextField
                fullWidth={true}
                margin="normal"
                required
                id="new_password"
                name="new_pass"
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
              />

              {newPassword && (
                <div style={{ marginLeft: "10px" }}>
                  Password Strength: {passwordStrength.feedback}
                </div>
              )}
            </Box>
            <Box>
              <InputLabel>Confirm New Password</InputLabel>
              <TextField
                fullWidth={true}
                required
                margin="normal"
                id="confirm_password"
                name="confirm_pass"
                type="password"
              />
            </Box>
            <Box>
              <Button
                type="submit"
                className="btn-red"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#C33F28",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ChangedPassword;
