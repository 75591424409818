import { useState } from "react";
import { api, AuthApi, resolveUrl } from "../../config/apis";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";

const ForgotPassword = () => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState([]);
  // const { dispatch } = useAuth();
  // const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      email: data.get("email"),
    };

    if (!payload.email) {
      setIsError(true);
      setErrorMsg(["Please fill in all the fields."]);
      return;
    }

    try {
      const response = await api.post(
        resolveUrl(AuthApi.FORGET_PASSWORD),
        payload
      );
      if (response.data) {
        setIsError(false);
        setIsSuccess(true);
        setSuccessMsg(response.data.message);
      }
    } catch (error) {
      setIsError(true);

      let message = "Email does not exist";
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          message = error.response.data.message;
        }
      }

      setErrorMsg([message]); // Store the error message as an array

      // Perform additional actions based on the error, such as displaying a notification or logging the error
      console.error("Error occurred while logging in:", error);
    }
  };
  return (
    <>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          marginTop: 42,
          marginBottom: 41.3,
        }}
      >
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: "white",
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 24,
            }}
          >
            Confirm Email
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {isError && (
              <Alert severity="error">
                <ul style={{ padding: "0", margin: "0" }}>
                  {errorMsg.map((value, index) => {
                    return <li key={index}>{value}</li>;
                  })}
                </ul>
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">
                <ul style={{ padding: "0", margin: "0" }}>
                  <li>{successMsg}</li>
                </ul>
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#C33F28",
                color: "white",
                fontWeight: 700,
              }}
            >
              Send Link
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ForgotPassword;
