import UnProtectRoutes from "../hooks/ProtectedRoutes/unProtectedRoutes";
import ChangePassword from "../layout/auth/ChangePassword";
import ForgotPassword from "../layout/auth/ForgotPassword";
import Login from "../layout/auth/Login";

export const AuthRoutes = {
  element: <UnProtectRoutes />,
  children: [
    {
      path: "login",
      element: <Login title="Admin Login" />,
    },
    {
      path: "changepassword/:token",
      element: <ChangePassword />,
    },
    {
      path: "forgotpassword/:token",
      element: <ForgotPassword />,
    },
    {
      path: "forgotpassword",
      element: <ForgotPassword />,
    },
  ],
};
