import { Box } from "@mui/material";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FilterBase from "./filter/filter-base";
import styles from "./manage-teacher.module.css";
import CSVDownloadButton from "../../../components/downloadCSV";
import {HasPermission} from '../../../helper/helperFunction'
import { DateView } from "../../../components/DateView/DateView";
import ImageView from "../../../components/imageShow";
import { toTitleCase, limitStringLength } from "../../../helper/helperFunction";
import Tooltip from "@mui/material/Tooltip";



export default function ViewSections() {
  const navigate = useNavigate();

  const [filters, setFilters] = useState();

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const tableColumns = [
    {
      selector: (row) => limitStringLength(row.name, 20),
      name: "Title",
      sortable: false,
      width: "180px",
    },
    {
      cell: (row) => (
        <>
          <ImageView
            imageName={row.profile_image ?? ""}
            name={row.teacher ?? ""}
          />
          {toTitleCase(`${row.teacher}`)}
        </>
      ),

      name: "Teacher Assign",
      sortable: false,
      width: "250px",
    },
    {
      selector: (row) => (
        <>
          <div className="shift">
            <p>
              <Tooltip
                title={
                  <div className="shift">
                    <table>
                      <tbody>
                        {row.shift.map((item, index) => (
                          <tr key={index}>
                            <th>{item.days}</th>
                            <td>{item.start_time}</td>
                            <td>to</td>
                            <td>{item.end_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }
              >
                {row.time_slot}
              </Tooltip>
            </p>
          </div>
        </>
      ),
      name: "Time Slot",
      sortable: false,
      width: "120px",
    },
    {
      selector: (row) => limitStringLength(row.cohort, 25),
      name: "Cohort",
      sortable: false,
      width: "250px",
    },
    {
      selector: (row) => <DateView date={row.start_date} />,

      name: "Started on",
      sortable: false,
      width: "250px",
    },
    {
      selector: (row) => <DateView date={row.end_date} />,

      name: "Ended on",
      sortable: false,
      width: "250px",
    },
  ];


  // const onEdit = async (id) => {
  //   setViewId(id);
  //   navigate(`/admin/section/edit/${id}`);
  // };

  const onView = async (id) => {
    navigate(`/admin/section/view/${id}`);
  };
  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.SECTION_MODULE_DELETE, id));
  };

  return (
    <>
      <div className="container">
        <div className="course-content">
          <h1 className="main-heading">Sections</h1>
        </div>
        
        <div className={styles.filter_flex}>
          <FilterBase palcehoder="Section Name" onFiltersChange={handleFiltersChange} />
        </div>

        <div className="page-container table-phases">
        <Datatable
          customClass={"section-table"}
          tableColumns={tableColumns}
          dataUrl={AdminApi.TEACHER_SECTION_MODULE_PAGINATION}
          onDelete={onDelete}
          onView={onView}
          actions={{
            edit: false,
            view: true,
            delete: false,
          }}
          filters={filters}
          module="manage-sections"
        />
        </div>
      </div>
    </>
  );
}
