import { useEffect, useState } from "react";
import AdminApi from "../../../../../config/apis/admin";
import useApiData from "../../../../../hooks/useApiData/useApiData";

const useSectionDropdown = ({ courseState, updateCourseState }) => {
  const { cohort_id } = courseState;

  const cohortId = cohort_id && cohort_id.length === 0 ? -1 : cohort_id;

  const { options } = useApiData(
    AdminApi.REPORTS_SECTIONS_BY_COHORT,
    cohortId ?? ""
  );
  const [selectedIds, setSelectedIds] = useState([]);
  useEffect(() => {
    const selectedIdsArray = options.map((option) => option.id);
    const selectedIdsArrayFilter = selectedIds.filter((element) =>
      selectedIdsArray.includes(element)
    );
    setSelectedIds(selectedIdsArrayFilter);
    updateCourseState({
      section_id: selectedIdsArrayFilter,
    });
  }, [options]);

  const handleAutocompleteChange = (event, value) => {
    const selectedIds = value.map((option) => option.id);
    setSelectedIds(selectedIds);
    updateCourseState({
      section_id: selectedIds,
    });
  };
  const handleChipDelete = (idToDelete) => {
    const updatedIds = selectedIds.filter((id) => id !== idToDelete);
    setSelectedIds(updatedIds);
    updateCourseState({
      section_id: updatedIds,
    });
  };
  return {
    options,
    handleAutocompleteChange,
    selectedIds,
    setSelectedIds,
    handleChipDelete,
  };
};

export default useSectionDropdown;
