import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { resolveUrl } from "../../../config/apis";
import { useParams } from "react-router-dom";
import { Box, Typography, Chip, Tooltip } from "@mui/material";
import { useState } from "react";
import styles from "./style.module.css";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
import { limitStringLength } from "../../../helper/helperFunction";
import GlobalModal from "../../../components/modalBox";
import SwitchCell from "../../../components/switch";
import Moment from "react-moment";
import { HasPermission } from "../../../helper/helperFunction";

export const ViewSecOutline = ({ courseId }) => {
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [reRender, setReRender] = useState(false);

  const [uploadMedia, setUploadMedia] = useState();
  const [sectionMedia, setSectionMedia] = useState();

  const tableColumns = [
    {
      selector: (row, index) => (
        <>
          <Typography variant="body1" component="sapn" className={styles.fs14}>
            Lecture {index + 1}:
          </Typography>
        </>
      ),
      width: "130px",
      name: "Number",
      sortable: false,
    },
    {
      selector: (row) => (
        <>
          <Tooltip title={row.name || ""} arrow>
            <Typography
              variant="body1"
              component="span"
              className={styles.fs14}
              sx={{ fontWeight: "700" }}
            >
              {row.name && limitStringLength(row.name, 15)}
            </Typography>
          </Tooltip>
        </>
      ),
      width: "200px",
      name: "Lecture Title",
      sortable: false,
    },
    {
      selector: (row) => (
        <>
          <Typography
            variant="body1"
            component="sapn"
            className={styles.fs14}
            sx={{ fontWeight: "400" }}
          >
            {row.date && (
              <Moment format="MMM, DD YYYY">{row.date ?? ""}</Moment>
            )}{" "}
            {row.start_time} {row.end_time}
          </Typography>
        </>
      ),
      width: "300px",
      name: "Lecture Date / Time",
      sortable: false,
    },
    {
      selector: (row) => (
        <>
          <Box>
            {row.upload_media && row.upload_media.length > 0
              ? row.upload_media.slice(0, 2).map((mediaItem, index) => (
                  <a
                    style={{ cursor: "pointer", display: "inline-block" }}
                    key={index}
                    href={`${SERVER_KEY}${mediaItem.upload_file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Chip
                      className={styles.ChipSet}
                      label={
                        mediaItem.upload_title &&
                        limitStringLength(mediaItem.upload_title, 10)
                      }
                      icon={<InsertDriveFileIcon />}
                    />
                  </a>
                ))
              : ""}
            {row.section_media && row.section_media.length > 0
              ? row.section_media.slice(0, 2).map((mediaItem, index) => (
                  <a
                    className={styles.sectionMedia}
                    style={{ cursor: "pointer", display: "inline-block" }}
                    key={index}
                    href={`${SERVER_KEY}${mediaItem.section_upload_file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Chip
                      className={styles.ChipSet}
                      label={
                        mediaItem.section_upload_title &&
                        limitStringLength(mediaItem.section_upload_title, 10)
                      }
                      icon={<InsertDriveFileIcon />}
                    />
                  </a>
                ))
              : ""}
            {row.section_media &&
            (row.upload_media.length > 2 || row.section_media.length > 1) ? (
              <>
                <span
                  className={styles.viewMore}
                  onClick={() => {
                    setOpen(true);
                    setUploadMedia(row.upload_media ?? []);
                    setSectionMedia(row.section_media ?? []);
                  }}
                >
                  View All
                </span>
              </>
            ) : (
              ""
            )}
          </Box>
        </>
      ),

      name: "Content",
      width: "500px",
      sortable: false,
    },
    {
      selector: (row) => (
        <>
          <Typography
            variant="body1"
            component="a"
            className={styles.fs14}
            sx={{ fontWeight: "500", textDecoration: "none" }}
            href={row.url}
            target="_blank"
          >
            {row.url && limitStringLength(row.url, 20)}
          </Typography>
        </>
      ),
      width: "200px",
      name: "Links",
      sortable: false,
    },
    {
      selector: (row) => (
        <>
          <Typography variant="body1" component="sapn" className={styles.fs14}>
            {row.is_completed === 1 ? "Completed" : "Remaining"}
          </Typography>
        </>
      ),
      width: "150px",
      name: "Status",
      sortable: false,
    },
    {
      selector: (row) => row.status,
      cell: (row) => (
        <>
          <HasPermission permission="manage-sec-outline.edit">
            <SwitchCell
              id={row.id}
              is_active={row.status === "Active" ? true : false}
              EndPoint={AdminApi.SEC_COURSE_OUTLINE_STATUS}
              requestBody={{
                is_completed: row.is_completed,
              }}
              queryparams={{
                section_id: params.id,
              }}
              setReRender={setReRender}
            />
          </HasPermission>
        </>
      ),
      name: "Action",
      sortable: false,
      width: "100px",
    },
    {
      selector: (row) => (
        <>
          <Typography variant="body1" component="sapn" className={styles.fs14}>
            {row.duration} Hrs
          </Typography>
        </>
      ),
      width: "100px",
      name: "Duration",
      sortable: false,
    },
  ];

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        className={`page-container table-phases ${styles.courseOutlineTable}`}
      >
        {courseId && (
          <Datatable
            tableColumns={tableColumns}
            dataUrl={resolveUrl(
              AdminApi.SEC_COURSE_OUTLINE_PAGINATION,
              courseId
            )}
            qureyParam={{
              section_id: params.id,
            }}
            module="manage-sec-outline"
            actionColumns={false}
            refreshHandle={reRender}
          />
        )}
      </Box>

      {open && (
        <GlobalModal open={open} onClose={handleCloseModal} width={691}>
          <Box className={styles.chipModal}>
            {uploadMedia && uploadMedia.length > 0
              ? uploadMedia.map((mediaItem, index) => (
                  <a
                    style={{ cursor: "pointer", display: "inline-block" }}
                    key={index}
                    href={`${SERVER_KEY}${mediaItem.upload_file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Chip
                      className={styles.ChipSet}
                      label={mediaItem.upload_title}
                      icon={<InsertDriveFileIcon />}
                    />
                  </a>
                ))
              : ""}
            {sectionMedia && sectionMedia.length > 0
              ? sectionMedia.map((mediaItem, index) => (
                  <a
                    className={styles.sectionMedia}
                    style={{ cursor: "pointer", display: "inline-block" }}
                    key={index}
                    href={`${SERVER_KEY}${mediaItem.section_upload_title}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Chip
                      className={`${styles.ChipSet} ${styles.ChipSetSec}`}
                      label={mediaItem.section_upload_title}
                      icon={<InsertDriveFileIcon />}
                    />
                  </a>
                ))
              : ""}
          </Box>
        </GlobalModal>
      )}
    </>
  );
};
