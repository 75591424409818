import { useState } from "react";

export const useCheckboxStates = () => {
  const [checkboxStates, setCheckboxStates] = useState([]);
  const [columnsWithIds, setColumnsWithIds] = useState([]);

  const handleCheckboxChange = (
    sectionIndex,
    columnIndex,
    column,
    accodianData
  ) => {
    const newCheckboxStates = [...checkboxStates];
    const section = newCheckboxStates[sectionIndex];

    if (!section) {
      newCheckboxStates[sectionIndex] = [column];
    } else {
      const columnIndexInSection = section.findIndex(
        (col) => col.label === column.label
      );

      if (columnIndexInSection === -1) {
        newCheckboxStates[sectionIndex] = [...section, column];
      } else {
        section.splice(columnIndexInSection, 1);
        newCheckboxStates[sectionIndex] = section;
      }
    }

    const allSelectedColumns = newCheckboxStates.flatMap(
      (section) => section || []
    );

    const columnsWithIds = allSelectedColumns.map((col, index) => ({
      ...col,
      id: `column-${index + 1}`,
    }));

    setColumnsWithIds(columnsWithIds);

    return {
      newCheckboxStates,
      columnsWithIds,
    };
  };

  return {
    checkboxStates,
    columnsWithIds,
    handleCheckboxChange,
    setCheckboxStates,
  };
};
