import { Chip, FormControl, MenuItem, Select, Checkbox } from "@mui/material";
import useCourseDropdown from "./hook/useCourseDropdown";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CoursesDropdown = ({ updateCourseState, courseState }) => {
  const { handleAutocompleteChange, handleChipDelete, options, selectedIds } =
    useCourseDropdown({
      updateCourseState,
      courseState,
    });
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <FormControl fullWidth className="MuiAutocomplete-wrap">
      <Autocomplete
        fullWidth
        multiple
        id="checkboxes-tags-demo"
        options={options}
        disableCloseOnSelect
        value={options.filter((option) => selectedIds.includes(option.id))}
        onChange={handleAutocompleteChange}
        getOptionLabel={(option) => option.title}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )}
        renderInput={(params) => <TextField {...params} />}
        renderTags={() => null}
      />

      <div style={{ marginTop: 10 }}>
        {selectedIds.map((id) => (
          <Chip
            key={id}
            label={options && options.find((option) => option.id === id).title}
            onDelete={() => handleChipDelete(id)}
            style={{
              marginRight: "7px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          />
        ))}
      </div>
    </FormControl>
  );
};

export default CoursesDropdown;
