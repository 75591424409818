import React, { useState } from "react";
import { Chip, FormControl, Grid, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  formatDateYYMMDD,
  formatLabel,
} from "../../../../../helper/helperFunction";

const TypeDateSelect = ({ label, updateCourseState, courseState }) => {
  const modifiedLabel = label && formatLabel(label);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    updateCourseState({
      [modifiedLabel]: {
        min: start ? formatDateYYMMDD(start) : "",
        max: end ? formatDateYYMMDD(end) : "",
      },
    });
  };

  const selectedDateRange = { startDate, endDate }; // Define selectedDateRange
  const handleDeleteChip = () => {
    setStartDate(null);
    setEndDate(null);
    updateCourseState({
      [modifiedLabel]: "yes",
    });
  };
  return (
    <FormControl fullWidth className="date_range_filter">
      <Grid container alignItems={"center"} flexWrap={"nowrap"}>
        <Grid container spacing={2} xs={5} minWidth={"421px"}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Start Date:</Typography>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleDateChange(date, endDate)}
              startDate={startDate}
              endDate={endDate}
              selectsStart
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">End Date:</Typography>
            <DatePicker
              selected={endDate}
              onChange={(date) => handleDateChange(startDate, date)}
              startDate={startDate}
              endDate={endDate}
              selectsEnd
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={7} paddingLeft={2} paddingTop={3}>
          {selectedDateRange && startDate && endDate && (
            <Chip
              label={`${selectedDateRange.startDate.toLocaleDateString()} - ${selectedDateRange.endDate.toLocaleDateString()}`} // Add labels for start date and end date
              onDelete={handleDeleteChip}
              style={{
                marginRight: "7px",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
            />
          )}
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default TypeDateSelect;
