// import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { Box, Tooltip, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DiscountFormModal from "./modal";
import { DateView } from "../../../components/DateView/DateView";
import SwitchCell from "../../../components/switch";
import { HasPermission } from "../../../helper/helperFunction";
import FilterBase from "./filter/filter-base";
import styles from "./style.module.css";
import CSVDownloadButton from "../../../components/downloadCSV";

export default function ViewCohorts() {
  // const navigate = useNavigate();
  const [viewId, setViewId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reRender, setReRender] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const tableColumns = [
    {
      selector: (row) => (
        <Tooltip title={row.title} arrow>
          <div>{row.title}</div>
        </Tooltip>
      ),
      name: "Name",
      sortable: true,
    },
    {
      selector: (row) => row.phase.title,
      name: "Phase",
      sortable: true,
    },
    {
      selector: (row) => row.location,
      name: "Location",
      sortable: true,
    },
    {
      cell: (row) => <DateView date={row.start_date} />,
      name: "Started on",
      sortable: true,
      selector: "start_date",
    },
    {
      cell: (row) => <DateView date={row.end_date} />,
      name: "Ended on",
      sortable: true,
      selector: "end_date",
    },
    {
      selector: (row) => `${row.fee} ${row.currency}`,
      name: "Fees",
      sortable: true,
    },
    {
      selector: (row) => row.status,
      cell: (row) => (
        <>
          <HasPermission permission="manage-cohorts.edit">
            <SwitchCell
              id={row.id}
              is_active={row.status === "active" ? true : false}
              EndPoint={AdminApi.COHORT_STATUS}
              setReloadData={setReRender}
              params={""}
            />
          </HasPermission>
        </>
      ),
      name: "Status",
      sortable: false,
      width: "100px",
    },
  ];

  const onEdit = async (id) => {
    setViewId(id);
    if (id) {
      setIsModalOpen(true);
    }
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.COHORT_DELETE, id));
  };

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);
  const [filters, setFilters] = useState();

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };
  return (
    <>
      <div className="container">
        <div className="course-content">
          <h1 className="main-heading">Cohorts</h1>

          <HasPermission permission="manage-cohorts.create">
            <div className="course_content">
              <Box className="btn-wrapper">
                <Link to={""} onClick={toggleModal}>
                  Add Cohorts
                </Link>
              </Box>
            </div>
          </HasPermission>
        </div>
        <div className="page-container table-phases">
          <div className={styles.filter_flex}>
            <FilterBase
              palcehoder="Course Name , Phase"
              onFiltersChange={handleFiltersChange}
            />
            <div className={styles.filter_flex}>
              <Box className={styles.filter_btn}>
                <CSVDownloadButton
                  apiEndpoint={AdminApi.COHORT_CSV}
                  params={"asc"}
                  fileName={"Cohorts"}
                />
              </Box>
            </div>
          </div>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.COHORT_PAGINATE}
            onEdit={onEdit}
            onDelete={onDelete}
            refreshHandle={reRender}
            actions={{
              view: false,
            }}
            qureyParam={filters}
            module="manage-cohorts"
          />
        </div>

        <DiscountFormModal
          id={viewId}
          open={isModalOpen}
          closeModal={toggleModal}
          setFormSuccess={setFormSuccess}
        />
      </div>
    </>
  );
}
