import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { OrgFundGrid } from "../manage-organization-Funds/OrgTransactionsTable";
import { DetailsOrgBanner } from "./detail-banner";
import { Grid, Container } from "@mui/material";
import {HasPermission} from '../../../helper/helperFunction'




export default function DetailsOrgTransactions() {
  const params = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setModalOpen(false);
  }, [formSuccess]);

  return (
    <>
      <DetailsOrgBanner setModalOpen={setModalOpen} formSuccess={formSuccess} tittle="Organization Detail" amoutBox="Total Funded Amount"/>
      <Container maxWidth="xl">
        <HasPermission permission="manage-organization-transactions.view">
        <Grid container marginTop={4}>
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ padding: "0 30px" }}
          >

            <OrgFundGrid
              modalOpen={modalOpen}
              handleCloseModal={handleCloseModal}
              setModalOpen={setModalOpen}
              editId={params.id ?? ""}
              setFormSuccess={setFormSuccess}
              formSuccess={formSuccess}
            />
          </Grid>
        </Grid>
        </HasPermission>
      </Container>
    </>
  );
}
