import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  // father_name: Yup.string().required("Father / Husband Name is required"),
  cnic: Yup.string().required("cnic number is required"),
  // emergency_number: Yup.string().required("emergency number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // phone: Yup.string().required("Whatsapp number is required"),
  // gender: Yup.string().required("Gender is required"),
  // date_of_birth: Yup.string().required("Date of Birth is required"),
  country_id: Yup.string().required("Country is required"),
  state_id: Yup.string().required("State is required"),
  city_id: Yup.string().required("City is required"),
  // is_studying: Yup.string().required("Studying status is required"),
  registration_date: Yup.string().required("Registration date is required"),
  joining_date: Yup.string().required("Joining date is required"),
  status: Yup.string().required("Status is required"),
  is_flagged: Yup.string().required("Flag status is required"),
  cohort_id: Yup.string().required("Cohort is required"),
  course_id: Yup.string().required("Course is required"),
  fund_type: Yup.string().required("Payment info is required"),
  registration_fee: Yup.string().required("Registration fee is required"),
  original_location_id: Yup.string().required("Location is required"),
  // qualification_id: Yup.string().required("Qualification is required"),
  // qualification_field_id: Yup.string().required("Qualification field is required"),
  section_id: Yup.string().required("Section is required"),
  currency_id: Yup.string().required("Currency is required"),
  organization_id: Yup.string().required("Organization is required"),

  organization_id: Yup.string().test({
    test: function (value) {
      return this.parent.fund_type !== "self_paid" ? !!value : true;
    },
    message: "Organization is required",
  }),
});

export default validationSchema;
