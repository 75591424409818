import React, { useState, useEffect } from "react";
import { Button, Box, Grid, Typography, Container } from "@mui/material";
import styles from "./manage-transactions.module.css";
import ImageView from "../../../components/imageShow";
import { useNavigate, useParams } from "react-router-dom";
import { EditTransactions } from "./edit";
import { useFetchData } from "../../../helper/helperFunction";
import AdminApi from "../../../config/apis/admin";
import Moment from "react-moment";
import { Margin, usePDF } from "react-to-pdf";
import { HasPermission } from "../../../helper/helperFunction";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import cgLogo from "../../../static/images/cg_logo.png";

export default function DetailsTransactions() {
  const params = useParams();
  const nevigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Define a state to hold the transaction data
  const [transactionData, setTransactionData] = useState(null);
  console.log(transactionData, "transactionData");

  // Create success and error handlers for the data fetch
  const handleFetchSuccess = (data) => {
    setTransactionData(data);
  };

  const handleFetchError = (error) => {
    console.error(error);
  };

  // Use the custom hook to fetch data
  useFetchData(
    params.id ?? "",
    handleFetchSuccess,
    handleFetchError,
    AdminApi.TRANSACTIONS_DETAIL,
    formSuccess
  );

  const onBack = async () => {
    nevigate("/admin/transactions");
  };

  useEffect(() => {
    setModalOpen(false);
  }, [formSuccess]);
  const { toPDF, targetRef } = usePDF({
    filename: "Invoice.pdf",
    page: { margin: Margin.SMALL },
  });

  return (
    <>
      <Container maxWidth="xl" className={styles.transactionsDetail}>
        <Grid container marginTop={12}>
          <Typography variant="h1" component="h1" className={styles.heading}>
            Transactions
          </Typography>
          <Grid container className={styles.gradientBackground} ref={targetRef}>
            <Grid item xs={12}>
              <Grid container mb={3} mt={1} className={styles.header_wrap}>
                <img src={cgLogo} alt="cg logo" />
                <Typography
                  className={styles.INVOICE}
                  variant="body2"
                  component="h2"
                >
                  INVOICE
                </Typography>
              </Grid>
              <Grid container mb={3} mt={1} justifyContent={"space-between"}>
                <Grid>
                  <Typography
                    className={styles.avatarName}
                    variant="body2"
                    component="p"
                  >
                    {transactionData && transactionData.name}
                  </Typography>
                  <Typography
                    className={styles.avatarEmail}
                    variant="body2"
                    component="p"
                  >
                    {transactionData && transactionData.email}
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "baseline" }}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={styles.subHeading}
                  >
                    Transaction ID
                  </Typography>
                  <Typography
                    variant="body1"
                    component="sapn"
                    className={styles.transactionsId}
                  >
                    #{transactionData && transactionData.id}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Table
                  className={styles.infoTable}
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow className={styles.th_row}>
                      <TableCell>Email</TableCell>
                      <TableCell>Payment Type</TableCell>
                      <TableCell>Date / Time</TableCell>
                      <TableCell>Organization Name</TableCell>
                      <TableCell>Fund Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className={styles.email_td}>
                        {transactionData && transactionData.email}
                      </TableCell>
                      <TableCell>
                        {transactionData && transactionData.type}
                      </TableCell>
                      <TableCell>
                        <Moment format="MMM DD, YYYY hh:mmA">
                          {transactionData && transactionData.datetime_at}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        {transactionData && transactionData.organization_name}
                      </TableCell>
                      <TableCell>
                        {transactionData &&
                          transactionData.fund_type &&
                          transactionData.fund_type.replace(/_/g, " ")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box className={styles.note_wrap}>
                    <Typography
                      variant="body1"
                      component="span"
                      className={styles.note}
                    >
                      Note : This Is system generated document, No Stamp or
                      Signature required
                    </Typography>
                  </Box>
                  <Box className={styles.ammoutWrap}>
                    <Typography
                      variant="body1"
                      component="span"
                      className={styles.label}
                    >
                      Amount Paid
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      className={styles.amount}
                    >
                      {transactionData && transactionData.currency_symbol}{" "}
                      {transactionData && transactionData.amount}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            marginTop={4}
            className="btn_container"
          >
            <Box className={styles.buttonWrap}>
              <Button onClick={toPDF}>Download Invoice</Button>
            </Box>
            <Box className={styles.buttonWrap}>
              <Button
                onClick={() => {
                  onBack();
                }}
              >
                Back
              </Button>
              <HasPermission permission="manage-transactions.edit">
                <Button
                  className={styles.buttonRed}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  Update
                </Button>
              </HasPermission>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <EditTransactions
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        setModalOpen={setModalOpen}
        editId={params.id ?? ""}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
