import { Grid } from "@mui/material";
import styles from "./style.module.css";
import AttendenceChart from "./summrayChart";
import React, { useState, useEffect } from "react";
import { api } from "../../../../config/apis";
import AdminApi from "../../../../config/apis/admin";

export default function AttendenceSummary({ filters }) {
  const [chartData, setChartData] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async (filters) => {
      setIsDataLoaded(true);
      try {
        const res = await api.get(AdminApi.TEACHER_ATTENDENCE_GRAPH, {
          params: {
            ...filters,
          },
        });
        setChartData(res.data.data);
        setIsDataLoaded(false);
      } catch (error) {
        console.error(error);
      }
    };
    if (filters.teacher_id && filters.section_id) {
      fetchData(filters);
    }
  }, [filters]);

  return (
    <>
      <Grid container spacing={2} className={styles.attendance_wrap}>
        <Grid item xs={12}>
          <AttendenceChart chartData={chartData} isDataLoaded={isDataLoaded} />
        </Grid>
      </Grid>
    </>
  );
}
