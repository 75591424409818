import { useState, useEffect } from "react";
import { api, AuthApi, resolveUrl } from "../../config/apis";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";

const ChangePassword = () => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);

  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState([]);

  const [token, setToken] = useState("");


  const navigate = useNavigate();

  const params = useParams();


  useEffect(() => {
    api
      .get(resolveUrl(AuthApi.FORGET_PASSWORD_TOKEN_VERIFY, params.token))
      .then((response) => {
        if (response.data && response.data.status) {
          if (params.token) setToken(params.token);
        } else {
          setIsError(true);
          setErrorMsg(['Invalid or expired token']);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) navigate('/not-found');
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const newPassword = data.get("new_password");
    const confirmPassword = data.get("confirm_password");

    if (!newPassword || !confirmPassword) {
      setIsError(true);
      setErrorMsg(["Please fill in all the fields."]);
      return;
    }

    if (newPassword !== confirmPassword) {
      setIsError(true);
      setErrorMsg(["Passwords do not match."]);
      return;
    }

    const payload = {
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    api
      .post(resolveUrl(AuthApi.CHANGE_PASSWORD, params.token), payload)
      .then((response) => {
        if (response.data) {
          setIsError(false);
          setIsSuccess(true);
          setSuccessMsg(response.data.message);

          navigate("/login");
        }
      })
      .catch((err) => {
        setIsError(true);

        let message = ["Server Error"];
        if (err.response) {
          message = err.response.data.message;
          if (typeof message !== "object")
            message = [err.response.data.message];
        }
        setErrorMsg(message);
      });
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          marginTop: 36.7,
          marginBottom: 36.6,
        }}
      >
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: "white",
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 24,
            }}
          >
            New Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {isError && (
              <Alert severity="error">
                <ul style={{ padding: "0", margin: "0" }}>
                  {errorMsg.map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              </Alert>
            )}

            {isSuccess && (
              <Alert severity="success">
                <ul style={{ padding: "0", margin: "0" }}>
                  <li>{successMsg}</li>
                </ul>
              </Alert>
            )}
            <TextField
              fullWidth={true}
              margin="normal"
              required
              label="Enter New Password"
              id="new_password"
              name="new_password"
              autoComplete="new_password"
              type="password"
            />
            <TextField
              fullWidth={true}
              required
              margin="normal"
              label="Confirm  New Password"
              id="confirm_password"
              name="confirm_password"
              type="password"
              autoComplete="confirm_password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#C33F28",
                color: "white",
                fontWeight: 700,
              }}
            >
              Save and login
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ChangePassword;
