import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid,
  InputLabel,
  Chip,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Form from "../../../components/forms/Forms";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import styles from "./style.module.css";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
import { IsStudent, downloadFile } from "../../../helper/helperFunction";

export default function EditSkills(params) {
  const userIsStudent = IsStudent();

  const [formData, setFormData] = useState({
    skills: [],
    job_type: [],
    other_skill_name: [],
    resume: null,
  });

  const [fileView, setFileView] = useState();
  const [skillLevel, setSkillLevel] = useState([]);
  const [modules, setModules] = useState([]);
  const [succes, setSucces] = useState(false);

  const FechSkillsData = useCallback(async () => {
    const res = await api.get(AdminApi.STUDENT_SKILLS_LIST);
    const ResSkillLevel = await api.get(AdminApi.PROFILE_SKILLS_LEVELS);
    setSkillLevel(ResSkillLevel.data.data ?? []);
    setModules(res.data.data ?? []);
  }, []);

  useEffect(() => {
    FechSkillsData();
  }, [FechSkillsData]);

  useEffect(() => {
    const FechProfileData = async () => {
      try {
        const res = await api.get(AdminApi.PROFILE_VIEW);
        const data = res.data.data || {};

        setFormData({
          skills: data.skills || [],
          job_type: data.job_type || [],
          other_skill_name: data.other_skill_name || [],
          resume: null,
        });
        setFileView(data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    if (!params.id) {
      FechProfileData();
    }
  }, [succes]);

  useEffect(() => {
    const FechProfileData = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.PROFILE_VIEW_ADMIN, params.id)
        );
        const data = res.data.data || {};

        setFormData({
          skills: data.skills || [],
          job_type: data.job_type || [],
          other_skill_name: data.other_skill_name || [],
          resume: null,
        });
        setFileView(data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    if (params.id) {
      FechProfileData();
    }
  }, [params.id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => {
      const updatedSkills = [...prevFormData.skills];
      const existingSkillIndex = updatedSkills.findIndex(
        (s) => s.skill_id == name
      );

      if (existingSkillIndex !== -1) {
        updatedSkills[existingSkillIndex] = {
          skill_id: name,
          skill_level_id: value,
        };
      } else {
        updatedSkills.push({ skill_id: name, skill_level_id: value });
      }

      return {
        ...prevFormData,
        skills: updatedSkills,
      };
    });
  };

  const handleJobTypeGroupChange = (event) => {
    setFormData({
      ...formData,
      job_type: [event.target.value],
    });
  };

  const handleJobTypeCheckbox = (item) => {
    setFormData((prevFormData) => {
      const updatedJobTypes = prevFormData.job_type.includes(item)
        ? prevFormData.job_type.filter((selectedItem) => selectedItem !== item)
        : [...prevFormData.job_type, item];

      return {
        ...prevFormData,
        job_type: updatedJobTypes,
      };
    });
  };
  const handleOtherSkillChange = (e) => {
    const inputValue = e.target.value;
    const newArray = inputValue.split(",").map((skill) => skill.trim());
    setFormData({
      ...formData,
      other_skill_name: newArray,
    });
  };
  const handleChipDelete = (chipIndex) => {
    const updatedArray = [...formData.other_skill_name];
    updatedArray.splice(chipIndex, 1);
    setFormData({
      ...formData,
      other_skill_name: updatedArray,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      resume: file,
    });
  };
  const ApiUrl = userIsStudent
    ? AdminApi.PROFILE_SAVE
    : AdminApi.PROFILE_SAVE_FROM_ADMIN;

  const handleDownload = (filePathParam, fileNameParam) => {
    const filePath = filePathParam;
    const fileName = fileNameParam;

    try {
      downloadFile(filePath, fileName);
    } catch (error) {
      console.error("Error during download:", error);
    }
  };

  const UpdatedFormData = {
    ...formData,
    other_skill_name: formData.other_skill_name.filter((skill) => skill) ?? [],
  };

  return (
    <>
      <Typography variant="h2" gutterBottom className={styles.heding_2}>
        Skills
      </Typography>
      <Box className="page-container permission_wrap">
        <Form
          submitUrl={ApiUrl}
          formData={UpdatedFormData}
          setFormSuccess={setSucces}
          successPath={
            userIsStudent ? "/admin/setting" : "/admin/student-resume"
          }
          onSuccess={params.setSuccess}
          multipart={true}
          editId={params.id}
        >
          <Box className="permission_table">
            <Table>
              <TableHead>
                <TableRow className="table-head">
                  <TableCell className="table-heading">Skill Name</TableCell>
                  {skillLevel.map((item) => {
                    return (
                      <TableCell className="table-heading" key={item.id}>
                        {item.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {modules.map((module) => (
                  <TableRow key={module.id}>
                    <TableCell className="table-cell">{module.skill}</TableCell>
                    <RadioGroup
                      sx={{ display: "contents" }}
                      name={`${module.id}`}
                      value={
                        formData.skills.find(
                          (p) => `${p.skill_id}` == `${module.id}`
                        )?.skill_level_id || "first"
                      }
                      onChange={handleInputChange}
                    >
                      {skillLevel.map((item) => {
                        const radioName = `${item.label}`;
                        return (
                          <TableCell className="table-cell">
                            <FormControlLabel
                              key={radioName}
                              value={item.id}
                              control={<Radio />}
                              // label={item.label}
                            />
                          </TableCell>
                        );
                      })}
                    </RadioGroup>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <InputLabel
                sx={{
                  textAlign: "left",
                  whiteSpace: "normal",
                  lineHeight: "18px",
                }}
              >
                Other Skills (Separate each skill with a comma to add more
                skills.)
              </InputLabel>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{ maxWidth: "725px" }}
                value={formData.other_skill_name.join(", ")}
                placeholder="Add comma seprated skill name"
                onChange={handleOtherSkillChange}
              />
              <Box marginTop={2}>
                {formData.other_skill_name.map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill}
                    onDelete={() => handleChipDelete(index)}
                    style={{ margin: 4 }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
          <Divider light />

          <Grid
            xs={12}
            container
            justifyContent={"space-between"}
            marginTop={"40px"}
          >
            <Grid item>
              <Typography variant="h5" gutterBottom className={styles.heding_5}>
                My CV
              </Typography>
              <Box className={styles.upload_file_wrap}>
                <Typography variant="body1">
                  {formData.resume && formData.resume.name}
                </Typography>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="resumeInput"
                />
                <label htmlFor="resumeInput">
                  <Button
                    variant="outlined"
                    component="span"
                    color="primary"
                    className={styles.u_button}
                  >
                    Choose File
                  </Button>
                </label>
              </Box>
            </Grid>
            <Grid item>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <InputLabel
                  sx={{
                    textAlign: "left",
                    whiteSpace: "normal",
                    lineHeight: "18px",
                  }}
                >
                  Job Type
                </InputLabel>
              </Grid>
              <Grid item>
                <RadioGroup
                  className={styles.radio_label}
                  name="job_type"
                  defaultValue="first"
                  value={formData.job_type}
                  onChange={handleJobTypeGroupChange}
                  sx={{ display: "contents" }}
                >
                  {["Part-time", "Full time", "Home Based"].map(
                    (item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={item}
                          control={
                            <Checkbox
                              checked={formData.job_type.includes(item)}
                              onChange={() => handleJobTypeCheckbox(item)}
                            />
                          }
                          label={item}
                        />
                      );
                    }
                  )}
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            justifyContent={"space-between"}
            marginBottom={"40px"}
          >
            <Grid item>
              {fileView && fileView.upload_file && (
                <a
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    marginTop: "20px",
                  }}
                  href={`${SERVER_KEY}/${fileView.upload_file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Chip
                    className={styles.ChipSet}
                    label={fileView.upload_title}
                    icon={<InsertDriveFileIcon />}
                    sx={{ cursor: "pointer" }}
                  />
                </a>
              )}
            </Grid>
          </Grid>
        </Form>
      </Box>
    </>
  );
}
