import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../hooks/auth/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ActionButtons(params) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const { state } = useAuth();

  const actions = {
    edit:
      params.actions && params.actions.hasOwnProperty("edit")
        ? params.actions.edit
        : true,
    view:
      params.actions && params.actions.hasOwnProperty("view")
        ? params.actions.view
        : true,
    delete:
      params.actions && params.actions.hasOwnProperty("delete")
        ? params.actions.delete
        : true,
  };

  const onEdit = () => {
    params.onEdit(params.id);
  };

  const onView = () => {
    params.onView(params.id);
  };

  const onDelete = () => {
    params
      .onDelete(params.id)
      .then((res) => {
        setOpenConfirm(false);
        params.refreshHandle();

        if ((res.data && res.data.status === 200) || res.status === 200) {
          const successMessage = res.data.message || "Deletion successful";
          toast.success(successMessage);
        } else if (res.response.data && res.response.status === 200) {
          const successMessage =
            res.response.data.message || "Deletion successful";
          toast.success(successMessage);
        } else {
          toast.error("Deletion failed: Unexpected server response");
        }
      })
      .catch((err) => {
        setOpenConfirm(false);
        params.refreshHandle();

        if (err.response && err.response.data) {
          const errorMessage =
            err.response.data.message ||
            err.response.data.error ||
            "Deletion failed";
          toast.error("Deletion failed: " + errorMessage);
        } else {
          console.error(err);
          toast.error("Deletion failed: Unexpected error response");
        }
      });
  };

  return (
    <>
      {state &&
        (state.userRolepermissions.includes(`${params.module}.delete`) ||
          state.userRole === "Super Admin") &&
        actions.delete && (
          <Button className="delete" onClick={() => setOpenConfirm(true)}>
            Delete
          </Button>
        )}
      {state &&
        (state.userRolepermissions.includes(`${params.module}.view`) ||
          state.userRole === "Super Admin") &&
        actions.view && (
          <Button className="view" onClick={() => onView()}>
            view
          </Button>
        )}
      {state &&
        (state.userRolepermissions.includes(`${params.module}.edit`) ||
          state.userRole === "Super Admin") &&
        actions.edit && (
          <Button className="edit" onClick={() => onEdit()}>
            Edit
          </Button>
        )}

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{ textAlign: "center", padding: "20px" }}
          className="action_btn"
        >
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the record?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button className="btn save" onClick={() => setOpenConfirm(false)}>
              Cancel
            </Button>
            <Button className="btn cancel" onClick={onDelete} autoFocus>
              Okay
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
