import React from 'react';
import FooterLinks from './FooterLinks';
import FooterText from './FooterText';
import "./admin-footer-style.css";


const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
      <footer className={`dashboard-footer footer`}>
        {/* <footer className='footer'> */}
        <FooterText text={`Copyright © ${currentYear} LMS. All rights reserved.`} />
        <FooterLinks />
      </footer>
  );
};

export default Footer;