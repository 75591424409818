import { Grid, Select, MenuItem, Box } from "@mui/material";
import AdminApi from "../../../config/apis/admin";
import { useState } from "react";
import FilterBase from "../../../components/filter/filter-base";
import CSVDownloadButton from "../../../components/downloadCSV";
import { OrgFundHistoryGrid } from "./OrgFundHistoryTable";
import { resolveUrl } from "../../../config/apis";
import { useParams } from "react-router-dom";

export default function ViewOrgFundHistory() {
  const params = useParams();
  const [filters, setFilters] = useState();

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const [currentValue, setCurrentValue] = useState("asc");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  return (
    <>
      <Box className="transaction_module">
        <Grid container marginBottom={2}>
          <Grid item xs={8.5}>
            <FilterBase onFiltersChange={handleFiltersChange} />
          </Grid>
          <Grid item xs={3.5} display="flex" justifyContent="flex-end">
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
                justifyContent: "flex-end",
              }}
            >
              <CSVDownloadButton
                apiEndpoint={resolveUrl(
                  AdminApi.ORGANIZATION_FUNDS_HISTORY_CSV,
                  params.id
                )}
                params={currentValue}
                fileName={"Organization_funds"}
              />
              <Select
                value={currentValue}
                onChange={handleChange}
                sx={{ height: "43px" }}
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <OrgFundHistoryGrid filters={filters} currentValue={currentValue} />
      </Box>
    </>
  );
}
