import React from "react";
import { Box, Modal } from "@mui/material";
import "./manage-phases-modal.css";
import DiscountForm from "./edit";

const DiscountFormModal = ({ open, id, closeModal , setFormSuccess}) => {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box className="popup">
        <button onClick={closeModal}  className="close_btn">
          Close
        </button>
        <DiscountForm id={id} setFormSuccess={setFormSuccess}/>
      </Box>
    </Modal>
  );
};

export default DiscountFormModal;
