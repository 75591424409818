import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Style from "../style.module.css";

const ReportAccordion = ({
  accodianData,
  setCheckboxStates,
  handleCheckboxChange,
  checkboxStates,
}) => {
  // Function to calculate checked item count for a given accordion section
  const calculateCheckedItemCount = (sectionIndex) => {
    if (checkboxStates[sectionIndex]) {
      return checkboxStates[sectionIndex].length;
    }
    return "";
  };
  return (
    <>
      {accodianData &&
        accodianData.map((row, rowIndex) => (
          <Accordion key={rowIndex} className={Style.accordion_wrap}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${rowIndex}-content`}
              id={`panel${rowIndex}-header`}
            >
              {row.section_name}
              {calculateCheckedItemCount(rowIndex) > 0 && (
                <span className={Style.section_selected_item}>
                  {calculateCheckedItemCount(rowIndex)}
                  <span className={Style.section_selected_label}>
                    {" "}
                    Selected
                  </span>
                </span>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {row.section_columns &&
                row.section_columns.map((column, colIndex) => {
                  const isChecked =
                    checkboxStates[rowIndex] &&
                    checkboxStates[rowIndex].findIndex(
                      (col) => col.label === column.label
                    ) !== -1;
                  return (
                    <span key={colIndex}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked || false}
                            onChange={() =>
                              setCheckboxStates((prevState) => {
                                const { newCheckboxStates, columnsWithIds } =
                                  handleCheckboxChange(
                                    rowIndex,
                                    colIndex,
                                    column,
                                    accodianData
                                  );
                                return newCheckboxStates;
                              })
                            }
                            inputProps={{ "aria-label": "Checkbox demo" }}
                          />
                        }
                        label={column.label}
                      />
                    </span>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

export default ReportAccordion;
