import { HasPermission } from "../../../helper/helperFunction";
import { Link } from "react-router-dom";
import FilterBase from "../../../components/filterJob/filter-base1";
import { Box, Select, MenuItem, Grid } from "@mui/material";
import styles from "./style.module.css";
import Datatable from "../../../components/dataTables/DataTable";
import StudentApi from "../../../config/apis/student";
import { useState } from "react";
import Moment from "react-moment";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/auth/auth";

export const JobRecords = () => {
  const nevigate = useNavigate();

  const [filters, setFilters] = useState();
  const [currentValue, setCurrentValue] = useState("desc");

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  const tableColumns = [
    {
      selector: (row) => (
        <Tooltip title={row.company_name} arrow>
          <div>{row.company_name}</div>
        </Tooltip>
      ),
      name: "Company",
      width: "150px",
    },
    {
      selector: (row) => (
        <Tooltip title={row.job_title} arrow>
          <div>{row.job_title}</div>
        </Tooltip>
      ),
      name: "Position",
    },
    {
      selector: (row) => (
        <Tooltip title={row.job_type} arrow>
          <div>{row.job_type}</div>
        </Tooltip>
      ),
      name: "Job status",
    },
    {
      selector: (row) => (
        <Moment format="MMM, DD YYYY">{row.joining_date}</Moment>
      ),
      name: "Date of Joining",
      width: "150px",
    },
    {
      selector: (row) => (
        <Tooltip title={row.salary} arrow>
          <div>{row.salary}</div>
        </Tooltip>
      ),
      name: "Salary",
    },
    {
      selector: (row) => (
        <Tooltip title={row.work_experience} arrow>
          <div>{row.work_experience}</div>
        </Tooltip>
      ),
      name: "Total Work Experience",
      width: "200px",
    },
    {
      selector: (row) => (
        <Tooltip title={row.referrer} arrow>
          <div>{row.referrer}</div>
        </Tooltip>
      ),
      name: "Referred by",
    },

    {
      selector: (row) =>
        row.end_date ? (
          <Moment format="MMM, DD YYYY">{row.end_date}</Moment>
        ) : (
          "-"
        ),
      name: "Date of Leaving",
      width: "150px",
    },
    {
      selector: (row) =>
        row.leaving_reason ? (
          <Tooltip title={row.leaving_reason} arrow>
            <div>{row.leaving_reason}</div>
          </Tooltip>
        ) : (
          "-"
        ),
      name: "Reason for Leaving",
      width: "200px",
    },
  ];

  const onEdit = (id) => {
    nevigate(`/admin/job-records/edit/${id}`);
  };
  const { state } = useAuth();

  return (
    <>
      <Box className="container">
        {state.userRole === "Student" ? (
          <>
            <Box className="course-content">
              <h1 className="main-heading">Job Records </h1>
            </Box>
            <Box className={styles.display_left}>
              <FilterBase
                onFiltersChange={handleFiltersChange}
                showIdField={false}
              />
              <Box className={styles.display_right}>
                <Box>
                  <Select
                    value={currentValue}
                    onChange={handleChange}
                    className="select-menu"
                  >
                    <MenuItem value="asc">Ascending</MenuItem>
                    <MenuItem value="desc">Descending</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="course-content">
            <h1 className="main-heading">Student Job Records </h1>
          </Box>
        )}

        <Box
          className={`page-container table-phases global_font_style ${styles.table_wrap}`}
          marginTop={5}
        >
          <Datatable
            tableColumns={tableColumns}
            dataUrl={StudentApi.JOB_RECORDS_LIST}
            onEdit={onEdit}
            actions={{
              view: false,
            }}
            sortOrder={currentValue}
            filters={filters}
            module="manage-student-job-record"
          />
        </Box>
        <Grid container marginTop={5} justifyContent={"flex-end"}>
          <Box className="course-content">
            <HasPermission permission="manage-student-job-record.create">
              <Box className="btn-wrapper">
                <Link to={"/admin/job-records/edit"}>Add a Job Records</Link>
              </Box>
            </HasPermission>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
