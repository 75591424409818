import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import "../../static/css/style.css";
import LogoWhite from "../../static/images/consulnet-logo.png";
import ToggleIcon from "../../static/images/toggle.svg";
import Menu from "./partial/Menu";
import Header from "./partial/Header";
import { Link, useOutlet } from "react-router-dom";
import Footer from "./partial/Footer/Footer";
import OutletTransition from "../../components/outletTransitions/OutletTransitions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../hooks/auth/auth";
import CurrencyAlert from "../../pages/admin/manage-currency-alert/edit";

const drawerWidth = 305;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  background: "linear-gradient(180deg, #071836 0%, #273A5C 35.58%)",
}));

const Layout = () => {
  const { state } = useAuth();

  const [open, setOpen] = useState(true);
  const currentOutlet = useOutlet();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer
        className="side-bar"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className="main-logo">
          <Box component="img" alt="Logo" src={LogoWhite} />
        </DrawerHeader>
        <Menu />
      </Drawer>
      <Main
        open={open}
        className={`main-content dashboard-main ${
          !open ? "dashboard-main-close" : ""
        }`}
      >
        <AppBar
          position="fixed"
          open={open}
          className={`main-header ${open ? "main-header-open" : ""}`}
        >
          <Box
            className="togglebutton"
            component="img"
            onClick={handleDrawerToggle}
            alt="Logo"
            src={ToggleIcon}
          />
          <Header />
        </AppBar>
        <ToastContainer />
        <Box className="mainContainer">
          {state.userRole === "Super Admin" && (
            <>
              <CurrencyAlert />
            </>
          )}

          <OutletTransition outlet={currentOutlet} />
        </Box>
        <Box className="dashboard-footer">
          <Footer />
        </Box>
      </Main>
    </Box>
  );
};

export default Layout;
