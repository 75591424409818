import { Box } from "@mui/material";
import { Modal } from "@mui/material";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import CurrencyAlertForm from "./currency-form";
import React, { useState, useEffect } from "react";
import { api } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";

const CurrencyAlert = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [formSuccess, setFormSuccess] = useState(false);
  const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  }));
  useEffect(() => {
    const CheckCurrncy = async () => {
      try {
        const res = await api.get(AdminApi.CURRENCY_UPDATE_CHECK);
        setIsModalOpen(!res.data.exists);
      } catch (error) {
        console.error(error);
      }
    };
    CheckCurrncy();
  }, []);
  return (
    <Modal open={isModalOpen} onClose={toggleModal}>
      <Box className={styles.popup}>
        <CloseButton onClick={toggleModal}>
          <CloseIcon />
        </CloseButton>

        {isSelected ? (
          <CurrencyAlertForm toggleModal={toggleModal} />
        ) : (
          <>
            <Box className={styles.currency_rate_alert}>
              <svg
                width="84"
                height="84"
                viewBox="0 0 84 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_8197_62174)">
                  <path
                    d="M82.4306 66.1244L48.3854 9.66301C47.0441 7.43871 44.5974 6.05688 41.9999 6.05688C39.4024 6.05688 36.9557 7.43871 35.6143 9.66317L1.56918 66.1244C0.182986 68.4234 0.141972 71.3038 1.46203 73.6414C2.78241 75.979 5.27014 77.4312 7.95467 77.4312H76.0451C78.7297 77.4312 81.2176 75.979 82.5379 73.6413C83.858 71.3035 83.817 68.4231 82.4306 66.1244ZM77.8326 70.9841C77.4691 71.6275 76.7842 72.0275 76.0451 72.0275H7.95467C7.21561 72.0275 6.5307 71.6277 6.16742 70.9843C5.80397 70.3407 5.81531 69.5477 6.19676 68.915L40.2422 12.4536C40.6114 11.8413 41.285 11.4608 42.0001 11.4608C42.715 11.4608 43.3885 11.8413 43.7578 12.4536L77.8029 68.915C78.1847 69.5478 78.196 70.3407 77.8326 70.9841Z"
                    fill="#C33F28"
                  />
                  <path
                    d="M42.0231 28.2898C39.9675 28.2898 38.3633 29.3928 38.3633 31.3482C38.3633 37.3141 39.0651 45.8871 39.0651 51.8532C39.0652 53.4073 40.419 54.059 42.0232 54.059C43.2264 54.059 44.931 53.4073 44.931 51.8532C44.931 45.8873 45.6328 37.3143 45.6328 31.3482C45.6328 29.3929 43.9784 28.2898 42.0231 28.2898Z"
                    fill="#C33F28"
                  />
                  <path
                    d="M42.0753 57.5183C39.8695 57.5183 38.2148 59.273 38.2148 61.3788C38.2148 63.4343 39.8693 65.2393 42.0753 65.2393C44.1309 65.2393 45.8857 63.4343 45.8857 61.3788C45.8857 59.273 44.1307 57.5183 42.0753 57.5183Z"
                    fill="#C33F28"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_8197_62174">
                    <rect
                      width="83"
                      height="83"
                      fill="white"
                      transform="translate(0.5 0.244141)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <h2>Alert</h2>
              <p>Please select the currency rate for this month first.</p>
              <Box className="btn-wrapper">
                <Link to={"#"} onClick={() => setIsSelected(true)}>
                  Select Now
                </Link>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CurrencyAlert;
