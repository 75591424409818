import React, { useState, useEffect } from "react";
import { Box, MenuItem, Modal, Select } from "@mui/material";
import AdminApi from "../../../../config/apis/admin";
import { Grid, InputLabel } from "@mui/material";
import Form from "../../../../components/forms/Forms";
import { Textarea } from "@mui/joy";

const EditFlag = ({ open, row, closeModal, setReRender }) => {
  const [formData, setFormData] = useState({
    is_flagged: null,
    flag_reason: "",
  });
  const [formSuccess, setFormSuccess] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (row) {
      setFormData({
        ...formData,
        is_flagged: Number(row.is_flagged),
        flag_reason: row.flag_reason || "", // Ensure flag_reason is not undefined
      });
    }
  }, [row]);

  useEffect(() => {
    if (formData.is_flagged === 0) {
      setFormData((prevState) => ({
        ...prevState,
        flag_reason: "", // Clear flag_reason if is_flagged is set to 0
      }));
    }
  }, [formData.is_flagged]);

  useEffect(() => {
    if (formSuccess) {
      closeModal();
      setFormSuccess(false);
      setReRender((prevState) => !prevState);
    }
  }, [formSuccess]);

  return (
    <Modal open={open} onClose={closeModal}>
      {row && (
        <Box className="popup" maxWidth={600}>
          <button onClick={closeModal} className="close_btn">
            Close
          </button>
          <Form
            successPath="/admin/user/students"
            submitUrl={AdminApi.USER_MODULE_STUDENT_FLAG_STATUS}
            formData={formData}
            editId={row?.id}
            setFormSuccess={setFormSuccess}
          >
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center", pr: 2 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Flagged*</InputLabel>
              </Grid>
              <Grid item xl={6} sm={8.1}>
                <Select
                  labelId="flag-select-label"
                  id="flag-select"
                  fullWidth
                  value={formData.is_flagged} // Control Select value
                  onChange={handleInputChange}
                  name="is_flagged"
                >
                  {[0, 1].map((flag) => (
                    <MenuItem key={flag} value={flag}>
                      {flag ? "Yes" : "No"}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center", pr: 2 }}
            >
              <Grid item xl={2} sm={3.1} mb={1}>
                <InputLabel> Comment for flag* </InputLabel>
              </Grid>
              <Grid item xl={6} sm={8.1}>
                <Textarea
                  name="flag_reason"
                  minRows={4}
                  maxRows={4}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={formData.flag_reason}
                  disabled={formData.is_flagged !== 1} // Disable Textarea if is_flagged is not 1
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      )}
    </Modal>
  );
};

export default EditFlag;
