import { useState, useEffect } from "react";

const useForm = (initialState, validationSchema) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleImageChange = (name, selectedFile) => {
    setFormData({
      ...formData,
      [name]: selectedFile,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      if (formData.is_flagged === "1" && formData.flag_reason.trim() === "") {
        const newErrors = {
          flag_reason: "Flag reason is required when flagged.",
        };
        setErrors(newErrors);
        return false;
      }
      return true;
    } catch (error) {
      const newErrors = {};
      if (error.inner) {
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
      } else {
        console.error(error);
      }
      setErrors(newErrors);
      return false;
    }
  };

  return {
    formData,
    setFormData,
    errors,
    setErrors,
    handleInputChange,
    handleImageChange,
    handleUpdate,
    customValidation,
  };
};

export default useForm;
