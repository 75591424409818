import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Button,
  Select,
  Grid,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import BootstrapInput from "../../../layout/admin/partial/BootstrapInput";
import Form from "../../../components/forms/Forms";
import styles from "./manage-cohorts.module.css";
import moment from "moment";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import * as yup from "yup";
import SelectOptionsApi from "../../../components/SelectApi";
import {
  formatDateYYMMDD,
  useCurrencyId,
} from "../../../helper/helperFunction";

export default function EditCohort({ id, setFormSuccess }) {
  const [locationId, setLocationID] = useState();

  const [formData, setFormData] = useState({
    CohortData: {
      name: "",
      phase_id: null,
      start_date: "",
      end_date: "",
      currency_id: null,
      location_id: null,
      fee: null,
    },
    AssessmentData: [
      {
        title: "Weekly Assignments weightage*",
        weightage: null,
        assessment_type: "",
      },
      {
        title: "Assignments weightage*",
        weightage: null,
        assessment_type: "",
      },
    ],
  });
  const [title, setTitle] = useState("");
  const [phase, setPhase] = useState([]);
  const [assessmentWeightage, setAssessmentWeightage] = useState(0);

  const addField = () => {
    if (title.trim() !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        AssessmentData: [
          ...prevFormData.AssessmentData,
          { title: title, weightage: null, assessment_type: "" },
        ],
      }));
      setTitle("");
      setValidationMessage("");
    } else {
      setValidationMessage("Title cannot be empty");
    }
  };

  const removeField = (index) => {
    const updatedData = [...formData.AssessmentData];
    updatedData.splice(index, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      AssessmentData: updatedData,
    }));
  };

  const handleFieldChange = (index, key, value) => {
    const updatedData = [...formData.AssessmentData];
    updatedData[index][key] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      AssessmentData: updatedData,
    }));
  };

  const handleFormSuccess = async (response) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.COHORT_VIEW, id));
        setFormData((prevFormData) => ({
          ...prevFormData,
          CohortData: {
            ...prevFormData.CohortData,
            name: res.data.data.Cohort.name,
            phase_id: res.data.data.Cohort.phase_id,
            location_id: res.data.data.Cohort.location_id,
            start_date: moment(res.data.data.Cohort.start_date).format(
              "YYYY-MM-DD"
            ),
            end_date: moment(res.data.data.Cohort.end_date).format(
              "YYYY-MM-DD"
            ),
            fee: res.data.data.Cohort.fee,
            currency_id: res.data.data.Cohort.currency_id,
          },

          AssessmentData: res.data.data.Assessment.map((item) => ({
            title: item.title,
            weightage: item.weightage,
            assessment_type: item.assessment_type,
          })),
        }));
        setLocationID({ location_id: res.data.data.Cohort.location_id });
      } catch (error) {
        // Error handling
      }
    };

    const fetchPhase = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.PHASES_VIEW));
        setPhase(res.data.data);
      } catch (error) {
        // Error handling
      }
    };

    fetchData();
    fetchPhase();
  }, [id]);

  const renderSelectOptions = () => {
    const timeOptions = [
      "Weekly Assignment",
      "Final Viva",
      "Final Project",
      "Assignment-1",
      "Assignment-2",
    ];

    return timeOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ));
  };

  useEffect(() => {
    const totalWeightage = formData.AssessmentData.reduce(
      (sum, field) => sum + (field.weightage || 0),
      0
    );
    setAssessmentWeightage(totalWeightage + 15);
  }, [formData.AssessmentData]);

  const schema = yup.object().shape({
    CohortData: yup.object().shape({
      name: yup.string().required("Cohort name is required"),
      location_id: yup.string().required("Location is required"),
      phase_id: yup
        .number()
        .typeError("Phase ID must be a number")
        .required("Phase ID is required"),
      start_date: yup.string().required("Start date is required"),
      end_date: yup.string().required("End date is required"),
      fee: yup.number().required("Fee is required"),
      currency_id: yup.number().required("Currency is required"),
      location_id: yup.number().required("location is required"),
    }),
    AssessmentData: yup
      .array()
      .of(
        yup.object().shape({
          weightage: yup
            .number()
            .typeError("Weightage must be a number")
            .required("Weightage is required"),
          assessment_type: yup
            .string()
            .when("weightage", (weightage, schema) => {
              return weightage
                ? schema.required("Assessment type is required")
                : schema;
            }),
        })
      )
      .min(1, "At least one assessment is required"),
  });

  const [errors, setErrors] = useState({});

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false }); // Validate with Yup schema
      const newErrors = {};

      // Custom validation for assessmentWeightage
      if (assessmentWeightage !== 100) {
        newErrors["assessmentWeightage"] =
          "Assessment weightage cannot be greater than 100% and less than 100%";
      }

      setErrors(newErrors); // Set Yup validation errors in the state

      // Check if there are any errors (Yup or custom)
      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors); // Set Yup validation errors in the state
      return false; // Validation failed
    }
  };
  const [validationMessage, setValidationMessage] = useState("");

  const handleUpdate = (name, value) => {
    setLocationID({ [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      CohortData: {
        ...prevFormData.CohortData,
        location_id: value,
      },
    }));
  };
  const handleUpdateCurrency = (name, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      CohortData: {
        ...prevFormData.CohortData,
        currency_id: value,
      },
    }));
  };

  const handleOnChangeFee = (event) => {
    const valueTarget = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      CohortData: {
        ...prevFormData.CohortData,
        fee: valueTarget ? Number(valueTarget, 10) : null,
      },
    }));
  };

  return (
    <Box className="page-container">
      <Form
        onSuccess={handleFormSuccess}
        validate={customValidation}
        successPath="/admin/cohorts"
        submitUrl={AdminApi.COHORT_SAVE}
        formData={formData}
        editId={id}
      >
        <Box className={styles.flexform}>
          <Box className={styles.stepone}>
            <Box className="field" mb="20px">
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    color: "#001C28",
                    fontSize: "14px !important",
                    fontWeight: "700",
                    transform: "unset",
                  }}
                >
                  Cohort Title*
                </InputLabel>
                <BootstrapInput
                  required
                  id="outlined-required"
                  fullWidth
                  name="name"
                  value={formData.CohortData.name}
                  onChange={(event) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      CohortData: {
                        ...prevFormData.CohortData,
                        name: event.target.value,
                      },
                    }))
                  }
                  size="small"
                />
              </FormControl>
              <FormHelperText error>{errors["CohortData.name"]}</FormHelperText>
            </Box>
            <Box className="field" mb="20px">
              <Grid item xs={12} sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel
                    sx={{ textAlign: "left", fontSize: "14px !important" }}
                  >
                    Phase*
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    sx={{ maxWidth: "100%" }}
                    select
                    required
                    id="outlined-required"
                    fullWidth
                    name="phase_id"
                    value={formData.CohortData.phase_id}
                    onChange={(event) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        CohortData: {
                          ...prevFormData.CohortData,
                          phase_id: event.target.value,
                        },
                      }))
                    }
                    size="small"
                  >
                    {phase &&
                      phase.map((phase) => (
                        <MenuItem key={phase.id} value={phase.id}>
                          {phase.title}
                        </MenuItem>
                      ))}
                  </TextField>
                  <FormHelperText error>
                    {errors["CohortData.phase_id"]}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Box>
            <Box className={styles.fieldflex}>
              <Box
                className="field-set"
                mb="20px"
                mr="25px"
                sx={{ width: "100%" }}
              >
                <InputLabel
                  sx={{ textAlign: "left", fontSize: "14px !important" }}
                >
                  Started On*
                </InputLabel>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <DatePicker
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    dayAriaLabel="Day"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date"
                    onChange={(date) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        CohortData: {
                          ...prevFormData.CohortData,
                          start_date: date ? formatDateYYMMDD(date) : null,
                        },
                      }))
                    }
                    value={
                      formData.CohortData.start_date
                        ? new Date(formData.CohortData.start_date)
                        : null
                    }
                    yearAriaLabel="Year"
                  />

                  <FormHelperText error>
                    {errors["CohortData.start_date"]}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className="field-set" mb="20px" sx={{ width: "100%" }}>
                <InputLabel
                  sx={{ textAlign: "left", fontSize: "14px !important" }}
                >
                  Ended On*
                </InputLabel>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <DatePicker
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    dayAriaLabel="Day"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date"
                    onChange={(date) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        CohortData: {
                          ...prevFormData.CohortData,
                          end_date: date ? formatDateYYMMDD(date) : null,
                        },
                      }))
                    }
                    value={
                      formData.CohortData.end_date
                        ? new Date(formData.CohortData.end_date)
                        : null
                    }
                    yearAriaLabel="Year"
                  />
                  {errors.date_of_birth && (
                    <FormHelperText error>
                      {errors.date_of_birth}
                    </FormHelperText>
                  )}

                  <FormHelperText error>
                    {errors["CohortData.end_date"]}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Box>
            <Box className="field" mb="20px">
              <Grid item xs={12} sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel
                    sx={{ textAlign: "left", fontSize: "14px !important" }}
                  >
                    locations*
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <SelectOptionsApi
                    apiEndpoint={AdminApi.LOCATION_PAGINATE}
                    formData={locationId}
                    onUpdate={handleUpdate}
                    labelKey="name"
                    name="location_id"
                    valueKey="id"
                    errorText={errors.location_id}
                  />
                  <FormHelperText error>
                    {errors["CohortData.location_id"]}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Box>
            <Grid item xl={12} lg={12} md={12}>
              <InputLabel>Fees*</InputLabel>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  maxWidth: "250px",
                }}
                className="currncy_ammount"
              >
                <Box
                  sx={{
                    maxWidth: "120px",
                  }}
                  className="SelectOptionsApi"
                >
                  <SelectOptionsApi
                    apiEndpoint={AdminApi.CURRENCY_VIEW}
                    formData={{
                      currency_id: formData.CohortData.currency_id,
                    }}
                    onUpdate={handleUpdateCurrency}
                    name={"currency_id"}
                    labelKey="symbol"
                    valueKey="id"
                    defaultText="Currency"
                    customStyle={{ border: "0" }}
                  />
                </Box>
                <TextField
                  className="registration_fee"
                  fullWidth
                  name="fee"
                  variant="outlined"
                  placeholder="fee"
                  value={formData.CohortData.fee ?? null}
                  InputProps={{
                    sx: {
                      height: "40px",
                      padding: "0 10px",
                    },
                  }}
                  inputProps={{
                    type: "number",
                    pattern: "[0-9]*",
                  }}
                  onChange={handleOnChangeFee}
                  error={errors && errors["CohortData.fee"] !== undefined}
                />
              </Box>

              {errors && (
                <FormHelperText error>
                  {errors["CohortData.fee"]}
                </FormHelperText>
              )}
              {errors && (
                <FormHelperText error>
                  {errors["CohortData.currency_id"]}
                </FormHelperText>
              )}
            </Grid>
          </Box>
          <div className={styles.steptwo}>
            {validationMessage && (
              <FormHelperText error sx={{ marginLeft: 4 }}>
                {validationMessage}
              </FormHelperText>
            )}
            <label>
              <h5 className={styles.margin}>
                Assessment Weightage ({assessmentWeightage}%)
              </h5>
            </label>
            <TextField
              className={styles.seperatemargin}
              name="title"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(event) => setTitle(event.target.value)}
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              className={styles.plusbtn}
              onClick={addField}
            >
              +
            </Button>

            <div>
              <h5 className={styles.margin}>Attendance weightage* </h5>
              <Box className={styles.flexformfield}>
                <TextField
                  sx={{ backgroundColor: "#F4F4F4" }}
                  className={styles.margin}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={12}
                  disabled
                />

                <Select
                  sx={{ backgroundColor: "#F4F4F4" }}
                  value={"Attendance weightage"}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  disabled
                >
                  <MenuItem value={"Attendance weightage"} selected>
                    Attendance weightage
                  </MenuItem>
                </Select>
                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.minusbtn}
                  style={{ opacity: "0" }}
                >
                  -
                </Button>
              </Box>
            </div>
            <div>
              <h5 className={styles.margin}>Workshop Attendance weightage*</h5>
              <Box className={styles.flexformfield}>
                <TextField
                  className={styles.margin}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={3}
                  disabled
                  sx={{ backgroundColor: "#F4F4F4" }}
                />

                <Select
                  sx={{ backgroundColor: "#F4F4F4" }}
                  value={"Workshop Attendance"}
                  variant="outlined"
                  disabled
                  fullWidth
                  margin="normal"
                  size="small"
                >
                  <MenuItem value={"Workshop Attendance"} selected>
                    Workshop Attendance*
                  </MenuItem>
                </Select>
                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.minusbtn}
                  style={{ opacity: "0" }}
                >
                  -
                </Button>
              </Box>
            </div>

            {formData.AssessmentData.map((field, index) => (
              <div key={index}>
                <h5 className={styles.margin}>{field.title}</h5>
                <Box className={styles.flexformfield}>
                  <TextField
                    className={styles.margin}
                    value={field.weightage}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        "weightage",
                        e.target.value ? parseInt(e.target.value) : null
                      )
                    }
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                  />

                  <Select
                    value={field.assessment_type}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        "assessment_type",
                        e.target.value
                      )
                    }
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                  >
                    {renderSelectOptions()}
                  </Select>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={styles.minusbtn}
                    onClick={() => removeField(index)}
                  >
                    -
                  </Button>
                </Box>
                <Box
                  sx={{ marginLeft: 4, marginTop: "-20px", marginBottom: 2 }}
                >
                  {errors[`AssessmentData[${index}].weightage`] && (
                    <FormHelperText error sx={{ marginLeft: 4 }}>
                      {errors[`AssessmentData[${index}].weightage`]}
                    </FormHelperText>
                  )}
                  {errors[`AssessmentData[${index}].assessment_type`] && (
                    <FormHelperText error sx={{ marginLeft: 4 }}>
                      {errors[`AssessmentData[${index}].assessment_type`]}
                    </FormHelperText>
                  )}
                </Box>
              </div>
            ))}
            {errors["AssessmentData"] && (
              <FormHelperText error sx={{ marginLeft: 4 }}>
                {errors["AssessmentData"]}
              </FormHelperText>
            )}
            {errors["assessmentWeightage"] && (
              <FormHelperText error sx={{ marginLeft: 4 }}>
                {errors["assessmentWeightage"]}
              </FormHelperText>
            )}
          </div>
        </Box>
      </Form>
    </Box>
  );
}
