import React from "react";
import { Grid, Box } from "@mui/material";
import Style from "./style.module.css";
import { useCheckboxStates } from "./useCheckboxStates";
import { useAccodianData } from "./useAccodianData";
import DragAndDropTable from "./DragAndDropTable/DragAndDropTable";
import ReportFilter from "./filters/fiters";
import useReportState from "./filters/useReportState";
import ReportAccordion from "./Accordion/Accordion";
import GenerateNow from "./GenerateNow/GenerateNow";

const Reports = () => {
  const { courseState, updateCourseState, UpdateCourseFilter, courseFilter } =
    useReportState();

  const {
    columnsWithIds,
    checkboxStates,
    handleCheckboxChange,
    setCheckboxStates,
  } = useCheckboxStates();
  const accodianData = useAccodianData();

  return (
    <>
      <Grid
        container
        className={`${Style.course_content} course-content`}
        marginTop={12}
      >
        <h1 className="main-heading">Student Reports</h1>
      </Grid>
      <Grid container className={Style.report_wrap}>
        <ReportFilter
          courseState={courseFilter}
          updateCourseState={UpdateCourseFilter}
        />
        <Grid item xs={12} marginTop={5}>
          <ReportAccordion
            handleCheckboxChange={handleCheckboxChange}
            setCheckboxStates={setCheckboxStates}
            accodianData={accodianData}
            checkboxStates={checkboxStates}
            updateCourseState={updateCourseState}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={5} className={Style.report_wrap}>
        <DragAndDropTable
          coloumData={checkboxStates.flatMap((section) => section || [])}
          courseState={courseState}
          updateCourseState={updateCourseState}
        />
        <Box className={Style.Genrate_Now_wrap}>
          <GenerateNow
            columnsWithIds={columnsWithIds}
            courseState={courseState}
            courseFilter={courseFilter}
          />
        </Box>
      </Grid>
    </>
  );
};
export default Reports;
