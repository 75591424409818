import React, { useState } from "react";
import { Container } from "@mui/material";
import styles from "./style.module.css";
import WorkShopBanner from "./workshop-banner";
import { WorkshopAttendenceGrid } from "../manage-workshop-attendence/view";
import { HasPermission } from "../../../helper/helperFunction";

export default function WorkShopDetail() {
  const [reRender, setReRender] = useState(false);

  return (
    <>
      <Container maxWidth="xl" className={styles.workShopDetail}>
        <WorkShopBanner setReRender={setReRender}/>
          <HasPermission permission="manage-work-shop-attendance.view"> 
            <WorkshopAttendenceGrid reRender={reRender}/>
          </HasPermission>
      </Container>
    </>
  );
}
