const StudentApi = {
  ATTENDANCE: "student/attendance",
  ASSIGNMENT: "student/student-assignment",
  GET_STUDENT_DATA: "student/get-student-data",
  PRE_ASSESSMENT_FORM: "student/pre-assessment/:id?",
  GET_PRE_ASSESSMENT_FORM: "student/pre-assessment-edit/:id",
  GET_POST_ASSESSMENT_FORM: "student/post-assessment-edit/:id",
  POST_ASSESSMENT_FORM: "student/post-assessment/:id?",
  GET_PRE_ASSESSMENT_FORM_DATA: "student/pre-assessment-fetch/:id?",
  GET_POST_ASSESSMENT_FORM_DATA: "student/post-assessment-fetch/:id?",
  GET_STUDENT_CHART_DATA: "student/attendance-graph",
  GET_STUDENT_PERCENTAGE: "student/attendance-percentage",
  GET_STUDENT_PERCENTAGE: "student/attendance-percentage",

  JOB_RECORDS_LIST: "student/job-records",
  JOB_RECORDS_SAVE: "student/job-record/:id?",
  JOB_RECORDS_VIEW: "/student/job-record-edit/:id",
  STUDENT_PROFILE: "student/student-detail",

  APPLIED_JOB_LIST: "student/applied-jobs",
  STUDENT_PROFILE_UPDATE: "student/student-profile-update/:id",
  STUDENT_PROFILE_PREFILED: "student/student-detail/:id",
};

export default StudentApi;
