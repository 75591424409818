import React, { useEffect, useState } from "react";
import styles from "./filter.module.css";
import { Button, TextField, Typography, Grid } from "@mui/material";
import DatePicker from "react-datepicker";
import { formatDate } from "../../helper/helperFunction";

const FilterBase = ({ onFiltersChange }) => {
  const [filterData, setFilterData] = useState({});

  const labelStyle = {
    color: "#001C28",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
  };

  const handleFilter = () => {
    onFiltersChange({
      ...filterData,
      from_date: formatDate(filterData.from_date),
      to_date: formatDate(filterData.to_date),
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body1"
            sx={labelStyle}
            style={{ minWidth: "90px" }}
          >
            Search By
          </Typography>
          <TextField
            InputProps={{
              sx: {
                height: "40px",
                padding: "0 10px",
              },
            }}
            variant="outlined"
            fullWidth
            placeholder="ID"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleFilter();
              }
            }}
            onChange={(event) =>
              setFilterData((prevState) => ({
                ...prevState,
                id: event.target.value,
              }))
            }
          />
        </Grid>
        <Grid
          item
          xs={2.25}
          sx={{ display: "flex", alignItems: "center", padding: "0 10px" }}
        >
          <DatePicker
            dateFormat="dd-MM-yyyy"
            required
            id="outlined-required"
            fullWidth
            name="from_date"
            selected={
              filterData.from_date ? new Date(filterData.from_date) : null
            }
            onChange={(date) =>
              setFilterData((prevFormData) => ({
                ...prevFormData,
                from_date: date,
              }))
            }
            showTimeSelect={false}
            size="small"
            placeholderText="Date To"
          />
        </Grid>
        <Grid
          item
          xs={2.25}
          sx={{ display: "flex", alignItems: "center", paddingRight: "10px" }}
        >
          <DatePicker
            dateFormat="dd-MM-yyyy"
            required
            id="outlined-required"
            fullWidth
            selected={filterData.to_date ? new Date(filterData.to_date) : null}
            name="to_date"
            onChange={(date) =>
              setFilterData((prevFormData) => ({
                ...prevFormData,
                to_date: date,
              }))
            }
            showTimeSelect={false}
            size="small"
            placeholderText="Date From"
          />
        </Grid>
        <Grid
          item
          xs={3.5}
          sx={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}
        >
          <Typography
            variant="body1"
            sx={labelStyle}
            style={{ minWidth: "50px" }}
          >
            Or
          </Typography>
          <TextField
            InputProps={{
              sx: {
                height: "40px",
                padding: "0 10px",
              },
            }}
            variant="outlined"
            fullWidth
            placeholder="Name"
            className={styles.studentTopFilterKeywordInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleFilter();
              }
            }}
            onChange={(event) =>
              setFilterData((prevState) => ({
                ...prevState,
                name: event.target.value,
              }))
            }
          />
        </Grid>

        <Grid
          item
          xs={1}
          sx={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}
        >
          <Button
            sx={{
              backgroundColor: "rgba(0, 28, 40, 1)",
              color: "#fff",
              height: "40px",
              "&:hover": {
                backgroundColor: "rgba(0, 28, 40, 1)",
                color: "#fff",
                opacity: "0.8",
              },
            }}
            onClick={() => {
              handleFilter();
            }}
          >
            Go
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterBase;
