import { Box, Grid, Button, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import Datatable from "../../../../components/dataTables/DataTable";
import "react-datepicker/dist/react-datepicker.css"; // Import the DatePicker CSS
import AdminApi from "../../../../config/apis/admin";

import {
  formatDate,
  getTimeDifference,
} from "../../../../helper/helperFunction";
import DatePicker from "react-datepicker";
import styles from "./style.module.css";
import Tooltip from "@mui/material/Tooltip";

export default function Notification() {
  const tableColumns = [
    {
      selector: (row) => (
        <Tooltip
          title={`${row.owner_name} ${row.event} ${row.module} ${row.module_element} with ID ${row.effected_module_id}.`}
          arrow
        >
          <span>{`${row.owner_name} ${row.event} ${row.module} ${row.module_element} with ID ${row.effected_module_id}.`}</span>
        </Tooltip>
      ),
      name: "",
      sortable: false,
    },
    {
      selector: (row) => (
        <Tooltip title={getTimeDifference(row.created_at)} arrow>
          <span>{getTimeDifference(row.created_at)}</span>
        </Tooltip>
      ),
      name: "",
      sortable: false,
    },
  ];

  const [filterData, setFilterData] = useState({});

  const [filters, setFilters] = useState({});

  const handleFilter = () => {
    setFilters({
      from_date: formatDate(filterData.from_date),
      to_date: formatDate(filterData.to_date),
    });
  };
  const [order, setOrder] = useState("descending");

  return (
    <>
      <Box className="container">
        <Box class="course-content">
          <h1 class="main-heading">Notifications</h1>
        </Box>

        <Grid
          container
          marginBottom={4}
          className={styles.Notifications_wrap_filter}
        >
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              paddingRight: "10px",
            }}
          >
            <DatePicker
              dateFormat="dd-MM-yyyy"
              required
              id="outlined-required"
              fullWidth
              name="from_date"
              selected={
                filterData.from_date ? new Date(filterData.from_date) : null
              }
              onChange={(date) =>
                setFilterData((prevFormData) => ({
                  ...prevFormData,
                  from_date: date,
                }))
              }
              showTimeSelect={false}
              size="small"
              placeholderText="Date From"
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              paddingRight: "10px",
            }}
          >
            <DatePicker
              dateFormat="dd-MM-yyyy"
              required
              id="outlined-required"
              fullWidth
              selected={
                filterData.to_date ? new Date(filterData.to_date) : null
              }
              name="to_date"
              onChange={(date) =>
                setFilterData((prevFormData) => ({
                  ...prevFormData,
                  to_date: date,
                }))
              }
              showTimeSelect={false}
              size="small"
              placeholderText="Date To"
            />
          </Grid>

          <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                backgroundColor: "rgba(0, 28, 40, 1)",
                color: "#fff",
                height: "40px",
                "&:hover": {
                  backgroundColor: "rgba(0, 28, 40, 1)",
                  color: "#fff",
                  opacity: "0.8",
                },
              }}
              onClick={() => {
                handleFilter();
              }}
            >
              Go
            </Button>
          </Grid>
          <Select
            sx={{ minWidth: "135px", marginLeft: "auto" }}
            value={order}
            onChange={(event) => setOrder(event.target.value)}
          >
            <MenuItem value="ascending">Ascending</MenuItem>
            <MenuItem value="descending">Descending</MenuItem>
          </Select>
        </Grid>
        <Grid container spacing={2} className={styles.Notifications_wrap}>
          <Grid item xs={12} padding={0} style={{ padding: "0" }}>
            <Datatable
              tableColumns={tableColumns}
              dataUrl={AdminApi.DASH_TOP_NOTIFICATIONS}
              actionColumns={false}
              module="manage-notifications"
              pagination={true}
              filters={filters}
              sortOrder={order}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
