import FilterBase from "../../../components/filterJob/filter-base1";
import { Box, Select, MenuItem, Grid } from "@mui/material";
import styles from "./style.module.css";
import Datatable from "../../../components/dataTables/DataTable";
import StudentApi from "../../../config/apis/student";
import { useState } from "react";
import Moment from "react-moment";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

export const StudentAppliedJobs = () => {
  const [filters, setFilters] = useState();
  const [currentValue, setCurrentValue] = useState("desc");

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  const tableColumns = [
    {
      selector: (row) => (
        <Tooltip title={row.company_name} arrow>
          <div>{row.company_name}</div>
        </Tooltip>
      ),
      name: "Company",
      width: "250px",
    },
    {
      selector: (row) => (
        <Tooltip title={row.job_title} arrow>
          <div>{row.job_title}</div>
        </Tooltip>
      ),
      name: "Position",
    },
    {
      selector: (row) => (
        <Stack direction="row" spacing={1}>
          <Chip
            label={row.job_type}
            style={{ backgroundColor: "#043B53", color: "#F8F8F9" }}
          />
        </Stack>
      ),
      name: "Job status",
    },
    {
      selector: (row) => (
        <Moment format="MMM, DD YYYY">{row.applied_on}</Moment>
      ),
      name: "Applied on",
    },
    {
      selector: (row) => row.salary,
      name: "Salary",
    },
  ];

  const nevigate = useNavigate();

  const onView = (id) => {
    nevigate(`/admin/jobs/view/${id}`);
  };

  return (
    <>
      <Box className="container">
        <Box className="course-content">
          <h1 className="main-heading">Applied Jobs</h1>
        </Box>
        <Box className={styles.display_left}>
          <FilterBase
            onFiltersChange={handleFiltersChange}
            showIdField={false}
          />
          <Box className={styles.display_right}>
            <Box>
              <Select
                value={currentValue}
                onChange={handleChange}
                className="select-menu"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
        <Box
          className={`page-container table-phases global_font_style ${styles.table_wrap}`}
          marginTop={5}
        >
          <Datatable
            tableColumns={tableColumns}
            dataUrl={StudentApi.APPLIED_JOB_LIST}
            sortOrder={currentValue}
            filters={filters}
            onView={onView}
            actions={{
              edit: false,
              delete: false,
            }}
            module="manage-student-applied-job"
          />
        </Box>
      </Box>
    </>
  );
};
