import {
  Typography,
} from "@mui/material";
import { toTitleCase } from "../../../../helper/helperFunction";
import Datatable from "../../../../components/dataTables/DataTable";
import AdminApi from "../../../../config/apis/admin";
import { api, resolveUrl } from "../../../../config/apis";
import { useState } from "react";
import ImageView from "../../../../components/imageShow";
import "../style.css"
import Moment from "react-moment";

export default function ViewAssignmentsMarksDetail(params) {

  const [tableData, setTableData] = useState([]);

  const getMaxCriteriaCount = (data) => {
    let maxCount = 0;
    data.forEach((row) => {
      if (row.scoring_criteria && row.scoring_criteria.length > maxCount) {
        maxCount = row.scoring_criteria.length;
      }
    });
    return maxCount;
  };

  // Get the maximum number of criteria in your data
  const maxCriteriaCount = getMaxCriteriaCount(tableData);

  // Create an array to hold the columns for scoring criteria
  const scoringCriteriaColumns = [];
  for (let i = 0; i < maxCriteriaCount; i++) {
    scoringCriteriaColumns.push({
      cell: (row) => {
        const criteria = row.scoring_criteria[i];
        return criteria ? criteria.score : "";
      },
      name: tableData[0].scoring_criteria[i]?.title,
      sortable: false,
      width: "200px",
    });
  }
  const tableColumns = [
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.name} />
          <Typography variant="body2" component="span" className="fontStyleName">
            {toTitleCase(`${row.name}`)}
          </Typography>
        </>
      ),
      name: "Submitted By",
      sortable: false,
      width: "300px",
    },
    ...scoringCriteriaColumns,
    {
      selector: (row) => (
        <Moment format="MMM, DD YYYY">{row.submitted_on}</Moment>
        ), 
      name: "Submitted on",
      sortable: false,
      width: "150px",
    },
    {
      selector: (row) => (
        <>
          <Typography
            variant="body2"
            component="span"
            style={{
              color:
                row.obtained_marks === row.totalMarks / 2 && row.obtained_marks !== 0
                  ? "#C33F28"
                  : row.obtained_marks > row.totalMarks / 2
                    ? "#00CF53"
                    : "#001C28",
              fontWeight:"700",
              fontSize:"16px"
            }}
          >
          {row.obtained_marks} 
          <span className="markObtain" > /{row.totalMarks}</span>
          </Typography>
        </>
      ),
      name: "Marks Obtain",
      sortable: false,
      width: "150px",
    },
    {
      cell: (row) => (
        <>
          <Typography
            variant="body2"
            component="span"
            style={{
              color:
                row.percentage <= 50 && row.percentage !== 0
                  ? "#C33F28"
                  : row.percentage > 50
                    ? "#00CF53"
                    : "#001C28",
            }}
          >
            <span className="fontStyle">{row.percentage}%</span>
          </Typography>
        </>
      ),
      name: "%",
      sortable: false,
      width: "100px",
    },
    {
      selector: (row) => row.scale_by_max,
      name: "Scaled by max",
      sortable: false,
    },
  ];


  return (
    <>
      <div>
        <div className="page-container table-phases" style={{ padding: "30px 15px 0px" }}>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={resolveUrl(
              AdminApi.ASSIGNMENT_MARKS_MODULE_PAGINATION,
              params.id
            )}
            setTableData={setTableData}
            module="manage-courses"
            actionColumns={false}
          />
        </div>
      </div>


    </>
  );
}
