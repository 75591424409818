import React from "react";
import useGenrateReport from "./useGenrateReport";
import { Button, CircularProgress } from "@mui/material";

const GenerateNow = ({ courseState, courseFilter, columnsWithIds }) => {
  const { handleSubmit, isLoading } = useGenrateReport({ columnsWithIds });

  return (
    <Button
      onClick={() => handleSubmit({ courseState, courseFilter })}
      disabled={isLoading}
      style={{ position: "relative" }}
    >
      {isLoading && (
        <CircularProgress
          size={24}
          color="inherit"
          style={{
            position: "absolute",
            top: "50%",
            right: "14px",
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
      Generate Now
    </Button>
  );
};
export default GenerateNow;
