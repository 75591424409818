import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";
import styles from "./styles.module.css";

const CurrencyAlertForm = ({ toggleModal }) => {
  const [formData, setFormData] = useState([]);

  const [errors, setErrors] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirm, setConfirm] = useState(true);

  const schema = yup.object().shape({
    currencyRate: yup
      .array()
      .of(
        yup.object().shape({
          value: yup
            .number()
            .nullable(false)
            .required("Value should not be empty"),
        })
      )
      .required(),
  });
  const customValidation = async () => {
    try {
      await schema.validate(updateFormData, { abortEarly: false });
      setErrors({});
      const newErrors = {};

      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleFormSuccess = async (response) => {
    if (response) {
      toggleModal();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(AdminApi.CURRENCY_ADD_LIST);
        setFormData(res.data.data ?? []);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  const handleChange = (id, e) => {
    const updatedFormData = formData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value: e.target.value,
        };
      }
      return item;
    });
    setFormData(updatedFormData);
  };

  const updateFormData = {
    currencyRate: formData.map((item) => ({
      id: item.id,
      value: item.value ? parseFloat(item.value) : null,
    })),
  };

  // const confirmAction = () => {
  //   var result = window.confirm("Are you sure you want to proceed?");
  //   if (result) {
  //     alert("You clicked OK!");
  //   } else {
  //     alert("You clicked Cancel!");
  //   }
  // };
  return (
    <>
      <Box>
        <Box sx={{ width: "100%" }}>
          <Grid container className="page-container">
            <Grid item xs={12} className="inner-container">
              <h2 className={styles.Currency_heding}>Currency Rate</h2>
              <Form
                submitUrl={AdminApi.CURRENCY_RATE_UPDATE}
                formData={updateFormData}
                onSuccess={handleFormSuccess}
                validate={customValidation}
                // setConfirmModal={confirmAction()}
              >
                <Grid
                  item
                  xs={12}
                  container
                  sx={{ mb: 3, alignItems: "center" }}
                >
                  {formData.map((item, index) => (
                    <>
                      <Grid item xs={2}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          1 USD =
                        </InputLabel>
                      </Grid>
                      <Grid item xs={10} className={styles.symbol_wrap}>
                        <TextField
                          key={item.id}
                          value={item.value}
                          onChange={(e) => handleChange(item.id, e)}
                          fullWidth
                          margin="normal"
                          type="number"
                          error={!!errors[`currencyRate[${index}].value`]}
                          helperText={errors[`currencyRate[${index}].value`]}
                        />
                        <span className={styles.symbol}>{item.symbol}</span>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Dialog
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{ textAlign: "center", padding: "20px" }}
          className="action_btn"
        >
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to Update?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button className="btn save" onClick={() => setConfirmModal(false)}>
              Cancel
            </Button>
            <Button
              className="btn cancel"
              autoFocus
              onClick={() => setConfirm(false)}
            >
              Okay
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CurrencyAlertForm;
