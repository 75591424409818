import DynamicTabs from "../../../components/Tabs/Tabs";
import { Box } from "@mui/material";
import ViewPhases from "../manage-phases/view";
import ViewRoles from "../manage-roles/view";
import ViewCurrency from "../manage-currency/view";
import ChangePassword from "../manage-change-password/view";
import ViewShift from "../manage-shifts/view";
import ViewAdmin from "../manage-admins/view";
import ViewLocation from "../manage-location/view";
import ViewCohorts from "../manage-cohorts/view";
import SettingGeneral from "../manage-general/view";
import StudentProfile from "../../student/manage-profile/details";
import {
  HasPermission,
  HasPermissionTab,
} from "../../../helper/helperFunction";
import { useAuth } from "../../../hooks/auth/auth";

function Settings() {
  const { state } = useAuth();

  const tabs = [
    {
      label: "General",
      content: (
        <HasPermission permission="manage-general.view">
          <SettingGeneral />
        </HasPermission>
      ),
    },
    {
      label: "Admins",
      content: (
        <HasPermission permission="manage-admins.view">
          <ViewAdmin />
        </HasPermission>
      ),
    },
    {
      label: "Change Password",
      content: <ChangePassword />,
    },
    {
      label: "Role management",
      content: (
        <HasPermission permission="manage-roles.view">
          <ViewRoles />
        </HasPermission>
      ),
    },
    {
      label: "Phases",
      content: (
        <HasPermission permission="manage-phases.view">
          <ViewPhases />
        </HasPermission>
      ),
    },
    // {
    //   label: "Cohorts",
    //   content: (
    //     <HasPermission permission="manage-cohorts.view">
    //       <ViewCohorts />
    //     </HasPermission>
    //   ),
    // },
    {
      label: "Shifts",
      content: (
        <HasPermission permission="manage-shifts.view">
          <ViewShift />
        </HasPermission>
      ),
    },
    {
      label: "Locations",
      content: (
        <HasPermission permission="manage-locations.view">
          <ViewLocation />
        </HasPermission>
      ),
    },
    {
      label: "Currency",
      content: (
        <HasPermission permission="manage-currency.view">
          <ViewCurrency />
        </HasPermission>
      ),
    },
  ];

  if (state.userRole === "Student") {
    const studentProfile = {
      label: "Profile",
      content: (
        <HasPermission permission="manage-student-profile.view">
          <StudentProfile />
        </HasPermission>
      ),
    };
    tabs.push(studentProfile);
  }

  const allowedTabs = tabs.filter((tab) => {
    const hasPermission = HasPermissionTab(tab.content.props.permission);
    return (
      tab.content !== null &&
      (tab.content.props.permission ? hasPermission : true)
    );
  });

  return (
    <>
      <Box className="container settings">
        <h1>Settings</h1>
        <DynamicTabs tabs={allowedTabs} />
      </Box>
    </>
  );
}

export default Settings;
