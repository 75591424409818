import { Typography, Grid, Divider } from "@mui/material";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageView from "../../../components/imageShow";
import GlobalModal from "../../../components/modalBox";
import CircularProgress from "@mui/material/CircularProgress";
import {GetUserName} from "../../../helper/helperFunction"

export default function ViewAssignmentsDetail({
  viewId,
  handleCloseModal,
  viewDetailOpen,
  reRender,
}) {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [viewDetail, setViewDetail] = useState();

  const loginUser = GetUserName();

  useEffect(() => {
    const fetchViewData = async () => {
      if (viewId) {
        try {
          const res = await api.get(
            resolveUrl(AdminApi.STUDENT_SUBMITED_ASSIGNMENT_VIEW, params.id),
            {
              params: {
                student_id: viewId,
              },
            }
          );

          const updatedFormData = {
            feedback: res.data.data.feedback,
            name: res.data.data.name,
            obtained_marks: res.data.data.obtained_marks,
            totalMarks: res.data.data.totalMarks,
            student_id: res.data.data.id,
          };
          setViewDetail(updatedFormData);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchViewData();
  }, [viewId, reRender, params.id]);

  useEffect(() => {
    if (!viewDetailOpen) {
      setIsLoading(true);
    }
  }, [viewDetailOpen]);

  return (
    <>
      {viewId && (
        <>
          <GlobalModal
            className="assignment_Detail_form"
            open={viewDetailOpen}
            onClose={handleCloseModal}
            width={1400}
            padding="0"
          >
            {isLoading ? (
              <Grid
                className="loader"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "400px",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              viewDetail && (
                <>
                  <Grid
                  marginTop={5}
                    item
                    xs={12}
                    container
                    sx={{
                      justifyContent: "space-between",
                      padding: "16px 32px 24px",

                    }}
                  >
                    <Grid>
                      <Grid sx={{ display: "flex", alignItems: "center" }}>
                        <ImageView
                          imageName={viewDetail.profile_image}
                          name={viewDetail.name ?? ""}
                          width="70px"
                          height="70px"
                        />
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{
                            color: "#001C28",
                            fontFamily: "Poppins",
                            fontSize: "22px",
                            fontWeight: 700,
                            lineHeight: 1,
                          }}
                        >
                          {viewDetail.name ?? ""}
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              width: "100%",
                              display: "inline-block",
                              fontFamily: "Poppins",
                            }}
                          >
                            #{viewDetail.student_id}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          color: "#001C28",
                          fontFamily: "Poppins",
                          fontSize: "32px",
                          fontWeight: "700",
                          lineHeight: "38px",
                          display: "inline-block",
                          textAlign: "end",
                          width: "100%",
                        }}
                      >
                        {`${viewDetail.obtained_marks}/${viewDetail.totalMarks}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "400",
                          width: "100%",
                          display: "inline-block",
                          fontFamily: "Poppins",
                          textAlign: "end",
                        }}
                      >
                        Done
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  {viewDetail && viewDetail.feedback ? (
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{
                        justifyContent: "space-between",
                        padding: "16px 32px 24px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="h3"
                        sx={{
                          fontSize: "22px",
                          color: "#001C28",
                          fontWeight: "700",
                          width: "100%",
                          display: "inline-block",
                          lineHeight: 2,
                          fontFamily: "Poppins",
                        }}
                      >
                        Feedback
                      </Typography>

                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#29292908",
                          padding: "25px",
                        }}
                        container
                      >
                        <Grid item xl={1} lg={1.2} sm={1.4}>
                          <ImageView
                            imageName={loginUser}
                            name={loginUser}
                            width="85px"
                            height="85px"
                          />
                        </Grid>
                        <Grid item xl={11} lg={10.8} sm={10.6}>
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              color: "#001C28",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: 700,
                              lineHeight: 1.5,
                              display: "block",
                            }}
                          >
                            Admin
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                width: "100%",
                                display: "inline-block",
                                fontFamily: "Poppins",
                                lineHeight: 1.5,
                              }}
                            >
                              AUGUST 5, 2020
                            </Typography>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="p"
                            sx={{
                              color: "#001C28",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "28px",
                            }}
                          >
                            {viewDetail && viewDetail.feedback}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              )
            )}
          </GlobalModal>
        </>
      )}
    </>
  );
}
