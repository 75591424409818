import {
  Typography,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from "@mui/material";
import Datatable from "../../../components/dataTables/DataTable";
import AdminApi from "../../../config/apis/admin";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./manage-transactions.module.css";
import Moment from "react-moment";
import { resolveUrl } from "../../../config/apis";
import GlobalModal from "../../../components/modalBox";
import { useState, useEffect } from "react";
import SelectOptionsApi from "../../../components/SelectApi/index";
import Form from "../../../components/forms/Forms";
import * as yup from "yup";

export const StuTransactionsGrid = () => {
  const navigate = useNavigate();
  const params = useParams();

  const tableColumns = [
    {
      cell: (row) => (
        <Typography variant="body3" fontWeight={"700"}>
          #{row.id}
        </Typography>
      ),
      name: "ID",
      sortable: true,
      selector: "id",
    },

    {
      cell: (row) => (
        <Typography
          variant="body3"
          fontWeight={"700"}
          sx={{ textTransform: "capitalize" }}
        >
          {row.type}
        </Typography>
      ),
      name: "Payment Type",
      sortable: true,
      selector: "type",
    },
    {
      cell: (row) => (
        <Typography variant="body3" fontWeight={"700"}>
          {row.date_time && (
            <Moment format="MMM DD, YYYY hh:mmA">{row.date_time}</Moment>
          )}
        </Typography>
      ),
      name: "Date / Time",
      sortable: true,
      selector: "date_time",
    },
    {
      cell: (row) => (
        <Typography variant="body3" fontWeight={"700"}>
          {row.amount} {row.currency_symbol}
        </Typography>
      ),
      name: "Amount",
      sortable: true,
      selector: "amount",
    },
    {
      selector: (row) => (
        <>
          {row && row.type === "registration" && (
            <Button
              disabled={row.refund_status === "Refunded" ? true : false}
              style={row.refund_status === "Refunded" ? { opacity: "0.5" } : {}}
              onClick={() =>
                onRefund(row.currency_id, row.student_id, row.section_id)
              }
              className={styles.refundButton}
            >
              {row.refund_status === "Refunded" ? "Refunded" : "Refund"}
            </Button>
          )}
        </>
      ),
      name: "",
      sortable: true,
    },
  ];

  const onView = async (id) => {
    navigate(`/admin/transactions/view/${id}`);
  };

  const onRefund = async (currency_id, student_id, section_id) => {
    setFormData({
      student_id: student_id,
      section_id: section_id,
      currency_id: currency_id,
    });
    setModalOpen(true);
  };

  const defaultFormData = {
    student_id: null,
    section_id: null,
    refund_amount: null,
    currency_id: null,
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const handleCloseModal = () => {
    setModalOpen(false);
    setFormData({ defaultFormData });
  };
  const [errors, setErrors] = useState({});
  const handleUpdate = (name, value) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleFeeUpdate = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      refund_amount: value ? Number(value) : "",
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  const schema = yup.object().shape({
    refund_amount: yup.string().required("This field required"),
    currency_id: yup.string().required("This field required"),
  });
  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      const newErrors = {};
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error) {
      const newErrors = {};

      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };
  useEffect(() => {
    handleCloseModal();
  }, [formSuccess]);

  return (
    <>
      <Box
        className={`page-container table-phases ${styles.studentTransaction}`}
        sx={{ padding: "0 15px" }}
      >
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(
            AdminApi.STUDENT_TRANSACTIONS_PAGINATION,
            params.id
          )}
          onView={onView}
          actions={{
            view: true,
            edit: false,
            delete: false,
          }}
          refreshHandle={formSuccess}
          module="manage-transactions"
        />
        <GlobalModal open={modalOpen} onClose={handleCloseModal} width={400}>
          <Form
            successPath={`/admin/user/student/view/${params.id}`}
            submitUrl={AdminApi.STUDENT_TRANSACTIONS_REFUND}
            formData={formData}
            setFormSuccess={setFormSuccess}
            validate={customValidation}
            customClass={styles.tractionForm}
          >
            <Grid
              container
              justifyContent={"space-between"}
              sx={{ marginBottom: 2 }}
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={12}>
                  <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                    Student ID*
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    InputProps={{
                      sx: {
                        height: "40px",
                        width: "100%",
                      },
                    }}
                    variant="outlined"
                    value={formData.student_id}
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} container sx={{ alignItems: "center" }}>
                <Grid item xs={12}>
                  <InputLabel sx={{ textAlign: "left", marginBottom: 2 }}>
                    Fees *
                  </InputLabel>
                </Grid>
                <Grid container item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                    className="currncy_ammount"
                  >
                    <Box
                      sx={{
                        maxWidth: "220px",
                      }}
                      className="SelectOptionsApi"
                    >
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.CURRENCY_VIEW}
                        formData={formData}
                        onUpdate={handleUpdate}
                        name="currency_id"
                        labelKey="symbol"
                        valueKey="id"
                        defaultText="Currency"
                        customStyle={{ border: "0" }}
                      />
                    </Box>
                    <TextField
                      className="registration_fee"
                      fullWidth
                      name="refund_amount"
                      variant="outlined"
                      placeholder="fee"
                      value={formData.refund_amount}
                      InputProps={{
                        sx: {
                          height: "40px",
                          padding: "0 10px",
                        },
                      }}
                      inputProps={{
                        type: "number",
                        pattern: "[0-9]*",
                      }}
                      onChange={handleFeeUpdate}
                      error={errors.refund_amount !== undefined}
                    />
                  </Box>
                </Grid>
              </Grid>
              {errors.refund_amount && (
                <FormHelperText error>{errors.refund_amount}</FormHelperText>
              )}
            </Grid>
          </Form>
        </GlobalModal>
      </Box>
    </>
  );
};
