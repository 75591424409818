import Datatable from "../../../../components/dataTables/DataTable";
import AdminApi from "../../../../config/apis/admin";
import styles from "./style.module.css";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import {
  getStartAndEndOfMonth,
  getStartAndEndOfWeek,
} from "../../../../helper/helperFunction";
import { Box, Grid, Typography, Button } from "@mui/material";

export default function TopLocations() {
  const [activeButton, setActiveButton] = useState("soFar");
  const [filters, setFilters] = useState();
  const tableColumns = [
    {
      selector: (row) => (
        <Tooltip title={row.LocationName} arrow>
          <Typography variant="body1" className={styles.Area}>
            {row.LocationName}
          </Typography>
        </Tooltip>
      ),
      name: "Area",
      sortable: false,
    },
    {
      selector: (row) => (
        <Tooltip title={row.StudentCount} arrow>
          <Typography variant="body1" className={styles.StudentCount}>
            {row.StudentCount}
          </Typography>
        </Tooltip>
      ),
      name: "Students Enrolled",
      sortable: false,
    },
  ];
  const handleCurrentMonth = () => {
    const { startOfMonth, endOfMonth } = getStartAndEndOfMonth();

    setFilters({
      from_date: startOfMonth,
      to_date: endOfMonth,
    });
    setActiveButton("currentMonth");
  };
  const handleThisWeek = () => {
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();

    setFilters({
      from_date: startOfWeek,
      to_date: endOfWeek,
    });
    setActiveButton("thisWeek");
  };
  const handleSoFar = () => {
    setFilters();
    setActiveButton("soFar");
  };
  const handleCurrentYear = () => {
    setFilters({
      year: new Date().getFullYear(),
    });
    setActiveButton("currentYear");
  };
  return (
    <>
      <Box className={styles.topskillsMain}>
        <Grid item xs={12} className={styles.totalJobsContainer_}>
          <Box>
            <Typography variant="h3" className={styles.fs_h3}>
              Top Locations
            </Typography>{" "}
          </Box>
          <Box className={styles.btn_wrapper}>
            <Button
              className={`${styles.btns} ${
                activeButton === "soFar" ? styles.active : ""
              }`}
              onClick={handleSoFar}
            >
              So Far
            </Button>
            <Button
              className={`${styles.btns} ${
                activeButton === "thisWeek" ? styles.active : ""
              }`}
              onClick={handleThisWeek}
            >
              This week
            </Button>
            <Button
              className={`${styles.btns} ${
                activeButton === "currentMonth" ? styles.active : ""
              }`}
              onClick={handleCurrentMonth}
            >
              This Month
            </Button>
            <Button
              className={`${styles.btns} ${
                activeButton === "currentYear" ? styles.active : ""
              }`}
              onClick={handleCurrentYear}
            >
              This Year
            </Button>
          </Box>
        </Grid>
        <div
          className="page-container table-phases"
          style={{ paddingBottom: "0" }}
        >
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.DASH_TOP_LOCATIONS}
            actionColumns={false}
            module="manage-job-skills"
            pagination={false}
            filters={filters}
          />
        </div>
      </Box>
    </>
  );
}
