import { Grid, Box, Select, MenuItem } from "@mui/material";
import AdminApi from "../../../config/apis/admin";
import { useState } from "react";
import { Link } from "react-router-dom";
import FilterBase from "../../../components/filter/filter-base";
import CSVDownloadButton from "../../../components/downloadCSV";
import { SupportTicketsGrid } from "./SupportTable";
import { HasPermission } from "../../../helper/helperFunction";

export default function ViewSupportTicket() {
  const [filters, setFilters] = useState();

  const handleFiltersChange = (filterState) => {
    setFilters(filterState);
  };

  const [currentValue, setCurrentValue] = useState("desc");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="container transaction_module">
        <div className="course-content">
          <h1 className="main-heading"> Support Tickets </h1>
          <HasPermission permission="manage-transactions.create">
            <Box className="btn-wrapper">
              <Link
                to={"#"}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Add Tickets
              </Link>
            </Box>
          </HasPermission>
        </div>

        <Grid container marginBottom={2}>
          <Grid item xl={8.5} xs={12}>
            <FilterBase onFiltersChange={handleFiltersChange} />
          </Grid>
          <Grid
            item
            xl={3.5}
            xs={12}
            marginTop={{ xl: 0, xs: 2 }}
            display="flex"
            justifyContent="flex-end"
          >
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
                justifyContent: "flex-end",
              }}
            >
              <CSVDownloadButton
                apiEndpoint={AdminApi.TRANSACTIONS_CSV}
                params={currentValue}
                fileName={"Transactions"}
              />
              <Select
                value={currentValue}
                onChange={handleChange}
                sx={{ height: "43px", marginLeft: "20px" }}
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <SupportTicketsGrid
          filters={filters}
          currentValue={currentValue}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </div>
    </>
  );
}
