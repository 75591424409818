import { Typography, Box, Grid, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./manage-assignment.module.css";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { DateView } from "../../../components/DateView/DateView";
import ViewAssignmentsMarks from "../manage-assignment-marks/view";
import ImageView from "../../../components/imageShow";

// SECTION MODULE
export default function AssignmentDetail() {
  const params = useParams();
  const [assignmentInfo, setAssignmentInfo] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id) {
          const res = await api.get(
            resolveUrl(AdminApi.ASSIGNMENT_MODULE_DETAIL, params.id)
          );
          setAssignmentInfo(res.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [params.id]);

  return (
    <>
      <div className="container">
        <h1>{assignmentInfo && assignmentInfo?.course_name}</h1>
        <Box
          className={styles.courseDetailFlex}
          mb={"80px"}
          sx={{ width: "100%" }}
        >
          <Box
            className={styles.courseDetailFlexContent}
            sx={{ width: "100%" }}
          >
            <Box className={styles.courseDetailFlexHeading}>
              <h2>{assignmentInfo && assignmentInfo?.assignment_title}</h2>
            </Box>
            <Typography variant="p" gutterBottom className={styles.coude}>
              <span className="bold">
                {assignmentInfo && assignmentInfo?.assignment_description}
              </span>
            </Typography>
            <Box>
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <Grid sx={{ display: "flex", alignItems: "center" }}>
                  <ImageView
                    imageName={assignmentInfo && assignmentInfo?.profile_image}
                    name={"john"}
                    width="35px"
                    height="35px"
                    marginRight="10px"
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: 1,
                      marginRight: "15px",
                    }}
                  >
                    {assignmentInfo && assignmentInfo?.teacher_name}
                  </Typography>
                </Grid>
                |
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    color: "#001C28",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: 1,
                    marginRight: "15px",
                    marginLeft: "15px",
                  }}
                >
                  {assignmentInfo && assignmentInfo?.cohort_name}
                </Typography>
                |
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    color: "#001C28",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: 1,
                    marginRight: "15px",
                    marginLeft: "15px",
                  }}
                >
                  {assignmentInfo && assignmentInfo?.shift_name}
                </Typography>
                {assignmentInfo && assignmentInfo?.assessment_type && (
                  <>
                    |
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        color: "#001C28",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: 1,
                        marginRight: "15px",
                        marginLeft: "15px",
                      }}
                    >
                      {assignmentInfo && assignmentInfo?.assessment_type}
                    </Typography>
                  </>
                )}
              </Grid>
            </Box>
            <Divider
              sx={{
                margin: "0 -50px",
                paddingTop: 2.5,
              }}
            />

            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 3,
              }}
            >
              {assignmentInfo && assignmentInfo?.assessment_type && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "10px",
                  }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: 1,
                      marginBottom: 1,
                    }}
                  >
                    Type
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: 700,
                      lineHeight: "15.6px",
                    }}
                  >
                    {assignmentInfo && assignmentInfo?.assessment_type}
                  </Typography>
                </Grid>
              )}
              {assignmentInfo && assignmentInfo?.assignment_start && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 10px",
                  }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: 1,
                      marginBottom: 1,
                    }}
                  >
                    Started on
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: 700,
                      lineHeight: "15.6px",
                    }}
                  >
                    {
                      <DateView
                        date={
                          assignmentInfo && assignmentInfo?.assignment_start
                        }
                      />
                    }
                  </Typography>
                </Grid>
              )}
              {assignmentInfo && assignmentInfo?.assignment_due && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 10px",
                  }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: 1,
                      marginBottom: 1,
                    }}
                  >
                    Due on
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: 700,
                      lineHeight: "15.6px",
                    }}
                  >
                    {
                      <DateView
                        date={assignmentInfo && assignmentInfo?.assignment_due}
                      />
                    }
                  </Typography>
                </Grid>
              )}
              {assignmentInfo && assignmentInfo?.assigned && (
                <Grid sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: 1,
                      marginBottom: 1,
                    }}
                  >
                    Assigned
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: 700,
                      lineHeight: "15.6px",
                    }}
                  >
                    {assignmentInfo && assignmentInfo?.assigned}
                  </Typography>
                </Grid>
              )}
              {assignmentInfo && assignmentInfo?.assessment_type && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 10px",
                  }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: 1,
                      marginBottom: 1,
                    }}
                  >
                    Summited By
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: 700,
                      lineHeight: "15.6px",
                    }}
                  >
                    {assignmentInfo && assignmentInfo?.assessment_type}
                  </Typography>
                </Grid>
              )}
              {assignmentInfo && assignmentInfo?.assessment_type && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 10px",
                  }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: 1,
                      marginBottom: 1,
                    }}
                  >
                    Marked
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: 700,
                      lineHeight: "15.6px",
                    }}
                  >
                    {assignmentInfo && assignmentInfo?.assessment_type}
                  </Typography>
                </Grid>
              )}
              {assignmentInfo && assignmentInfo?.total_number && (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 10px",
                  }}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: 1,
                      marginBottom: 1,
                    }}
                  >
                    Total Numbers
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      color: "#001C28",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: 700,
                      lineHeight: "15.6px",
                    }}
                  >
                    {assignmentInfo && assignmentInfo?.total_number}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <ViewAssignmentsMarks />
      </div>
    </>
  );
}
