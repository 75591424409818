import { Box, Grid } from "@mui/material";
import styles from "./style.module.css";
import { Tabs } from "./job-detail-tabs/tabs";
import { HasPermission, IsStudent } from "../../../helper/helperFunction";
import { JobsDetailsTab } from "./job-details/job-detail-tab";

export const JobsDetailsView = () => {
  const userIsStudent = IsStudent();

  return (
    <>
      <Box className="container settings">
        <Box className="course-content">
          <h1 className="main-heading">Job Details </h1>
        </Box>
        {userIsStudent ? (
          <HasPermission permission="manage-jobs-board.view">
            <Grid
              container
              marginTop={2}
              spacing={2}
              justifyContent={"space-between"}
              sx={{
                background: "#fff",
                boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.08)",
              }}
            >
              <JobsDetailsTab />
            </Grid>
          </HasPermission>
        ) : (
          <Grid
            container
            marginTop={2}
            spacing={2}
            justifyContent={"space-between"}
          >
            <Tabs />
          </Grid>
        )}
      </Box>
    </>
  );
};
