import styles from "./style.module.css";

import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import SwitchCell from "../../../../components/switch";
import EditIcon from "@mui/icons-material/Edit";
import Moment from "react-moment";
import { HasPermission } from "../../../../helper/helperFunction";
import AdminApi from "../../../../config/apis/admin";

const JobSingleCard = ({ item, lg = 6 }) => {
  return (
    <Grid item xs={12} lg={lg} className={styles.col}>
      <Box className={styles.col_wrap}>
        <Link className={styles.Link} to={`view/${item.id}`}></Link>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h3">{item.job_title}</Typography>

          <Box
            zIndex={1}
            display={"flex"}
            className={styles.edit_wrap}
            alignItems={"center"}
          >
            <HasPermission permission="manage-jobs-board.create">
              <SwitchCell
                EndPoint={AdminApi.JOBS_STATUS}
                id={item.id}
                is_active={item.job_status}
              />
              <Button>
                <Link to={`edit/${item.id}`}>
                  <EditIcon style={{ color: "#001C28", fontSize: 15 }} />
                </Link>
              </Button>
            </HasPermission>
          </Box>
        </Box>
        <Typography variant="h4">{item.company_name}</Typography>
        <Typography variant="p">{item.company_overview}</Typography>
        <Typography variant="h6">
          <span>Qualification</span> : {item.qualification_name}
        </Typography>
        <Box className={styles.small_box_wrap}>
          <Box className={styles.small_box}>
            <span className={styles.key}>{item.applicant_count}</span>
            <span className={styles.value}>Applicants</span>
          </Box>
          <Box className={styles.small_box}>
            <span className={styles.key}>{item.timings}</span>
            <span className={styles.value}>{item.job_type}</span>
          </Box>
          <Box className={styles.small_box}>
            <span className={styles.key}>Salary</span>
            <span className={styles.value}>
              {`${item.salary || "-"} ${item.currency}`}
            </span>
          </Box>
          <Box className={styles.small_box}>
            <span className={styles.key}>
              <Moment format="MMM, DD YYYY">{item.last_date_apply}</Moment>
            </span>
            <span className={styles.value}>Last Date</span>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default JobSingleCard;
