import { api, resolveUrl } from "../../../../config/apis";
import AdminApi from "../../../../config/apis/admin";
import { useState, useEffect, useCallback } from "react";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";
import { useParams } from "react-router-dom";

export const useCreateCsv = (currentValue) => {
  const param = useParams();

  const [reportData, setReportData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(
        resolveUrl(AdminApi.JOBS_APPLICANTS, param.id),
        {
          params: {
            sort_order: currentValue,
          },
        }
      );

      const data = response.data.data ? response.data.data : [];

      const modifiedData = data.map((item) => {
        const { profile_image, resume, ...rest } = item;

        const Resume = `${SERVER_KEY}${resume}`;

        return { ...rest, Resume };
      });

      setReportData(modifiedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [currentValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { reportData };
};
