import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Style from "../style.module.css";
import { FilterComponent } from "./Filter";
import useDraggableTable from "./useDraggableTable";

function DragAndDropTable({ coloumData, updateCourseState, courseState }) {
  const { rowsWithIds, onDragEnd } = useDraggableTable({
    coloumData,
    updateCourseState,
  });

  return (
    <>
      {/* <DragDropContext onDragEnd={onDragEnd}>
        <TableContainer component={Paper} className={Style.table_wrap}>
          <Table aria-label="draggable table">
            <TableHead>
              <TableRow className={Style.table_row}>
                <TableCell sx={{ width: "30%" }}>Column</TableCell>

                <TableCell>Filters</TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId="rows">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {rowsWithIds &&
                    rowsWithIds.map((row, index) => (
                      <Draggable
                        key={row.id}
                        draggableId={row.id}
                        index={index}
                      >
                        {(provided) => (
                          <TableRow
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <TableCell sx={{ width: "30%" }}>
                              {row.label}
                            </TableCell>
                            <TableCell>
                              <FilterComponent
                                type={row.type}
                                options={row.options ?? []}
                                label={row.label}
                                courseState={courseState}
                                updateCourseState={updateCourseState}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </DragDropContext> */}
      <TableContainer component={Paper} className={Style.table_wrap}>
        <Table aria-label="draggable table">
          <TableHead>
            <TableRow className={Style.table_row}>
              <TableCell sx={{ width: "30%" }}>Selected Columns </TableCell>
              <TableCell>
                Filters (A filter is only needed for specific data)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsWithIds &&
              rowsWithIds.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ width: "30%" }}>{row.label}</TableCell>
                  <TableCell>
                    <FilterComponent
                      type={row.type}
                      options={row.options ?? []}
                      label={row.label}
                      courseState={courseState}
                      updateCourseState={updateCourseState}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default DragAndDropTable;
