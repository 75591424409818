import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datatable from "../../../components/dataTables/DataTable";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { Box } from "@mui/material";
import ShiftFormModal from "./modal";
import React from "react";
import { DateView } from "../../../components/DateView/DateView";
import { HasPermission } from "../../../helper/helperFunction";

export default function ViewShift() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState("");
  const [reRender, setReRender] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const tableColumns = [
    {
      selector: (row) => row.title,
      name: "Title",
      sortable: true,
    },
    {
      selector: (row) => {
        const shortenedDays = row.shift_datetimes.map((item) => {
          let shortenedDay = item.days;
          switch (item.days) {
            case "Monday":
              shortenedDay = "Mon";
              break;
            case "Tuesday":
              shortenedDay = "Tue";
              break;
            case "Wednesday":
              shortenedDay = "Wed";
              break;
            case "Thursday":
              shortenedDay = "Thu";
              break;
            case "Friday":
              shortenedDay = "Fri";
              break;
            case "Saturday":
              shortenedDay = "Sat";
              break;
            case "Sunday":
              shortenedDay = "Sun";
              break;
            default:
              break;
          }
          return shortenedDay;
        });

        if (
          shortenedDays.includes("Mon") &&
          shortenedDays.includes("Tue") &&
          shortenedDays.includes("Wed") &&
          shortenedDays.includes("Thu")
        ) {
          return <span>Mon - Thu</span>;
        }
        if (shortenedDays.days !== "undefined") {
          const filteredDays = shortenedDays.filter((day) => day !== "");
          const lastIndex = filteredDays.length - 1;

          return filteredDays.map((day, index) => (
            <span key={index}>
              {day}
              {index !== lastIndex && ","}{" "}
            </span>
          ));
        }
      },
      name: "Days",
      sortable: true,
    },
    {
      cell: (row) => <DateView date={row.created_at} />,
      selector: "created_at",
      name: "Created on",
      sortable: true,
    },
    {
      cell: (row) => <DateView date={row.updated_at} />,
      selector: "updated_at",
      name: "Edited on",
      sortable: true,
    },
  ];

  const onEdit = async (id) => {
    setViewId(id);
    if (id) {
      setIsModalOpen(true);
    }
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const onDelete = async (id) => {
    return await api.delete(resolveUrl(AdminApi.SHIFTS_DELETE, id));
  };

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);

  return (
    <>
      <div className="page-container table-phases">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.SHIFTS_PAGINATE}
          onEdit={onEdit}
          onDelete={onDelete}
          refreshHandle={reRender}
          actions={{
            view: false,
          }}
          module="manage-shifts"
        />
      </div>

      <HasPermission permission="manage-shifts.create">
        <div className="course_content">
          <Box className="btn-wrapper">
            <Link to={""} onClick={toggleModal}>
              Add Shifts
            </Link>
          </Box>
        </div>
      </HasPermission>
      <ShiftFormModal
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
